import { TPalette } from 'components/tailwind/pallete/ColorsDeclaration';
import { classNames } from 'helpers/classNames';

type CircleBadge = {
  color?: TPalette;
  className?: string;
};

const CircleBadge: React.FC<CircleBadge> = ({ children, color, className }) => {
  return (
    <div
      className={classNames(
        `flex max-h-[40px] min-h-[40px] min-w-[40px] max-w-[40px] items-center justify-center rounded-full bg-${color}-50`,
        className
      )}
    >
      {children}
    </div>
  );
};

export default CircleBadge;

// Libraries
import SimpleFilter, {
  TOptions
} from 'components/tailwind/filters/SimpleFilter';
import { useEffect, useState } from 'react';
import { Tag } from 'screens/dashboard/explore';

type ExploreHeaderRightProps = {
  handleOnClickTag: (newTag: Tag) => void;
};

const tags: Tag[] = [
  { id: 0, name: '💜 Todos los creadores', value: 'all' },
  { id: 1, name: '👾 Streamer', value: 'streamer' },
  { id: 2, name: '🎙 Podcaster', value: 'podcaster' },
  { id: 3, name: '🕺 TikToker', value: 'tiktoker' },
  { id: 4, name: '📽 YouTuber', value: 'youtuber' },
  { id: 5, name: '🧠 Mentor', value: 'mentor' },
  { id: 6, name: '🏃‍♀️ Fitness Coach', value: 'fitness-coach' },
  { id: 7, name: '📄 Blogger', value: 'blogger' },
  { id: 9, name: '🎶 Músico', value: 'musico' },
  { id: 8, name: '🖼  Artsy', value: 'artsy' }
];

const options: TOptions[] = [
  { textContent: '💜 Todos los creadores', value: 'all', defaultValue: true },
  { textContent: '👾 Streamer', value: 'streamer' },
  { textContent: '🎙 Podcaster', value: 'podcaster' },
  { textContent: '🕺TikToker', value: 'tiktoker' },
  { textContent: '📽 YouTuber', value: 'youtuber' },
  { textContent: '🧠 Mentor', value: 'mentor' },
  { textContent: '🏃‍♀️ Fitness Coach', value: 'fitness-coach' },
  { textContent: '📄 Blogger', value: 'blogger' },
  { textContent: '🎶 Músico', value: 'musico' },
  { textContent: '🖼 Artsy', value: 'artsy' }
];

const optionToTag = (option: TOptions): Tag => {
  return tags.find((tag) => tag.value === option.value);
};

const ExploreHeaderRight: React.FC<ExploreHeaderRightProps> = ({
  handleOnClickTag
}) => {
  const [selected, setSelected] = useState<TOptions>(options[0]);

  useEffect(() => {
    handleOnClickTag(optionToTag(selected));
  }, [selected]);

  return <SimpleFilter options={options} setValue={setSelected} />;
};

export default ExploreHeaderRight;

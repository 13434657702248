import MetaDefault from 'components/meta/MetaDefault';
import React, { Fragment } from 'react';

// Components
import AddImage from './addImage/Index';

// Components onboarding referred || verified
import Onboarding from './verified/index';

interface IOnboardingIndexProps {
  step: number;
  is_verified: boolean;
}

const OnboardingIndexProps = ({ step, is_verified }: IOnboardingIndexProps) => {
  const RenderComponent = () => {
    if (step === 1) return <AddImage />;
    else if (is_verified) {
      if (step === 2) return <Onboarding />;
    }

    return null;
  };

  return (
    <Fragment>
      <MetaDefault title={'Proceso de Registro'} />
      <RenderComponent />
    </Fragment>
  );
};

export default OnboardingIndexProps;

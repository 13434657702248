import { Switch as HeadlessSwitch } from '@headlessui/react';
import classNames from 'classnames';

// Types
import { profileThemes } from 'types/customizationTypes';

interface ISwitchProps {
  show: boolean;
  setShow: any;
  disabled?: boolean;
  theme?: keyof typeof profileThemes;
  className?: string;
}

const Switch = ({
  show,
  setShow,
  disabled = false,
  theme = 'default',
  className = ''
}: ISwitchProps) => {
  const themeMapShow = {
    [profileThemes.default]: 'bg-primary',
    [profileThemes.pride]: 'items-center pride-switch border-0 transition-all'
  };

  const themeMap = {
    [profileThemes.default]: '',
    [profileThemes.pride]: 'shadow'
  };

  return (
    <HeadlessSwitch
      checked={show}
      onChange={setShow}
      disabled={disabled}
      className={classNames(
        className,
        themeMap[theme],
        show ? themeMapShow[theme] : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out disabled:cursor-not-allowed'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          show ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </HeadlessSwitch>
  );
};

export default Switch;

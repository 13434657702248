// Helpers
import classNames from 'utils/class-names';

type ActionButtonProps = {
  title: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  variant?: 'red' | 'green';
  loading?: boolean;
  loadingChildren?: React.ReactNode;
  size?: 'mini' | 'small' | 'medium' | 'large' | 'xlarge';
};

const ActionButton: React.FC<ActionButtonProps> = ({
  title,
  onClick,
  className,
  disabled,
  variant,
  loading,
  loadingChildren,
  size = 'medium'
}) => {
  const spinnerSize = {
    mini: 'h-5 w-5 border-4',
    small: 'h-6 w-6 border-4',
    medium: 'h-6 w-6 border-4',
    large: 'h-6 w-6 border-4',
    xlarge: 'h-6 w-6 border-4'
  };

  return (
    <button
      type="button"
      className={classNames(
        className,
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        loading ? 'cursor-not-allowed' : 'cursor-pointer',
        variant === 'red' &&
          'border-red-700 bg-red-500 hover:bg-red-800 active:ring-red-500 disabled:hover:bg-red-500',
        variant === 'green' &&
          'border-[#059669] bg-[#10B981] hover:bg-green-800 active:ring-[#10B981] disabled:hover:bg-[#10B981]',
        'inline-flex min-w-[134px] items-center justify-center whitespace-nowrap rounded-md border py-3 px-6 text-base font-medium text-white shadow-sm active:outline-none active:ring-2 active:ring-offset-2 disabled:active:ring-0 disabled:active:ring-offset-0'
      )}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <span className="flex items-center gap-[10px]">
          <div
            className={classNames(
              spinnerSize[size],
              `loader-${variant} border-white border-opacity-40`
            )}
          />
          {loadingChildren}
        </span>
      ) : (
        <span className="flex items-center gap-3">{title}</span>
      )}
    </button>
  );
};

export default ActionButton;

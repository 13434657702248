import { useEffect, useState } from 'react';

import EmptyPlayer from './EmptyPlayer';
import Player from './Player';
import Recorder from './Recorder';
import { AudioPlayerState } from './types';

enum AudioPlayerError {
  MicrophonePermissionMissing = 'El permiso de grabación no fué concedido. No es posible usar el grabador.'
}

interface AudioPlayerProps {
  onlyPlay?: boolean;
  audio?: string;
  setAudioUrl?: (audio: string) => void;
  setAction?: () => void;
  resetState?: () => void;
}

const AudioPlayer = (props: AudioPlayerProps) => {
  const { onlyPlay, setAudioUrl, resetState, setAction } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [audio, setAudio] = useState<string | null>(null);
  const [state, setState] = useState<AudioPlayerState>(
    onlyPlay == true ? AudioPlayerState.player : AudioPlayerState.empty
  );

  useEffect(() => {
    // Only request recording permission on states diff from player state.
    if (state !== AudioPlayerState.player) getLocalStreamPermission();
    else {
      setHasPermission(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (props.audio) setAudio(props.audio);
  }, [props.audio]);

  const getLocalStreamPermission = async () => {
    await navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false
      })
      .then(async (_) => {
        setHasPermission(true);
      })
      .catch(() => alert(AudioPlayerError.MicrophonePermissionMissing));

    setLoading(false);
  };

  const startRecording = () => {
    setAction && setAction();
    setState(AudioPlayerState.recording);
  };

  const broadcastAudioRecorded = async (audio: string) => {
    setAudio(audio);
    setAudioUrl(audio);
    setState(AudioPlayerState.player);
  };

  const deleteAudio = () => {
    setState(AudioPlayerState.empty);
    setAudio(null);
    resetState && resetState();
  };

  if (loading) return <div>loading</div>;

  if (loading === false && hasPermission === false)
    return <div>permission denied</div>;

  if (state == AudioPlayerState.empty)
    return <EmptyPlayer startRecording={startRecording} />;
  if (state == AudioPlayerState.player)
    return (
      <Player audio={audio} deleteAudio={deleteAudio} onlyPlay={onlyPlay} />
    );
  if (state == AudioPlayerState.recording)
    return (
      <Recorder
        broadcastAudioRecorded={broadcastAudioRecorded}
        deleteAudio={deleteAudio}
      />
    );
};

export default AudioPlayer;

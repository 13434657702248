import { FC } from "react"
import { FaShopify } from "react-icons/fa"

interface Props {
  title: string,
  price: number,
  image: string,
  currency: string
}

const Preview: FC<Props> = function({
  title,
  price,
  image,
  currency,
}) {
  const hasProduct = !!title && !!price && !!image && !!currency

  return (
    <div>
      <h4 className="mb-2 font-medium text-gray-700 text-base">Vista previa</h4>

      <div className={`flex items-center w-full min-h-[7rem] rounded-lg p-4 ${!hasProduct ? "bg-gray-100" : "bg-primary"}`}>
        {
          hasProduct && (
            <div className="flex w-full">
              <div className="min-w-[5rem] min-h-[5rem] max-w-[5rem] max-h-[5rem] bg-gray-100 rounded-lg mr-4">
                <img
                  className="w-full h-full object-cover object-center rounded-lg"
                  src={image}
                />
              </div>

              <div className="text-white text-lg truncate">
                <h5 className="font-semibold mb-1 truncate">{title}</h5>
                <span className="font-medium">${price.toFixed(2)} {currency}</span>
              </div>
            </div>
          )
        }
      </div>

      {
        hasProduct && (
          <div className="text-gray-500 flex items-center text-sm mt-2">
            <FaShopify
              className="w-4 h-4 mr-2 text-gray-500"
            />

            <h6>Powered by <span className="font-medium">Shopify</span></h6>
          </div>
        )
      }
    </div>
  )
}

export default Preview
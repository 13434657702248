async function copyToClipboard(text: string): Promise<Error | true> {
  if (!navigator.clipboard) return;

  return await navigator.clipboard.writeText(text).then(
    () => true,
    (error) => {
      console.error('copyToClipboard, Could not copy text: ', error);
      return new Error('Copying to clipboard has failed.');
    }
  );
}

export default copyToClipboard;

import { AxiosApi } from "../../AxiosBase";
import { getCookie } from "helpers/handleCookies";

const AxiosUnLinkAccountPhantom = async () => {
    const userToken = getCookie("token");

    if (!userToken) return { error: "No estas logeado" }

    try {
        const { data } = await AxiosApi({
            method: "POST",
            url: "/link-account/unlink/phantom",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userToken}`
            }
        });

        if (data.message !== "OK") return { error: data.message }

        return { success: "PRO" }
    }

    catch(e) {
        console.log(e);
        console.log("AxiosUnLinkAccountPhantom() Error");
        return { error: "Error al conectarse con el servidor" }
    }
}

export default AxiosUnLinkAccountPhantom;
interface IPurchaseCardProps {
  productName: string;
  creatorName: string;
  creatorUserName: string;
}

const PurchaseTitle: React.FC<IPurchaseCardProps> = (props) => {
  const { productName, creatorName, creatorUserName } = props;

  return (
    <p className="font-semibold text-gray-700">
      {productName} de{' '}
      <a
        target="_blank"
        href={`/${creatorUserName}`}
        className="font-normal text-primary underline decoration-[1px] underline-offset-2"
      >
        {creatorUserName}
      </a>
    </p>
  );
};

export default PurchaseTitle;

import solanaProvider from 'helpers/solanaProvider';
import { useUser } from 'hooks/useUser';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import AxiosLinkAccountPhantom from 'requests/local_api/linkAccount/AxiosLinkAccountPhantom';
import AxiosUnLinkAccountPhantom from 'requests/local_api/linkAccount/AxiosUnLinkAccountPhantom';
import { Mixpanel } from 'utils/mixpanel';
import Card from './Card';

const PhantomButton = () => {
  const { updateUserRedux, user } = useUser();
  const [fetching, setFetching] = useState<boolean>(false);

  const handleOnClick = async () => {
    const provider = solanaProvider();

    if (!provider) return toast.error('No tienes phantom instalado');

    try {
      localStorage.removeItem('mf_phantom');
      setFetching(true);
      if (user.linked_accounts.phantom.addr) {
        const { error } = await AxiosUnLinkAccountPhantom();

        if (error) return toast.error(error);

        updateUserRedux({
          linked_accounts: {
            ...user.linked_accounts,
            phantom: { addr: '' }
          }
        });

        Mixpanel.track('Click on unlink Phantom Account', {
          username: user.username
        });
        toast.success('Wallet desconectada');
        return;
      }

      else if (user.internal_options?.links_accounts?.phantom?.enabled === false) return

      const resp = await provider.connect();
      const walletAddr = resp.publicKey.toString();

      const { error } = await AxiosLinkAccountPhantom({ addr: walletAddr });

      if (error) {
        toast.error(error);
        return;
      }

      updateUserRedux({
        linked_accounts: {
          ...user.linked_accounts,
          phantom: {
            addr: walletAddr
          }
        }
      });

      Mixpanel.track('Click on link Phantom Account', {
        username: user.username
      });
      toast.success('Wallet conectada');
    } catch (e) {
      console.log(e);
      console.log('PhantomButton handleOnClick() Error');
      toast.error('Error mientras se logeaba con phantom');
    } finally {
      setFetching(false);
    }
  };

  return (
    <Card
      img="/img/icons/phantom.svg"
      imgBackgroundColor="#542AE2"
      title="Phantom"
      description="Recibe AirDrops a tu wallet."
      buttonText={
        user.linked_accounts.phantom.addr ? 'Desconectar' : 'Conectar'
      }
      buttonClassName={
        user.linked_accounts.phantom.addr
          ? 'bg-red-100 text-red-700 hover:bg-red-200'
          : 'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
      }
      onClickButton={handleOnClick}
      loading={fetching}
      isConnected={user.linked_accounts.phantom.addr && true}
      disconnectEnabled={true}
      active={!!user.linked_accounts.phantom.addr || user.internal_options?.links_accounts?.phantom?.enabled !== false}
      disableButton={!user.linked_accounts.phantom.addr && user.internal_options?.links_accounts?.phantom?.enabled === false}
    />
  );
};

export default PhantomButton;

import Landing from './landing';
import { useUser } from 'hooks/useUser';
import { useEffect } from 'react';
import DashboardScreen from 'screens/dashboard';
import Onboarding from 'screens/onboarding';
import AddEmailForm from 'screens/onboarding/AddEmailForm';
import { Mixpanel } from 'utils/mixpanel';

const Index = () => {
  const { status, user } = useUser();

  useEffect(() => {
    Mixpanel.track('Landing Page Hits');
  }, []);

  if (status === 1) {
    // Verify if user has an email
    if (!user.email) return <AddEmailForm />;
    else if (
      user.registerProcess < 2 ||
      (user.registerProcess < 3 && (user.referred || user.is_verified))
    )
      return (
        <Onboarding
          step={user.registerProcess}
          is_verified={user.referred || user.is_verified}
        />
      );

    return <DashboardScreen />;
  }

  return (
    <>
      <Landing />
    </>
  );
};

export default Index;

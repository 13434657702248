import { getCookie } from 'helpers/handleCookies';
import { AxiosApi } from '../AxiosBase';

interface IAxiosAuthConfirmationProps {
  email: string;
}

export const AxiosAuthSendConfirmationEmail = async ({
  email
}: IAxiosAuthConfirmationProps) => {
  try {
    const userToken = getCookie('token');

    if (!userToken) return { error: 'No estas logeado' };
    const { data } = await AxiosApi.post(
      '/auth/send-verification-email-from-form',
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    if (data.message !== 'OK') return { error: data.message };

    return { data };
  } catch (e) {
    console.log(e);
    console.log('AxiosAuthSendConfirmationEmail() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

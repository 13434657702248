import { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NextRouter } from 'next/router';

import { supabase } from 'utils/supabase-client';
import { Account } from 'types/accounts';
import { ActionTriggerType } from 'types/actionTriggerType';
import { DigitalProduct } from 'types/digitalProduct';
import { ScreenType } from 'types/screenType';

import { CommandAction } from '../types';
import { complexActions } from './complexActions';
import { deepNavigationActions } from './deepNavigationActions';
import { directNavigationActions } from './directNavigationActions';

export interface LoadProps {
  account: Account;
  router: NextRouter;
  setActionTrigger: (actionTrigger: ActionTriggerType) => void;
  setScreen: Dispatch<SetStateAction<keyof typeof ScreenType>>;
}

export type LoadResult = Array<CommandAction>;

export class CommandActionManager {
  static async load({
    account,
    router,
    setActionTrigger,
    setScreen
  }: LoadProps) {
    const isValidStripeConnectAccount = await this.validateStripeConnectAccount(
      account
    );

    try {
      const _complexActions = await this.buildComplexActions();
      const _deepNavigationActions = await this.buildDeepNavigationActions({
        account,
        router,
        setActionTrigger,
        isValidStripeConnectAccount,
        setScreen
      });
      const _directNavigationActions = await this.buildDirectNavigationActions({
        account,
        router,
        setScreen
      });

      return [
        ..._complexActions,
        ..._deepNavigationActions,
        ..._directNavigationActions
      ];
    } catch (error) {
      console.error('Unable to build actions. ', error);

      return [
        ...complexActions({}),
        ...deepNavigationActions({
          account,
          setActionTrigger,
          setScreen,
          isValidStripeConnectAccount
        }),
        ...directNavigationActions({ account, router, setScreen })
      ];
    }
  }

  private static buildComplexActions = async (): Promise<
    Array<CommandAction>
  > => {
    return [...complexActions({})];
  };

  private static buildDeepNavigationActions = async ({
    account,
    isValidStripeConnectAccount,
    router,
    setActionTrigger,
    setScreen
  }: {
    account: Account;
    isValidStripeConnectAccount: boolean;
    router: NextRouter;
    setActionTrigger: (actionTrigger: ActionTriggerType) => void;
    setScreen: Dispatch<SetStateAction<keyof typeof ScreenType>>;
  }): Promise<Array<CommandAction>> => {
    let creatorActions: CommandAction[] = [];
    let productActions: CommandAction[] = [];

    if (account.is_verified) {
      creatorActions = this.buildCreatorActions(
        await this.getCreators(),
        router
      );
    }

    if (
      (await this.validateStripeConnectAccount(account)) &&
      account.is_verified
    ) {
      productActions = this.buildProductActions(
        await this.getProducts(account),
        router,
        account
      );
    }

    return [
      ...deepNavigationActions({
        account,
        setActionTrigger,
        setScreen,
        isValidStripeConnectAccount
      }),
      ...creatorActions,
      ...productActions
    ];
  };

  private static buildDirectNavigationActions = async ({
    account,
    router,
    setScreen
  }: {
    account: Account;
    router: NextRouter;
    setScreen: Dispatch<SetStateAction<keyof typeof ScreenType>>;
  }): Promise<Array<CommandAction>> => {
    return [...directNavigationActions({ account, router, setScreen })];
  };

  private static buildCreatorActions = (
    accounts: Array<Account>,
    router: NextRouter
  ): Array<CommandAction> => {
    return accounts
      .filter((a) => a.username !== undefined)
      .map((account) => {
        return {
          action: () => window.open(`/${account.username}`, '_blank'),
          key: uuidv4(),
          name: `Ir al perfil de creador ${account.username}`
        };
      });
  };

  private static buildProductActions = (
    products: Array<DigitalProduct>,
    router: NextRouter,
    account: Account
  ): Array<CommandAction> => {
    return products.map((product) => {
      return {
        action: () =>
          router.push(`/${account.username}/${product.product_uuid}`),
        key: uuidv4(),
        name: `Ir al producto ${product.name}`
      };
    });
  };

  private static getCreators = async (): Promise<Array<Account>> => {
    const { data, error } = await supabase
      .from<Account>('accounts')
      .select('*')
      .eq('is_verified', true);

    if (error) return [];

    return data;
  };

  private static getProducts = async (
    account: Account
  ): Promise<Array<DigitalProduct>> => {
    const { data, error } = await supabase
      .from<DigitalProduct>('digital_products')
      .select('*')
      .eq('is_deleted', false)
      .eq('creator_account_id', account.id);

    if (error) return [];

    return data;
  };

  private static validateStripeConnectAccount = async (
    account: Account
  ): Promise<boolean> => {
    if (this.hasInvalidConnectId(account)) return false;

    if (await this.hasIncompleteStripeConnectAccount(account)) return false;

    return true;
  };

  private static hasInvalidConnectId = (account: Account) => {
    return (
      account.connect_id == undefined ||
      account.connect_id == null ||
      account.connect_id == ''
    );
  };

  private static hasIncompleteStripeConnectAccount = async (
    account: Account
  ) => {
    const { message } = await fetch(
      `/api/verify-connect-account?connect_id=${account.connect_id}`,
      {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'same-origin'
      }
    )
      .then((res) => res.json())
      .catch(() => ({ message: 'Error' }));

    if (message === 'OK') return false;

    return true;
  };
}

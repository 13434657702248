import { getCookie } from 'helpers/handleCookies';
import { AxiosApi } from '../../AxiosBase';

type TAxiosApplyReferal = {
  referral: string;
  userId: string;
};

const AxiosApplyReferal = async (payload: TAxiosApplyReferal) => {
  try {
    const userToken: string | boolean = getCookie('token');

    if (!userToken) return { error: 'No estas logeado' };

    const result = await AxiosApi.post(`/user/apply-referal`, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      }
    });

    return result.data;
  } catch (error) {
    console.error('AxiosApplyReferal Error:', error);

    if (error.message) return { error: error.message };

    if (error instanceof Error) return error;

    return new Error(`Error al ejecutar AxiosApplyReferal: ${error}`);
  }
};

export default AxiosApplyReferal;

import { XIcon } from '@heroicons/react/outline';
import Modal from 'components/Modal';

interface IStripeAccountMissingModalProps {
  description: string;
  handleModal: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  title: string;
}

const StripeAccountMissingModal = (props: IStripeAccountMissingModalProps) => {
  const { description, handleModal, open, title } = props;

  const onClickCloseModal = () => {
    handleModal(!open);
  };

  return (
    <Modal open={open} setOpen={handleModal}>
      <div
        className={
          'inline-block w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle'
        }
      >
        <div className={'absolute right-0 top-0 hidden pr-4 pt-4 sm:block'}>
          <button
            className={
              'rounded-md text-stone-800 hover:bg-primary hover:text-white focus:outline-none'
            }
            onClick={onClickCloseModal}
            type={'button'}
          >
            <span className={'sr-only'}>Close</span>
            <XIcon className={'h-6 w-6'} aria-hidden={'true'} />
          </button>
        </div>
        <div className={'items-start sm:flex'}>
          <div className={'mt-3 w-full text-left sm:mt-0'}>
            <h3 className="pb-8 text-2xl">{title}</h3>
            <p className="text-center">{description}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StripeAccountMissingModal;

import EmptyState from 'components/dashboard/EmptyState';
import SubCard from './card';

// Libraries
import useSWR from 'swr';

// Request
import { AxiosGetAudience } from 'requests/local_api/AxiosAudience';

// Components
import BlockSkeleton from 'components/tailwind/loaders/BlockSkeleton';
import UsersLineRegular from 'components/tailwind/icons/UsersLineRegular';

const SubsScreen = () => {
  const { error, data } = useSWR('/audience/fans', AxiosGetAudience);

  if (error === undefined && data === undefined) {
    return <BlockSkeleton quantity={6} className="flex flex-col gap-3" />;
  } else if (error) return <h4>{error.toString()}</h4>;

  return (
    <>
      {data.length === 0 ? (
        <EmptyState
          absolute={false}
          title="Aún no tienes ningún suscriptor"
          dashboard={true}
          className="flex-1"
          icon={
            <UsersLineRegular className="h-[27px] w-[39px] text-gray-400" />
          }
        />
      ) : (
        <div className="flex flex-col gap-4">
          {data.map((subscription, index) => (
            <div key={'subs-card:' + subscription.id + `, index: ${index}`}>
              <SubCard sub={subscription} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SubsScreen;

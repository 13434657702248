import { useFormik } from 'formik';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { supabase } from 'utils/supabase-client';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

// Components
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import Input from 'components/tailwind/inputs/Input';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
// import Switch from 'components/tailwind/inputs/Switch';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import { Mixpanel } from 'utils/mixpanel';

const AddDividerForm = ({ closeModal, setBlocks, setLoading, handleClose }) => {
  const { user, updateUserRedux } = useUser();
  const [onTop, setOnTop] = useState(true);

  const formik: any = useFormik({
    initialValues: {
      title: ''
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('El título del separador es obligatorio')
        .max(64, 'El título debe tener como máximo 64 carácteres')
    }),
    onSubmit: async (values) => {
      setLoading(true);

      const newBlock = {
        ...values,
        id: uuidv4(),
        visible: true,
        type: 'divider'
      };

      const newBlocksPosition = onTop
        ? [newBlock, ...user.blocks]
        : [...user.blocks, newBlock];

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks: user.blocks ? newBlocksPosition : [newBlock]
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
        setLoading(false);
      }

      if (data) {
        Mixpanel.track('Added block', {
          username: user.username,
          type: 'divider'
        });
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Añadido', defaultToastOptions);
        setLoading(false);
        handleClose();
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormBody>
        <LabelRequired isRequired={true} name="title" title="Título" />
        <div className="mt-1">
          <Input
            type="text"
            name="title"
            id="title"
            placeholder="Escribe el título"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            error={formik.errors.title && formik.touched.title}
          />
        </div>
        {formik.errors.title && formik.touched.title && (
          <ErrorMessage errorText={formik.errors.title} />
        )}
        {/* //! Logic to add on Top or bottom of the block stack */}
        {/* <div>
          <Switch show={onTop} setShow={() => setOnTop(!onTop)} />
          <span>Logic to Add to top or bottom, true for top</span>
        </div> */}
      </FormBody>
      <FormFooter>
        <WhiteButton
          title="Cancelar"
          onClick={closeModal}
          disabled={formik.isSubmitting}
          className="px-6"
        />
        <BasicButton
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          loading={formik.isSubmitting}
          title="Añadir"
          className="w-40"
          loadingChildren="Añadiendo"
        />
      </FormFooter>
    </form>
  );
};

export default AddDividerForm;

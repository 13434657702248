// Libraries
import { useState } from 'react';

import { useUser } from 'hooks/useUser';
import CreatorTags from './CreatorTags';
import LinkTree from './Linktree';

// Components
import OnboardingContainer from 'components/onboarding/OnboardingContainer';
import BuildingProfile from 'components/home/BuildingProfile';

const VerifiedOnboarding: React.FC = () => {
  const [buildingProfile, setBuildingProfile] = useState(false);
  const { user } = useUser();

  if (buildingProfile) {
    return <BuildingProfile />;
  }

  return (
    <OnboardingContainer>
      {user.subStep === 2 ? (
        <CreatorTags setBuildingProfile={setBuildingProfile} />
      ) : (
        <LinkTree />
      )}
    </OnboardingContainer>
  );
};

export default VerifiedOnboarding;

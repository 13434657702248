// Libraries
import { PencilIcon } from '@heroicons/react/solid';
import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import useSWR from 'swr';

// Types
import { Account } from 'types/accounts';
import { IBlocks } from 'types/Block';
import { DigitalProduct, DigitalProductAggregate } from 'types/digitalProduct';
import { IUser, IUserEdit } from 'types/User';

// Helpers
import copyToClipboard from 'utils/copyToClipboard';
import { supabase } from 'utils/supabase-client';
import { defaultToastOptions } from 'helpers/toastOptions';
import classNames from 'classnames';

// Components
import Avatar from 'components/tailwind/avatars/Avatar';
import ShoppingBagRegular from 'components/tailwind/icons/ShoppingBagRegular';
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import CopyRegularIcon from 'components/tailwind/icons/CopyRegularIcon';
import BlockSkeleton from 'components/dashboard/blocks/BlockSkeleton';
import EmptyState from 'components/dashboard/EmptyState';
import ErrorState from 'components/dashboard/ErrorState';
import PurchaseSubtitle from 'components/dashboard/productsHistory/cards/PurchaseSubtitle';
import PurchaseCard from 'components/dashboard/productsHistory/cards/PurchaseCard';

enum MyProductScreenError {
  loadHistoryFailure = 'No hemos podido cargar tu historial de compras, por favor contacta con el equipo de soporte.',
  updatingEnabledFailure = 'Ha habido un error cambiando el estado habilitado del Producto Digital. Por favor contacta con el equipo de soporte.',
  updatingBlockEnabledFailure = 'Ha habido un error cambiando el estado habilitado del Bloque de este Producto Digital. Por favor contacta con el equipo de soporte.',
  getAccountFailure = 'Hemos tenido un problema al obtener los bloques de esta cuenta. Por favor contacta con el equipo de soporte.'
}

interface MyProductsScreenProps {
  creatorAccount: IUser;
  digitalProductsAggregate: Array<DigitalProductAggregate> | undefined;
  digitalProductsError: any | undefined;
  loading: boolean;
  onClickPreviewDigitalProduct: (digitalProduct: DigitalProduct) => void;
  refreshDigitalProducts: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  updateUserRedux: (payload: IUserEdit) => {
    type: string;
    payload: IUserEdit;
  };
}

const MyProductsScreen = (props: MyProductsScreenProps) => {
  const {
    creatorAccount,
    digitalProductsAggregate,
    digitalProductsError,
    refreshDigitalProducts,
    updateUserRedux
  } = props;

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [productLoadingId, setProductLoadingId] = useState<string | number>('');

  useEffect(() => {
    if (digitalProductsError) {
      setErrorMessage(MyProductScreenError.loadHistoryFailure);
      return setError(true);
    }
  }, []);

  const onClickButtonCopyLink = async (digitalProduct: DigitalProduct) => {
    let productLink = `${process.env.NEXT_PUBLIC_URL}/`;
    productLink += `${creatorAccount.username}/`;
    productLink += digitalProduct.product_uuid;

    const result = await copyToClipboard(productLink);

    if (!(result instanceof Error))
      toast(
        '¡Se ha copiado el enlace de compra en tu papelera!',
        defaultToastOptions
      );
  };

  const onClickPreviewDigitalProduct = (digitalProduct: DigitalProduct) => {
    props.onClickPreviewDigitalProduct(digitalProduct);
  };

  const onClickSwitchEnable = async (digitalProduct: DigitalProduct) => {
    setProductLoadingId(digitalProduct.id);

    let { error } = await supabase
      .from<DigitalProduct>('digital_products')
      .update({
        ...digitalProduct,
        is_enabled: !digitalProduct.is_enabled
      })
      .match({ id: digitalProduct.id });

    if (error) {
      setErrorMessage(MyProductScreenError.updatingEnabledFailure);
      return setError(true);
    }

    await refreshDigitalProducts();

    const { data, error: accountError } = await supabase
      .from<Account>('accounts')
      .select('*')
      .filter('id', 'eq', digitalProduct.creator_account_id);

    if (accountError) {
      setErrorMessage(MyProductScreenError.getAccountFailure);
      return setError(true);
    }

    let blocks = data[0].blocks;

    if (blocks != undefined || blocks != null) {
      if (blocks.length > 0) {
        const blockIndex = blocks.findIndex(
          (block) => block.digitalProductId == digitalProduct.id
        );

        if (blockIndex >= 0) {
          blocks[blockIndex].visible = !digitalProduct.is_enabled;

          const { error: updateAccountError } = await supabase
            .from('accounts')
            .update({
              blocks
            })
            .eq('id', digitalProduct.creator_account_id);

          if (updateAccountError) {
            setErrorMessage(MyProductScreenError.updatingBlockEnabledFailure);
            return setError(true);
          }

          updateUserRedux({
            blocks: blocks as unknown as IBlocks[]
          });
        }
      }
    }

    setProductLoadingId('');
  };

  const goToProductLink = (digitalProduct: DigitalProduct) => {
    let productLink = `${process.env.NEXT_PUBLIC_URL}/`;
    productLink += `${creatorAccount.username}/`;
    productLink += digitalProduct.product_uuid;

    window.open(productLink);
  };

  const errorProductList = (
    <ErrorState absolute={false} title={'Oops!'} description={errorMessage} />
  );

  const loadingProductList = (
    <BlockSkeleton quantity={8} className={'flex flex-col gap-2'} />
  );

  const emptyProductList = (
    <EmptyState
      absolute={false}
      title="Aún no tienes ningún producto"
      dashboard={true}
      className="flex-1"
      icon={<ShoppingBagRegular className="h-[39px] w-[34px] text-gray-400" />}
    />
  );

  const digitalProductsList = (
    <ul className="flex flex-col gap-4">
      {digitalProductsAggregate !== undefined &&
      digitalProductsAggregate.length > 0
        ? digitalProductsAggregate.map((digitalProductAggregate, index) => {
            return (
              <PurchaseCard
                key={'block: ' + index}
                avatar={
                  <Avatar
                    profilePicture={
                      digitalProductAggregate.digitalProduct.placeholder
                    }
                    shape="square"
                  />
                }
                title={
                  <p className="font-semibold text-gray-700">
                    {digitalProductAggregate.digitalProduct.name}
                  </p>
                }
                subtitle={
                  <PurchaseSubtitle
                    date={
                      digitalProductAggregate.purchase_data.count +
                      ' ' +
                      (digitalProductAggregate.purchase_data.count === 1
                        ? 'Venta'
                        : 'Ventas')
                    }
                    price={digitalProductAggregate.purchase_data.total_amount}
                    fileType={
                      digitalProductAggregate.digitalProduct.type ===
                      'fileProtected'
                        ? 'Archivo descargable'
                        : 'Enlace externo'
                    }
                    fileTypeIcon={digitalProductAggregate.digitalProduct.type}
                    subTitleType="products"
                  />
                }
                onClick={() =>
                  goToProductLink(digitalProductAggregate.digitalProduct)
                }
                actions={
                  <div
                    className={
                      'flex h-6 items-center justify-items-end space-x-3'
                    }
                  >
                    <Switch
                      className={classNames(
                        digitalProductAggregate.digitalProduct.is_enabled
                          ? 'bg-primary'
                          : 'bg-gray-200',
                        productLoadingId ===
                          digitalProductAggregate.digitalProduct.id &&
                          'cursor-not-allowed opacity-50',
                        'relative hidden h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none sm:inline-flex'
                      )}
                      checked={
                        digitalProductAggregate.digitalProduct.is_enabled
                      }
                      onChange={() =>
                        onClickSwitchEnable(
                          digitalProductAggregate.digitalProduct
                        )
                      }
                      disabled={
                        productLoadingId ===
                        digitalProductAggregate.digitalProduct.id
                      }
                    >
                      <span
                        className={classNames(
                          digitalProductAggregate.digitalProduct.is_enabled
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                        aria-hidden={'true'}
                      />
                    </Switch>
                    <SecondaryButton
                      title={
                        <p className="hidden xl:inline-block">Copiar Enlace</p>
                      }
                      variant={variantType.gray}
                      LeftIcon={
                        <CopyRegularIcon className="h-[18px] w-[18px] fill-gray-400" />
                      }
                      onClick={() =>
                        onClickButtonCopyLink(
                          digitalProductAggregate.digitalProduct
                        )
                      }
                      size="small"
                    />
                    <SecondaryButton
                      title={<p className="hidden lg:inline-block">Editar</p>}
                      variant={variantType.gray}
                      LeftIcon={
                        <PencilIcon className="h-[18px] w-[18px] fill-gray-500 lg:hidden" />
                      }
                      onClick={() =>
                        onClickPreviewDigitalProduct(
                          digitalProductAggregate.digitalProduct
                        )
                      }
                      size="small"
                    />
                  </div>
                }
              />
            );
          })
        : null}
    </ul>
  );

  if (digitalProductsError) return errorProductList;

  if (
    digitalProductsError === undefined &&
    digitalProductsAggregate === undefined
  )
    return loadingProductList;

  if (digitalProductsAggregate && digitalProductsAggregate.length > 0)
    return digitalProductsList;

  return emptyProductList;
};

export default MyProductsScreen;

import { CommandMenuOption } from './option';
import { CommandAction } from './types';

export interface CommandMenuRecentSearchList {
  recentActions: Array<CommandAction>;
}

export const CommandMenuRecentSearchList = ({
  recentActions
}: CommandMenuRecentSearchList) => {
  return (
    <li className="p-2">
      <h2 className="mt-4 mb-2 px-3 text-xs font-semibold text-gray-500">
        Búsquedas recientes
      </h2>
      <ul className="text-sm text-gray-700">
        {recentActions.map((action) => (
          <CommandMenuOption action={action} key={action.key} />
        ))}
      </ul>
    </li>
  );
};

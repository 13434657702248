import React from 'react';

interface SkeletonCardProps {
  quantity: number;
}

const SkeletonCard = (SkeletonCardProps: SkeletonCardProps) => {
  return (
    <>
      {Array.from(Array(SkeletonCardProps.quantity).keys()).map((index) => (
        <div key={index} className="block animate-pulse rounded-md p-1">
          <div className="mb-2">
            <div className="flex justify-between">
              <div className="w-full">
                <div className="flex items-start text-base text-gray-500">
                  <div className="iw_skeleton-light mr-2 flex max-h-[2.25rem] min-h-[2.25rem] min-w-[2.25rem] max-w-[2.25rem] items-center justify-center rounded-full bg-slate-200"></div>

                  <div className="w-full">
                    <p className="iw_skeleton-text-light  h-[.5rem] w-[4.5rem] rounded-md  bg-slate-200 text-sm text-gray-500"></p>
                    <div className="iw_skeleton-text-light my-2 mb-1 h-[.5rem] w-64 rounded-md bg-slate-200 text-gray-700"></div>
                    <div className="iw_skeleton-text-light my-2 mb-1 h-[.5rem] w-64 rounded-md bg-slate-200 text-gray-700"></div>
                    <span className="iw_skeleton-text-light bg-slate-200 "></span>
                  </div>
                </div>

                <div className="ml-[2.7rem] mt-2 flex items-center sm:mt-0">
                  <button className="iw_skeleton-text-light mt-2  inline-flex h-32 w-64 items-center rounded-md bg-slate-200   py-2 px-4 text-sm font-medium text-white shadow-sm "></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default SkeletonCard;

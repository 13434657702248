import { classNames } from 'helpers/classNames';

enum BadgeIconColors {
  primary = 'primary',
  red = 'red',
  pink = 'pink',
  yellow = 'yellow',
  green = 'green',
  cyan = 'cyan',
  blue = 'blue',
  purple = 'purple',
  fuchsia = 'fuchsia',
  gray = 'gray'
}

type BadgeIconProps = {
  text: string;
  Icon: React.ReactElement;
  className?: string;
  color?: keyof typeof BadgeIconColors;
  type?: 'primary' | 'secondary';
};

const BadgeIcon: React.FC<BadgeIconProps> = ({
  text,
  Icon,
  className,
  color,
  type = 'primary'
}) => {
  return (
    <span
      className={classNames(
        className,
        type === 'primary'
          ? `text-white ${
              color == 'primary' ? 'bg-primary' : 'bg-' + color + '-500'
            }`
          : `bg-${color}-200 text-${color}-700`,
        `inline-flex items-center gap-1 rounded-lg py-2 px-3 text-sm font-medium leading-4`
      )}
    >
      {Icon}
      {text}
    </span>
  );
};

export default BadgeIcon;

// Libraries
import { UploadIcon } from '@heroicons/react/solid';

// Components
import WhiteButton from 'components/tailwind/buttons/WhiteButton';

interface EmptyUploaderProps {
  showUploader: () => void;
}

export const EmptyUploader = (props: EmptyUploaderProps) => {
  const { showUploader } = props;

  const onClickShowUploader = () => showUploader();

  return (
    <WhiteButton
      onClick={onClickShowUploader}
      title="Subir archivo de audio"
      type={'button'}
      className="w-full"
      leftIcon={<UploadIcon className="h-5 w-5 text-gray-400" />}
    />
  );
};

import { FC, useState } from "react"

// Components
import Card from "./Card"
import LinkTreeModal from "components/modals/LinkTreeModal"

const LinkTreeButton: FC = function() {
  const [show, setShow] = useState<boolean>(false)

  const handleOnClick = () => {
    setShow(true)
  }

  return (
    <>
    <Card
      title="Linktree"
      description="Importa tu perfil de Linktree"
      buttonText="Importar"
      img="/img/icons/linktree.svg"
      onClickButton={handleOnClick}
      imgBackgroundColor="#43E660"
    />

    <LinkTreeModal
      show={show}
      setShow={setShow}
    />
    </>
  )
}

export default LinkTreeButton
import { FC, useState, ChangeEvent, useEffect, useRef } from "react"
import { supabase } from "utils/supabase-client"
import { v4 as uuidv4 } from "uuid"
import { IBlocks } from "types/Block"
import { toast } from "react-hot-toast"

// Components
import LabelRequired from "components/tailwind/inputs/LabelRequired"
import Input from "components/tailwind/inputs/Input"
import FormFooter from "components/tailwind/overlays/FormFooter"
import BasicButton from "components/tailwind/buttons/BasicButton"
import WhiteButton from "components/tailwind/buttons/WhiteButton"
import SecondaryButton, { variantType } from "components/tailwind/buttons/SecondaryButton"
import Preview from "./Preview"

// Requests
import FetchGetProductData from "requests/local_next/gloset/FetchGetProductData"

// Hooks
import { useUser } from "hooks/useUser"

interface Props {
  mode?: "edit" | "add",
  initialValue?: any,
  setBlocks: (value: IBlocks[] | ((prev: IBlocks[]) => IBlocks[])) => void,
  onClickCancel: () => void
}

const initialGlosetProductData = {
  image: "",
  price: 0,
  title: "",
  currency: "",

}

const GlosetProductForm: FC<Props> = function({
  mode = "add",
  initialValue,
  setBlocks,
  onClickCancel
}) {
  const firstRender = useRef<boolean>(true)
  const { user, updateUserRedux } = useUser()
  const [loading, setLoading] = useState<boolean>(false)
  const [fetching, setFetching] = useState<boolean>(false)
  const [value, setValue] = useState<string>(initialValue?.url || "")
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [valid, setValid] = useState<boolean>(true)
  const [glosetProduct, setGlosetProduct] = useState(initialValue?.data || initialGlosetProductData)

  const hasProduct = !!glosetProduct.currency && !!glosetProduct.image && !!glosetProduct.price && !!glosetProduct.title

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    else if (!value) {
      setGlosetProduct(initialGlosetProductData)
      setErrorMessage("")
      return
    }

    const timeout = setTimeout(() => {
      getGlosetData()
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [value])

  const handleOnChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (valid) {
      setValid(false)
    }
    setValue(e.target.value)
  }

  const getGlosetData = async () => {
    setGlosetProduct(initialGlosetProductData)
    setLoading(true)

    const { error, data } = await FetchGetProductData(value)

    setLoading(false)

    if (error) {
      setGlosetProduct(initialGlosetProductData)
      setErrorMessage(error)
      return
    }

    setValid(true)
    setGlosetProduct(data)
    setErrorMessage("")
  }

  const handleOnClickAdd = async () => {
    if (
      fetching ||
      loading ||
      !hasProduct ||
      !value
    ) return

    setFetching(true)

    const payload = {
      id: uuidv4(),
      url: value,
      data: glosetProduct,
      type: "gloset_product",
      visible: true
    }

    const theBlocks = [payload, ...user.blocks]

    const { error } = await supabase
      .from("accounts")
      .update({
        blocks: theBlocks
      })
      .match({ id: user.id })
    
    setFetching(false)

    if (error) {
      console.error(error)
      toast.error("A ocurrido un error")
      return
    }

    updateUserRedux({
      blocks: theBlocks
    })

    setBlocks(theBlocks)

    toast.success("Bloque agregado satisfactoriamente")

    onClickCancel()
  }

  const handleOnClickRemove = async () => {
    if (
      fetching ||
      loading ||
      !initialValue ||
      !initialValue?.id
    ) return

    const theConfirmation = confirm("Estas seguro que desea eliminarlo?")

    if (!theConfirmation) return

    setLoading(true)

    const filter = user.blocks.filter((block) => block.id !== initialValue?.id)

    const { error } = await supabase
      .from("accounts")
      .update({
        blocks: filter
      })
      .match({ id: user.id })

    setLoading(false)

    if (error) {
      console.error(error)
      toast.error("A ocurrido un error mientras se borraba el bloque :(")
      return
    }

    setBlocks(filter)
    updateUserRedux({
      blocks: filter
    })

    toast.success("Se a borrado el bloque satisfactoriamente")

    onClickCancel()
  }

  const handleOnClickUpdate = async () => {
    if (
      loading ||
      fetching ||
      !value ||
      !hasProduct
    ) return

    setFetching(true)

    const map = user.blocks.map((block) => {
      if (block.id !== initialValue?.id) return block

      block.url = value
      block.data = glosetProduct

      return block
    })

    const { error } = await supabase
      .from("accounts")
      .update({
        blocks: map
      })
      .match({ id: user.id })
    
    setFetching(false)

    if (error) {
      console.error(error)
      toast.error("A ocurrido un error mientras se actualizaba el bloque")
      return
    }

    setBlocks(map)
    updateUserRedux({
      blocks: map
    })

    toast.success("Bloque actualizado satisfactoriamente")

    onClickCancel()
  }

  return (
    <div>
      <div className="px-6 py-4">
        <div className="mb-4">
          <LabelRequired
            className="mb-2"
            title="URL"
            isRequired={true}
          />

          <Input
            type="text"
            value={value}
            onChange={handleOnChangeInput}
            name="url"
            placeholder="https://gloset.mx/l/my-producto/3434234234"
            error={!!errorMessage}
            disabled={loading || fetching}
          />

          {
            errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
          }
        </div>
        
        <Preview
          {...glosetProduct}
        />
      </div>

      <FormFooter>
        {
          mode === "edit" ? (
            <SecondaryButton
              variant={variantType.red}
              title="Eliminar"
              onClick={handleOnClickRemove}
              disabled={loading || fetching}
            />
          ) : (
            <WhiteButton
              type="button"
              title="Cancelar"
              disabled={loading || fetching}
              onClick={onClickCancel}
            />
          )
        }

        <BasicButton
          type="button"
          title={mode === "edit" ? "Guardar cambios" : "Añadir"}
          disabled={loading || !hasProduct || !valid || (mode === "edit" && initialValue?.url === value)}
          loading={fetching}
          onClick={mode === "edit" ? handleOnClickUpdate : handleOnClickAdd}
        />
      </FormFooter>

    </div>
  )
}

export default GlosetProductForm
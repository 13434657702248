// Libraries
import { useState, useEffect } from 'react';
import { HiSearch } from 'react-icons/hi';
import useSWR from 'swr';

// Types
import { TDropDownOptions } from 'components/tailwind/inputs/DropDown';

// Request
import AxiosGetFanPurchases from 'requests/local_api/fanPurchases/AxiosGetFanPurchases';

// Hooks
import useApp from 'hooks/useApp';

// Components
import HeaderScreen from 'components/dashboard/HeaderScreen';
import ProductHistoryScreen from 'components/dashboard/productsHistory/ProductsHistoryScreen';
import InputWithLeadingIcon from 'components/tailwind/inputs/InputWithLeadingIcon';
import Dropdown from 'components/tailwind/inputs/DropDown';

let timeout: any = undefined;

const options: Array<TDropDownOptions> = [
  { textContent: 'Hoy', value: 'today', defaultValue: true },
  { textContent: 'Esta semana', value: 'weekly' },
  { textContent: 'Este mes', value: 'monthly' },
  { textContent: 'Todo el tiempo', value: 'infinity' }
];

const MyShoppingScreen: React.FC = () => {
  const [search, setSearch] = useState<string>('');
  const {
    app: {
      purchaseScreen: { option }
    },
    editPurchaseRedux
  } = useApp();

  const { error, data } = useSWR(
    [
      `/fan-purchases?time=${option.value}${search ? `&search=${search}` : ''}`,
      search,
      option.value
    ],
    AxiosGetFanPurchases
  );

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const search = formData.get('search') as string;

    if (timeout) {
      clearTimeout(timeout);
    }

    setSearch(search);
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setSearch(e.target.value);
    }, 500);
  };

  const handleOnChangeDropdown = (option: TDropDownOptions) => {
    setSearch('');
    editPurchaseRedux({ option });
  };

  useEffect(() => {
    localStorage.setItem('mf_dashboard', 'products_history');
  }, []);

  return (
    <div className="flex min-h-full flex-col">
      <HeaderScreen
        title="Mis Compras"
        RightComponent={
          <Dropdown
            value={option}
            onChange={(option) => handleOnChangeDropdown(option)}
            options={options}
          />
        }
      />

      <div className="mb-4 flex flex-col gap-4 md:flex-row md:items-center">
        <form className="flex-grow" onSubmit={handleOnSubmit}>
          <InputWithLeadingIcon
            Icon={<HiSearch className="mr-1 h-5 w-5 text-gray-400" />}
            placeholder="Buscar producto"
            name="search"
            onChange={handleOnChangeInput}
          />
        </form>
      </div>

      <h3 className="mb-4 font-medium text-gray-600 md:text-lg">
        Aquí podrás encontrar todas las compras que haz hecho
      </h3>

      <ProductHistoryScreen error={error} data={data} />
    </div>
  );
};

export default MyShoppingScreen;

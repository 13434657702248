// Libraries
import React from 'react';

// Components
import SpinnerLoader from 'components/SpinnerLoader';

// Utils
import classNames from 'utils/class-names';

type BasicButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  size?: 'mini' | 'small' | 'medium' | 'large' | 'xlarge';
  isConnected?: boolean;
  disconnectEnabled?: boolean;
  initialText?: string;
};

const BasicButton = ({
  onClick,
  disabled,
  className = '',
  loading = false,
  size = 'medium',
  isConnected = false,
  disconnectEnabled = false,
  initialText = 'Conectar'
}: BasicButtonProps) => {
  const buttonSize = {
    mini: 'text-xs',
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-lg',
    xlarge: 'text-xl'
  };

  return (
    <button
      type="button"
      className={classNames(
        className,
        buttonSize[size],
        loading && !isConnected && 'bg-primary text-white',
        isConnected &&
          !disconnectEnabled &&
          !loading &&
          'bg-primary text-white',
        !isConnected &&
          !loading &&
          'border border-gray-300 bg-white text-gray-700 hover:bg-primary-50 disabled:bg-white',
        isConnected &&
          disconnectEnabled &&
          'bg-red-100 text-red-700 hover:bg-red-200',
        'flex cursor-pointer items-center justify-center rounded-md py-2 px-4 font-medium shadow-sm disabled:cursor-not-allowed'
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {!isConnected && !loading && initialText}
      {isConnected && disconnectEnabled && !loading && 'Desconectar'}
      {isConnected && !disconnectEnabled && !loading && 'Conectado'}
      {loading && !isConnected && (
        <span className="flex items-center gap-[10px]">
          <div
            className={`loader-white h-5 w-5 rounded-full border-4 border-white border-opacity-40`}
          />
          Conectando
        </span>
      )}
      {loading && isConnected && (
        <span className="flex items-center gap-[10px]">
          <div
            className={`loader-red h-5 w-5 rounded-full border-4 border-white border-opacity-40`}
          />
          Abriendo Portal
        </span>
      )}
    </button>
  );
};

export default BasicButton;

import { useEffect, useState } from 'react';
import {
  PlayIcon,
  StopIcon,
  TrashIcon,
  CheckIcon,
  MicrophoneIcon
} from '@heroicons/react/solid';
import useAudioRecorder from './useAudioRecorder';

// Components
import PauseIcon from 'components/tailwind/icons/PauseIcon';

enum RecorderState {
  recording = 'recording',
  paused = 'paused',
  stopped = 'stopped',
  empty = 'empty'
}

interface RecorderProps {
  broadcastAudioRecorded: (audio: string) => void;
  deleteAudio: () => void;
}

const Recorder = (props: RecorderProps) => {
  const { broadcastAudioRecorded, deleteAudio } = props;

  const [state, setState] = useState<RecorderState>(RecorderState.recording);
  const [label, setLabel] = useState<string>('00:00');
  const [timer, setTimer] = useState<number>(0);

  const player = useAudioRecorder({});

  useEffect(() => {
    if (state == RecorderState.recording && player.ready) {
      let interval = setInterval(() => {
        setTimer(timer + 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  });

  useEffect(() => {
    setLabel(parseTimer());
  }, [timer]);

  useEffect(() => {
    switch (state) {
      case RecorderState.recording:

      case RecorderState.paused:
      case RecorderState.stopped:
        break;
      case RecorderState.empty:
      default:
        break;
    }
  }, [state]);

  useEffect(() => {
    if (player.audio != null) broadcastAudioRecorded(player.audio);
  }, [player.audio]);

  const parseTimer = () => {
    let minutes = (timer / 60).toFixed(0).toString();
    let seconds = (timer % 60).toFixed(0).toString();

    if (minutes.length < 2) minutes = `${minutes}`;
    if (seconds.length < 2) seconds = `0${seconds}`;

    return `${minutes}:${seconds}`;
  };

  const onClickPlay = () => {
    player.play();
    setState(RecorderState.recording);
  };

  const onClickResume = () => {
    player.resume();
    setState(RecorderState.recording);
  };

  const onClickPause = () => {
    player.pause();
    setState(RecorderState.paused);
  };

  const onClickStop = () => {
    player.stop();
    setState(RecorderState.stopped);
  };

  const onClickDelete = () => {
    setTimer(0);
    player.restart();
    setState(RecorderState.empty);
  };

  return (
    <div className={'flex w-full flex-col gap-[10px] text-gray-800'}>
      <div className="relative flex flex-row items-center justify-center">
        <p className="absolute left-0 p-2 text-lg font-medium">{label}</p>

        {state == RecorderState.recording && (
          <div className="flex animate-pulse items-center gap-2 text-lg font-medium">
            <div className="h-[10px] min-w-[10px] rounded-full bg-red-500"></div>
            Grabando
          </div>
        )}

        {state == RecorderState.paused && (
          <div className="flex items-center gap-2 text-lg font-medium">
            <div className="h-[10px] min-w-[10px] rounded-full bg-yellow-500"></div>
            Pausado
          </div>
        )}
      </div>
      <div className="flex items-center justify-between">
        <button
          onClick={deleteAudio}
          disabled={state == RecorderState.empty}
          className="rounded-full p-2 text-gray-400 transition-all hover:text-gray-500"
        >
          <TrashIcon className="h-8 w-8" />
        </button>

        {state == RecorderState.empty && (
          <button onClick={onClickPlay}>
            <PlayIcon className={'h-12 w-12 text-primary '} />
          </button>
        )}
        {state == RecorderState.recording && (
          <button
            onClick={onClickPause}
            className="rounded-full p-2 text-gray-400 transition-all hover:text-gray-500"
          >
            <PauseIcon className="h-8 w-8" />
          </button>
        )}
        {state == RecorderState.stopped && (
          <button onClick={onClickStop} disabled={true}>
            <StopIcon className={'h-12 w-12 text-primary-200'} />
          </button>
        )}
        {state == RecorderState.paused && (
          <button
            onClick={onClickResume}
            className="rounded-full p-2 text-gray-400 transition-all hover:text-gray-500"
          >
            <MicrophoneIcon className="h-8 w-8" />
          </button>
        )}
        {(state == RecorderState.recording ||
          state === RecorderState.paused) && (
          <button
            onClick={onClickStop}
            className="rounded-full bg-primary p-1 transition-all hover:bg-primary-800"
          >
            <CheckIcon className={'h-[30px] w-[30px] text-white'} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Recorder;

import { getCookie } from 'helpers/handleCookies';
import { useDispatch, useSelector } from 'react-redux';
import {
  editFeatureNotification,
  getNotifications,
  pushNotification
} from 'reduxState/notification/notificationState';
import { Account } from 'types/accounts';
import { DigitalProductPurchase } from 'types/digitalProductPurchase';
import { Audio } from 'types/monetization';
import { INotificationRedux } from 'types/Notification';
import { supabase } from 'utils/supabase-client';

export const useNotification = () => {
  const notifications: INotificationRedux = useSelector(
    (state: any) => state.notification
  );
  const dispatch = useDispatch();

  const getFeaturesNotifications = async () => {
    const userToken = getCookie('token');

    if (!userToken) return;

    const dispatching = await getNotifications(userToken);

    dispatch(dispatching);
  };

  const answerQuestionNotification = async (url, id) => {
    const { error, data }: any = await supabase
      .from('questions')
      .update({
        url,
        answeredAt: new Date(),
        status: 'done'
      })
      .eq('id', id);

    if (error) {
      return { error };
    }

    const dispatching = editFeatureNotification(data[0]);

    dispatch(dispatching);

    return { message: 'OK' };
  };

  const rejectQuestionNotification = async (reason, id) => {
    const { error, data }: any = await supabase
      .from('questions')
      .update({
        reasonOfRejection: reason,
        rejected: true,
        answeredAt: new Date(),
        status: 'rejected'
      })
      .eq('id', id);

    if (error) {
      return { error };
    }

    const dispatching = editFeatureNotification(data[0]);

    dispatch(dispatching);

    return { message: 'OK' };
  };

  const answerGreetingNotification = async (url, id) => {
    const { error, data }: any = await supabase
      .from('greetings')
      .update({
        url,
        answeredAt: new Date(),
        status: 'done'
      })
      .eq('id', id);

    if (error) {
      return { error };
    }

    const dispatching = editFeatureNotification(data[0]);

    dispatch(dispatching);

    return { message: 'OK' };
  };

  const rejectGreetingNotification = async (reason, id) => {
    const { error, data }: any = await supabase
      .from('greetings')
      .update({
        reasonOfRejection: reason,
        rejected: true,
        answeredAt: new Date(),
        status: 'rejected'
      })
      .eq('id', id);

    if (error) {
      return { error };
    }

    const dispatching = editFeatureNotification(data[0]);

    dispatch(dispatching);

    return { message: 'OK' };
  };

  const answerAudioNotification = async (
    original: string,
    id: number,
    converted?: string
  ) => {
    const { error, data }: any = await supabase
      .from<Audio>('audios')
      .update({
        url: converted,
        originalUrl: original,
        answeredAt: new Date(),
        status: 'done'
      })
      .eq('id', id);

    if (error) {
      return { error };
    }

    const dispatching = editFeatureNotification(data[0]);

    dispatch(dispatching);

    return { message: 'OK' };
  };

  const rejectAudioNotification = async (reason: string, id: number) => {
    const { error, data }: any = await supabase
      .from<Audio>('audios')
      .update({
        reasonOfRejection: reason,
        rejected: true,
        answeredAt: new Date(),
        status: 'rejected'
      })
      .eq('id', id);

    if (error) {
      return { error };
    }

    const dispatching = editFeatureNotification(data[0]);

    dispatch(dispatching);

    return { message: 'OK' };
  };

  const pushNotificationFeature = (payload: any) => {
    const fan_id = payload.fan_id || payload.purchase_account_id;

    const select = payload.fan_id
      ? 'id, username, profilePicture, name'
      : 'linked_accounts, name, username, is_verified, profilePicture, id';

    supabase
      .from('accounts')
      .select(select)
      .eq('id', fan_id)
      .then(({ error, data }) => {
        if (error && fan_id) {
          console.log(error);
          console.log('pushNotificiationFeature() Error');
          return;
        }

        const dispatching = pushNotification({
          ...payload,
          fan_data: error ? null : data[0]
        });

        dispatch(dispatching);
      });
  };

  const listenFeaturesNotifications = (creatorId: string) => {
    const donations = supabase
      .from('donations')
      .on('*', (payload) => {
        if (payload.new.creator_id === creatorId) {
          pushNotificationFeature(payload.new);
        }
      })
      .subscribe();

    const questions = supabase
      .from('questions')
      .on('*', (payload) => {
        if (payload.new.creator_id === creatorId) {
          pushNotificationFeature(payload.new);
        }
      })
      .subscribe();

    const greetings = supabase
      .from('greetings')
      .on('*', (payload) => {
        if (payload.new.creator_id === creatorId) {
          pushNotificationFeature(payload.new);
        }
      })
      .subscribe();

    const audios = supabase
      .from('audios')
      .on('*', (payload) => {
        if (payload.new.creator_account_id === creatorId) {
          pushNotificationFeature(payload.new);
        }
      })
      .subscribe();

    const digitalProducts = supabase
      .from<DigitalProductPurchase>('digital_product_purchases')
      .on('*', (payload) => {
        if (
          payload.new.creator_account_id === creatorId &&
          payload.new.status === 'purchased'
        ) {
          pushNotificationFeature(payload.new);
        }
      })
      .subscribe();

    return { questions, greetings, donations, audios, digitalProducts };
  };

  return {
    answerAudioNotification,
    answerGreetingNotification,
    answerQuestionNotification,
    getFeaturesNotifications,
    listenFeaturesNotifications,
    notifications: notifications.data,
    rejectAudioNotification,
    rejectGreetingNotification,
    rejectQuestionNotification,
    status: notifications.status
  };
};

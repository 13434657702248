import React from 'react';

// Components
import ThemeToggleButton from 'components/tailwind/buttons/ThemeToggleButton';

interface IHeaderScreenProps {
  title: string;
  RightComponent?: React.ReactElement;
}

const HeaderScreen = ({ title, RightComponent = null }: IHeaderScreenProps) => {
  return (
    <div className="mb-6 flex items-center justify-between">
      <h2 className="text-2xl font-semibold">{title}</h2>

      {/* <ThemeToggleButton className="ml-auto mr-8" /> */}

      {RightComponent}
    </div>
  );
};

export default HeaderScreen;

import Tooltip from 'components/Tooltip';
import { classNames } from 'helpers/classNames';
import React from 'react';
import Button from '../../../buttons/Button';

// Components
import ConnectButton from './ConnectButton';

interface ICardProps {
  img: string;
  imgBackgroundColor?: string;
  title: string;
  description: string;
  buttonClassName?: string;
  buttonText: string;
  onClickButton: React.MouseEventHandler<HTMLButtonElement>;
  disableButton?: boolean;
  active?: boolean;
  loading?: boolean;
  tooltipText?: string;
  isConnected?: boolean;
  disconnectEnabled?: boolean;
}

const Card = ({
  img,
  imgBackgroundColor = '#181818',
  title,
  description,
  buttonClassName,
  buttonText,
  onClickButton,
  disableButton = false,
  active = true,
  loading = false,
  tooltipText = '',
  isConnected,
  disconnectEnabled
}: ICardProps) => {
  return (
    <div
      className={classNames(
        active ? 'opacity-100' : 'opacity-50',
        'flex w-full flex-col items-center rounded-lg border border-gray-300 bg-white px-3.5 py-3.5 md:flex-row md:px-6 md:py-5 lg:flex-col xl:flex-row'
      )}
    >
      <div className="flex w-full">
        <div
          className="mr-3 mb-auto flex h-10 min-w-[40px] items-center justify-center rounded-full sm:mb-0 md:my-auto"
          style={{
            background: imgBackgroundColor
          }}
        >
          <img className="h-5 w-5 text-white" src={img} alt="icon" />
        </div>

        <div className="flex w-full flex-col sm:flex-row">
          <div className="flex flex-grow flex-col">
            <h4 className="text-base font-medium leading-5 text-gray-900">
              {title}
            </h4>
            <h5 className="text-base text-gray-500">{description}</h5>
          </div>
        </div>
      </div>

      <ConnectButton
        loading={loading}
        isConnected={isConnected}
        onClick={onClickButton}
        size="medium"
        disabled={disableButton}
        disconnectEnabled={disconnectEnabled}
        initialText={buttonText}
        className="ml-auto mt-3 h-fit w-full md:my-auto md:w-fit lg:mt-3 lg:w-full xl:mt-2 xl:w-fit"
      />
    </div>
  );
};

export default Card;

import { useState } from 'react';

// Components
import RectangleFileInput from 'components/tailwind/inputs/RectangleFileInput';
import { EmptyUploader } from './EmptyUploader';
import { AudioUploaderState } from './types';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';

interface AudioUploaderProps {
  file: File;
  setFile: (file: File) => void;
  setAction?: () => void;
  resetState?: () => void;
}

export const AudioUploader = ({
  file,
  setFile,
  resetState,
  setAction
}: AudioUploaderProps) => {
  const [state, setState] = useState<AudioUploaderState>(
    AudioUploaderState.empty
  );
  const [message, setMessage] = useState<string>('');

  const showUploader = () => {
    setAction && setAction();
    setState(AudioUploaderState.uploading);
  };

  const resetStateProxy = () => {
    setState(AudioUploaderState.empty);
    resetState();
  };

  if (state === AudioUploaderState.empty)
    return <EmptyUploader showUploader={showUploader} />;

  if (state === AudioUploaderState.uploading)
    return (
      <>
        <RectangleFileInput
          previsualize={false}
          setFile={setFile}
          file={file}
          type="audio"
          resetState={resetStateProxy}
          setErrorMessage={setMessage}
        />
        {message && <ErrorMessage errorText={message} />}
      </>
    );
};

import EmptyState from 'components/dashboard/EmptyState';
import HeaderScreen from 'components/dashboard/HeaderScreen';
import AudioCard from 'components/dashboard/notifications/cards/AudioCard';
import DigitalProductCard from 'components/dashboard/notifications/cards/DigitalProductCard';
import DonationCard from 'components/dashboard/notifications/cards/DonationCard';
import GreetingCard from 'components/dashboard/notifications/cards/GreetingCard';
import QuestionCard from 'components/dashboard/notifications/cards/QuestionCard';
import SkeletonCard from 'components/dashboard/notifications/cards/SkeletonCard';
import SimpleFilter, {
  TOptions
} from 'components/tailwind/filters/SimpleFilter';
import { useNotification } from 'hooks/useNotification';
import { useEffect, useState } from 'react';

// Components
import SubscriptionCard from 'components/dashboard/notifications/cards/SubscriptionCard';

const options: TOptions[] = [
  {
    textContent: 'Todos',
    value: 'all',
    defaultValue: true
  },
  {
    textContent: 'Pendientes',
    value: 'pending'
  },
  {
    textContent: 'Completados',
    value: 'done'
  }
];

const NotificationsScreen = () => {
  const [filter, setFilter] = useState<TOptions>(
    options.find((option) => option.defaultValue)
  );
  const { notifications, status: notificationStatus } = useNotification();

  const [localNotifications, setLocalNotifications] = useState(
    notifications.features
  );

  useEffect(() => {
    if (filter.value === 'all') setLocalNotifications(notifications.features);
    else {
      setLocalNotifications(
        notifications.features.filter(
          (notification) => notification.status === filter.value
        )
      );
    }
  }, [filter, notifications]);

  return (
    <div className="relative flex min-h-full flex-col">
      <HeaderScreen
        title="Notificaciones"
        RightComponent={<SimpleFilter setValue={setFilter} options={options} />}
      />
      {localNotifications?.length === 0 && notificationStatus !== 0 && (
        <EmptyState
          absolute={false}
          title="Aún no tienes ninguna actividad en tu página."
          dashboard={true}
          className="flex-1"
        />
      )}
      {notificationStatus === 0 && <SkeletonCard quantity={15} />}
      {notificationStatus === 1 &&
        localNotifications.map((notification, index) => {
          if (notification.status && notification.status === 'paying')
            return null;

          return (
            <div key={'noti-card:' + notification.id + `, index: ${index}`}>
              {notification.type === 'donation' && (
                <DonationCard notification={notification} />
              )}
              {notification.type === 'question' && (
                <QuestionCard notification={notification} />
              )}
              {notification.type === 'greeting' && (
                <GreetingCard notification={notification} />
              )}
              {notification.type === 'audio' && (
                <AudioCard notification={notification} />
              )}
              {(notification.type === 'fileProtected' ||
                notification.type === 'externalLink') && (
                <DigitalProductCard notification={notification} />
              )}
              {notification.metadata && (
                <SubscriptionCard notification={notification} />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default NotificationsScreen;

type IconButtonProps = {
  children: React.ReactNode;
  onClick?: () => void | Promise<void>;
};

const IconButton: React.FC<IconButtonProps> = ({ children, onClick }) => {
  return (
    <button
      className={'text-gray-400 hover:text-gray-500 focus:outline-none'}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
};

export default IconButton;

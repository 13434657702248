import { MenuAction } from './types';

interface ActionButtonProps<T> {
  action: MenuAction<T>;
  value: T;
}

const ActionButton = <T,>(props: ActionButtonProps<T>) => {
  const { action, value } = props;

  return (
    <button
      className={'block w-full px-4 py-2 text-left text-sm text-gray-700'}
      id={'menu-item-0'}
      onClick={() => action.event(value)}
      role={'menuitem'}
      tabIndex={1}
    >
      {action.label}
    </button>
  );
};

export default ActionButton;

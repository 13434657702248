import {
  BlockScreen,
  AudienceScreen,
  SubscriptionsScreen,
  AnalyticsScreen,
  ProductsScreen,
  PurchaseScreen
} from 'types/App';
import { AppTypes } from './appTypes';

const editBlockScreen = (payload: BlockScreen) => {
  return {
    type: AppTypes.edit_block_screen,
    payload
  };
};

const editAudienceScreen = (payload: AudienceScreen) => {
  return {
    type: AppTypes.edit_audience_screen,
    payload
  };
};

const editSubscriptionsScreen = (payload: SubscriptionsScreen) => {
  return {
    type: AppTypes.edit_subscriptions_screen,
    payload
  };
};

const editAnalyticsScreen = (payload: AnalyticsScreen) => {
  return {
    type: AppTypes.edit_analytics_screen,
    payload
  };
};

const editProductsScreen = (payload: ProductsScreen) => {
  return {
    type: AppTypes.edit_products_screen,
    payload
  };
};

const editPurchaseScreen = (payload: PurchaseScreen) => {
  return {
    type: AppTypes.edit_purchase_screen,
    payload
  };
};

const editProfileViewer = (payload: boolean) => {
  return {
    type: AppTypes.edit_profile_viewer,
    payload
  };
};

const userLogout = () => {
  return {
    type: AppTypes.user_logout
  };
};

export {
  editBlockScreen,
  editAudienceScreen,
  editSubscriptionsScreen,
  editAnalyticsScreen,
  editProductsScreen,
  editPurchaseScreen,
  editProfileViewer,
  userLogout
};

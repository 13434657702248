import { FolderIcon } from '@heroicons/react/outline';

export interface CommandMenuNotFoundProps {}

export const CommandMenuNotFound = ({}: CommandMenuNotFoundProps) => {
  return (
    <div className="py-14 px-6 text-center sm:px-14">
      <FolderIcon className="mx-auto h-6 w-6 text-gray-400" />
      <p className="mt-4 text-sm text-gray-900">
        No hemos encontrado ningún resultado de acuerdo a tu búsqueda. Intenta
        de nuevo.
      </p>
    </div>
  );
};

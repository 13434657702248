import React from 'react';
import { useNotification } from 'hooks/useNotification';

const NotificationCount = () => {
  const {
    notifications: { pendingCount }
  } = useNotification();

  if (pendingCount === 0) return null;

  return (
    <div className="flex h-7 w-9 items-center justify-center rounded-full bg-[#D5D4FF] font-semibold text-primary">
      {pendingCount > 99 ? '99+' : pendingCount}
    </div>
  );
};

export default NotificationCount;

import { useFormik } from 'formik';
import { classNames } from 'helpers/classNames';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import React from 'react';
import toast from 'react-hot-toast';
import { supabase } from 'utils/supabase-client';
import * as Yup from 'yup';
import { handleDeleteBlockClick } from 'requests/local_next/analytics';

// Components
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import Input from 'components/tailwind/inputs/Input';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import TextArea from 'components/tailwind/inputs/TextArea';
import PriceInput from 'components/tailwind/inputs/PriceInput';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import CharacterCounter from 'components/tailwind/inputs/CharacterCounter';

const EditMonetizationForm = ({
  selectedBlock,
  initialValues,
  closeModal,
  setBlocks,
  setLoading
}) => {
  const { user, updateUserRedux } = useUser();

  const formik: any = useFormik({
    initialValues,
    validationSchema: Yup.object({
      title: Yup.string()
        .required('El título es requerido')
        .max(64, 'El título debe tener como máximo 64 carácteres'),
      description: Yup.string().max(
        260,
        'La URL debe tener como máximo 4000 carácteres'
      ),
      price: Yup.number()
        .required('El precio es requerido')
        .min(1, 'El precio debe ser mayor a 0 USD')
        .max(1000000, 'El precio debe ser menor a 1000000 USD')
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const blocks = user.blocks.reduce((acc, block) => {
        if (
          block.id === selectedBlock.id &&
          selectedBlock.feature !== 'gifts'
        ) {
          acc.push({
            id: selectedBlock.id,
            title: values.title,
            description: values.description,
            price: values.price,
            visible: block.visible,
            type: block.type,
            feature: block.feature
          });
        } else if (
          block.id === selectedBlock.id &&
          selectedBlock.feature === 'gifts'
        ) {
          acc.push({
            id: selectedBlock.id,
            title: values.title,
            description: values.description,
            visible: block.visible,
            type: block.type,
            feature: block.feature
          });
        } else {
          acc.push(block);
        }
        return acc;
      }, []);

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
        setLoading(false);
      }

      if (data) {
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Editado', defaultToastOptions);
        closeModal();
        setLoading(false);
      }
    }
  });

  const handleOnDeleteBlock = async () => {
    const confirmed = confirm(
      '¿Estás seguro de que quieres eliminar este bloque?'
    );

    if (confirmed) {
      setLoading(true);
      const blocks = user.blocks.reduce((acc, block) => {
        if (block.id !== selectedBlock.id) {
          acc.push(block);
        }
        return acc;
      }, []);

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
      }

      if (data) {
        let last_url: string = '';

        if (selectedBlock.feature === 'greetings') {
          last_url = '/saludos';
        } else if (selectedBlock.feature === 'questions') {
          last_url = '/preguntas';
        } else if (selectedBlock.feature === 'gifts') {
          last_url = '/regalos';
        } else {
          last_url = '/audios';
        }

        handleDeleteBlockClick({
          block_id: selectedBlock.id,
          last_title: selectedBlock.title,
          last_url
        });
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Eliminado', defaultToastOptions);
      }

      setLoading(false);
      closeModal();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormBody>
        <div>
          <LabelRequired
            name="title"
            title="Título del enlace"
            isRequired={true}
          />
          <div className="mt-1">
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="Escribe el título"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={formik.errors.title && formik.touched.title}
            />
          </div>
          {formik.errors.title && formik.touched.title && (
            <ErrorMessage errorText={formik.errors.title} />
          )}
        </div>

        <div className="mt-4">
          <LabelRequired
            name="description"
            title="Descripción"
            isRequired={false}
          />
          <div className="mt-1">
            <TextArea
              name="description"
              id="description"
              placeholder="Escribe una descripción del producto"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={formik.errors.description && formik.touched.description}
              rows={4}
              maxLength={230}
            />
            <CharacterCounter
              value={formik.values.description}
              maxLength={230}
            />
          </div>
          {formik.errors.description && formik.touched.description && (
            <ErrorMessage errorText={formik.errors.description} />
          )}
        </div>
        {selectedBlock.feature !== 'gifts' && (
          <div className="mt-4">
            <LabelRequired name="price" title="Precio" isRequired={true} />
            <PriceInput
              name="price"
              id="price"
              aria-describedby="price-currency"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.price}
              className="mt-1"
            />
            {formik.errors.price && formik.touched.price && (
              <ErrorMessage errorText={formik.errors.price} />
            )}
          </div>
        )}
      </FormBody>
      <FormFooter>
        <SecondaryButton
          title="Eliminar"
          onClick={handleOnDeleteBlock}
          variant={variantType.red}
          className="px-6"
          disabled={formik.isSubmitting}
        />
        <BasicButton
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          loading={formik.isSubmitting}
          title="Guardar cambios"
          className="w-40 whitespace-nowrap"
          loadingChildren={'Guardando'}
        />
      </FormFooter>
    </form>
  );
};

export default EditMonetizationForm;

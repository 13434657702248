import SpotifyPlayer from 'components/players/SpotifyPlayer';
import TikTokPlayer_V2 from 'components/players/TikTokPlayer';
import TwitterPlayer from 'components/players/TwitterPlayer';
import { useFormik } from 'formik';
import { classNames } from 'helpers/classNames';
import getYoutubeId from 'helpers/getYoutubeId';
import { matchHttp } from 'helpers/matchHttp';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import FacebookPlayer from 'react-player/facebook';
import TwitchVideoPlayer from 'react-player/twitch';
import YoutubeVideoPlayer from 'react-player/youtube';
import { supabase } from 'utils/supabase-client';
import AxiosShortenerUrl from 'requests/local_next/AxiosShortenerUrl';
import { tiktokShort_RegExp, facebookShort_RegExp } from 'helpers/customRegExp';
import * as Yup from 'yup';

// Components
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import Input from 'components/tailwind/inputs/Input';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import YoutubePlayerMedia from 'components/profile/blocks/media/YoutubePlayerMedia';
import TwitchPlayerMedia from 'components/profile/blocks/media/TwitchPlayerMedia';
import FetchWebsiteTitle from 'requests/local_next/FetchWebsiteTitle';

const playerPlatforms = {
  youtube: YoutubeVideoPlayer,
  spotify: SpotifyPlayer,
  twitch_live: TwitchVideoPlayer,
  tiktok: TikTokPlayer_V2,
  fbgaming: FacebookPlayer,
  twitter: TwitterPlayer
};

enum platformsUrl {
  youtube = 'www.youtube.com',
  spotify = 'open.spotify.com',
  twitch_live = 'www.twitch.tv',
  tiktok = 'www.tiktok.com',
  fbgaming = 'www.facebook.com/gaming',
  twitter = 'www.twitter.com'
}

const EditMediaForm = ({
  selectedBlock,
  initialValues,
  closeModal,
  setBlocks,
  setLoading
}) => {
  const { user, updateUserRedux } = useUser();
  const [disableUrlInput, setDisableUrlInput] = useState<boolean>(false);

  Yup.addMethod(Yup.string, 'urlMatchPlatform', function (errorMessage) {
    return this.test(`test-card-type`, errorMessage, function (url) {
      const { path, createError } = this;

      return (
        playerPlatforms[selectedBlock.platform].canPlay(url) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: Yup.object({
      title: Yup.string()
        .required('El título es requerido')
        .max(64, 'El título debe tener como máximo 64 carácteres'),
      url: Yup.string()
        .matches(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
          'URL inválida'
        )
        .required('La URL es requerida')
        .max(4000, 'La URL debe tener como máximo 4000 carácteres')
        // @ts-ignore
        .urlMatchPlatform(
          'La URL no es válida para el tipo de contenido seleccionado'
        )
    }),
    onSubmit: async (values) => {
      setLoading(true);
      if (
        selectedBlock.platform === 'youtube' &&
        !YoutubeVideoPlayer.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      } else if (
        selectedBlock.platform === 'spotify' &&
        !SpotifyPlayer.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      } else if (
        selectedBlock.platform === 'twitch_live' &&
        !TwitchVideoPlayer.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      } else if (
        selectedBlock.platform === 'tiktok' &&
        !TikTokPlayer_V2.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      } else if (
        selectedBlock.platform === 'fbgaming' &&
        !FacebookPlayer.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      } else if (
        selectedBlock.platform === 'twitter' &&
        !TwitterPlayer.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      }

      const blocks = user.blocks.reduce((acc, block) => {
        if (block.id === selectedBlock.id) {
          acc.push({
            id: selectedBlock.id,
            title: values.title,
            url: values.url,
            platform: block.platform,
            visible: block.visible,
            type: block.type
          });
        } else {
          acc.push(block);
        }
        return acc;
      }, []);

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
        setLoading(false);
      }

      if (data) {
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Editado', defaultToastOptions);
        closeModal();
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    const effectFunc = async () => {
      if (
        selectedBlock.platform === 'tiktok' ||
        selectedBlock.platform === 'fbgaming'
      ) {
        if (
          tiktokShort_RegExp.test(formik.values.url) ||
          facebookShort_RegExp.test(formik.values.url)
        ) {
          setDisableUrlInput(true);
          const { error, data } = await AxiosShortenerUrl(formik.values.url);

          if (error) {
            toast.error(error, defaultToastOptions);
          } else {
            formik.values.url = data;
          }

          setDisableUrlInput(false);
        }
      }
    };
    effectFunc();
  }, [formik.values]);

  useEffect(() => {
    const effectFunc = async () => {
      const url = formik.values.url

      if (
        !YoutubeVideoPlayer.canPlay(url) &&
        !SpotifyPlayer.canPlay(url)
      ) return

      const { error, data } = await FetchWebsiteTitle(url)

      if (error) {
        console.error(error)
        return
      }

      const theTitle = data.length > 60 ? data.slice(0, 61) + "..." : data

      formik.setFieldValue("title", theTitle || "No titulo")
    }

    effectFunc()
  }, [formik.values.url])

  const handleOnDeleteBlock = async () => {
    const confirmed = confirm(
      '¿Estás seguro de que quieres eliminar este bloque?'
    );

    if (confirmed) {
      setLoading(true);
      const blocks = user.blocks.reduce((acc, block) => {
        if (block.id !== selectedBlock.id) {
          acc.push(block);
        }
        return acc;
      }, []);

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
      }

      if (data) {
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Eliminado', defaultToastOptions);
      }

      setLoading(false);
      closeModal();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormBody>
        <div>
          <LabelRequired
            name="title"
            title="Título del enlace"
            isRequired={true}
          />
          <div className="mt-1">
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="Escribe el título"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={formik.errors.title && formik.touched.title}
            />
          </div>
          {formik.errors.title && formik.touched.title && (
            <ErrorMessage errorText={formik.errors.title} />
          )}
        </div>
        <div className="mt-4">
          <LabelRequired name="url" title="URL" isRequired={true} />
          <div className="mt-1">
            <Input
              type="text"
              name="url"
              id="url"
              placeholder={platformsUrl[selectedBlock.platform]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.url}
              disabled={disableUrlInput}
              error={formik.errors.url && formik.touched.url}
            />
          </div>
          {formik.errors.url && formik.touched.url && (
            <ErrorMessage errorText={formik.errors.url} />
          )}
        </div>
        {!formik.values.url && (
          <div>
            <LabelRequired
              name="url"
              title="Vista previa"
              className="mt-4 mb-1"
              isRequired={true}
            />
            <div
              className="relative inset-0 mb-2 flex items-center"
              aria-hidden="true"
            >
              <div className="flex h-24 w-full items-center justify-center rounded-md bg-gray-100">
                <span className="text-xs text-gray-500">
                  Al pegar tu enlace podrás previsualizarlo aquí
                </span>
              </div>
            </div>
          </div>
        )}
        {selectedBlock.platform === 'youtube' &&
          YoutubeVideoPlayer.canPlay(formik.values.url) && (
            <div className={'mt-4'}>
              <YoutubePlayerMedia url={formik.values.url} />
            </div>
          )}
        {selectedBlock.platform === 'spotify' &&
          SpotifyPlayer.canPlay(matchHttp(formik.values.url)) && (
            <div className={'mt-4'}>
              <SpotifyPlayer url={matchHttp(formik.values.url)} />
            </div>
          )}
        {selectedBlock.platform === 'twitch_live' &&
          TwitchVideoPlayer.canPlay(matchHttp(formik.values.url)) && (
            <div className={'mt-4'}>
              {/*@ts-ignore*/}
              <TwitchPlayerMedia url={matchHttp(formik.values.url)} />
            </div>
          )}

        {selectedBlock.platform === 'tiktok' &&
          TikTokPlayer_V2.canPlay(matchHttp(formik.values.url)) && (
            <div className="my-4">
              <div className="flex justify-center">
                <TikTokPlayer_V2 url={matchHttp(formik.values.url)} size="xl" />
              </div>
            </div>
          )}

        {selectedBlock.platform === 'fbgaming' &&
          FacebookPlayer.canPlay(matchHttp(formik.values.url)) && (
            <div className={'mt-4'}>
              {/*@ts-ignore*/}
              <FacebookPlayer
                url={matchHttp(formik.values.url)}
                width="100%"
                height="100%"
              />
            </div>
          )}

        {selectedBlock.platform === 'twitter' &&
          TwitterPlayer.canPlay(matchHttp(formik.values.url)) && (
            <div className="mt-4">
              <TwitterPlayer url={matchHttp(formik.values.url)} />
            </div>
          )}
      </FormBody>
      <FormFooter>
        <SecondaryButton
          title="Eliminar"
          onClick={handleOnDeleteBlock}
          variant={variantType.red}
          className="px-6"
          disabled={formik.isSubmitting}
        />
        <BasicButton
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          loading={formik.isSubmitting}
          title="Guardar cambios"
          className="w-40 whitespace-nowrap"
          loadingChildren={'Guardando'}
        />
      </FormFooter>
    </form>
  );
};

export default EditMediaForm;

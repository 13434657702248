import { useFormik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import { Account, Block } from 'types/accounts';
import { UseFormikResult } from 'types/formikTypes';
import {
  MonetizationBlockType,
  MonetizationForm,
  MonetizationType
} from 'types/monetization';
import { IUserEdit } from 'types/User';
import { supabase } from 'utils/supabase-client';

// Components
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import CharacterCounter from 'components/tailwind/inputs/CharacterCounter';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import Input from 'components/tailwind/inputs/Input';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import PriceInput from 'components/tailwind/inputs/PriceInput';
import SelectMenu from 'components/tailwind/inputs/SelectMenu';
import TextArea from 'components/tailwind/inputs/TextArea';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import { Mixpanel } from 'utils/mixpanel';
// import Switch from 'components/tailwind/inputs/Switch';

const blockTypes: Array<MonetizationBlockType> = [
  // { id: 1, name: 'Regalos', value: MonetizationType.gifts },
  { id: 2, name: 'Preguntas', value: MonetizationType.questions },
  { id: 3, name: 'Saludos', value: MonetizationType.greetings },
  { id: 4, name: 'Audio', value: MonetizationType.audio }
];

const AddMonetizationForm = ({
  closeModal,
  setBlocks,
  setLoading,
  handleClose
}) => {
  const [selected, setSelected] = useState(blockTypes[0]);
  const [errorSelectionMessage, setErrorSelectionMessage] = useState('');
  const [onTop, setOnTop] = useState(true);

  const { user, updateUserRedux } = useUser();

  const formik: UseFormikResult = useFormik<MonetizationForm>({
    initialValues: {
      description: '',
      price: undefined,
      title: ''
    },
    validationSchema: Yup.object({
      selection: Yup.string(),
      title: Yup.string()
        .required('El título es requerido')
        .max(64, 'El título debe tener como máximo 64 carácteres'),
      description: Yup.string().max(
        260,
        'El título debe tener como máximo 260 carácteres'
      ),
      price: Yup.number()
        .required('El precio es requerido')
        .min(1, 'El precio debe ser mayor a 0 USD')
        .max(1000000, 'El precio debe ser menor a 1000000 USD')
    }),
    onSubmit: async (values) => await createMonetizationBlock(values)
  });

  const createMonetizationBlock = async (values: MonetizationForm) => {
    let finish = false;

    setLoading(true);

    const { data: account, error: getAccountError } = await supabase
      .from<Account>('accounts')
      .select('blocks')
      .eq('id', user.id);

    if (getAccountError) {
      toast.error(getAccountError.message, defaultToastOptions);
      return setLoading(false);
    }

    if (account[0]?.blocks) {
      const blocks = account[0].blocks;

      finish = monetizationBlockAlreadyExists(blocks);
    }

    // Little hack to finish the funtion if an error is found.
    if (finish) return setLoading(false);

    const newValues = {
      ...values
    };

    if (selected.value === MonetizationType.gifts) delete newValues.price;

    const newBlock = {
      ...newValues,
      id: uuidv4(),
      visible: true,
      type: 'monetization',
      feature: selected.value
    };

    const newBlocksPosition = onTop
      ? [newBlock, ...user.blocks]
      : [...user.blocks, newBlock];

    const { data: updatedAccount, error: updatedAccountError } = await supabase
      .from<Account>('accounts')
      .update({
        blocks: user.blocks ? newBlocksPosition : [newBlock]
      })
      .eq('id', user.id);

    if (updatedAccountError) {
      toast.error(updatedAccountError.message, defaultToastOptions);
      return setLoading(false);
    }

    if (updatedAccount && updatedAccount.length > 0) {
      updateUserRedux(updatedAccount[0] as unknown as IUserEdit);
      setBlocks(updatedAccount[0].blocks);

      Mixpanel.track('Added block', {
        username: user.username,
        type: 'monetization'
      });

      toast.success('Bloque Añadido', defaultToastOptions);
      setLoading(false);
      handleClose();
    }
  };

  const monetizationBlockAlreadyExists = (blocks: Array<Block>): boolean => {
    let result = false;

    for (let i = 0; i < blocks.length; i++) {
      if (
        blocks[i].type === 'monetization' &&
        blocks[i].feature === 'gifts' &&
        selected.value === MonetizationType.gifts
      ) {
        result = true;
        setErrorSelectionMessage('Ya tienes un bloque de regalos');
        break;
      }

      if (
        blocks[i].type === 'monetization' &&
        blocks[i].feature === 'questions' &&
        selected.value === MonetizationType.questions
      ) {
        result = true;
        setErrorSelectionMessage('Ya tienes un bloque de preguntas');
        break;
      }

      if (
        blocks[i].type === 'monetization' &&
        blocks[i].feature === 'greetings' &&
        selected.value === MonetizationType.greetings
      ) {
        result = true;
        setErrorSelectionMessage('Ya tienes un bloque de saludos');
        break;
      }

      if (
        blocks[i].type === 'monetization' &&
        blocks[i].feature === 'audio' &&
        selected.value === MonetizationType.audio
      ) {
        result = true;
        setErrorSelectionMessage('Ya tienes un bloque de audios');
        break;
      }
    }

    return result;
  };

  const changeSelection = (e) => {
    setSelected(e);
    formik.resetForm({
      values: {
        description: '',
        price: '',
        title: ''
      }
    });
    setErrorSelectionMessage('');
  };

  const filterBlockTypes = blockTypes.filter((block) => {
    if (block.id === 2) {
      return user.internal_options.blocks?.question !== false
    }

    else if (block.id === 3) {
      return user.internal_options.blocks?.greeting !== false
    }

    else if (block.id === 4) {
      return user.internal_options.blocks?.audio !== false
    }

    return true
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormBody>
        <LabelRequired title="Tipo de bloque" isRequired={true} />
        <SelectMenu
          options={filterBlockTypes}
          value={selected}
          onChange={(e) => changeSelection(e)}
        />
        {errorSelectionMessage && (
          <ErrorMessage errorText={errorSelectionMessage} />
        )}
        <div className="mt-4">
          <LabelRequired
            name="title"
            title="Título del enlace"
            isRequired={true}
          />
          <div className="mt-1">
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="Escribe el título"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={(formik.errors.title && formik.touched.title) as boolean}
            />
          </div>
          {formik.errors.title && formik.touched.title && (
            <ErrorMessage errorText={formik.errors.title as string} />
          )}
        </div>
        <div className="mt-4">
          <LabelRequired
            name="description"
            title="Descripción"
            isRequired={false}
          />
          <div className="mt-1">
            <TextArea
              name="description"
              id="description"
              placeholder="Escribe una descripción del producto"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                (formik.errors.description &&
                  formik.touched.description) as boolean
              }
              rows={4}
            />
            <CharacterCounter
              value={formik.values.description}
              maxLength={230}
              className="mt-1"
            />
          </div>
          {formik.errors.description && formik.touched.description && (
            <ErrorMessage errorText={formik.errors.description as string} />
          )}
        </div>
        {selected.id !== 1 && (
          <div className="mt-4">
            <LabelRequired
              name="price"
              title="Precio"
              isRequired={true}
              className="mb-1"
            />
            <PriceInput
              name="price"
              id="price"
              aria-describedby="price-currency"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.price}
              error={(formik.errors.price && formik.touched.price) as boolean}
            />
            {formik.errors.price && formik.touched.price && (
              <ErrorMessage errorText={formik.errors.price as string} />
            )}
          </div>
        )}
        {/* //! Logic to add on Top or bottom of the block stack */}
        {/* <div>
          <Switch show={onTop} setShow={() => setOnTop(!onTop)} />
          <span>Logic to Add to top or bottom, true for top</span>
        </div> */}
      </FormBody>
      <FormFooter>
        <WhiteButton
          title="Cancelar"
          onClick={closeModal}
          disabled={formik.isSubmitting}
          className="px-6"
        />
        <BasicButton
          type="submit"
          disabled={(!formik.isValid || !formik.dirty) as boolean}
          title="Añadir"
          loading={formik.isSubmitting}
          className="w-40"
          loadingChildren="Añadiendo"
        />
      </FormFooter>
    </form>
  );
};

export default AddMonetizationForm;

// Helpers
import { classNames } from 'helpers/classNames';

type CardWithAvatarAndActionsProps = {
  actions?: React.ReactElement;
  avatar: React.ReactElement;
  onClick?: () => void;
  showAvatar?: boolean;
  subtitle: React.ReactNode;
  title?: React.ReactNode;
};

const CardWithAvatarAndActions: React.FC<CardWithAvatarAndActionsProps> = ({
  actions,
  avatar,
  onClick,
  showAvatar = true,
  subtitle,
  title
}) => {
  return (
    <div
      className={classNames(
        onClick && 'cursor-pointer',
        'block rounded-md border border-gray-200 shadow-sm'
      )}
    >
      <div className="px-5 py-4 sm:py-5 sm:px-6">
        <div className="flex items-center">
          <div
            className={classNames('flex cursor-pointer gap-3')}
            onClick={onClick}
          >
            {showAvatar && avatar}

            <div className="min-w-0 cursor-pointer overflow-hidden text-ellipsis">
              <p className="flex-1 truncate text-base font-semibold text-gray-700">
                {title ? title : 'N/A'}
              </p>
              <p className="flex-1 truncate text-base leading-snug text-gray-500">
                {subtitle}
              </p>
            </div>
          </div>

          <div className="mt-2 ml-auto flex items-center sm:mt-0">
            {actions}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWithAvatarAndActions;

// Helpers
import { classNames } from 'helpers/classNames';

interface IProgressBarProps {
  className?: string;
  progress: number;
}

const ProgressBar: React.FC<IProgressBarProps> = (props) => {
  const { className, progress } = props;

  return (
    <div className={classNames(className, 'flex h-3 w-full justify-center')}>
      <div className={'w-full rounded-full bg-primary-100'}>
        <div
          className={'h-full w-full rounded-full bg-primary transition-all'}
          style={{
            width: `${progress}%`
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;

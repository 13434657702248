// Helpers
import { classNames } from 'helpers/classNames';

type LabelRequiredProps = {
  name?: string;
  title: string;
  className?: string;
  isRequired?: boolean;
};

const LabelRequired: React.FC<LabelRequiredProps> = ({
  name,
  title,
  className,
  isRequired = false
}) => {
  return (
    <label
      htmlFor={name}
      className={classNames(
        className,
        'block text-base font-medium text-gray-700'
      )}
    >
      {title}
      <span className="text-red-600">{isRequired && '*'}</span>{' '}
      <span className="font-normal text-gray-400">
        {isRequired ? '(Obligatorio)' : '(Opcional)'}
      </span>
    </label>
  );
};

export default LabelRequired;

import { CommandMenuOption } from './option';
import { CommandAction } from './types';

export interface CommandMenuEmptySearchProps {
  actions: Array<CommandAction>;
}

export const CommandMenuEmptySearch = ({
  actions
}: CommandMenuEmptySearchProps) => {
  return (
    <li className="p-2">
      <ul className="text-sm text-gray-700">
        {actions
          .filter((action) => action.predefined)
          .map((action) => (
            <CommandMenuOption action={action} key={action.key} />
          ))}
      </ul>
    </li>
  );
};

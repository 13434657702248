// Libraries
import React, { useEffect } from 'react';

// Requests
import { AxiosAllData } from 'requests/local_api/AxiosAnalytics';

// Hooks
import { useUser } from 'hooks/useUser';
import useApp from 'hooks/useApp';

// Components
import Dropdown from 'components/tailwind/inputs/DropDown';

export interface IOnValues {
  optionSelected: TOptions;
  clicks: Array<{
    value: number;
    title: string;
    url: string;
    deleted?: boolean;
  }>;
  donations: number;
  greetings: number;
  questions: number;
  audios: number;
  digital_products: number;
  subscriptions: {
    balance: number;
    quantity: number;
    growth: number;
  };
  visits: number;
}

interface IAnalyticsHeaderRightProps {
  onValues: (value: IOnValues) => void;
}

const options = [
  { textContent: 'Hoy', value: 'today', defaultValue: true },
  { textContent: 'Esta semana', value: '7d' },
  { textContent: 'Este mes', value: '30d' },
  { textContent: 'Todo el tiempo', value: 'infinity' }
];

type TOptions = typeof options[number];

export const AnalyticsHeaderRight = ({
  onValues
}: IAnalyticsHeaderRightProps) => {
  const { user, status } = useUser();
  const { app, editAnalyticsRedux } = useApp();

  useEffect(() => {
    analyticsFunc(app.analyticsScreen.data.optionSelected.value);
  }, [status, user]);

  const analyticsFunc = async (thePeriod: any) => {
    if (status !== 1) return;

    let thePeriod_1 = 'today';

    if (thePeriod === '7d') thePeriod_1 = 'weekly';
    else if (thePeriod === '30d') thePeriod_1 = 'monthly';
    else if (thePeriod === 'infinity') thePeriod_1 = 'infinity';

    const { error, data } = await AxiosAllData(thePeriod_1);

    if (error) {
      console.log({ error });
      return;
    }

    onValues({
      optionSelected: options.find((option) => option.value === thePeriod),
      ...data
    });
  };

  const handleOnChange = (option) => {
    editAnalyticsRedux({
      isLoading: true,
      data: {
        optionSelected: option,
        clicks: [],
        donations: 0,
        greetings: 0,
        questions: 0,
        audios: 0,
        digital_products: 0,
        subscriptions: { balance: 0, quantity: 0, growth: 0 },
        visits: 0
      }
    });
    analyticsFunc(option.value);
  };

  return (
    <Dropdown
      value={app.analyticsScreen.data.optionSelected}
      onChange={(e) => handleOnChange(e)}
      options={options}
    />
  );
};

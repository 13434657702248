import { classNames } from 'helpers/classNames';
import React from 'react';

// Components
import Switch from 'components/tailwind/inputs/Switch';

interface ISwitchCardProps {
  className?: string;
  title: string;
  description: string;
  actived: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  loading?: boolean;
  Icon?: React.ReactElement;
  disabled?: boolean;
}

const SwitchCard2 = ({
  className = '',
  title,
  Icon,
  description,
  actived,
  onClick,
  loading = false,
  disabled = false
}: ISwitchCardProps) => {
  return (
    <div
      className={`dark:bg-[#2A2A2A] flex items-center justify-between rounded-lg border border-gray-300 px-6 py-5 shadow-sm ${className}`}
    >
      <div className="flex items-center">
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-mf-purple-50">
          {!loading && Icon && Icon}
        </div>
        <div className="ml-2 flex flex-1 flex-col">
          <h3
            className={classNames(
              loading && 'iw_skeleton h-5',
              '-mb-1 text-base font-medium text-gray-900'
            )}
          >
            {!loading && title}
          </h3>
          <p
            className={classNames(
              loading && 'iw_skeleton mt-1',
              'mr-1 mt-1 text-sm font-normal text-gray-500'
            )}
          >
            {!loading && description}
          </p>
        </div>
      </div>
      {!loading && (
        <Switch show={actived} disabled={disabled} setShow={onClick} />
      )}
    </div>
  );
};

export default SwitchCard2;

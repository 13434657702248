import { AxiosApi } from '../AxiosBase';
import { getCookie } from 'helpers/handleCookies';

export const AxiosWallets = async () => {
  const userToken = getCookie('token');

  try {
    if (!userToken) throw 'No estas logeado';

    const { data } = await AxiosApi.get('/audience/wallets', {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    if (data.message !== 'OK') throw data.message;

    return data.data;
  } catch (e: any) {
    console.log(e);
    console.log('AxiosWallets() Error');
    throw new Error(e.toString());
  }
};

export const AxiosGetAudience = async () => {
  const userToken = getCookie('token');

  try {
    const result = await AxiosApi.get('/audience/fans', {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    if (result.status == 204) return null;

    return result.data;
  } catch (error) {
    console.error('AxiosGetFanAudience Error:', error);

    if (error instanceof Error) return error;

    return new Error(`Error al ejecutar AxiosGetFanAudience: ${error}`);
  }
};

import { useEffect } from 'react';

export function useGlobalModalState() {
  const localStorageKey = 'global_modal_state';

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(false));
  }, []);

  const getModalState = () => {
    const data: string | null = localStorage.getItem(localStorageKey);

    if (data !== null) {
      const state: boolean = JSON.parse(data);
      return state;
    }

    return false;
  };

  const setModalOpen = (state: boolean) => {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  };

  return { getModalState, setModalOpen };
}

import { AxiosApi } from 'requests/AxiosBase';
import { notificationTypes } from '../types';

export const getNotifications = async (userToken: string) => {
  try {
    if (!userToken) throw 'No logged';

    const { data } = await AxiosApi.get('/notification-features', {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    if (data.message !== 'OK') throw data.message;

    return {
      type: notificationTypes.all_features,
      payload: data.data
    };
  } catch (e) {
    console.log(e);
    console.log('getNotifications() Error');
    return { type: 'askdjasdxd' };
  }
};

export const editFeatureNotification = (data: object) => {
  return {
    type: notificationTypes.edit_feature,
    payload: data
  };
};

export const pushNotification = (data: any) => {
  if (
    data.status &&
    data.status !== 'pending' &&
    data.status !== 'payed' &&
    data.status !== 'purchased'
  ) {
    return { type: '@notification_no_push' };
  }

  return {
    type: notificationTypes.push_feature,
    payload: data
  };
};

import AudienceHeaderRight from 'components/dashboard/audience/AudienceHeaderRight';
import SubsScreen from 'components/dashboard/audience/subs/index';
import WalletScreen from 'components/dashboard/audience/wallet/Index';
import HeaderScreen from 'components/dashboard/HeaderScreen';
import { useUser } from 'hooks/useUser';
import { useEffect, useState } from 'react';

const AudienceScreen = (): JSX.Element => {
  const [screen, setScreen] = useState<string>('subscriptions');
  const { user } = useUser();

  useEffect(() => {
    if (user && !user.is_verified) {
      localStorage.setItem('mf_dashboard', 'blocks');
      window.location.reload();
    }
  }, [user]);

  return (
    <div className="flex min-h-full flex-col">
      <HeaderScreen
        title="Audiencia"
        RightComponent={<AudienceHeaderRight setValue={setScreen} />}
      />

      {screen === 'subscriptions' && <SubsScreen />}

      {screen === 'wallets' && <WalletScreen />}
    </div>
  );
};

export default AudienceScreen;

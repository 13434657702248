import { useFormik } from 'formik';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { supabase } from 'utils/supabase-client';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

// Helpers
import { isValidUrl } from 'helpers/logicFunctions';

// Libs
import AwsStorage from "libs/AwsStorage";

// Components
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import Input from 'components/tailwind/inputs/Input';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import ImageInput from "./ImageInput"
// import Switch from 'components/tailwind/inputs/Switch';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import { Mixpanel } from 'utils/mixpanel';
import FetchLinkMetaData from "requests/local_next/FetchLinkMetaData"

const AddLinkForm = ({ closeModal, setBlocks, setLoading, handleClose }) => {
  const { user, updateUserRedux } = useUser();
  const [onTop, setOnTop] = useState(true);
  const [count, setCount] = useState<number>(0)
  const [fetching, setFetching] = useState<boolean>(false)
  const [file, setFile] = useState({
    blob: null,
    url: ""
  })

  const formik: any = useFormik({
    initialValues: {
      title: '',
      url: ''
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('El título es requerido')
        .max(64, 'El título debe tener como máximo 64 carácteres'),
      url: Yup.string()
        .test('is-url', 'URL inválida', (value) => {
          return isValidUrl(value);
        })
        .required('La URL es requerida')
        .max(4000, 'La URL debe tener como máximo 4000 carácteres')
    }),
    onSubmit: async (values) => {
      setLoading(true);

      let image_url = file.url.startsWith("blob:") ? "" : file.url

      if (file.blob) {
        const response = await AwsStorage({
          file: file.blob,
          folder: "og_image_links",
          contentType: "image/jpeg",
          metadata: { website: values.url }
        })

        if (!(response instanceof Error)) {
          image_url = response
        }
      }

      const newBlock = {
        ...values,
        og_image: image_url,
        id: uuidv4(),
        visible: true,
        type: 'link'
      };

      const newBlocksPosition = onTop
        ? [newBlock, ...user.blocks]
        : [...user.blocks, newBlock];

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks: user.blocks ? newBlocksPosition : [newBlock]
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
        setLoading(false);
      }

      if (data) {
        Mixpanel.track('Added block', {
          username: user.username,
          type: 'link'
        });
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Añadido', defaultToastOptions);
        setLoading(false);
        handleClose();
      }
    },
  });

  useEffect(() => {
    if (count <= 0) return
    else if (file.url || file.blob) {
      setFile((prev) => (
        {
          ...prev,
          url: "",
          blob: null
        }
      ))
    }

    formik.values.title = ""

    const { url } = formik.errors

    if (url) return

    const timeout = setTimeout(() => {
      getHeadData()
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [count, formik])

  const getHeadData = async () => {
    setCount(0)
    setFetching(true)
    const { error, data } = await FetchLinkMetaData(formik.values.url)

    setFetching(false)

    if (error) {
      toast.error(error)
      return
    }

    setFile((prev) => (
      {
        ...prev,
        url: data.image
      }
    ))

    const theTitle = data.title.length > 64 ? data.title.slice(0, 61) + "..." : data.title

    formik.setValues({ title: theTitle, url: formik.values.url })
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormBody>
        <div>
          <LabelRequired name="url" title="URL" isRequired={true} />
          <div className="mt-1">
            <Input
              type="text"
              name="url"
              id="url"
              placeholder="ej www.google.com"
              onChange={(e) => {
                setCount((prev) => prev + 1)
                formik.handleChange(e)
              }}
              onBlur={formik.handleBlur}
              value={formik.values.url}
              error={formik.errors.url && formik.touched.url}
              disabled={fetching || formik.isSubmitting}
            />
          </div>
          {formik.errors.url && formik.touched.url && (
            <ErrorMessage errorText={formik.errors.url} />
          )}
        </div>
        <div className="mt-4 flex">
          <ImageInput
            className="mr-4"
            file={file}
            setFile={setFile}
            disabled={fetching || formik.isSubmitting}
          />
          <div className="w-full">
            <LabelRequired
              name="title"
              title="Título del enlace"
              isRequired={true}
            />
            <div className="mt-1">
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="Escribe el título"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                error={(formik.errors.title && formik.touched.title) as boolean}
                disabled={fetching || formik.isSubmitting}
              />
            </div>
            {formik.errors.title && formik.touched.title && (
              <ErrorMessage errorText={formik.errors.title} />
            )}
          </div>
        </div>
        
        {/* //! Logic to add on Top or bottom of the block stack */}
        {/* <div>
          <Switch show={onTop} setShow={() => setOnTop(!onTop)} />
          <span>Logic to Add to top or bottom, true for top</span>
        </div> */}
      </FormBody>

      <FormFooter>
        <WhiteButton
          title="Cancelar"
          onClick={closeModal}
          disabled={fetching || formik.isSubmitting}
          className="px-6"
        />
        <BasicButton
          type="submit"
          disabled={fetching || !formik.isValid || !formik.dirty}
          loading={formik.isSubmitting}
          title="Añadir"
          className="w-40"
          loadingChildren="Añadiendo"
        />
      </FormFooter>
    </form>
  );
};

export default AddLinkForm;

export enum ActionTriggerBlocks {
  addBlockDivider = 'addBlockDivider',
  addBlockLink = 'addBlockLink',
  addBlockMedia = 'addBlockMedia',
  addBlockMonetization = 'addBlockMonetization',
  addBlockNFT = 'addBlockNFT',
  addBlockProduct = 'addBlockProduct'
}

export enum ActionTriggerDigitalProduct {
  addProduct = 'addProduct'
}

export type ActionTriggerType =
  | ActionTriggerBlocks
  | ActionTriggerDigitalProduct;

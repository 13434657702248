// Libraries
import React, { useEffect } from 'react';
import useSWR from 'swr';

// Components
import BlockSkeleton from 'components/tailwind/loaders/BlockSkeleton';
import EmptyState from 'components/dashboard/EmptyState';
import SubsCard from './SubsCard';
import UserPlusFa from 'components/tailwind/icons/UserPlusFa';

// Request
import { AxiosGetSubscriptions } from 'requests/local_api/AxiosSubscriptions';

const SubsScreen = () => {
  const { error, data, mutate } = useSWR(
    '/subscriptions/creators',
    AxiosGetSubscriptions
  );

  const handleOnSwitch = (value: { id: string }) => {
    const copySubs = [...data];
    const map = copySubs.map((sub) => {
      return {
        ...sub,
        cancel_at_period_end:
          sub.id === value.id
            ? !sub.cancel_at_period_end
            : sub.cancel_at_period_end
      };
    });
    mutate(map, false);
  };

  if (error === undefined && data === undefined) {
    return <BlockSkeleton quantity={6} className="flex flex-col gap-3" />;
  } else if (error) return <h4>{error.toString()}</h4>;

  return (
    <>
      {data.length === 0 ? (
        <EmptyState
          absolute={false}
          title="Aún no estas suscrito a ningún creador"
          dashboard={true}
          className="flex-1"
          icon={<UserPlusFa className="h-[31px] w-[39px] text-gray-400" />}
        />
      ) : (
        <div className="flex flex-col gap-4">
          {data.map((subscription, index) => (
            <SubsCard
              key={index}
              sub={subscription}
              onSwitch={handleOnSwitch}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default SubsScreen;

type EmptyIconProps = {
  className: string;
};

const EmptyIcon: React.FC<EmptyIconProps> = ({ className }) => {
  return (
    <svg
      width="40"
      height="42"
      viewBox="0 0 40 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 9V29C1 33.418 8.163 37 17 37C18.381 37 19.721 36.913 21 36.748M1 9C1 13.418 8.163 17 17 17C25.837 17 33 13.418 33 9M1 9C1 4.582 8.163 1 17 1C25.837 1 33 4.582 33 9M33 9V23M33 19C33 23.418 25.837 27 17 27C8.163 27 1 23.418 1 19M33 29V35M33 35V41M33 35H39M33 35H27"
        stroke="#97A7B4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmptyIcon;

import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';

// Helpers
import { classNames } from 'helpers/classNames';

type TSelectMenuOptions = {
  id: number;
  name: React.ReactNode;
  value: string;
};

type SelectMenuProps = {
  disabled?: boolean;
  options: TSelectMenuOptions[];
  value: TSelectMenuOptions;
  onChange: (option: TSelectMenuOptions) => void;
  className?: string;
};

const SelectMenu: React.FC<SelectMenuProps> = ({
  disabled = false,
  options,
  value,
  onChange,
  className
}) => {
  return (
    <Listbox value={value} disabled={disabled} onChange={onChange}>
      {({ open }) => (
        <div
          className={classNames(
            disabled && 'opacity-50',
            'relative mt-1',
            className
          )}
        >
          <Listbox.Button className="relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-base shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary disabled:cursor-not-allowed">
            <span className="block truncate text-gray-700">{value.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'relative cursor-pointer select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate'
                        )}
                      >
                        {option.name}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-primary' : 'text-primary',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default SelectMenu;

import { getCookie } from 'helpers/handleCookies';
import { AxiosApi } from '../../AxiosBase';
import { UpsertDigitalProduct, DigitalProduct } from 'types/digitalProduct';

const AxiosUpdateDigitalProduct = async (
  digitalProductId: number,
  digitalProduct: UpsertDigitalProduct
): Promise<DigitalProduct | Error> => {
  try {
    const userToken: string | boolean = getCookie('token');

    if (!userToken) return new Error('No estas logeado');

    const result = await AxiosApi.put<DigitalProduct>(
      `/digital-products/${digitalProductId}`,
      digitalProduct,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    return result.data;
  } catch (error) {
    console.log({ error });
    console.error('AxiosUpdateDigitalProduct Error:', error);

    if (error instanceof Error) return error;

    return new Error(`Error al ejecutar AxiosUpdateDigitalProduct: ${error}`);
  }
};

export default AxiosUpdateDigitalProduct;

import { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CommandAction } from '../types';
import { Account } from 'types/accounts';
import {
  ActionTriggerBlocks,
  ActionTriggerDigitalProduct,
  ActionTriggerType
} from 'types/actionTriggerType';
import { ScreenType } from 'types/screenType';

export interface DeepNavigationActionsProps {
  account: Account;
  isValidStripeConnectAccount: boolean;
  setActionTrigger: (actionTrigger: ActionTriggerType) => void;
  setScreen: Dispatch<SetStateAction<keyof typeof ScreenType>>;
}

export function deepNavigationActions({
  account,
  isValidStripeConnectAccount,
  setActionTrigger,
  setScreen
}: DeepNavigationActionsProps): Array<CommandAction> {
  let actions: Array<CommandAction> = [
    {
      action: () => {
        setActionTrigger(ActionTriggerBlocks.addBlockLink);
        setScreen('blocks');
      },
      key: uuidv4(),
      name: (
        <>
          Añadir Bloque <span className="font-medium">Enlace</span>
        </>
      ),
      shouldBeVerified: true
    },
    {
      action: () => {
        setActionTrigger(ActionTriggerBlocks.addBlockMonetization);
        setScreen('blocks');
      },
      key: uuidv4(),
      name: (
        <>
          Añadir Bloque <span className="font-medium">Monetización</span>{' '}
        </>
      ),
      shouldBeVerified: true
    },
    {
      action: () => {
        setActionTrigger(ActionTriggerBlocks.addBlockProduct);
        setScreen('blocks');
      },
      key: uuidv4(),
      name: (
        <>
          Añadir Bloque <span className="font-medium">Producto Digital</span>
        </>
      ),
      shouldBeVerified: true
    },
    {
      action: () => {
        setActionTrigger(ActionTriggerBlocks.addBlockMedia);
        setScreen('blocks');
      },
      key: uuidv4(),
      name: (
        <>
          Añadir Bloque <span className="font-medium">Media</span>
        </>
      ),
      shouldBeVerified: true
    },
    {
      action: () => {
        setActionTrigger(ActionTriggerBlocks.addBlockNFT);
        setScreen('blocks');
      },
      key: uuidv4(),
      name: (
        <>
          Añadir Bloque <span className="font-medium">NFT Showcase</span>
        </>
      ),
      shouldBeVerified: true
    },
    {
      action: () => {
        setActionTrigger(ActionTriggerBlocks.addBlockDivider);
        setScreen('blocks');
      },
      key: uuidv4(),
      name: (
        <>
          Añadir Bloque <span className="font-medium">Separador</span>
        </>
      ),
      shouldBeVerified: true
    }
  ];

  if (isValidStripeConnectAccount) {
    actions.push({
      action: () => {
        setActionTrigger(ActionTriggerDigitalProduct.addProduct);
        setScreen('products');
      },
      key: uuidv4(),
      name: (
        <>
          Añadir Bloque <span className="font-medium">Producto</span>
        </>
      ),
      shouldBeVerified: true
    });
  }

  return actions.filter((action) =>
    action.shouldBeVerified === true
      ? account.is_verified && account.is_verified
        ? true
        : false
      : true
  );
}

type IconProps = {
  className?: string;
};

const SolanaIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="currentColor"
      className={className}
    >
      <path
        d="M3.91788 15.2665C4.04052 15.11 4.20531 15.022 4.37777 15.022H20.3396C20.6308 15.022 20.7765 15.4719 20.5695 15.731L17.4155 19.7555C17.2929 19.9119 17.1281 20 16.9556 20H0.993785C0.702525 20 0.556895 19.5501 0.763843 19.2909L3.91788 15.2665Z"
        fill="#542AE2"
      />
      <path
        d="M3.91788 0.244499C4.04052 0.0880196 4.20531 0 4.37777 0H20.3396C20.6308 0 20.7765 0.449878 20.5695 0.709047L17.4155 4.72861C17.2929 4.88509 17.1281 4.97311 16.9556 4.97311H0.993785C0.702525 4.97311 0.556895 4.52323 0.763843 4.26406L3.91788 0.244499Z"
        fill="#542AE2"
      />
      <path
        d="M17.4155 7.70666C17.2929 7.55018 17.1281 7.46216 16.9556 7.46216H0.993785C0.702525 7.46216 0.556895 7.91204 0.763843 8.1712L3.91788 12.1908C4.04052 12.3472 4.20531 12.4353 4.37777 12.4353H20.3396C20.6308 12.4353 20.7765 11.9854 20.5695 11.7262L17.4155 7.70666Z"
        fill="#542AE2"
      />
    </svg>
  );
};

export default SolanaIcon;

import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import NavLink from './NavLink';
import {
  UsersIcon,
  TrendingUpIcon,
  BellIcon,
  SearchIcon,
  TagIcon,
  HeartIcon,
  CogIcon,
  SupportIcon,
  VideoCameraIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  ViewGridIcon
} from '@heroicons/react/outline';

import NotificationCount from '../NotificationCount';
import { useUser } from 'hooks/useUser';

interface INavigation_1Props {
  className?: string;
  screen: string;
  setScreen: (value: string | ((prev: string) => void)) => void;
}

export const navigation = [
  {
    text: 'Bloques',
    value: 'blocks',
    icon: ViewGridIcon,
    showOnVerified: true
  },
  {
    text: 'Comunidad',
    value: 'super_fans',
    icon: HeartIcon,
    showOnVerified: true,
    type: '',
    href: '/'
  },
  {
    text: 'Productos',
    value: 'products',
    icon: ShoppingBagIcon,
    showOnVerified: true
  },
  {
    text: 'Notificaciones',
    value: 'notifications',
    icon: BellIcon,
    showOnVerified: false
  },
  {
    text: 'Audiencia',
    value: 'audience',
    icon: UsersIcon,
    showOnVerified: true
  },

  {
    text: 'Analíticas',
    value: 'analytics',
    icon: TrendingUpIcon,
    showOnVerified: true,
    divider: true
  },
  // {
  //   text: 'Alertas de stream',
  //   value: 'stream_alerts',
  //   icon: VideoCameraIcon,
  //   showOnVerified: true,
  //   divider: true
  // },
  {
    text: 'Explorar',
    value: 'explore',
    icon: SearchIcon,
    showOnVerified: false
  },
  {
    text: 'Mis Suscripciones',
    value: 'my_subscriptions',
    icon: TagIcon,
    showOnVerified: false
  },
  {
    text: 'Mis Compras',
    value: 'products_history',
    icon: ShoppingCartIcon,
    showOnVerified: false
  },
  { text: 'Ajustes', value: 'settings', icon: CogIcon, showOnVerified: false }
];

export const Navigation = ({
  className = '',
  screen,
  setScreen
}: INavigation_1Props) => {
  const { getIsConnectAccountEnabled, user, status } = useUser();
  const [isConnectAccountEnabled, setIsConnectAccountEnabled] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const fetchIsConnectAccountEnabled = async () => {
      if (status !== 1) return;
      else if (user.linked_accounts?.paypal?.merchant_id) {
        setIsConnectAccountEnabled(true);
        return;
      }

      const isValid = await getIsConnectAccountEnabled();
      setIsConnectAccountEnabled(isValid);
    };

    fetchIsConnectAccountEnabled();
  }, [status, user]);

  return (
    <div className={className}>
      {navigation.map((value, index) => {
        if (value.value === 'audience' && !user.is_verified) return;
        if (value.showOnVerified && !user.is_verified) return;
        if (value.value === 'products' && (!isConnectAccountEnabled || user.internal_options.blocks?.digital_product === false)) {
          return;
        }
        return (
          <NavLink
            id={value.value === 'support' ? 'iw_supportBtn' : ''}
            key={index}
            type={value.type === 'link' ? 'link' : 'button'}
            className={`mb-[6px] h-[40px] text-base text-[16px] font-medium leading-6 ${
              screen === value.value
                ? 'bg-[#F3F2FF] text-primary'
                : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
            }`}
            selected={screen === value.value && true}
            divider={value.divider ? true : false}
            text={value.text}
            icon={value.icon}
            href={!value.href ? '#' : value.href}
            onClick={
              value.type === 'link'
                ? undefined
                : () => {
                    if (value.value === 'support') return;
                    if (value.value === 'super_fans') {
                      router.push(`/${user.username}/hub`);
                      return;
                    }
                    localStorage.setItem('mf_dashboard', value.value);
                    setScreen(value.value);
                  }
            }
            JSXRight={
              value.value !== 'notifications' ? null : <NotificationCount />
            }
          />
        );
      })}
    </div>
  );
};

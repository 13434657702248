import { NotificationStatus } from 'types/notifications';

export enum MonetizationType {
  audio = 'audio',
  gifts = 'gifts',
  greetings = 'greetings',
  questions = 'questions'
}

export enum MonetizationCurrency {
  usd = 'usd'
}

export interface MonetizationForm {
  description: string;
  price: number;
  title: string;
}

export interface MonetizationBlockType {
  id: number;
  name: string;
  value: keyof typeof MonetizationType;
}

export interface Audio {
  amount: number;
  creation_date: Date;
  creator_account_id: string;
  currency: string;
  rejected: boolean;
  status: keyof typeof NotificationStatus;

  answeredAt?: Date;
  comments?: string;
  email?: string;
  fan_id?: string;
  id?: number;
  name?: string;
  reasonOfRejection?: string;
  type?: string;
  /** Url on AWS of the file converted. */
  url?: string;
  /** Url on AWS of the file without conversion. */
  originalUrl?: string;
  uuid?: string;
}

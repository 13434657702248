import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  QuestionMarkCircleIcon,
  PaperClipIcon,
  LinkIcon,
  UploadIcon
} from '@heroicons/react/outline';
import {
  UpsertDigitalProduct,
  DigitalProductType,
  DigitalProduct,
  DigitalProductFormType
} from 'types/digitalProduct';
import { Account, Block } from 'types/accounts';
import { MimeTypes } from 'types/MimeTypes';
import uploadFileAWS from 'libs/AwsStorage';
import { defaultToastOptions } from 'helpers/toastOptions';
import { supabase } from 'utils/supabase-client';
import AxiosCreateDigitalProduct from 'requests/local_api/digitalProducts/AxiosCreateDigitalProduct';
import AxiosUpdateDigitalProduct from 'requests/local_api/digitalProducts/AxiosUpdateDigitalProduct';

// Helpers
import { isValidUrl } from 'helpers/logicFunctions';
import { getValueAfterLastSlash } from 'helpers/formatData';
import { solscan_RegExp } from 'helpers/customRegExp';
import { toPublicKey, getTokenMetadata } from 'requests/local_next/web3';

// Components
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import Label from 'components/tailwind/inputs/Label';
import Input from 'components/tailwind/inputs/Input';
import TextArea from 'components/tailwind/inputs/TextArea';
import PriceInput from 'components/tailwind/inputs/PriceInput';
import InputWithLeadingIcon from 'components/tailwind/inputs/InputWithLeadingIcon';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import RectangleFileInput from 'components/tailwind/inputs/RectangleFileInput';
import SquareFileInput from 'components/tailwind/inputs/SquareFileInput';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import CharacterCounter from 'components/tailwind/inputs/CharacterCounter';
import SelectButtons from 'components/tailwind/inputs/SelectButtons';
import FormModal from 'components/tailwind/overlays/FormModal';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import { IUser, IUserEdit } from 'types/User';
import Collapse from 'components/tailwind/collapse/Collapse';
import SocialIcons from 'components/web3/CollectionHeader/SocialIcons';

enum DigitalProductFormError {
  assetFileRequiredError = "Es necesario proveer el Archivo Digital en Productos Digitales de tipo 'Archivo Protegido'.",
  assetLoadFailure = 'La carga del Archivo Digital ha fallado. Por favor contacta con el equipo de soporte.',
  assetUploadFailure = 'La carga del Archivo Digital ha fallado. Por favor contacta con el equipo de soporte.',
  addressInvalid = 'Inserta un enlace de Solscan válido',
  createDigitalProductFailure = 'Nuestro servidor ha rechazado la creación de tu Producto Digital. Por favor contacta con el equipo de soporte.',
  createSubmitFailure = 'La creación del producto digital ha fallado. Por favor contacta con el equipo de soporte.',
  collectionNameMaxLengthError = 'El nombre de la colección no puede superar los 240 caracteres.',
  deleteDigitalProductFailure = 'No se ha podido eliminar el Producto Digital. Por favor contacta con el equipo de soporte.',
  descriptionMaxLengthError = 'La descripción del producto dígital debe tener como máximo 1000 caracteres.',
  descriptionRequiredError = 'La descripción del producto es obligatoria.',
  externalUrlInvalidError = 'Debes proporcionar una url válida.',
  externalUrlMaxLengthError = 'La url externa del producto dígital debe tener como máximo 1000 caracteres. Contacte con soporte para más ayuda.',
  externalUrlRequiredError = 'La url externa del producto es obligatoria.',
  getBlockError = 'Ha habido un problema con la cuenta a la que pertenece el producto digital. Por favor contacta con el equipo de soporte.',
  nameMaxLengthError = 'El nombre del producto debe tener como máximo 240 caracteres.',
  nameRequiredError = 'El nombre del producto es obligatorio.',
  nftMissingCollection = 'El NFT no pertenece a ninguna colección.',
  placeholderFileRequiredError = 'Es necesario proveer la Imagen Descriptiva.',
  placeholderLoadFailure = 'La carga de la Imagen Descriptiva ha fallado. Por favor contacta con el equipo de soporte.',
  placeholderUploadFailure = 'La carga de la Imagen Descriptiva ha fallado. Por favor contacta con el equipo de soporte.',
  priceMaxLengthError = 'El precio no puede exceder el valor 999999.',
  priceMinLengthError = 'El precio no puede ser menor al valor de 1.',
  priceRequiredError = 'El precio del producto es obligatorio.',
  updateBlockError = 'Ha habido un problema actualizando el bloque existente que pertenece a este producto digital. Contacta con el equipo de soporte',
  updateDigitalProductFailure = 'Nuestro servidor ha rechazado la actualización de tu Producto Digital. Por favor contacta con el equipo de soporte.',
  updateSubmitFailure = 'La actualización del producto digital ha fallado. Por favor contacta con el equipo de soporte.'
}

export const assetFileMimeTypesSupported = Object.values(MimeTypes);

export const placeholderFileMimeTypesSupported = [
  MimeTypes['image/jpeg'],
  MimeTypes['image/png'],
  MimeTypes['image/webp']
];

type DigitalProductTypeOption = {
  id: number;
  name: string;
  value: keyof typeof DigitalProductType;
  icon: any;
};

type DigitalProductTypeOptions = Array<DigitalProductTypeOption>;

const digitalProductTypeOptions: DigitalProductTypeOptions = [
  {
    id: 1,
    name: 'Archivo Digital',
    value: 'fileProtected',
    icon: PaperClipIcon
  },
  { id: 2, name: 'Enlace Externo', value: 'externalLink', icon: LinkIcon }
];

interface DigitalProductModalProps {
  creatorAccount: IUser;
  digitalProductId?: number;
  formType: DigitalProductFormType;
  handleModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialFormData: UpsertDigitalProduct;
  loading: boolean;
  open: boolean;
  refreshDigitalProducts: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  updateUserRedux: (payload: IUserEdit) => {
    type: string;
    payload: IUserEdit;
  };
}

const DigitalProductModal = (props: DigitalProductModalProps) => {
  const {
    creatorAccount,
    digitalProductId,
    formType,
    handleModal,
    initialFormData,
    loading,
    open,
    refreshDigitalProducts,
    setLoading,
    updateUserRedux
  } = props;

  /* Form States. */
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [digitalProductType, setDigitalProductType] =
    useState<DigitalProductTypeOption>(digitalProductTypeOptions[0]);
  const [fileHadChanged, setFileHadChanged] = useState<boolean>(false);
  const [fileUploadProgress, setFileUploadProgress] = useState<number>(null);

  /* Form schema */
  let digitalProductSchema = Yup.object({
    name: Yup.string()
      .required(DigitalProductFormError.nameRequiredError)
      .max(240, DigitalProductFormError.nameMaxLengthError),
    description: Yup.string()
      .required(DigitalProductFormError.descriptionRequiredError)
      .max(1000, DigitalProductFormError.descriptionMaxLengthError),
    external_url: Yup.string()
      .optional()
      .nullable()
      .max(1000, DigitalProductFormError.externalUrlMaxLengthError),
    price: Yup.number()
      .required(DigitalProductFormError.priceRequiredError)
      .min(1, DigitalProductFormError.priceMinLengthError)
      .max(999999, DigitalProductFormError.priceMaxLengthError),
    collection: Yup.object({
      nftExample: Yup.string()
        .test('is-url', 'URL inválida', (value) => {
          return value ? isValidUrl(value) : true;
        })
        .test('is-solscan', DigitalProductFormError.addressInvalid, (value) => {
          return value ? solscan_RegExp.test(value) : true;
        })
        .test(
          'nft-has-collection',
          DigitalProductFormError.nftMissingCollection,
          async (value) => {
            try {
              const nftAddress = getValueAfterLastSlash(value).split('#')[0];
              const exampleNFT = await getTokenMetadata(
                toPublicKey(nftAddress)
              );
              return exampleNFT.collection !== null ? true : false;
            } catch (e) {
              return value ? false : true;
            }
          }
        ),
      name: Yup.string().max(
        240,
        DigitalProductFormError.collectionNameMaxLengthError
      ),
      socialMedia: Yup.object({
        facebook: Yup.string().test('is-url', 'URL inválida', (value) => {
          return value ? isValidUrl(value) : true;
        }),
        twitter: Yup.string().test('is-url', 'URL inválida', (value) => {
          return value ? isValidUrl(value) : true;
        }),
        discord: Yup.string().test('is-url', 'URL inválida', (value) => {
          return value ? isValidUrl(value) : true;
        }),
        openSea: Yup.string().test('is-url', 'URL inválida', (value) => {
          return value ? isValidUrl(value) : true;
        }),
        website: Yup.string().test('is-url', 'URL inválida', (value) => {
          return value ? isValidUrl(value) : true;
        })
      })
    })
  });
  if (digitalProductType.value === 'externalLink') {
    digitalProductSchema = Yup.object({
      name: Yup.string()
        .required(DigitalProductFormError.nameRequiredError)
        .max(240, DigitalProductFormError.nameMaxLengthError),
      description: Yup.string()
        .required(DigitalProductFormError.descriptionRequiredError)
        .max(1000, DigitalProductFormError.descriptionMaxLengthError),
      external_url: Yup.string()
        .required(DigitalProductFormError.externalUrlRequiredError)
        .nullable()
        .max(1000, DigitalProductFormError.externalUrlMaxLengthError)
        .url(DigitalProductFormError.externalUrlInvalidError),
      price: Yup.number()
        .required(DigitalProductFormError.priceRequiredError)
        .min(1, DigitalProductFormError.priceMinLengthError)
        .max(999999, DigitalProductFormError.priceMaxLengthError),
      collection: Yup.object({
        nftExample: Yup.string()
          .test('is-url', 'URL inválida', (value) => {
            return value ? isValidUrl(value) : true;
          })
          .test(
            'is-solscan',
            DigitalProductFormError.addressInvalid,
            (value) => {
              return value ? solscan_RegExp.test(value) : true;
            }
          )
          .test(
            'nft-has-collection',
            DigitalProductFormError.nftMissingCollection,
            async (value) => {
              try {
                const nftAddress = getValueAfterLastSlash(value).split('#')[0];
                const exampleNFT = await getTokenMetadata(
                  toPublicKey(nftAddress)
                );
                return exampleNFT.collection !== null ? true : false;
              } catch (e) {
                return value ? false : true;
              }
            }
          ),
        name: Yup.string().max(
          240,
          DigitalProductFormError.collectionNameMaxLengthError
        ),
        socialMedia: Yup.object({
          facebook: Yup.string().test('is-url', 'URL inválida', (value) => {
            return value ? isValidUrl(value) : true;
          }),
          twitter: Yup.string().test('is-url', 'URL inválida', (value) => {
            return value ? isValidUrl(value) : true;
          }),
          discord: Yup.string().test('is-url', 'URL inválida', (value) => {
            return value ? isValidUrl(value) : true;
          }),
          openSea: Yup.string().test('is-url', 'URL inválida', (value) => {
            return value ? isValidUrl(value) : true;
          }),
          website: Yup.string().test('is-url', 'URL inválida', (value) => {
            return value ? isValidUrl(value) : true;
          })
        })
      })
    });
  }

  /* Data States. */
  const [assetFile, setAssetFile] = useState<File>(null);
  const [placeholderFile, setPlaceholderFile] = useState<File>(null);

  // On each modal state change, clean the states.
  useEffect(() => {
    // Clean states only if isn't submitting data.
    if (loading === false) cleanStates();
  }, [open]);

  // Detect if some file had change.
  useEffect(() => {
    if (formType === 'edit') {
      if (assetFile || placeholderFile) {
        setFileHadChanged(true);
      }
    }
  }, [assetFile, placeholderFile]);

  // Formik!
  const digitalProductFormik = useFormik({
    initialValues: {
      ...initialFormData
    },
    onSubmit: async (values) => {
      const digitalProduct: UpsertDigitalProduct = {
        ...values
      };

      await handleFormOnSubmit(digitalProduct);
    },
    validationSchema: digitalProductSchema
  });

  /** Separate submit flows based on form type. */
  const handleFormOnSubmit = async (values: UpsertDigitalProduct) => {
    switch (formType) {
      case 'create':
        return await createDigitalProduct(values);
      case 'edit':
        return await updateDigitalProduct(values);
    }
  };

  const createDigitalProduct = async (values: UpsertDigitalProduct) => {
    try {
      setLoading(true);

      let asset: string = null;
      let placeholder: string = null;

      // Validate if placeholder was not setted.
      if (placeholderFile == null) {
        setErrorMessage(DigitalProductFormError.placeholderFileRequiredError);
        return setLoading(false);
      }

      // Upload placeholder file.
      if (placeholderFile != null) {
        const result = await uploadPlaceholderFile();

        if (result instanceof Error) {
          setErrorMessage(DigitalProductFormError.placeholderUploadFailure);
          return setLoading(false);
        }

        placeholder = result;
      }

      // Validate if form has 'fileProtected' type and asset file was not setted.
      if (digitalProductType.value === 'fileProtected' && assetFile === null) {
        setErrorMessage(DigitalProductFormError.assetFileRequiredError);
        return setLoading(false);
      }

      if (digitalProductType.value === 'fileProtected') {
        const result = await uploadAssetFile();

        if (result instanceof Error) {
          setLoading(false);
          return setErrorMessage(result.message);
        }

        asset = result;
      }

      // Set collection token metadata if address is setted.
      if (values.collection.nftExample) {
        const nftAddress = getValueAfterLastSlash(values.collection.nftExample);
        const exampleNFT = await getTokenMetadata(toPublicKey(nftAddress));
        const collectionNFT = await getTokenMetadata(exampleNFT.collection.key);
        values.collection.address = exampleNFT.collection.key.toString();
        values.collection.name = collectionNFT.name;
      }

      // Create request object.
      let request: UpsertDigitalProduct = {
        ...values,
        creator_account_id: creatorAccount.id,
        type: digitalProductType.value
      };

      if (asset != null) request.asset = asset;
      if (placeholder != null) request.placeholder = placeholder;

      const result = await AxiosCreateDigitalProduct(request);

      if (result instanceof Error) {
        setErrorMessage(DigitalProductFormError.createDigitalProductFailure);
        return setLoading(false);
      }

      await refreshDigitalProducts();
      cleanStates();
      handleModal(!open);
    } catch (error) {
      setErrorMessage(DigitalProductFormError.createSubmitFailure);
      return setLoading(false);
    }
  };

  const updateDigitalProduct = async (values: UpsertDigitalProduct) => {
    setLoading(true);

    try {
      let asset: string = null;
      let placeholder: string = null;

      // Set collection token metadata if address is setted.
      if (values.collection.nftExample) {
        const nftAddress = getValueAfterLastSlash(values.collection.nftExample);
        const exampleNFT = await getTokenMetadata(toPublicKey(nftAddress));
        const collectionNFT = await getTokenMetadata(exampleNFT.collection.key);
        values.collection.address = exampleNFT.collection.key.toString();
        values.collection.name = collectionNFT.name;
      }

      let request: UpsertDigitalProduct = {
        ...values,
        type: digitalProductType.value
      };

      // AssetFile isn't required on update!
      if (assetFile != null) {
        const result = await uploadAssetFile();

        if (result instanceof Error) {
          setErrorMessage(DigitalProductFormError.assetUploadFailure);
          return setLoading(false);
        }

        asset = result;
      }

      // PlaceholderFile isn't required on update!
      if (placeholderFile != null) {
        const result = await uploadPlaceholderFile();

        if (result instanceof Error) {
          setErrorMessage(DigitalProductFormError.placeholderUploadFailure);
          return setLoading(false);
        }

        placeholder = result;
      }

      if (asset != null) request.asset = asset;
      if (placeholder != null) request.placeholder = placeholder;

      const result = await AxiosUpdateDigitalProduct(digitalProductId, request);

      if (result instanceof Error) {
        setErrorMessage(DigitalProductFormError.updateDigitalProductFailure);
        return setLoading(false);
      }

      await updateDigitalProductBlock(result);
      await refreshDigitalProducts();

      cleanStates();
      handleModal(!open);
    } catch (error) {
      setErrorMessage(DigitalProductFormError.updateSubmitFailure);
      return setLoading(false);
    }
  };

  const updateDigitalProductBlock = async (digitalProduct: DigitalProduct) => {
    const { data, error: accountError } = await supabase
      .from<Account>('accounts')
      .select('*')
      .filter('id', 'eq', digitalProduct.creator_account_id);

    if (accountError) {
      return setErrorMessage(DigitalProductFormError.getBlockError);
    }

    let blocks = data[0].blocks;

    if (blocks != undefined || blocks != null) {
      if (blocks.length > 0) {
        const blockIndex = blocks.findIndex(
          (block) => block.digitalProductId == digitalProduct.id
        );

        if (blockIndex > 0) {
          let productLink = `${process.env.NEXT_PUBLIC_URL}/`;
          productLink += `${creatorAccount.username}/`;
          productLink += digitalProduct.product_uuid;

          blocks[blockIndex].currency = digitalProduct.currency;
          blocks[blockIndex].image = digitalProduct.placeholder;
          blocks[blockIndex].price = digitalProduct.price;
          blocks[blockIndex].title = digitalProduct.name;
          blocks[blockIndex].url = productLink;

          const { error: updateAccountError } = await supabase
            .from('accounts')
            .update({
              blocks
            })
            .eq('id', digitalProduct.creator_account_id);

          if (updateAccountError) {
            return setErrorMessage(DigitalProductFormError.updateBlockError);
          }
        }
      }
    }
  };

  const uploadPlaceholderFile = async () => {
    return await uploadFileAWS({
      contentType: placeholderFile.type as MimeTypes,
      file: placeholderFile,
      folder: 'products',
      metadata: {
        process: 'createDigitalProduct',
        name: 'placeholder',
        userId: creatorAccount.id
      }
    });
  };

  const uploadAssetFile = async () => {
    return await uploadFileAWS({
      contentType: assetFile.type as MimeTypes,
      file: assetFile,
      folder: 'products',
      metadata: {
        process: 'createDigitalProduct',
        name: 'asset',
        userId: creatorAccount.id
      },
      setUploadProgress: setFileUploadProgress
    });
  };

  const resetDigitalProductType = () => {
    const option = digitalProductTypeOptions.find(
      (op) => op.value == initialFormData.type
    );

    setDigitalProductType(option);
  };

  const cleanStates = () => {
    setErrorMessage(null);
    setLoading(false);
    setAssetFile(null);
    setPlaceholderFile(null);
    resetDigitalProductType();
    setFileHadChanged(false);
    setFileUploadProgress(null);
    digitalProductFormik.resetForm({
      values: { ...initialFormData }
    });
  };

  const onClickDeleteDigitalProduct = async () => {
    const confirmed = confirm(
      '¿Estás seguro de que quieres eliminar este producto?'
    );

    if (confirmed) {
      setLoading(true);

      // Find the creatorAccount's fresh data.
      const { data, error: accountError } = await supabase
        .from<Account>('accounts')
        .select('*')
        .eq('id', creatorAccount.id);

      if (accountError) {
        toast(
          DigitalProductFormError.deleteDigitalProductFailure,
          defaultToastOptions
        );
      }

      let account = data.length > 0 ? data[0] : null;

      // If creator has almost one block, try to find some block related to
      // this deleted DigitalProduct.
      if (account && account.blocks.length > 0) {
        const ownBlock: Block | undefined = account.blocks.find(
          (b) => b.digitalProductId === digitalProductId
        );

        // If exists some block related with this DigitalProduct, delete it.
        if (ownBlock) {
          const newBlocksList = account.blocks.reduce((acc, block) => {
            if (block.id !== ownBlock.id) {
              acc.push(block);
            }
            return acc;
          }, []);

          const { data: user } = await supabase
            .from('accounts')
            .update({
              blocks: newBlocksList
            })
            .eq('id', account.id);

          updateUserRedux(user[0]);
        }
      }

      let { error } = await supabase
        .from<DigitalProduct>('digital_products')
        .update({
          is_deleted: true
        })
        .match({ id: digitalProductId });

      if (error) {
        toast(
          DigitalProductFormError.deleteDigitalProductFailure,
          defaultToastOptions
        );
      }

      await refreshDigitalProducts();
      cleanStates();
      handleModal(!open);
      setLoading(false);
    }
  };

  const onClickCloseModal = () => {
    handleModal(!open);
  };

  const onChangeProductTypeOption = (e: DigitalProductTypeOption) => {
    setDigitalProductType(e);
    setErrorMessage(null);
  };

  const onClickHelpAsset = () => {
    const link =
      'https://misfans-1.gitbook.io/product-docs/guides/productos-digitales/subir-archivo-digital';
    window.open(link, '_blank').focus();
  };

  const onClickHelpPlaceholder = () => {
    const link =
      'https://misfans-1.gitbook.io/product-docs/guides/productos-digitales/subir-imagen-descriptiva';
    window.open(link, '_blank').focus();
  };

  /** Centralized execution of the submit in formik. This feature exists because
   * there are multiple submit buttons and formik conflicts with it. */
  const onClickSubmit = () => {
    digitalProductFormik.submitForm();
  };

  return (
    <FormModal
      title={formType == 'create' ? 'Crear Producto' : 'Editar Producto'}
      open={open}
      setOpen={handleModal}
    >
      <form onSubmit={digitalProductFormik.handleSubmit}>
        <FormBody>
          {/* digitalProductType options. */}
          <div className="mb-5">
            <LabelRequired
              title="Selecciona un tipo de producto"
              className="mb-3"
              isRequired={true}
            />
            <SelectButtons
              disabled={loading || formType == 'edit'}
              options={digitalProductTypeOptions}
              onChange={onChangeProductTypeOption}
              value={digitalProductType}
            />
          </div>

          {/* placeholder input file. */}
          <div className="mb-4 flex flex-col justify-between gap-4 sm:flex-row">
            <div>
              <div className="mb-2 flex items-center">
                <LabelRequired title="Imagen" isRequired={true} />
              </div>

              <SquareFileInput
                file={placeholderFile}
                mimeTypesSupported={placeholderFileMimeTypesSupported}
                parentLoading={loading}
                setErrorMessage={setErrorMessage}
                setFile={setPlaceholderFile}
                url={initialFormData.placeholder}
              />

              {errorMessage && <ErrorMessage errorText={errorMessage} />}
            </div>
            <div className="flex flex-col gap-4 md:max-w-fit">
              {/* name input. */}
              <div>
                <LabelRequired
                  title="Título del producto"
                  isRequired={true}
                  className="mb-1 whitespace-nowrap"
                />
                <Input
                  id="name"
                  name="name"
                  onBlur={digitalProductFormik.handleBlur}
                  onChange={digitalProductFormik.handleChange}
                  placeholder="Ej. Mi Producto"
                  value={digitalProductFormik.values.name}
                  disabled={loading}
                  error={
                    digitalProductFormik.errors.name &&
                    digitalProductFormik.touched.name
                  }
                />
                {digitalProductFormik.errors.name &&
                  digitalProductFormik.touched.name && (
                    <ErrorMessage
                      errorText={digitalProductFormik.errors.name}
                    />
                  )}
              </div>
              {/* price and currency inputs. */}
              <div>
                <LabelRequired
                  title="Precio"
                  isRequired={true}
                  className="mb-1"
                />
                <PriceInput
                  id="price"
                  name="price"
                  onChange={digitalProductFormik.handleChange}
                  onBlur={digitalProductFormik.handleBlur}
                  value={digitalProductFormik.values.price}
                  disabled={loading}
                  error={
                    digitalProductFormik.errors.price &&
                    digitalProductFormik.touched.price
                  }
                  errorText={digitalProductFormik.errors.price}
                />
              </div>
            </div>
          </div>

          {/* description input. */}
          <div className={'mb-4'}>
            <LabelRequired
              title="Descripción del Producto"
              isRequired={true}
              className="mb-1"
            />
            <TextArea
              id="description"
              name="description"
              onBlur={digitalProductFormik.handleBlur}
              onChange={digitalProductFormik.handleChange}
              placeholder="Ej. Este es mi asombroso producto digital"
              value={digitalProductFormik.values.description}
              disabled={loading}
              error={
                digitalProductFormik.errors.description &&
                digitalProductFormik.touched.description
              }
              rows={3}
              maxLength={230}
            />
            <CharacterCounter
              value={digitalProductFormik.values.description}
              maxLength={230}
              className="mt-2"
            />
            {digitalProductFormik.errors.description &&
              digitalProductFormik.touched.description && (
                <ErrorMessage
                  errorText={digitalProductFormik.errors.description}
                />
              )}
          </div>
          {/* asset input. */}
          {digitalProductType.value === 'fileProtected' && (
            <div>
              <div className="mb-1 flex items-center">
                <LabelRequired title="Archivo" isRequired={true} />
                <QuestionMarkCircleIcon
                  className={
                    'ml-1 inline-block h-5 w-5 cursor-pointer text-gray-400'
                  }
                  onClick={onClickHelpAsset}
                />
              </div>

              <RectangleFileInput
                file={assetFile}
                fileUploadProgress={fileUploadProgress}
                mimeTypesSupported={assetFileMimeTypesSupported}
                parentLoading={loading}
                previsualize={false}
                setErrorMessage={setErrorMessage}
                setFile={setAssetFile}
                url={initialFormData.asset}
                type="file"
              />
            </div>
          )}
          {/* external_url input. */}
          {digitalProductType.value == 'externalLink' && (
            <div className={'mt-4'}>
              <LabelRequired
                title="Enlace del Archivo"
                isRequired={true}
                className="mb-1"
              />
              <Input
                id="external_url"
                name="external_url"
                onChange={digitalProductFormik.handleChange}
                onBlur={digitalProductFormik.handleBlur}
                placeholder="Enlace de Google Drive, Dropbox, etc"
                value={
                  digitalProductFormik.values.external_url
                    ? digitalProductFormik.values.external_url
                    : ''
                }
                disabled={loading}
                error={
                  digitalProductFormik.errors.external_url &&
                  digitalProductFormik.touched.external_url
                }
              />
              {digitalProductFormik.errors.external_url &&
                digitalProductFormik.touched.external_url && (
                  <ErrorMessage
                    errorText={digitalProductFormik.errors.external_url}
                  />
                )}
            </div>
          )}

          <Collapse
            buttonContent={
              <Label
                className="cursor-pointer text-primary-600"
                title="Producto solo para owners de colección"
              />
            }
            className="mt-4"
          >
            <div className="my-4 mt-2">
              <Label title="Link de Solscan" />
              <Input
                name="collection.nftExample"
                onChange={digitalProductFormik.handleChange}
                onBlur={digitalProductFormik.handleBlur}
                value={
                  digitalProductFormik.values.collection?.nftExample
                    ? digitalProductFormik.values.collection.nftExample
                    : ''
                }
                placeholder="https://solscan.io/token/AkLEC...MwNj"
                disabled={loading}
              />
              {digitalProductFormik.errors.collection?.nftExample &&
                digitalProductFormik.touched.collection?.nftExample && (
                  <ErrorMessage
                    errorText={
                      digitalProductFormik.errors.collection.nftExample
                    }
                  />
                )}
            </div>

            <Label title="Redes sociales" />
            <div className="mt-2 flex flex-col gap-4">
              <div>
                <InputWithLeadingIcon
                  Icon={
                    <SocialIcons icon="website" className="text-gray-400" />
                  }
                  name="collection.socialMedia.website"
                  onChange={digitalProductFormik.handleChange}
                  onBlur={digitalProductFormik.handleBlur}
                  value={
                    digitalProductFormik.values.collection.socialMedia.website
                      ? digitalProductFormik.values.collection.socialMedia
                          .website
                      : ''
                  }
                  disabled={loading}
                />
                {digitalProductFormik.errors.collection?.socialMedia?.website &&
                  digitalProductFormik.touched.collection?.socialMedia
                    .website && (
                    <ErrorMessage
                      errorText={
                        digitalProductFormik.errors.collection.socialMedia
                          .website
                      }
                    />
                  )}
              </div>
              <div>
                <InputWithLeadingIcon
                  Icon={
                    <SocialIcons icon="twitter" className="text-gray-400" />
                  }
                  name="collection.socialMedia.twitter"
                  onChange={digitalProductFormik.handleChange}
                  onBlur={digitalProductFormik.handleBlur}
                  value={
                    digitalProductFormik.values.collection.socialMedia.twitter
                      ? digitalProductFormik.values.collection.socialMedia
                          .twitter
                      : ''
                  }
                  disabled={loading}
                />
                {digitalProductFormik.errors.collection?.socialMedia?.twitter &&
                  digitalProductFormik.touched.collection?.socialMedia
                    .twitter && (
                    <ErrorMessage
                      errorText={
                        digitalProductFormik.errors.collection.socialMedia
                          .twitter
                      }
                    />
                  )}
              </div>
              <div>
                <InputWithLeadingIcon
                  Icon={
                    <SocialIcons icon="opensea" className="text-gray-400" />
                  }
                  name="collection.socialMedia.opensea"
                  onChange={digitalProductFormik.handleChange}
                  onBlur={digitalProductFormik.handleBlur}
                  value={
                    digitalProductFormik.values.collection.socialMedia.openSea
                      ? digitalProductFormik.values.collection.socialMedia
                          .openSea
                      : ''
                  }
                  disabled={loading}
                />
                {digitalProductFormik.errors.collection?.socialMedia?.openSea &&
                  digitalProductFormik.touched.collection?.socialMedia
                    .openSea && (
                    <ErrorMessage
                      errorText={
                        digitalProductFormik.errors.collection.socialMedia
                          .openSea
                      }
                    />
                  )}
              </div>
            </div>
          </Collapse>
        </FormBody>

        {/* Edit Form Buttons */}
        <FormFooter>
          {formType == 'edit' && (
            <>
              <SecondaryButton
                title="Eliminar"
                variant={variantType.red}
                disabled={loading}
                onClick={onClickDeleteDigitalProduct}
                className="px-6"
              />
              <BasicButton
                title="Guardar cambios"
                disabled={
                  (!digitalProductFormik.isValid ||
                    !digitalProductFormik.dirty) &&
                  !fileHadChanged
                }
                loading={loading}
                onClick={onClickSubmit}
                type="button"
                className="w-40 whitespace-nowrap"
                loadingChildren={'Guardando'}
              />
            </>
          )}
          {/* Create Form Buttons */}
          {formType == 'create' && (
            <>
              <WhiteButton
                title="Cancelar"
                disabled={loading}
                onClick={onClickCloseModal}
                className="px-6"
              />
              <BasicButton
                title="Crear Producto"
                disabled={
                  !digitalProductFormik.isValid ||
                  !digitalProductFormik.dirty ||
                  (errorMessage && true)
                }
                loading={loading}
                onClick={onClickSubmit}
                type="button"
                className="w-40 whitespace-nowrap"
                loadingChildren="Creando"
              />
            </>
          )}
        </FormFooter>
      </form>
    </FormModal>
  );
};

export default DigitalProductModal;

// Helpers
import { AxiosApi } from 'requests/AxiosBase';
import { getCookie } from 'helpers/handleCookies';

const AxiosGetCreatorDigitalProducts = async (url: string, search: string) => {
  try {
    const userToken = getCookie('token');

    const result = await AxiosApi.get(
      `/digital-products${search ? `?search=${search}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    if (result.status == 204) return null;

    return result.data;
  } catch (error) {
    console.log('AxiosGetCreatorDigitalProducts Error:', error);
    if (error instanceof Error) return error;

    return new Error(
      'Error al ejecutar AxiosGetCreatorDigitalProducts: ' + error
    );
  }
};

export default AxiosGetCreatorDigitalProducts;

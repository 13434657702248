// Libraries
import React, { useState } from 'react';

// Components
import Dropdown from 'components/tailwind/inputs/DropDown';

interface IAudienceHeaderRightProps {
  setValue: (value: string | ((prev: string) => string)) => void;
}

const options = [
  { textContent: 'Suscriptores', value: 'subscriptions', defaultValue: true },
  { textContent: 'Wallets', value: 'wallets' }
];

const AudienceHeaderRight = ({ setValue }: IAudienceHeaderRightProps) => {
  const [selected, setSelected] = useState(options[0]);

  const handleOnChange = (option) => {
    setValue(option.value);
    setSelected(option);
  };

  return (
    <Dropdown
      value={selected}
      onChange={(e) => handleOnChange(e)}
      options={options}
    />
  );
};

export default AudienceHeaderRight;

// Libraries
import { HeartIcon, StarIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';

// Hooks
import { useUser } from 'hooks/useUser';

// Components
import SwitchCard from 'components/dashboard/settings/SwitchCard2';
import SolanaIcon from 'components/tailwind/icons/SolanaIcon';

// Request
import AxiosUpdateUserSettings from 'requests/local_api/AxiosUpdateUserSettings';

const Web3Settings: React.FC = () => {
  const { user, updateUserRedux } = useUser();

  return (
    <>
      {
        user.internal_options.blocks?.nft_carousel !== false && (
          <SwitchCard
            className={user.linked_accounts.phantom.addr === '' && 'opacity-50'}
            title="NFTs"
            description="Muestra en tu perfil los NFTs que coleccionas en tu wallet de Solana."
            Icon={
              <SolanaIcon className="h-5 w-5 text-[#542AE2]" aria-hidden="true" />
            }
            disabled={user.linked_accounts.phantom.addr === ''}
            actived={user.nftsEnabled}
            onClick={async () => {
              const { error } = await AxiosUpdateUserSettings({
                nftsEnabled: !user.nftsEnabled
              });

              if (error) {
                return toast.error(error);
              }

              updateUserRedux({
                nftsEnabled: !user.nftsEnabled
              });
            }}
          />
        )
      }
      <SwitchCard
        className={user.linked_accounts.phantom.addr === '' && 'opacity-50 '}
        title="Comunidad"
        description="Permite a tu audiencia conectar su wallet de Solana a tu perfil para acceder a diferentes beneficios."
        Icon={
          <HeartIcon className="h-6 w-6 text-[#542AE2]" aria-hidden="true" />
        }
        disabled={user.linked_accounts.phantom.addr === ''}
        actived={user.connectWalletEnabled}
        onClick={async () => {
          const { error } = await AxiosUpdateUserSettings({
            connectWalletEnabled: !user.connectWalletEnabled
          });

          if (error) {
            return toast.error(error);
          }

          updateUserRedux({
            connectWalletEnabled: !user.connectWalletEnabled
          });
        }}
      />
    </>
  );
};

export default Web3Settings;

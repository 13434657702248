import { useState } from 'react';
import ActionButton from './ActionButton';
import DotButton from './DotButton';
import { ActionsList } from './types';

interface OptionsMenuButtonProps<T> {
  value: T;
  actions: ActionsList<T>;
}

const OptionsMenuButton = <T,>(props: OptionsMenuButtonProps<T>) => {
  const { actions, value } = props;

  const [show, setShow] = useState<boolean>(false);

  const onClickShowActions = () => setShow(!show);

  return (
    <div className="relative inline-block text-left">
      <div>
        <DotButton onClickShowActions={onClickShowActions} />
      </div>
      {show && (
        <div
          aria-labelledby={'menu-button'}
          aria-orientation={'vertical'}
          className={
            'absolute right-[-16px] z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          }
          role={'menu'}
          tabIndex={-1}
        >
          <div className={'w-full py-1'} role={'none'}>
            {actions.map((action, i) => (
              <div key={i}>
                <ActionButton action={action} value={value} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OptionsMenuButton;

import { AxiosApi } from "requests/AxiosBase"
import { getCookie } from "helpers/handleCookies"

interface IAxiosLinkTree {
  username: string,
  options: Array<string>
}

const AxiosLinkTree = async (bodyPayload: IAxiosLinkTree) => {
  const userToken = getCookie("token")

  if (!userToken) return { error: "No estas logeado" }

  try {
    const { data } = await AxiosApi.post(
      "/import-account/linktree",
      bodyPayload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      }
    )

    if (data.message !== "OK") return { error: data.message }

    return {
      data: data.data
    }
  }

  catch(e) {
    console.error(e)
    console.error("AxiosLinkTree() Error")
    return { error: "Error al conectarse con el servidor" }
  }
}

export default AxiosLinkTree
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { HeartIcon } from '@heroicons/react/solid';
import { HeartIcon as HeartOutlineIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import classNames from 'classnames';

import { IUser } from 'types/User';
import { isProfileImageHexagon } from 'helpers/logicFunctions';
import SolanaWhite from 'assets/icons/SolanaWhite';

import Avatar from 'components/tailwind/avatars/Avatar';
import Button from 'components/buttons/Button';
import MisFansLogo from 'components/tailwind/icons/MisFansLogo';
import SolanaNFTs from 'components/profile/NFTs';
import SpinnerLoader from 'components/tailwind/loaders/SpinnerLoader';
import UserBlocks from 'components/profile/UserBlocks';
import UserInfo from 'components/profile/UserInfo';
import UserSocialsLinks from 'components/profile/UserSocialsLinks';
import ColorsDeclaration from 'components/tailwind/pallete/ColorsDeclaration';
import WalletCard from 'components/profile/WalletCard';
import toast from 'react-hot-toast';

export interface ProfileProps {
  account: IUser;
}

export const ProfileViewerLayout = (props: ProfileProps) => {
  const router = useRouter();

  const [account, setAccount] = useState<IUser>(null);

  useEffect(() => {
    if (props.account) setAccount(props.account);
  }, [props.account]);

  if (account === null) return <div>loading</div>;

  return (
    <div className="relative flex h-full w-full">
      <ColorsDeclaration />
      <div>
        <div
          className="fixed h-full max-h-full min-h-full w-full items-center justify-center bg-white bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: !account.cover_picture
              ? 'transparent'
              : `url("${account.cover_picture}")`
          }}
        >
          <div
            className={classNames(
              account.cover_picture && 'bg-black opacity-40',
              'h-full w-full'
            )}
          ></div>
        </div>
        {/* Content */}
        <div
          className={classNames(
            'absolute items-center justify-center text-black'
          )}
        >
          <div className="flex max-h-[calc(100vh-64px)] min-h-full w-screen flex-1 overflow-y-auto lg:max-h-screen lg:w-[414px]">
            <div className="gl:w-11/12 mx-auto flex min-h-full w-full max-w-[550px] flex-1 flex-col justify-between pt-[60px] lg:rounded-xl lg:px-4">
              <div>
                <Avatar
                  className="mx-auto mb-2 flex justify-center"
                  profilePicture={account.profilePicture}
                  shape={isProfileImageHexagon(account) ? 'hexagon' : 'circle'}
                  size="2xl"
                  theme={
                    account.customProfile?.theme === 'pride'
                      ? 'pride'
                      : 'default'
                  }
                />

                <div className="space-y-6 px-4 lg:px-0">
                  <div className="space-y-4">
                    <UserInfo
                      biography={account.biography}
                      customColor={account.customProfile.buttonColor}
                      hasCoverImage={account.cover_picture && true}
                      isVerified={account.is_verified}
                      name={account.name}
                      username={account.username}
                    />

                    {Object.keys(account.social_medias).length > 0 && (
                      <UserSocialsLinks
                        creatorUsername={account.username}
                        customColor={account.customProfile.buttonColor}
                        hasCoverImage={account.cover_picture && true}
                        isPreviewMode
                        social_medias={account.social_medias}
                      />
                    )}
                  </div>

                  <div className="space-y-3">
                    {account.id !== account.id &&
                      account.connectWalletEnabled && (
                        <WalletCard
                          user_id={account.id}
                          username={account.username}
                          addr={
                            !account.walletInfo.connected
                              ? ''
                              : account.walletInfo.addr
                          }
                          fan_authenticated={
                            account.walletInfo.fan_authenticated
                          }
                          connected={account.walletInfo.connected}
                          customColor={account.customProfile.buttonColor}
                        />
                      )}
                    {account.donationEnabled && (
                      <Button
                        className="w-full text-lg font-semibold transition-all duration-300"
                        text={`Enviar una donación`}
                        icon={() => (
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="mr-2 h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        )}
                        onClick={() => {
                          router.push(`/${account.username}/donate`);
                        }}
                        variant={
                          account.customProfile.buttonColor
                            ? 'customColorPrimary'
                            : 'primary'
                        }
                        customColor={account.customProfile.buttonColor}
                      />
                    )}
                    {account?.has_subscription &&
                    account.subscriptionEnabled ? (
                      <Button
                        className={`w-full cursor-pointer text-lg font-semibold transition-all duration-300 ${
                          account.customProfile.buttonColor
                            ? `bg-${account.customProfile.buttonColor}-50  outline-${account.customProfile.buttonColor}-700  hover:bg-${account.customProfile.buttonColor}-50 text-${account.customProfile.buttonColor}-700`
                            : 'bg-primary-50 text-primary-700  outline-primary-700 hover:bg-primary-50'
                        }`}
                        text={`Suscrito - Ir al Hub Del Creador`}
                        icon={() => <HeartIcon className="mr-2 h-5 w-5" />}
                        onClick={() => router.push(`/${account.username}/hub`)}
                      />
                    ) : account.subscriptionEnabled ? (
                      <Button
                        className={`w-full text-lg ${
                          account.customProfile.buttonColor
                            ? `bg-${account.customProfile.buttonColor}-700 hover:bg-${account.customProfile.buttonColor}-800 active:bg-${account.customProfile.buttonColor}-900`
                            : 'bg-primary-700 hover:bg-primary-800 active:bg-primary-900'
                        }  font-semibold  text-white transition-all duration-300`}
                        text={`Suscribirme por $5.00 USD`}
                        icon={() => (
                          <HeartOutlineIcon className="mr-2 h-5 w-5" />
                        )}
                        onClick={() =>
                          toast.error('No te puedes suscribir a ti mismo')
                        }
                      />
                    ) : (
                      <></>
                    )}

                    {account.collections?.length > 0 && (
                      <Button
                        className="w-full text-lg font-semibold transition-all duration-300"
                        text={`Colección del creador`}
                        icon={() => <SolanaWhite className="mr-2 h-5 w-5" />}
                        onClick={() =>
                          router.push(`${router.asPath}/collection`)
                        }
                        variant={
                          account.customProfile.buttonColor
                            ? 'customColorPrimary'
                            : 'primary'
                        }
                        customColor={account.customProfile.buttonColor}
                      />
                    )}

                    {account.linked_accounts.phantom.addr &&
                    account.nftsEnabled ? (
                      <SolanaNFTs
                        phantomAddress={account.linked_accounts.phantom.addr}
                        hasCoverImage={account.cover_picture && true}
                        customButton={account.customProfile.buttonColor}
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  <UserBlocks
                    blocks={account.blocks}
                    creatorUsername={account.username}
                    customButton={account.customProfile.buttonColor}
                    donationEnabled={account.donationEnabled}
                    fanUsername={account?.username || 'n/a'}
                    hasCoverImage={account.cover_picture && true}
                    isPreviewMode
                    userId={account.id}
                    internal_options_blocks={account.internal_options.blocks}
                  />
                </div>
              </div>

              <div className="mx-auto mt-10 flex flex-col items-center pb-28 lg:pb-11">
                <Link href="/">
                  <a>
                    <p className="mb-2 text-center text-sm text-gray-300">
                      Creado con
                    </p>
                    <MisFansLogo />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Helpers
import { classNames } from 'helpers/classNames';

interface IInputWithLeadingIconProps {
  className?: string;
  Icon: React.ReactNode;
  type?: 'text' | 'password' | 'email' | 'number';
  id?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  children?: React.ReactNode;
}

const InputWithLeadingIcon: React.FC<IInputWithLeadingIconProps> = (props) => {
  const {
    Icon,
    type = 'text',
    id,
    name,
    onChange,
    onFocus,
    onBlur,
    value,
    className,
    placeholder,
    error = false,
    disabled = false,
    maxLength,
    autoFocus = false
  } = props;

  return (
    <label
      className={classNames(
        className,
        error && 'border-red-500',
        disabled && 'cursor-not-allowed opacity-50',
        'flex items-center rounded-md border border-gray-300 py-2 px-3 text-gray-800 shadow-sm transition duration-200 ease-in-out focus-within:border-primary focus-within:ring-1 focus-within:ring-primary active:text-gray-600'
      )}
    >
      <span>{Icon}</span>
      <input
        id={id}
        type={type}
        className="ml-1 w-full border-none bg-transparent p-0 focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        maxLength={maxLength}
        autoFocus={autoFocus}
      />
    </label>
  );
};

export default InputWithLeadingIcon;

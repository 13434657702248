import { defaultToastOptions } from 'helpers/toastOptions';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import AxiosReSendVerifyEmail from 'requests/local_api/AxiosReSendVerifyEmail';

const classNames = (...classes) => classes.filter(Boolean).join(' ');

const VerifyEmailBanner = () => {
  const [disabledCount, setDisabledCount] = useState<number>(0);
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    if (disabledCount <= 0) return;

    const timeout = setTimeout(() => {
      setDisabledCount((prev) => prev - 1);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [disabledCount]);

  const handleOnClick = async () => {
    setFetching(true);

    const { error } = await AxiosReSendVerifyEmail();

    if (error) toast.error(error);
    else {
      toast.success(
        'Se ha enviado nuevamente la confirmación a tu correo!',
        defaultToastOptions
      );
    }

    setFetching(false);
    setDisabledCount(45);
  };
  return (
    <div className="relative z-50 bg-[#542AE2]">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:px-16 sm:text-center">
          <p className="font-medium text-white">
            <span className="inline">
              Favor de verificar tu cuenta de correo
            </span>
            <span className="block sm:ml-2 sm:inline-block">
              <button
                type="button"
                className={classNames(
                  fetching &&
                    disabledCount > 0 &&
                    'cursor-not-allowed text-gray-200',
                  'cursor-pointer font-bold text-white underline'
                )}
                onClick={handleOnClick}
                disabled={disabledCount > 0}
              >
                {disabledCount > 0
                  ? `${disabledCount} Segundos`
                  : `Enviar el correo de nuevo`}
                <span aria-hidden="true">&rarr;</span>
              </button>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailBanner;

// Helpers
import { classNames } from 'helpers/classNames';

// Components
import Tippy from '@tippyjs/react';
import { DuplicateIcon } from '@heroicons/react/outline';

type InputProps = {
  type?: 'text' | 'password' | 'email' | 'number';
  id?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  fixedValue?: string;
  display?: boolean;
  className?: string;
  parentClassName?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  children?: React.ReactNode;
};

const Input: React.FC<InputProps> = ({
  type = 'text',
  id,
  name,
  onChange,
  onFocus,
  onBlur,
  value,
  fixedValue,
  display,
  className,
  parentClassName = '',
  placeholder,
  error = false,
  disabled = false,
  maxLength,
  autoFocus = false,
  children
}) => {
  return (
    <div className={`relative ${display && 'flex'} ${parentClassName}`}>
      <input
        type={type}
        name={name}
        id={id}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={display ? fixedValue : value}
        disabled={disabled}
        className={classNames(
          className,
          error && 'border-red-500',
          ` block w-full rounded-md border border-gray-300 text-gray-800 shadow-sm transition duration-200 ease-in-out focus:border-primary focus:ring-primary active:text-gray-600 disabled:cursor-not-allowed disabled:opacity-50 ${
            display && ' '
          }`
        )}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
      />
      {display && (
        <Tippy
          content="¡Enlace copiado!"
          trigger="click"
          interactive={false}
          interactiveBorder={20}
          className="rounded bg-gray-800 p-1 text-white opacity-70"
          hideOnClick={false} // if you want
          onShow={(instance) => {
            setTimeout(() => {
              instance.hide();
            }, 1000);
          }}
        >
          <button
            onClick={() => navigator.clipboard.writeText(`${fixedValue}`)}
          >
            <DuplicateIcon className="ml-1 h-5 w-5 text-gray-500 hover:text-gray-700 " />
          </button>
        </Tippy>
      )}
      <div className="absolute left-[90%] bottom-1">{children}</div>
    </div>
  );
};

export default Input;

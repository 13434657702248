import { useState } from 'react'

// Components
import Card from './Card'

// Hooks
import { useUser } from "hooks/useUser"

interface IPaypalButtonProps {
  isConnectActive: boolean;
  platform: string;
}

const PaypalButton = ({ isConnectActive, platform }: IPaypalButtonProps) => {
  const { user } = useUser()

  // https://www.sandbox.paypal.com/connect?flowEntry=static&client_id=Ab66LuzFMDmaTyAwPtbuJ3BjXHpZIkIdDJvUeNqhr87-PtQoXPjXVASOkbU_sRASDyqEOKxjSjY2oChi&response_type=code&scope=https://uri.paypal.com/services/paypalattributes&redirect_uri=https://beta-supra-link.vercel.app/auth/paypal
  const handleOnClick = () => {
    if (user.internal_options?.links_accounts?.paypal?.enabled === false) return

    let domain = "beta-supra-link.vercel.app"

    if (window.location.protocol === "https:") {
      domain = window.location.host
    }

    const paypalHostname = `https://www.${process.env.NEXT_PUBLIC_PRODUCTION_MODE === "true" ? "" : "sandbox."}paypal.com`
    const redirect_uri = `https://${domain}/auth/paypal`
    const urlSearchParams = new URLSearchParams()

    urlSearchParams.append("flowEntry", "static")
    urlSearchParams.append("client_id", process.env.NEXT_PUBLIC_PAYPAL_CLIENT || "asd")
    urlSearchParams.append("response_type", "code")
    urlSearchParams.append("scope", "https://uri.paypal.com/services/paypalattributes")
    urlSearchParams.append("redirect_uri", redirect_uri)

    window.location.href = `${paypalHostname}/connect?${urlSearchParams.toString()}`
  };

  return (
    <Card
      img="/img/icons/paypal.svg"
      imgBackgroundColor="#0079C1"
      title="PayPal"
      description="Recibe pagos por PayPal"
      buttonText={
        isConnectActive && platform === 'paypal' ? 'Conectado' : 'Conectar'
      }
      buttonClassName={
        isConnectActive && platform === 'paypal'
          ? 'bg-primary text-white'
          : 'border border-gray-300 text-gray-700 bg-white focus:ring-2 focus:ring-primary focus:ring-offset-2 hover:bg-gray-50 disabled:hover:bg-white'
      }
      onClickButton={handleOnClick}
      active={(!platform && user.internal_options?.links_accounts?.paypal?.enabled !== false) || (isConnectActive && platform === "paypal")}
      //active={false}
      disableButton={isConnectActive || user.internal_options?.links_accounts?.paypal?.enabled === false}
      //disableButton={true}
      tooltipText={
        isConnectActive && platform === 'paypal'
          ? 'Si deseas conectar tu cuenta de Paypal, necesitas desconectar tu cuenta de stripe, favor de hablar con soporte'
          : ''
      }
      isConnected={isConnectActive && platform === 'paypal'}
    />
  );
};

export default PaypalButton;

import {
  CashIcon,
  CurrencyDollarIcon,
  CursorClickIcon,
  EyeIcon,
  HandIcon,
  MusicNoteIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  TagIcon,
  UsersIcon
} from '@heroicons/react/outline';
import styles from './slider.module.css';
import FileContext from 'context/file/FileContext';
import AwsStorage from 'libs/AwsStorage';
import { defaultToastOptions } from 'helpers/toastOptions';
import HeaderScreen from 'components/dashboard/HeaderScreen';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import BasicCheckbox from 'components/tailwind/checkbox/BasicCheckbox';
import Input from 'components/tailwind/inputs/Input';
import Label from 'components/tailwind/inputs/Label';
import classNames from 'utils/class-names';
import useApp from 'hooks/useApp';
import { useEffect, useState, useContext, useRef } from 'react';
import { toast } from 'react-hot-toast';

// Request
import AxiosUpdateUserSettings from 'requests/local_api/AxiosUpdateUserSettings';

// Hooks
import { useSocket } from "hooks/useSocket"
import useWindowDimensions from 'hooks/useWindowDimensions';
import PreviewStream from './preview';
import { useUser } from 'hooks/useUser';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import { number } from 'yup/lib/locale';
import StaticInput from 'components/tailwind/inputs/StaticInput';
import AudioPlayer from 'components/audioPlayer';

/* 
{
  "small": {
      "features": ["subscriptions", "digital_products"],
      "duration": 5,
      "sound": "https://aws.s3.com/elsonido.mp3",
   }
} 
*/

const StreamScreen = () => {
  const renderState = useRef<number>(0);
  const socket = useSocket()
  const { app, editAnalyticsRedux } = useApp();
  const { width } = useWindowDimensions();
  const { user, status } = useUser();
  const [audio, setAudio] = useState(null);

  const {
    file,
    setFile,
    updatePreviousData,
    showInputReject,
    setShowInputReject
  } = useContext(FileContext);
  const [features, setFeatures] = useState<string[]>([]);
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [isTriggered, setIsTriggered] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(5);
  const [urlGif, SetGif] = useState<string>('');
  const [isAudio, setIsAudio] = useState<boolean>(false);

  const url = 'https://mis.fans/stream/small?user_id=' + user.id;

  function handleTest() {
    if (socket) {
      socket.emit(`stream_alert_test`, {
        user_id: user.id,
        message: "Holaaaa " + (user.name || user.username),
        amount: 5,
        name: "Nombre del fan",
        feature: "donations"
      })
    }

    setIsTriggered(true);
    console.log(isTriggered);
    audio?.play()
    setTimeout(() => {
      setIsTriggered(false);
    }, Number(duration) * 1000);
  }

  const handleOnChangeFeatures = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (features.includes(value)) {
      setFeatures((prev) => prev.filter((feature) => feature !== value));
      return;
    }

    setFeatures((prev) => [...prev, value]);
  };

  const handleOnChangeGif = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    SetGif(value);
  };

  const handleOnChangeDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value }: any = e.target;

    if (isNaN(value)) {
      console.log('isNaN');
      setDuration(5);
    }

    if (!isNaN(value)) {
      if (value == '') {
        setDuration(5);
      } else if (value > 10) {
        setDuration(10);
      } else {
        console.log('ya');
        setDuration(Number(value));
      }
    }
  };

  const handleOnDragOver = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
    if (!dragOver) return setDragOver(true);
  };

  const handleOnDragLeave = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
    return setDragOver(false);
  };

  const handleOnDrop = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
    setDragOver(false);
    const file = e.dataTransfer.files[0];
    if (!file.type.includes('audio'))
      return toast.error('El archivo debe ser un audio', defaultToastOptions);
    const blobToUrl = URL.createObjectURL(file);
    // updatePreviousData(notification.id, {
    //   preview: blobToUrl,
    //   raw: file
    // });
    setFile((prev: any) => ({
      ...prev,
      preview: blobToUrl,
      raw: file
    }));
  };

  const handlefile = (audio: Blob) => {
    try {
      if (!audio.type.includes('audio'))
        return toast.error('El archivo debe ser un audio', defaultToastOptions);
      let preview = URL.createObjectURL(audio);
      setIsAudio(true);
      console.log(preview);
      return { preview: preview, raw: audio };
    } catch (error) {
      return error;
    }
  };

  const checkboxes = [
    {
      label: 'Donaciones',
      value: 'donations',
      description: 'Get notified when someone makes a donation.'
    },
    {
      label: 'Saludos',
      value: 'greetings',
      description: 'Get notified when someone greets you.'
    },
    {
      label: 'Preguntas',
      value: 'questions',
      description: 'Get notified when someone asks a question.'
    },
    {
      label: 'Audios',
      value: 'audios',
      description: 'Get notified when someone asks for an audio.'
    },
    {
      label: 'Subscripciones',
      value: 'subs',
      description: 'Get notified when someone subscribes to your profile.'
    }
  ];

  useEffect(() => {
    const { current } = renderState;
    console.log({ current });

    if (current === 0) return;
    else if (current === 1) {
      renderState.current = 2;
      return;
    }

    console.log('Effect Settings aaaa');

    const timeout = setTimeout(async () => {
      const { error } = await AxiosUpdateUserSettings({
        stream_alerts_cfg: {
          small: {
            ...user.stream_alerts_cfg.small,
            features,
            duration,
            urlGif
          }
        }
      });
      if (!error) {
        toast.success('Se actualizó correctamente');
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [features, duration, urlGif]);

  useEffect(() => {
    const { current } = renderState;
    if (current === 0) return;
    else if (current === 1) {
      renderState.current = 2;
      return;
    }

    if (file) {
      uploadAudio();
    }

    console.log('as');
    console.log(file);
  }, [file]);

  async function uploadAudio() {
    if (!file.raw) return;
    const result = await AwsStorage({
      contentType: 'audio/mpeg',
      file: file.raw,
      folder: 'stream_alerts',
      metadata: {}
    });
    console.log(result);
    const { error } = await AxiosUpdateUserSettings({
      stream_alerts_cfg: {
        small: {
          ...user.stream_alerts_cfg.small,
          audio: result
        }
      }
    });
    if (!error) {
      toast.success('Se actualizó correctamente');
    }
  }

  useEffect(() => {
    if (status !== 1) return;

    console.log(user.stream_alerts_cfg);

    SetGif(user.stream_alerts_cfg?.small?.urlGif || '');
    setDuration(user.stream_alerts_cfg?.small?.duration ?? 5);
    setFeatures(user.stream_alerts_cfg?.small?.features || []);

    if (typeof user.stream_alerts_cfg?.small?.audio === 'string') {
      setAudio(new Audio(user.stream_alerts_cfg?.small?.audio));
    }

    renderState.current = 1;
  }, [status]);

  return (
    <div className="">
      <HeaderScreen title="Alertas de stream" />
      <div className="flex flex-col sm:flex-row">
        <div className="mb-2 w-full sm:mr-[40px]">
          <span className="text-lg font-medium leading-6 text-gray-600">
            Configuración de las alertas
          </span>

          <div className="mt-[23px]">
            <Label
              title="URL"
              subtitle="(Pega este enlace en una vista de OBS)"
            />
            <StaticInput className="mt-1 " value={url} display={true} />
            <h1 className="mb-[12px] text-xs text-gray-600">
              Tamaño recomendado: 250x260
            </h1>
            <BasicButton
              title="Enviar alerta de prueba"
              className="w-full"
              onClick={handleTest}
            />
          </div>

          <div className="mt-[23px]">
            <h1 className="mb-2 text-lg font-medium leading-6 text-gray-700">
              Notificaciones
            </h1>
            <div>
              {checkboxes.map((checkbox, index) => (
                <div className="mb-[20px] last:mb-0" key={index}>
                  <BasicCheckbox
                    label={checkbox.label}
                    description={checkbox.description}
                    checked={features.includes(checkbox.value)}
                    value={checkbox.value}
                    onChange={handleOnChangeFeatures}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="mt-[23px]">
            <span className="text-lg font-medium leading-6 text-gray-700">
              Sonido para la alerta
            </span>

            {isAudio ? (
              <AudioPlayer audio={file.preview} onlyPlay />
            ) : (
              <div
                className={classNames(
                  dragOver && 'bg-gray-100 opacity-70',
                  'mt-1 flex h-full w-full flex-col items-center justify-center rounded-sm border-[.2rem] border-dashed p-5 text-sm font-medium'
                )}
                onDragOver={handleOnDragOver}
                onDragLeave={handleOnDragLeave}
                onDrop={handleOnDrop}
              >
                <img src="/UploadIcon.svg" className="mb-3 h-9 w-9" />
                <div className="flex">
                  <label
                    htmlFor="iw_inputFileCover"
                    className="cursor-pointer justify-center text-primary"
                  >
                    Sube un archivo
                    <input
                      id="iw_inputFileCover"
                      className="hidden"
                      type="file"
                      accept="audio/*"
                      onChange={(e) => setFile(handlefile(e.target.files[0]))}
                    />
                  </label>
                  <p className="ml-1 text-gray-600"> ó arrastralo al espacio</p>
                </div>
                <p className="text-gray-500">MP4 ó MOV hasta 15MB</p>
              </div>
            )}
          </div>

          <div className="mt-[23px]">
            <LabelRequired title="GIF para la alerta" />
            <Input
              name="duration"
              placeholder="https://giphy.com/gifs/ufc-uf..."
              onChange={handleOnChangeGif}
              value={urlGif}
            />
          </div>

          <div className="mt-[23px]">
            <LabelRequired title="Duración de la alerta" />
            <div className="flex gap-1">
              <h1>1</h1>
              <input
                className={styles.slider}
                name="duration"
                type="range"
                min="1"
                max="10"
                onChange={handleOnChangeDuration}
                value={duration.toString()}
              />
              <h1>10</h1>
            </div>
          </div>
        </div>

        <div className="grid min-h-[70vw] w-full place-items-center  rounded bg-primary-400 sm:min-h-0">
          <PreviewStream duration={duration} isTriggered={isTriggered} />
        </div>
      </div>
    </div>
  );
};

export default StreamScreen;

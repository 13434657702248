import { AxiosApi } from '../AxiosBase';

// Helpers
import { getCookie } from 'helpers/handleCookies';

interface IAxiosStepRegisterProcess {
  step: number;
  subStep?: number;
  name?: string;
  username?: string;
  profilePicture?: string;
  email?: string;
  bio?: string;
  referral?: string;
  tags?: number[];
}

const AxiosStepRegisterProcess = async (payload: IAxiosStepRegisterProcess) => {
  const userToken = getCookie('token');

  if (!userToken) return { error: 'No estas logeado' };

  try {
    const { data } = await AxiosApi.post(
      '/auth/step/register-process',
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    if (data.message !== 'OK') return { error: data.message };

    return {
      data: data.data === undefined ? 'OK' : data.data
    };
  } catch (e) {
    console.log(e);
    console.log('AxiosStepRegisterProcess() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

export default AxiosStepRegisterProcess;

// Libraries
import { useEffect, RefObject, useState } from 'react';

export type HTMLVideoElementMF = HTMLVideoElement & {
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullscreen(): Promise<void>;
  msRequestFullscreen(): Promise<void>;
  parentNode: HTMLElement & {
    requestFullscreen(): Promise<void>;
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
  };
};

type docWithBrowsersExitFunctions = Document & {
  mozCancelFullScreen(): Promise<void>;
  webkitExitFullscreen(): Promise<void>;
  msExitFullscreen(): Promise<void>;
};

const useVideoPlayer = (videoElement: RefObject<HTMLVideoElementMF>) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    isMuted: false,
    volume: 1,
    speed: 1,
    progress: 0,
    buffered: 100,
    duration: 0,
    currentTime: 0,
    fullscreen: false,
    videoEnded: false,
    started: false
  });

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
      videoEnded: false,
      started: true
    });
  };

  useEffect(() => {
    if (videoElement.current) {
      const video = videoElement.current;
      setPlayerState({
        ...playerState,
        duration: video.duration
      });
    }
  }, [videoElement.current?.duration]);

  useEffect(() => {
    playerState.isPlaying
      ? videoElement.current.play()
      : videoElement.current.pause();
  }, [playerState.isPlaying, videoElement]);

  const handleOnTimeUpdate = () => {
    const progress =
      (videoElement.current.currentTime / videoElement.current.duration) * 100;
    const currentTime = videoElement.current.currentTime;
    setPlayerState({
      ...playerState,
      progress,
      currentTime
    });
  };

  const handleBuffer = () => {
    var duration = videoElement.current.duration;
    if (duration > 0) {
      for (var i = 0; i < videoElement.current.buffered.length; i++) {
        const buffered =
          (videoElement.current.buffered.end(
            videoElement.current.buffered.length - 1 - i
          ) /
            duration) *
          100;
        setPlayerState({
          ...playerState,
          buffered
        });
        break;
      }
    }
  };

  const handleVideoProgress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    videoElement.current.currentTime =
      (parseFloat(value) / 100) * videoElement.current.duration;

    setPlayerState({
      ...playerState,
      progress: parseFloat(value),
      videoEnded: false
    });
  };

  const handleVideoSpeed = (e: React.ChangeEvent<HTMLInputElement>) => {
    const speed = parseFloat(e.target.value);
    videoElement.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted
    });
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const volume = parseFloat(e.target.value) / 100;
    videoElement.current.volume = volume;
    setPlayerState({
      ...playerState,
      volume
    });
  };

  useEffect(() => {
    playerState.isMuted
      ? (videoElement.current.muted = true)
      : (videoElement.current.muted = false);
  }, [playerState.isMuted, videoElement]);

  const handleVideoEnded = () => {
    setPlayerState({
      ...playerState,
      videoEnded: true,
      isPlaying: false
    });
  };

  const openFullScreen = () => {
    if (videoElement.current.parentNode.requestFullscreen) {
      videoElement.current.parentNode.requestFullscreen();
    } else if (videoElement.current.parentNode.mozRequestFullScreen) {
      videoElement.current.parentNode.mozRequestFullScreen();
    } else if (videoElement.current.parentNode.webkitRequestFullscreen) {
      videoElement.current.parentNode.webkitRequestFullscreen();
    } else if (videoElement.current.parentNode.msRequestFullscreen) {
      videoElement.current.parentNode.msRequestFullscreen();
    }
    setPlayerState({
      ...playerState,
      fullscreen: true
    });
  };

  const closeFullScreen = () => {
    const doc = document as docWithBrowsersExitFunctions;

    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    }
    setPlayerState({
      ...playerState,
      fullscreen: false
    });
  };

  /* UseEffect to know exit full screen*/
  useEffect(() => {
    const doc = document as docWithBrowsersExitFunctions;
    const fullscreenChange = () => {
      setPlayerState({
        ...playerState,
        fullscreen: doc.fullscreenElement !== null
      });
    };
    doc.addEventListener('fullscreenchange', fullscreenChange);
    doc.addEventListener('mozfullscreenchange', fullscreenChange);
    doc.addEventListener('webkitfullscreenchange', fullscreenChange);
    doc.addEventListener('msfullscreenchange', fullscreenChange);
    return () => {
      doc.removeEventListener('fullscreenchange', fullscreenChange);
      doc.removeEventListener('mozfullscreenchange', fullscreenChange);
      doc.removeEventListener('webkitfullscreenchange', fullscreenChange);
      doc.removeEventListener('msfullscreenchange', fullscreenChange);
    };
  }, [playerState.fullscreen]);

  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    handleBuffer,
    handleVolumeChange,
    openFullScreen,
    closeFullScreen,
    handleVideoEnded,
    toggleMute
  };
};

export default useVideoPlayer;

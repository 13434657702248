import React from 'react';
import NavLink from '../desktop/NavLink';
import { useUser } from 'hooks/useUser';
import { LoginIcon } from '@heroicons/react/outline';
import useApp from 'hooks/useApp';
import { cache } from 'swr';

const LogoutButton = () => {
  const { logout } = useUser();
  const { userLogout } = useApp();

  return (
    <button
      type="button"
      className="group my-3 w-full rounded-md p-3 text-base transition-all duration-300 hover:bg-red-600"
      onClick={() => {
        userLogout();
        cache.clear();
        logout();
      }}
    >
      <div className="flex items-center">
        <LoginIcon className="mr-2 h-6 w-6 text-gray-400 group-hover:text-white" />

        <span className="font-semibold text-gray-600 group-hover:text-white">
          Cerrar sesión
        </span>
      </div>
    </button>
  );
};

export default LogoutButton;

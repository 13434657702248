import React from 'react';
import BasicDivider from 'components/tailwind/dividers/BasicDivider';
// Helpers
import { classNames } from 'helpers/classNames';

interface INavLinkProps {
  className?: string;
  id?: string;
  type?: 'button' | 'link';
  icon: any;
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  href?: string;
  JSXRight?: React.ReactElement;
  selected?: boolean;
  divider?: boolean;
}

const NavLink = ({
  className = '',
  id = '',
  type = 'button',
  onClick,
  icon: Icon,
  text,
  href = '#',
  JSXRight,
  selected,
  divider = false
}: INavLinkProps) => {
  if (type === 'link' && !href)
    throw new Error(`Your <NavLink type='${type}'/> is missing href props`);
  else if (type !== 'button' && onClick)
    throw new Error(
      `Your <NavLink type='${type}' onClick only works with button type />`
    );

  const theClassName: string =
    'flex items-center justify-between transition-all duration-300 rounded-md p-3 w-full';

  if (type === 'link') {
    return (
      <a className={`${theClassName} ${className}`} href={href} target="_blank">
        <div className="flex items-center">
          <Icon
            className={classNames(
              selected ? 'text-primary' : 'text-gray-400',
              'mr-2 h-6 w-6'
            )}
          />

          <span className="font-semibold">{text}</span>
        </div>

        {JSXRight}
      </a>
    );
  }

  return (
    <div>
      <button
        id={id}
        type="button"
        className={`${theClassName} ${className}`}
        onClick={onClick}
      >
        <div className="flex items-center">
          <Icon
            className={classNames(
              selected ? 'text-primary' : 'text-gray-400',
              'mr-2 h-6 w-6'
            )}
          />

          <span className="font-semibold">{text}</span>
        </div>

        {JSXRight}
      </button>
      {divider && <BasicDivider className='my-[20px]'/>}
    </div>
  );
};

export default NavLink;

// Helpers
import { classNames } from 'helpers/classNames';

type CharacterCounterProps = {
  value: string;
  maxLength: number;
  className?: string;
};

const CharacterCounter: React.FC<CharacterCounterProps> = ({
  value,
  maxLength,
  className
}) => {
  return (
    <div
      className={classNames(
        value?.length > (80 / 100) * maxLength
          ? 'text-red-500'
          : 'text-gray-500',
        `text-sm font-medium leading-4 text-gray-400 ${className}`
      )}
    >
      {value ? value.length : 0}/{maxLength}
    </div>
  );
};

export default CharacterCounter;

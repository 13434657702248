import { SVGProps } from 'react';

export enum SpecialKey {
  alt = 'alt',
  cmd = 'command',
  ctrl = 'ctrl',
  option = 'option',
  shift = 'shift'
}

export enum CommonKey {
  L = 'L',
  M = 'M'
}

export interface ActionShorcutRules {
  common: ActionShortcut;
  macOS?: ActionShortcut;
  windows?: ActionShortcut;
}

/** Any Action Shorcut should be defined by a combination of two types of
 * hotkeys: specials (modifier key switch), and commons (alphabetical values).
 * Try to investigate if navigators are not using the combination you want to
 * apply to the CommandAction.
 *
 * @see https://stackoverflow.com/questions/11941180/available-keyboard-shortcuts-for-web-applications
 */
export interface ActionShortcut {
  specialKeys: Array<SpecialKey>;
  commonKeys: Array<CommonKey>;
}

export interface CommandAction {
  /** Function invoked when shorcut is triggered or Action is clicked. Optional
   * prop.  */
  action?: () => any | (() => Promise<any>);

  /** Icon rendered right aside of the name. Optional prop. */
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;

  /** Unique identifier used on render needs. This should be generated using
   * Uuidv4 library. */
  key: string;

  /** Text shown as the name of this action. */
  name: React.ReactNode | string;

  /** If true, this Action will appear as recommendation if no search was
   * provided. Optional prop. */
  predefined?: boolean;

  /** Shortcut value applied to fast execute this CommandAction. Optional prop. */
  shortcutRules?: ActionShorcutRules;

  /** boolean that determines that only verified users can use this action. */
  shouldBeVerified?: boolean;
}

export type CommandActionHistory = Array<Pick<CommandAction, 'key' | 'name'>>;

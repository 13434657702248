import { TrashIcon } from '@heroicons/react/solid';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { toast } from 'react-hot-toast';

// Config
import globalCfg from 'config/globalCfg';

// Helpers
import { classNames } from 'helpers/classNames';

// Request
import AxiosUpdateUserSettings from 'requests/local_api/AxiosUpdateUserSettings';

// Components
import Switch from 'components/tailwind/inputs/Switch';
import Avatar from 'components/tailwind/avatars/Avatar';
import Label from 'components/tailwind/inputs/Label';

const ProfileImage = () => {
  const { userEdit, userEditSettingsFields, user, updateUserRedux } = useUser();

  const hexagonShape =
    user.is_verified && user.linked_accounts.phantom.addr.length > 0;

  const handleFile = (file: Blob) => {
    if (!file.type.includes('image')) {
      toast.error('Tu foto de perfil debe ser una imagen', defaultToastOptions);
      return;
    } else if (file.size > globalCfg.MAX_SIZE_IMAGE) {
      toast.error(
        'La imagen no debe ser superior a 10 MB',
        defaultToastOptions
      );
      return;
    }

    const blobToUrl = URL.createObjectURL(file);

    userEditSettingsFields({
      profilePicture: {
        url: blobToUrl,
        blob: file
      }
    });
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files[0];

      handleFile(file);
    } catch (e) {
      console.log(e);
      console.log('handleOnChangeInput()');
    }
  };

  const handleOnClickRemovePicture = () => {
    userEditSettingsFields({
      profilePicture: {
        url: '',
        blob: null
      }
    });
  };

  const handleSwitch = async () => {
    const { error } = await AxiosUpdateUserSettings({
      customProfile: {
        ...user.customProfile,
        theme: user.customProfile.theme === 'pride' ? 'default' : 'pride'
      }
    });

    if (error) {
      return toast.error(error);
    }

    updateUserRedux({
      customProfile: {
        ...user.customProfile,
        theme: user.customProfile.theme === 'pride' ? 'default' : 'pride'
      }
    });

    userEditSettingsFields({
      customProfile: {
        ...user.customProfile,
        theme: user.customProfile.theme === 'pride' ? 'default' : 'pride'
      }
    });
  };

  return (
    <div className="flex items-center">
      <div className="relative h-[100px] w-[100px]">
        <Avatar
          profilePicture={userEdit.profilePicture.url}
          shape={hexagonShape ? 'hexagon' : 'circle'}
          size="xl"
          theme={
            userEdit.customProfile?.theme === 'pride' ? 'pride' : 'default'
          }
        />
        <div
          className={classNames(
            hexagonShape ? 'hexagon-clip-path' : 'rounded-full',
            'absolute inset-0 bg-white bg-opacity-50 opacity-0 transition-all duration-300 hover:opacity-100'
          )}
        >
          <div
            className="flex h-full w-full cursor-pointer items-center justify-center"
            onClick={handleOnClickRemovePicture}
          >
            <TrashIcon className="h-8 w-8 text-red-400" />
          </div>
        </div>
      </div>

      {user.is_verified ? (
        <div className="ml-5 flex h-[100px] flex-col">
          <label
            htmlFor="iw_inputFileProfile"
            className="w-fit cursor-pointer items-center justify-center rounded-md border border-gray-300 px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            Cambiar
            <input
              id="iw_inputFileProfile"
              className="hidden"
              type="file"
              onChange={handleOnChangeInput}
            />
          </label>
          <div className="mt-auto flex flex-col">
            <Label title="Show your Pride" />
            <Switch
              show={user.customProfile.theme === 'pride' && true}
              setShow={handleSwitch}
              theme="pride"
              className="mt-[5px]"
            />
          </div>
        </div>
      ) : (
        <label
          htmlFor="iw_inputFileProfile"
          className="ml-5 cursor-pointer items-center justify-center rounded-md border border-gray-300 px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-primary focus:ring-offset-2"
        >
          Cambiar
          <input
            id="iw_inputFileProfile"
            className="hidden"
            type="file"
            onChange={handleOnChangeInput}
          />
        </label>
      )}
    </div>
  );
};

export default ProfileImage;

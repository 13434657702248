// Libraries
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect } from 'react';
import { AtSymbolIcon } from '@heroicons/react/solid';

// Components
import IconButton from 'components/tailwind/buttons/IconButton';

type NotificationWithActionsProps = {
  icon?: React.ReactElement;
  title: string;
  description: string;
  actions: React.ReactElement;
  show: boolean;
  setShow: (show: boolean) => void;
  hideCloseIcon?: boolean;
  urlSound?: string;
};

const NotificationWithActions: React.FC<NotificationWithActionsProps> = ({
  icon,
  title,
  description,
  actions,
  show,
  setShow,
  hideCloseIcon = false,
  urlSound
}) => {
  useEffect(() => {
    if (urlSound) {
      const audio = new Audio(urlSound);
      audio.play();
    }
  }, []);
  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {icon ? (
                icon
              ) : (
                <AtSymbolIcon className="h-5 w-5 text-yellow-500" />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-500">{description}</p>
              {actions}
            </div>
            {!hideCloseIcon && (
              <div className="ml-4 flex flex-shrink-0">
                <IconButton
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default NotificationWithActions;

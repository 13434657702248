// Libraries
import { HiSearch } from 'react-icons/hi';
import { PlusIcon } from '@heroicons/react/solid';
import { Fragment, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

// Types
import {
  CurrencyCode,
  DigitalProduct,
  DigitalProductFormType,
  UpsertDigitalProduct
} from 'types/digitalProduct';
import { ActionTriggerDigitalProduct } from 'types/actionTriggerType';

// Helpers
import { ActionTriggerContext } from 'context/actionTrigger';

// Request
import AxiosGetCreatorDigitalProducts from 'requests/local_api/digitalProducts/AxiosGetCreatorDigitalProducts';

// Hooks
import { useUser } from 'hooks/useUser';

// Components
import HeaderScreen from 'components/dashboard/HeaderScreen';
import InputWithLeadingIcon from 'components/tailwind/inputs/InputWithLeadingIcon';
import MyProductsScreen from 'components/dashboard/products/myProducts';
import DigitalProductModal from 'components/dashboard/products/myProducts/DigitalProductModal';
import StripeAccountMissingModal from 'components/dashboard/products/StripeAccountMissingModal';
import BasicButton from 'components/tailwind/buttons/BasicButton';

/** Status that determines the different statuses that the creator's Stripe
 * Connect account can have.
 */
enum StripeAccountStatus {
  connectIdMissing = 'connectIdMissing',
  incompleteStripeAccount = 'incompleteStripeAccount',
  good = 'good'
}

const initialFormData: UpsertDigitalProduct = {
  asset: undefined,
  collection: {
    address: '',
    name: '',
    nftExample: '',
    socialMedia: { website: '', twitter: '', openSea: '' }
  },
  creator_account_id: undefined,
  currency: CurrencyCode.USD,
  description: undefined,
  external_url: undefined,
  name: '',
  placeholder: undefined,
  price: undefined,
  type: 'fileProtected'
};

let timeout: any = undefined;

const ProductsScreen = () => {
  const { action } = useContext(ActionTriggerContext);

  const [stripeAccountStatus, setStripeAccountStatus] =
    useState<StripeAccountStatus>(null);
  const [openDigitalProductModal, setOpenDigitalProductModal] =
    useState<boolean>(false);
  const [openStripeAccountMissingModal, setOpenStripeAccountMissingModal] =
    useState<boolean>(false);
  const [stripeAccountMissingModalTitle, setStripeAccountMissingModalTitle] =
    useState<string>('');
  const [
    stripeAccountMissingModalDescription,
    setStripeAccountMissingModalDescription
  ] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [formType, setFormType] = useState<DigitalProductFormType>('create');
  const [formData, setFormData] =
    useState<UpsertDigitalProduct>(initialFormData);
  const [previewId, setPreviewId] = useState<number>(null);
  const [search, setSearch] = useState<string>('');

  const { user, updateUserRedux } = useUser();

  const {
    error: digitalProductsError,
    data: digitalProductsAggregate,
    mutate
  } = useSWR(['/digital-products', search], AxiosGetCreatorDigitalProducts);

  const refreshDigitalProducts = async () => {
    await mutate();
  };

  useEffect(() => {
    // It's important to use setTimeout. For some reason the first immediate
    // render don't want to change the "selected" state.
    setTimeout(() => setActionTriggered(), 300);
  }, [action]);

  useEffect(() => {
    // TODO useUser hook should expose an error value for preventing infinite
    // loading on this screen.

    // If user isn't able, set loading.
    if (user) {
      setLoading(true);
      validateUserStripeAccount();
    } else {
      setLoading(false);
    }
  }, [user]);

  const setActionTriggered = () => {
    switch (action) {
      case ActionTriggerDigitalProduct.addProduct:
        setFormType('create');
        setOpenDigitalProductModal(true);
        break;
    }
  };

  const hasInvalidConnectId = () => {
    return !user.linked_accounts?.paypal?.merchant_id && !user.connect_id;
  };

  const hasIncompleteStripeConnectAccount = async () => {
    if (user.linked_accounts?.paypal?.merchant_id) return false;

    const { message } = (await fetch(
      `/api/verify-connect-account?connect_id=${user.connect_id}`,
      {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'same-origin'
      }
    ).then((res) => res.json())) as { message: string };

    if (message === 'OK') return false;

    return true;
  };

  /** Check on loading screen time if user has a valid connectId and
   * StripeAccount, otherwise show a modal noticing about missing configuration.
   */
  const validateUserStripeAccount = async () => {
    if (hasInvalidConnectId()) {
      setStripeAccountStatus(StripeAccountStatus.connectIdMissing);
      setStripeAccountMissingModalTitle('¡Configuración necesaria!');
      return setStripeAccountMissingModalDescription(
        'Para poder crear productos digitales es necesario que configures tu cuenta de Stripe Connect. Por favor completa tu configuración yendo a la sección "Ajustes".'
      );
    }

    if (await hasIncompleteStripeConnectAccount()) {
      // TODO: is necessary to show Stripe Button when user hasn't an account
      // email validation, but show him a message noticing about this email
      // validation required.
      setStripeAccountStatus(StripeAccountStatus.incompleteStripeAccount);
      setStripeAccountMissingModalTitle(
        '¡Cuenta de Stripe Connect incompleta!'
      );
      return setStripeAccountMissingModalDescription(
        'Tu cuenta de Stripe Connect no ha sido completada o le falta información. Por favor, completa tu cuenta de Stripe Connect yendo a la sección "Ajustes"'
      );
    }

    setStripeAccountStatus(StripeAccountStatus.good);
    setLoading(false);
  };

  const showStripeAccountMissingModal = () => {
    setOpenStripeAccountMissingModal(!openStripeAccountMissingModal);
  };

  const handleDigitalProductModal = () => {
    // Prevents closing modal while data is submitting.
    if (loading === false) setOpenDigitalProductModal(!openDigitalProductModal);

    setPreviewId(null);
  };

  const handleStripeAccountMissingModal = () => {
    // Prevents closing modal while data is submitting.
    if (loading === false)
      setOpenStripeAccountMissingModal(!openStripeAccountMissingModal);

    setPreviewId(null);
  };

  const onClickCreateProduct = async () => {
    // Only open if StripeAccountStatus has a valid status.
    if (stripeAccountStatus == StripeAccountStatus.good) {
      setFormData(initialFormData);
      setFormType('create');
      setOpenDigitalProductModal(!openDigitalProductModal);
    } else showStripeAccountMissingModal();
  };

  const onClickEditDigitalProduct = (digitalProduct: DigitalProduct) => {
    setPreviewId(digitalProduct.id);
    if (digitalProduct.collection === null) {
      setFormData({
        ...digitalProduct,
        collection: {
          address: '',
          name: '',
          nftExample: '',
          socialMedia: { website: '', twitter: '', openSea: '' }
        }
      });
    } else {
      setFormData(digitalProduct);
    }
    setFormType('edit');
    setOpenDigitalProductModal(!openDigitalProductModal);
  };

  // Input handle
  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const search = formData.get('search') as string;

    if (timeout) {
      clearTimeout(timeout);
    }

    setSearch(search);
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setSearch(e.target.value);
    }, 500);
  };

  return (
    <Fragment>
      {/* StripeAccountMissingModal for Stripe validations. */}
      <StripeAccountMissingModal
        description={stripeAccountMissingModalDescription}
        handleModal={handleStripeAccountMissingModal}
        open={openStripeAccountMissingModal}
        title={stripeAccountMissingModalTitle}
      />
      {/* DigitalProductModal (Create, Edit, Preview). */}
      <DigitalProductModal
        creatorAccount={user}
        digitalProductId={previewId}
        formType={formType}
        handleModal={handleDigitalProductModal}
        initialFormData={formData}
        loading={loading}
        open={openDigitalProductModal}
        refreshDigitalProducts={refreshDigitalProducts}
        setLoading={setLoading}
        updateUserRedux={updateUserRedux}
      />
      {/* Create Button & Select SubPage. */}
      <div className={'flex min-h-full flex-col'}>
        <HeaderScreen
          title="Productos"
          RightComponent={
            <BasicButton
              leftIcon={<PlusIcon className="h-5 w-5 text-white" />}
              title={'Añadir Producto'}
              onClick={() => onClickCreateProduct()}
              className="px-[13.5px]"
            />
          }
        />

        <div className="mb-4">
          <form className="flex-grow" onSubmit={handleOnSubmit}>
            <InputWithLeadingIcon
              Icon={<HiSearch className="mr-1 h-5 w-5 text-gray-400" />}
              placeholder="Buscar producto"
              name="search"
              onChange={handleOnChangeInput}
            />
          </form>
        </div>
        {/* MyProductsScreen Render. */}
        <MyProductsScreen
          creatorAccount={user}
          digitalProductsAggregate={digitalProductsAggregate}
          digitalProductsError={digitalProductsError}
          loading={loading}
          onClickPreviewDigitalProduct={onClickEditDigitalProduct}
          refreshDigitalProducts={refreshDigitalProducts}
          setLoading={setLoading}
          updateUserRedux={updateUserRedux}
        />
      </div>
    </Fragment>
  );
};

export default ProductsScreen;

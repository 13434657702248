import { useEffect, useState } from 'react';

interface AudioState {
  audio: string | null;
  error: DOMException | Error | null;
  ready: boolean;
}

interface UseAudioRecorderProps {}

interface useAudioRecorderReturn extends AudioState {
  pause: () => void;
  play: () => void;
  restart: () => void;
  resume: () => void;
  stop: () => void;
}

function useAudioRecorder(
  props: UseAudioRecorderProps
): useAudioRecorderReturn {
  const {} = props;

  const initialState: AudioState = {
    audio: null,
    error: null,
    ready: false
  };

  const [mediaStream, setMediaStream] = useState<MediaStream>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>(null);
  const [audioState, setAudioState] = useState<AudioState>(initialState);
  const [chunks, setChunks] = useState<Array<Blob>>([]);
  const [restarted, setRestarted] = useState<boolean>(false);

  useEffect(() => {
    createMediaStream();
  }, []);

  useEffect(() => {
    /** Required to listen via side effect for mediaStream because could be
     * reinstanciated on some render change, therefore, the mediaRecorder
     * instance requires the new mediaStream instance.
     */
    if (mediaStream != null) createMediaRecorder();
  }, [mediaStream]);

  useEffect(() => {
    if (mediaRecorder != null) {
      configureMediaRecorder();

      return () => {
        mediaRecorder.stream.getAudioTracks().forEach((track) => track.stop());
      };
    }
  }, [mediaRecorder]);

  useEffect(() => {
    if (chunks.length > 0) {
      const blob = new Blob(chunks, { type: 'audio/mpeg' });
      const audio = window.URL.createObjectURL(blob);

      setAudioState({
        ...audioState,
        audio
      });

      mediaRecorder.stream.getAudioTracks().forEach((track) => track.stop());
    }
  }, [chunks]);

  /** Starts the audioPlayer instance.
   */
  const createMediaStream = async () => {
    const media = await navigator.mediaDevices.getUserMedia({
      audio: true
    });

    setMediaStream(media);
  };

  const createMediaRecorder = () => {
    const recorder = new MediaRecorder(mediaStream, {});

    setMediaRecorder(recorder);

    setAudioState({
      ...audioState,
      ready: true
    });
  };

  const configureMediaRecorder = () => {
    try {
      let data: Array<Blob> = [];

      if (mediaRecorder.state === 'inactive') {
        mediaRecorder.start();

        mediaRecorder.onerror = (e: any) =>
          setAudioState({ ...audioState, error: e.error });

        mediaRecorder.ondataavailable = (e: any) => data.push(e.data);

        mediaRecorder.onstop = () => {
          if (restarted === false) setChunks(data);
          data = [];
        };
      }
    } catch (error) {
      new Error(error);
    }
  };

  const pause = () => {
    if (mediaRecorder.state === 'recording') mediaRecorder.pause();
  };

  const play = () => {
    if (mediaRecorder.state === 'inactive') mediaRecorder.start();
  };

  const restart = () => {
    if (mediaRecorder.state !== 'inactive') {
      setRestarted(true);

      mediaRecorder.stop();
      setChunks([]);

      setAudioState({
        ...audioState,
        audio: undefined
      });

      setRestarted(false);
    }
  };

  const resume = () => {
    if (mediaRecorder.state === 'paused') mediaRecorder.resume();
  };

  const stop = () => {
    if (mediaRecorder.state !== 'inactive') mediaRecorder.stop();
  };

  return { ...audioState, pause, play, restart, resume, stop };
}

export default useAudioRecorder;

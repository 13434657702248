import { useUser } from 'hooks/useUser';
import React, { Fragment, useState } from 'react';
import { toast } from 'react-hot-toast';
import AxiosStepRegisterProcess from 'requests/local_api/AxiosStepRegisterProcess';
import { sleep } from 'utils/functionHelpers';
import { Mixpanel } from 'utils/mixpanel';
import LinktreeImportSteps from './LinktreeImportSteps';

// Libraries
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Helpers
import { getValueAfterLastSlash } from 'helpers/formatData';
import { linktree_RegExp } from 'helpers/customRegExp';
import { isValidUrl } from 'helpers/logicFunctions';

// Components
import SimpleSteps from 'components/tailwind/steps/SimpleSteps';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import Input from 'components/tailwind/inputs/Input';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';

const steps: any = [
  { name: 'Crear tu cuenta', status: 'complete' },
  { name: 'Completa tu perfil', status: 'complete' },
  { name: 'Importa Linktree', status: 'current' },
  { name: 'Tags', status: 'upcoming' }
];

const LinkTree = () => {
  const { user, updateUserRedux, userEditSettingsFields } = useUser();
  const [fetching, setFetching] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const formik: any = useFormik({
    initialValues: {
      linktreeLink: ''
    },
    validationSchema: Yup.object({
      linktreeLink: Yup.string()
        .test('is-url', 'URL inválida', (value) => {
          return isValidUrl(value);
        })
        .test(
          'is-linktree',
          'Inserta un enlace de Linktree válido',
          (value) => {
            return linktree_RegExp.test(value);
          }
        )
        .max(4000, 'La URL debe tener como máximo 4000 carácteres')
    }),
    onSubmit: async (values) => {
      setFetching(true);

      const username = getValueAfterLastSlash(values.linktreeLink).split(
        '?'
      )[0];

      const { error, data } = await AxiosStepRegisterProcess({
        step: 2,
        subStep: 1,
        username
      });

      if (error) {
        toast.error(error);
        setFetching(false);
        return;
      }

      setSuccess(true);

      await sleep(1000);

      userEditSettingsFields({
        social_medias: data.social_medias,
        biography: data.biography,
        profilePicture: {
          url: data.profilePicture,
          blob: null
        },
        cover_picture: {
          url: data.cover_picture,
          blob: null
        }
      });

      updateUserRedux({
        registerProcess: 2,
        subStep: 2,
        blocks: data.blocks,
        social_medias: data.social_medias,
        profilePicture: data.profilePicture,
        cover_picture: data.cover_picture,
        biography: data.biography
      });

      Mixpanel.track('Onboarding - Linktree - Import', {
        importedBy: user.username,
        linktreeUsername: username
      });
    }
  });

  const onSkip = () => {
    updateUserRedux({
      subStep: 2
    });

    Mixpanel.track('Onboarding - Linktree - Skip');
  };

  return (
    <Fragment>
      <SimpleSteps steps={steps} />
      <form onSubmit={formik.handleSubmit} className="mt-4">
        <div className="mb-6">
          <h1 className="mb-2 text-2xl font-semibold text-gray-800">
            Importa tu enlace de Linktree
          </h1>
          <p className="text-gray-600">
            Escribe o pega tu enlace de Linktree para importar tu perfil
          </p>
        </div>

        <LabelRequired
          className="mb-1"
          title="Tu enlace de Linktree"
          isRequired={true}
        />

        <Input
          type="text"
          placeholder="www.linktr.ee/ejemplo"
          name="linktreeLink"
          id="linktreeLink"
          value={formik.values.linktreeLink}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            (formik.errors.linktreeLink &&
              formik.touched.linktreeLink) as boolean
          }
        />
        {formik.errors.linktreeLink && formik.touched.linktreeLink && (
          <ErrorMessage errorText={formik.errors.linktreeLink} />
        )}

        {fetching && <LinktreeImportSteps className="mt-2" success={success} />}

        <BasicButton
          type="submit"
          title="Continuar"
          disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
          className="mt-6 w-full"
        />

        <WhiteButton
          className="mt-6 w-full"
          onClick={onSkip}
          disabled={formik.isSubmitting}
          title="Saltar"
        />
      </form>
    </Fragment>
  );
};

export default LinkTree;

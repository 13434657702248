import classNames from 'classnames';

type TextAreaProps = {
  id?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  value?: string;
  className?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  rows?: number;
  maxLength?: number;
};

const TextArea = ({
  id,
  name,
  onChange,
  onBlur,
  value,
  className,
  placeholder,
  error = false,
  disabled = false,
  rows = 4,
  maxLength
}: TextAreaProps) => {
  return (
    <textarea
      rows={rows}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={classNames(
        className,
        error && 'border-red-500',
        'block w-full rounded-md border-gray-300 text-gray-800 shadow-sm  transition duration-200 ease-in-out focus:border-primary focus:ring-primary active:text-gray-600 disabled:cursor-not-allowed disabled:opacity-50'
      )}
      disabled={disabled}
      placeholder={placeholder}
      maxLength={maxLength}
    />
  );
};

export default TextArea;

type BlockSkeleton = {
  className?: string;
  quantity?: number;
};

const BlockSkeleton: React.FC<BlockSkeleton> = ({
  className,
  quantity = 1
}) => {
  return (
    <section className={className}>
      {[...Array(quantity)].map((e, i) => {
        return (
          <div
            key={i}
            className="flex animate-pulse items-center justify-between rounded-md border border-gray-200 py-4 px-6"
          >
            <div className="mr-4 flex max-h-[2.5rem] min-h-[2.5rem] min-w-[2.5rem] max-w-[2.5rem] items-center justify-center rounded-full bg-slate-200"></div>

            <div className="flex-grow">
              <h4 className="mb-2 h-4 w-20 rounded-lg bg-slate-200 font-semibold md:h-5 md:rounded-xl"></h4>
              <h5 className="h-4 w-full rounded-lg bg-slate-200"></h5>
            </div>

            <div className="w-2/12"></div>

            <div className="h-4 w-10 rounded-lg bg-slate-200 md:h-6 md:w-14 md:rounded-xl"></div>
          </div>
        );
      })}
    </section>
  );
};

export default BlockSkeleton;

import { getCookie } from 'helpers/handleCookies';
import { AxiosApi } from '../../AxiosBase';
import { UpsertDigitalProduct, DigitalProduct } from 'types/digitalProduct';

const AxiosCreateDigitalProduct = async (
  digitalProduct: UpsertDigitalProduct
): Promise<DigitalProduct | Error> => {
  try {
    const userToken: string | boolean = getCookie('token');

    if (!userToken) return new Error('No estas logeado');

    const result = await AxiosApi.post<DigitalProduct>(
      `/digital-products`,
      digitalProduct,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    return result.data;
  } catch (error) {
    console.error('AxiosCreateDigitalProduct Error:', error);

    if (error instanceof Error) return error;

    return new Error(`Error al ejecutar AxiosCreateDigitalProduct: ${error}`);
  }
};

export default AxiosCreateDigitalProduct;

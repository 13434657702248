interface IBasicCheckboxProps {
  checked: boolean;
  label: string;
  description: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const BasicCheckbox: React.FC<IBasicCheckboxProps> = ({
  checked,
  label,
  description,
  value,
  onChange
}) => {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          id="offers"
          aria-describedby="offers-description"
          name="offers"
          type="checkbox"
          value={value}
          onChange={onChange}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          checked={checked}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor="offers" className="font-medium text-gray-700">
          {label}
        </label>
        <p id="offers-description" className="text-gray-500">
          {description}
        </p>
      </div>
    </div>
  );
};

export default BasicCheckbox;

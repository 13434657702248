import { useState, useEffect } from "react"
import { io, Socket } from "socket.io-client"

export const useSocket = function() {
  const [socket, setSocket] = useState<Socket>(undefined)

  useEffect(() => {
    const connect = io(process.env.NEXT_PUBLIC_WEBSOCKET_SERVER || "http://localhost:8000")

    setSocket(connect)

    return () => {
      connect.disconnect()
      setSocket(undefined)
    }
  }, [])

  return socket
}
const FetchWebsiteTitle = async (url: string) => {
  try {
    const fetching = await fetch(
      "/api/website-title",
      {
        method: "POST",
        body: JSON.stringify({ url }),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )

    const data = await fetching.json()

    if (data.message !== "OK") return { error: data.message }

    return {
      data: data.data as string
    }
  }

  catch(e) {
    console.error(e)
    console.error("FetchWebsiteTitle() Error")
    return { error: "Error al conectarse con el servidor" }
  }
}

export default FetchWebsiteTitle
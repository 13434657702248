import { useEffect } from 'react';
import { XIcon, ArrowNarrowLeftIcon } from '@heroicons/react/solid';
import { Dialog } from '@headlessui/react';

import { useGlobalModalState } from 'hooks/useGlobalModalState';
import ModalHandler from 'components/tailwind/overlays/ModalHandler';

interface FormModalProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  title?: string;
  type?: 'modal' | 'checkout';
  preventClose?: boolean;
  onClickCloseButton?: () => void;
  disableCloseButton?: boolean;
}

const FormModal: React.FC<FormModalProps> = ({
  type = 'modal',
  open = false,
  setOpen,
  title,
  children,
  preventClose = false,
  onClickCloseButton,
  disableCloseButton = false
}) => {
  const { setModalOpen } = useGlobalModalState();

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  return (
    <ModalHandler open={open} setOpen={setOpen} preventClose={preventClose}>
      <div
        className={`fixed bottom-0 flex w-full flex-1 transform flex-col overflow-hidden rounded-t-lg bg-white text-left align-bottom shadow-xl transition-all sm:relative sm:my-8 sm:mx-4 sm:w-full ${
          type === 'checkout' ? 'sm:max-w-[400px]' : 'sm:max-w-[500px]'
        } sm:rounded-lg sm:align-middle`}
      >
        {type === 'checkout' ? (
          <button
            onClick={() =>
              onClickCloseButton ? onClickCloseButton() : setOpen(false)
            }
            className={
              'absolute left-0 top-0 w-fit items-center gap-3 p-4 text-gray-400 transition-all first-letter:flex hover:text-gray-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:text-gray-400'
            }
            disabled={disableCloseButton}
          >
            <ArrowNarrowLeftIcon className="h-[22px] w-[22px]" />
          </button>
        ) : (
          <button
            type="button"
            className="absolute right-0 top-0 block rounded-full pr-5 pt-6 text-gray-400 hover:text-gray-500 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:text-gray-500"
            onClick={() =>
              onClickCloseButton ? onClickCloseButton() : setOpen(false)
            }
            disabled={disableCloseButton}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}

        {type !== 'checkout' && (
          <div className="flex min-h-[72px] flex-col items-start border-b border-gray-200 p-6 md:flex-row">
            <Dialog.Title
              as="h3"
              className="text-base font-medium leading-6  text-gray-900 sm:mt-0 sm:text-lg"
            >
              {title}
            </Dialog.Title>
          </div>
        )}

        {children}
      </div>
    </ModalHandler>
  );
};

export default FormModal;

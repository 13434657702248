import BuildingProfile from 'components/home/BuildingProfile';
import Button from 'components/tailwind/buttons/BasicButton';
import Steps from 'components/tailwind/steps/Steps';
import { useUser } from 'hooks/useUser';
import { useEffect, useState, Fragment } from 'react';
import toast from 'react-hot-toast';
import AxiosStepRegisterProcess from 'requests/local_api/AxiosStepRegisterProcess';
import { getAllTags } from 'requests/local_next/tags';
import classNames from 'utils/class-names';
import { useRouter } from 'next/router';
import getUsernameReference from 'helpers/getUsernameReference';
import { Mixpanel } from 'utils/mixpanel';

// Components
import SimpleSteps from 'components/tailwind/steps/SimpleSteps';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';

const steps: any = [
  { name: 'Crear tu cuenta', status: 'complete' },
  { name: 'Completa tu perfil', status: 'complete' },
  { name: 'Importa Linktree', status: 'complete' },
  { name: 'Tags', status: 'current' }
];

interface ICreatorTagsProps {
  setBuildingProfile: (value: boolean) => void;
}

const CreatorTags: React.FC<ICreatorTagsProps> = ({ setBuildingProfile }) => {
  const router = useRouter();
  const { user, updateUserRedux, userEditSettingsFields } = useUser();
  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);

  useEffect(() => {
    const asyncGetTags = async () => {
      const newTags = await getAllTags();
      setTags(newTags);
    };
    asyncGetTags();
  }, []);

  const handleRedirectProfile = () => {
    const usernameRef = getUsernameReference();

    if (!usernameRef) return;

    router.push(`/${usernameRef}`);
  };

  const onSkip = async () => {
    setBuildingProfile(true);

    await AxiosStepRegisterProcess({
      step: -1
    });
    updateUserRedux({
      registerProcess: 3
    });

    Mixpanel.track('Onboarding - Tags - Skip', {
      username: user.username
    });

    handleRedirectProfile();
  };

  const onClickTag = (tagId, tagName) => {
    if (selected.includes(tagId)) {
      const newSelected = selected.filter((tag) => tag != tagId);
      const newSelectedNames = selectedNames.filter((tag) => tag != tagName);
      setSelected(newSelected);
      setSelectedNames(newSelectedNames);
    } else if (selected.length === 3) {
      toast.error('Puedes elegir máximo 3 tags');
    } else {
      const newSelected = [...selected, tagId];
      const newSelectedNames = [...selectedNames, tagName];
      setSelected(newSelected);
      setSelectedNames(newSelectedNames);
    }
  };

  const handleOnSubmit = async () => {
    setBuildingProfile(true);

    const { error, data } = await AxiosStepRegisterProcess({
      step: 2,
      subStep: 2,
      tags: selected
    });

    if (error) {
      toast.error(error);
      setBuildingProfile(false);
      return;
    }

    userEditSettingsFields({
      tags: data.tags
    });

    updateUserRedux({
      tags: data.tags,
      registerProcess: 3
    });

    Mixpanel.track('Onboarding - Tags - Submit', {
      username: user.username,
      tags: selectedNames
    });

    handleRedirectProfile();
  };

  if (tags.length < 1) {
    return null;
  }

  return (
    <Fragment>
      <SimpleSteps steps={steps} />

      <div className="mb-6 mt-4">
        <h1 className="mb-2 text-2xl font-semibold text-gray-800">
          ¿Cómo te defines?
        </h1>
        <p className="text-gray-600">
          Sabemos que todos los creadores son diferentes, queremos saber como te
          defines tú.
        </p>
      </div>
      <ul className="grid grid-cols-2 gap-2">
        {tags.length > 1 &&
          tags.map((tag) => {
            return (
              <button
                className={classNames(
                  selected.includes(tag.id)
                    ? 'bg-primary-100 font-semibold text-primary'
                    : 'hover:bg-gray-50',
                  `cursor-pointer rounded-md border py-1.5 text-center`
                )}
                key={tag.id}
                onClick={() => onClickTag(tag.id, tag.name)}
              >
                {tag.name}
              </button>
            );
          })}
      </ul>

      <div className="mt-6">
        <Button
          title="Continuar"
          type="button"
          cn={classNames(
            selected.length < 1 ? 'opacity-50' : 'hover:bg-indigo-700',
            'flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          )}
          disabled={selected.length < 1}
          onClick={handleOnSubmit}
        />
      </div>

      <WhiteButton className="mt-6 w-full" onClick={onSkip} title="Saltar" />
    </Fragment>
  );
};

export default CreatorTags;

import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { useUser } from 'hooks/useUser';
import { ProfileViewerLayout } from './profileViewerLayout';

// Components
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';

export interface LateralViewerProps {
  changeViewer: () => void;
  isAdmittedScreen: boolean;
  viewerOpen: boolean;
}

export const LateralViewer = ({
  changeViewer,
  isAdmittedScreen,
  viewerOpen
}: LateralViewerProps) => {
  const { user: account } = useUser();

  return (
    <>
      {(account.referred || account.is_verified) && isAdmittedScreen && (
        <div className="absolute bottom-20 z-20 flex w-full justify-center lg:hidden cursor-pointer">
          <WhiteButton
            title={viewerOpen ? 'Regresar' : 'Previsualizar'}
            leftIcon={
              viewerOpen ? (
                <EyeOffIcon className="w-6 fill-gray-700" />
              ) : (
                <EyeIcon className="w-6 fill-gray-700" />
              )
            }
            className="border border-gray-200 bg-gray-100 text-gray-700 shadow-md"
            onClick={changeViewer}
          />
        </div>
      )}
      {(account.referred || account.is_verified) && isAdmittedScreen && (
        <div className="relative hidden h-full lg:inline-block">
          <div className="h-full w-1 bg-gray-300"></div>
          <div
            className="absolute right-0 top-0 bottom-0 z-10 m-auto flex h-[100px] w-[40px] rounded-l-lg border border-gray-200 bg-gray-100 align-middle shadow"
            onClick={changeViewer}
          >
            <div className="flex w-full items-center justify-center cursor-pointer">
              {viewerOpen ? (
                <EyeOffIcon className="w-[20px] text-gray-700" />
              ) : (
                <EyeIcon className="w-[20px] text-gray-700" />
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={classNames(
          'absolute h-max transition-all duration-1000 ease-in-out lg:inline-block lg:w-[414px]',
          viewerOpen ? `right-full lg:right-0` : 'right-0 lg:right-[-414px]'
        )}
      >
        {<ProfileViewerLayout account={account} />}
      </div>
    </>
  );
};

import {
  CashIcon,
  CurrencyDollarIcon,
  CursorClickIcon,
  EyeIcon,
  HandIcon,
  MusicNoteIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  TagIcon,
  UsersIcon
} from '@heroicons/react/outline';
import AnalyticCard from 'components/dashboard/analytics/AnalityticsCard';
import {
  AnalyticsHeaderRight,
  IOnValues
} from 'components/dashboard/analytics/AnalyticsHeaderRight';
import HeaderScreen from 'components/dashboard/HeaderScreen';
import TableSkeleton from 'components/tailwind/loaders/TableSkeleton';
import { classNames } from 'helpers/classNames';
import useApp from 'hooks/useApp';

// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

const ctrPercentage = (visits: number, value: number) => {
  const result = Math.round((value / visits) * 100)

  if (result === Infinity) return 0

  return result
}

const AnalyticsScreen = () => {
  const { app, editAnalyticsRedux } = useApp();
  const { width } = useWindowDimensions();

  console.log(app.analyticsScreen.data)

  const handleOnValues = (values: IOnValues) => {
    editAnalyticsRedux({ isLoading: false, data: values });
  };

  return (
    <div>
      <HeaderScreen
        title="Analíticas"
        RightComponent={<AnalyticsHeaderRight onValues={handleOnValues} />}
      />

      <div className="mb-8">
        <h3 className="mb-5 text-lg font-medium text-gray-600">
          {app.analyticsScreen.data.optionSelected.textContent}
        </h3>
        <section className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
          <AnalyticCard
            title="Ingresos"
            Icon={CashIcon}
            value={parseFloat(
              (
                app.analyticsScreen.data.donations +
                app.analyticsScreen.data.greetings +
                app.analyticsScreen.data.questions +
                app.analyticsScreen.data.subscriptions.balance +
                app.analyticsScreen.data.audios +
                app.analyticsScreen.data.digital_products
              ).toFixed(2)
            )}
            isMoney={true}
            loading={app.analyticsScreen.isLoading}
          />
          <AnalyticCard
            title="Suscriptores"
            Icon={UsersIcon}
            value={app.analyticsScreen.data.subscriptions.quantity}
            growth={app.analyticsScreen.data.subscriptions.growth}
            period={app.analyticsScreen.data.optionSelected.value as any}
            loading={app.analyticsScreen.isLoading}
          />
          <AnalyticCard
            title="Visitas a tu página"
            Icon={EyeIcon}
            value={app.analyticsScreen.data.visits}
            loading={app.analyticsScreen.isLoading}
          />
          <AnalyticCard
            title="Clicks"
            Icon={CursorClickIcon}
            value={app.analyticsScreen.data.clicks.reduce(
              (prev, curr) => prev + curr.value,
              0
            )}
            loading={app.analyticsScreen.isLoading}
          />
        </section>
      </div>

      <div>
        <h3 className="text-lg font-medium text-gray-600">
          Desglose de monetización
        </h3>
        <section className="mt-5 mb-8 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
          <AnalyticCard
            title="Suscripciones"
            Icon={TagIcon}
            value={app.analyticsScreen.data.subscriptions.balance}
            isMoney={true}
            loading={app.analyticsScreen.isLoading}
          />
          <AnalyticCard
            title="Donaciones"
            Icon={CurrencyDollarIcon}
            value={app.analyticsScreen.data.donations}
            isMoney={true}
            loading={app.analyticsScreen.isLoading}
          />
          <AnalyticCard
            title="Saludos"
            Icon={HandIcon}
            value={app.analyticsScreen.data.greetings}
            isMoney={true}
            loading={app.analyticsScreen.isLoading}
          />
          <AnalyticCard
            title="Preguntas"
            Icon={QuestionMarkCircleIcon}
            value={app.analyticsScreen.data.questions}
            isMoney={true}
            loading={app.analyticsScreen.isLoading}
          />
          <AnalyticCard
            title="Audios"
            Icon={MusicNoteIcon}
            value={app.analyticsScreen.data.audios}
            isMoney={true}
            loading={app.analyticsScreen.isLoading}
          />
          <AnalyticCard
            title="Productos Digitales"
            Icon={ShoppingBagIcon}
            value={app.analyticsScreen.data.digital_products}
            isMoney={true}
            loading={app.analyticsScreen.isLoading}
          />
        </section>
      </div>

      <div>
        <h3 className="mb-5 text-lg font-medium text-gray-600">
          Clicks de enlaces
        </h3>

        <div className="flex flex-col">
          <div>
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr className="grid grid-cols-3 md:grid-cols-4">
                      <th
                        scope="col"
                        className="py-3 pl-4 pr-3 text-left text-base font-normal uppercase tracking-wide text-gray-500 sm:pl-6"
                      >
                        Título
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3 text-left text-base font-normal uppercase tracking-wide text-gray-500 md:block"
                      >
                        URL
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-base font-normal uppercase tracking-wide text-gray-500"
                      >
                        CLICKS
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-base font-normal uppercase tracking-wide text-gray-500"
                      >
                        CTR
                      </th>
                    </tr>
                  </thead>
                  {app.analyticsScreen.isLoading ? (
                    <TableSkeleton
                      numberOfRows={4}
                      numberOfColumns={width < 768 ? 3 : 4}
                      rowClassName="grid grid-cols-3 md:grid-cols-4"
                    />
                  ) : (
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {app.analyticsScreen.data.clicks
                        ?.sort((block) => (block.deleted ? 1 : -1))
                        .map((block, id) => (
                          <tr
                            key={block.title}
                            className={classNames(
                              block.deleted && 'opacity-50',
                              id % 2 !== 0 && 'bg-gray-50',
                              'grid grid-cols-3 md:grid-cols-4'
                            )}
                          >
                            <td className="block w-full truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {block.title}
                            </td>
                            <td className="hidden w-full truncate whitespace-nowrap px-3 py-4 text-sm text-gray-500 md:block">
                              {block.url}
                            </td>
                            <td className="w-full whitespace-nowrap px-3 py-4 text-center text-sm font-medium text-primary">
                              {block.value}
                            </td>
                            <td className="w-full whitespace-nowrap px-3 py-4 text-center text-sm font-medium text-primary">
                              { ctrPercentage(app.analyticsScreen.data.visits, block.value) }{' '}
                              %
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsScreen;

const FetchLinkMetaData = async (url: string) => {
  try {
    const data = await fetch("/api/link-metadata", {
      method: "POST",
      body: JSON.stringify({ url }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())

    if (data.message !== "OK") return { error: data.message }

    return {
      data: data.data
    }
  }

  catch(e) {
    console.error(e)
    console.error("FetchLinkMetaData() Error")
    return { error: "Error al conectarse con el servidor" }
  }
}

export default FetchLinkMetaData
import { AxiosApi } from '../AxiosBase';

// Helpers
import { getCookie } from 'helpers/handleCookies';

const AxiosAuthVerifyReferralCode = async (code: string) => {
  const userToken = getCookie('token');

  if (!userToken) return { error: 'No estas logueado' };

  try {
    const { data } = await AxiosApi.get(
      `/auth/step/verify-referral?referral=${code}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    if (data.message !== 'OK') return { error: data.message };

    return {
      data: data.data === undefined ? 'OK' : data.data
    };
  } catch (e) {
    console.log(e);
    console.log('AxiosAuthVerifyReferralCode() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

export default AxiosAuthVerifyReferralCode;

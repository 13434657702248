import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import Spinner from 'components/dashboard/products/buy/Spinner';
import EmptyIcon from 'components/tailwind/icons/EmptyIcon';
import SpinnerLoader from 'components/tailwind/loaders/SpinnerLoader';
import { defaultToastOptions } from 'helpers/toastOptions';
import toast from 'react-hot-toast';
import { Account, Block } from 'types/accounts';
import { DigitalProduct } from 'types/digitalProduct';
import { IUserEdit } from 'types/User';
import { supabase } from 'utils/supabase-client';
import { handleDeleteBlockClick } from 'requests/local_next/analytics';

// Components
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';

enum EditDigitalProductBlockFormError {
  loadDigitalProductsFailure = 'Ha habido un problema obteniendo tus productos digitales. Por favor contacta con soporte.'
}

interface EditDigitalProductBlockFormProps {
  blocks: Block[];
  closeModal: () => void;
  handleClose: () => void;
  selectedBlock: Block;
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  user: Account;
  updateUserRedux: (payload: IUserEdit) => {
    type: string;
    payload: IUserEdit;
  };
}

const EditDigitalProductBlockForm = (
  props: EditDigitalProductBlockFormProps
) => {
  const {
    blocks,
    closeModal,
    handleClose,
    selectedBlock,
    setBlocks,
    updateUserRedux,
    user
  } = props;
  const router = useRouter();

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [digitalProducts, setDigitalProducts] = useState<Array<DigitalProduct>>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selected, setSelected] = useState<DigitalProduct>(null);

  useEffect(() => {
    loadDigitalProductList();
  }, []);

  useEffect(() => {
    if (digitalProducts.length > 0) {
      const selected = digitalProducts.find(
        (dp) => dp.id == selectedBlock.digitalProductId
      );

      setSelected(selected);
    }
  }, [digitalProducts]);

  const loadDigitalProductList = async () => {
    let { data, error } = await supabase
      .from<DigitalProduct>('digital_products')
      .select('*')
      .filter('creator_account_id', 'eq', user.id)
      .filter('is_deleted', 'eq', false)
      .order('creation_date', {
        ascending: true
      });

    if (error) {
      setErrorMessage(
        EditDigitalProductBlockFormError.loadDigitalProductsFailure
      );
      return setError(true);
    } else {
      // Remove all products that already exists as blocks!
      setDigitalProducts(
        data.filter((value) => {
          if (!value.id) return false;

          return blocks.some((block) => {
            if (
              block.type == 'digitalProduct' &&
              block.digitalProductId == selectedBlock.digitalProductId
            )
              return true;

            if (
              block.type == 'digitalProduct' &&
              block.digitalProductId == value.id
            )
              return true;

            return false;
          });
        })
      );
    }

    setLoading(false);
  };

  const onClickEditBlock = async () => {
    setSubmitting(true);
    setLoading(true);

    let productLink = `${process.env.NEXT_PUBLIC_URL}/`;
    productLink += `${user.username}/`;
    productLink += selected.product_uuid;

    const updatedBlock: Block = {
      ...selectedBlock,
      currency: selected.currency,
      digitalProductId: selected.id,
      image: selected.placeholder,
      price: selected.price,
      title: selected.name,
      url: productLink,
      visible: true
    };

    var blocks = user.blocks;
    const updatedBlockIndex = blocks.findIndex((b) => b.id == selectedBlock.id);

    if (updatedBlockIndex >= 0) {
      blocks[updatedBlockIndex] = updatedBlock;
    } else return setLoading(false);

    const { data, error } = await supabase
      .from('accounts')
      .update({
        blocks
      })
      .eq('id', user.id);

    if (error) {
      toast.error(error.message, defaultToastOptions);
      setLoading(false);
    }

    updateUserRedux(data[0]);
    setBlocks(data[0].blocks);
    toast.success('Bloque Editado correctamente', defaultToastOptions);
    handleClose();
  };

  const onClickDelete = async () => {
    const confirmed = confirm(
      '¿Estás seguro de que quieres eliminar este bloque?'
    );

    if (confirmed) {
      setLoading(true);

      const blocks = user.blocks.reduce((acc, block) => {
        if (block.id !== selectedBlock.id) {
          acc.push(block);
        }
        return acc;
      }, []);

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
      }

      if (data) {
        handleDeleteBlockClick({
          block_id: selectedBlock.id,
          last_title: selectedBlock.title,
          last_url: selectedBlock.url || ''
        });
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Eliminado', defaultToastOptions);
      }

      setLoading(false);
      closeModal();
    }
  };

  const onClickDigitalProduct = (digitalProduct: DigitalProduct) => {
    if (selected == null) return setSelected(digitalProduct);

    if (digitalProduct.id == selected.id) return setSelected(null);
    else return setSelected(digitalProduct);
  };

  const onClickGoToMyProductsPage = () => {
    // TODO this navigation should be improved, reloading isn't the way.
    localStorage.setItem('mf_dashboard', 'products');
    router.reload();
  };

  const onClickCancel = () => {
    closeModal();
  };

  const loadingDigitalProducts = (
    <div className={'flex flex-col items-center justify-items-center py-24'}>
      <div className={'mb-2'}>
        <SpinnerLoader color={'#542ae2'} size={12} />
      </div>
      <p>Cargando los productos digitales</p>
    </div>
  );

  const errorBlockForm = (
    <div className={'my-24 mx-auto flex w-1/3 flex-col items-center'}>
      <ExclamationCircleIcon
        className={'mb-2 h-12 w-12 items-center text-gray-400'}
      />
      <h3 className={'mb-1 text-center text-xl'}>Oops! Algo ha pasado</h3>
      <p
        className={
          'dark:text-gray-400 text-center text-base font-semibold text-gray-900'
        }
      >
        {errorMessage}
      </p>
    </div>
  );

  const emptyDigitalProductList = (
    <div className={'my-24 mx-auto flex w-3/4 flex-col items-center'}>
      <EmptyIcon className={'mb-2 h-12 w-12 items-center text-gray-400'} />
      <h3 className={'mb-1 text-center text-xl'}>
        Oops! no tienes Productos Digitales
      </h3>
      <p
        className={
          'dark:text-gray-400 text-center text-base font-semibold text-gray-900'
        }
      >
        Parece que aún no tienes bloques de productos digitales. Crea un nuevo
        bloque de Producto Digital para poder editarlo.
      </p>
      <button
        className={
          'mx-auto mt-8 justify-center rounded-md border border-transparent bg-mf-purple py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-mf-purple-hover focus:outline-none focus:ring-2 focus:ring-mf-purple focus:ring-offset-2 sm:text-sm'
        }
        disabled={loading}
        onClick={onClickGoToMyProductsPage}
      >
        ir a mis productos
      </button>
    </div>
  );

  const digitalProductList = (
    <>
      <FormBody>
        <LabelRequired
          isRequired={true}
          title="Selecciona un producto"
          className="mb-2"
        />{' '}
        <ul
          role={'list'}
          className={classNames(
            submitting ? 'opacity-50 transition ease-in-out' : '',
            'mb-8 grid grid-cols-3 gap-x-6 gap-y-6'
          )}
        >
          {digitalProducts.map((digitalProduct, index) => (
            <li className={'relative'} key={index}>
              <div
                className={classNames(
                  selected != null && selected.id == digitalProduct.id
                    ? 'ring-2 ring-primary ring-offset-2 ring-offset-gray-100 transition ease-in-out'
                    : 'transition ease-in-out',
                  'group aspect-w-8 aspect-h-8 block w-full overflow-hidden rounded-lg bg-gray-100'
                )}
              >
                <img
                  src={digitalProduct.placeholder}
                  alt={''}
                  className={classNames(
                    submitting ? '' : 'group-hover:opacity-80',
                    'delay-30 pointer-events-none object-cover transition ease-in-out'
                  )}
                />
                <button
                  className={classNames(
                    submitting ? '' : 'focus:outline-none',
                    'absolute inset-0'
                  )}
                  disabled={loading || submitting}
                  onClick={() => onClickDigitalProduct(digitalProduct)}
                  type={'button'}
                >
                  <span className={'sr-only'}>{digitalProduct.name}</span>
                </button>
              </div>
              <p
                className={
                  'pointer-events-none mt-2 block truncate text-center text-sm font-medium text-gray-700'
                }
              >
                {digitalProduct.name}
              </p>
            </li>
          ))}
        </ul>
      </FormBody>

      <FormFooter>
        <SecondaryButton
          title="Eliminar"
          onClick={onClickDelete}
          variant={variantType.red}
          disabled={loading || submitting}
          className="px-6"
        />
        <BasicButton
          title="Guardar cambios"
          disabled={loading || selected == null}
          loading={submitting}
          onClick={onClickEditBlock}
          className="w-40 whitespace-nowrap"
          loadingChildren={'Guardando'}
        />
      </FormFooter>
    </>
  );

  if (loading) return loadingDigitalProducts;

  if (error) return errorBlockForm;

  if (loading == false && digitalProducts.length == 0)
    return emptyDigitalProductList;

  return digitalProductList;
};

export default EditDigitalProductBlockForm;

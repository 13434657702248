// Types
import { ActionsList } from 'components/tailwind/optionsMenu/types';

// Libraries
import { useRouter } from 'next/router';

// Components
import OptionsMenuButton from 'components/tailwind/optionsMenu/OptionsMenuButton';
import CircleBadge from 'components/tailwind/badges/CircleBadge';
import FileMusicFa from 'components/tailwind/icons/FileMusicFa';
import StatusButton from 'components/tailwind/buttons/StatusButton';
import PurchaseSubtitle from './PurchaseSubtitle';
import PurchaseCard from './PurchaseCard';
import PurchaseTitle from './PurchaseTitle';

// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Fragment } from 'react';

// Helpers
const dayjs = require('dayjs');
dayjs.locale(require('utils/dayjsCustomPlugins/es'));
dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('dayjs/plugin/isYesterday'));
dayjs.extend(require('utils/dayjsCustomPlugins/isBeforeYesterday'));

interface IAudioCardProps {
  purchase: any;
}

const AudioCard: React.FC<IAudioCardProps> = (props) => {
  const { purchase } = props;

  const { width } = useWindowDimensions();
  const router = useRouter();

  const onClickSeePurchase = (audioPurchase: any) => {
    router.push(
      `/${audioPurchase.creator_data.username}/record/${audioPurchase.uuid}`
    );
  };

  const actions: ActionsList<any> = [
    {
      event: purchase.status === 'done' ? onClickSeePurchase : () => {},
      label:
        purchase.status === 'done'
          ? 'Escuchar audio'
          : purchase.status === 'pending'
          ? 'Pendiente'
          : 'Rechazado'
    }
  ];

  return (
    <PurchaseCard
      avatar={
        <CircleBadge className="bg-primary-50">
          <FileMusicFa className="h-[22px] w-[22px] text-primary" />
        </CircleBadge>
      }
      title={
        <PurchaseTitle
          productName={'Audio'}
          creatorName={purchase.creator_data.name}
          creatorUserName={purchase.creator_data.username}
        />
      }
      subtitle={
        <PurchaseSubtitle
          date={dayjs(purchase.creation_date).format('MMMM DD, YYYY')}
          fileType=".MP3"
          price={purchase.amount}
        />
      }
      actions={
        <Fragment>
          {purchase.status === 'done' && (
            <StatusButton
              onClick={() => onClickSeePurchase(purchase)}
              status={purchase.status}
            >
              {width > 768 ? 'Escuchar audio ' : 'Escuchar'}
            </StatusButton>
          )}
          {purchase.status === 'pending' && (
            <StatusButton status={purchase.status}>Pendiente</StatusButton>
          )}
          {purchase.status === 'rejected' && (
            <StatusButton status={purchase.status}>Rechazado</StatusButton>
          )}
        </Fragment>
      }
    />
  );
};

export default AudioCard;

import { IUser } from 'types/User';
import { Collection } from 'types/Web3';
import { DigitalProductPurchase } from 'types/digitalProductPurchase';

export type DigitalProductFormType = 'create' | 'edit';

export enum DigitalProductFindByIdType {
  id = 'id',
  uuid = 'uuid'
}

export enum CurrencyCode {
  MXN = 'MXN',
  USD = 'USD'
}

export enum DigitalProductType {
  'fileProtected',
  'externalLink'
}

export interface UpsertDigitalProduct
  extends Pick<
    DigitalProduct,
    | 'asset'
    | 'collection'
    | 'creator_account_id'
    | 'currency'
    | 'description'
    | 'external_url'
    | 'name'
    | 'placeholder'
    | 'type'
  > {
  price?: number;
}

export interface DigitalProduct {
  creation_date: Date;
  currency: string;
  product_uuid: string;
  is_deleted: boolean;
  is_enabled: boolean;
  name: string;
  price: number;
  type: keyof typeof DigitalProductType;

  asset?: string;
  collection?: Collection;
  creator_account_id?: string;
  description?: string;
  external_url?: string;
  id?: number;
  placeholder?: string;
  stripe_digital_product_id?: number;
  service?: 'stripe' | 'paypal';

  fan_data?: Pick<
    IUser,
    | 'linked_accounts'
    | 'name'
    | 'username'
    | 'is_verified'
    | 'profilePicture'
    | 'id'
  >;
}

export interface DigitalProductAggregate {
  digitalProduct: DigitalProduct;
  purchase_data: {
    count: number;
    total_amount: number;
  };
}

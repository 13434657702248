// @ts-nocheck
import React, { useEffect } from 'react';
import { twitter_RegExp } from 'helpers/customRegExp';

interface ITwitterPlayerProps {
  url: string;
}

const canPlay = (url: string): boolean => twitter_RegExp.test(url);

const TwitterPlayer = ({ url }: ITwitterPlayerProps) => {
  if (!canPlay(url)) return null;

  useEffect(() => {
    const addedScript = document.getElementById('iw_twitter-widget');

    if (addedScript) {
      document.body.removeChild(addedScript);
    }

    const script = document.createElement('script');
    script.id = 'iw_twitter-widget';
    script.src = 'https://platform.twitter.com/widgets.js';

    document.body.appendChild(script);
  }, []);

  return (
    <blockquote className="twitter-tweet" data-theme="light" data-width="550">
      <p lang="en" dir="ltr"></p>
      <a href={`${url}?ref_src=twsrc%5Etfw`}></a>
    </blockquote>
  );
};

TwitterPlayer.canPlay = (url: string) => canPlay(url);

export default TwitterPlayer;

import { useUser } from 'hooks/useUser';
import useApp from 'hooks/useApp';
import { cache } from 'swr';
import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition, Disclosure, Menu } from '@headlessui/react';
import NotificationCount from '../sidebar/NotificationCount';
import LogoutButtonMobile from './logoutButtonMobile';
import { Mixpanel } from 'utils/mixpanel';
import detectSafariMobile from 'helpers/detectSafariMobile';
import InferiorButton from './inferiorButton';
import Link from 'next/link';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import styles from './Tooltip.module.css';
import Tippy from '@tippyjs/react';
import { XIcon, MenuIcon } from '@heroicons/react/solid';
import {
  BellIcon,
  HeartIcon,
  ViewGridIcon,
  UsersIcon,
  SearchIcon,
  TrendingUpIcon,
  ShoppingBagIcon,
  VideoCameraIcon,
  TagIcon,
  ShoppingCartIcon,
  CogIcon
} from '@heroicons/react/outline';

// Libraries
import { LoginIcon, DuplicateIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';

// Helpers
import { isProfileImageHexagon } from 'helpers/logicFunctions';

// Components
import Avatar from 'components/tailwind/avatars/Avatar';
import BasicDivider from 'components/tailwind/dividers/BasicDivider';

interface IMB_SideBarProps {
  screen: string;
  setScreen: (value: string | ((prev: string) => void)) => void;
}

const navigation = [
  {
    name: 'Bloques',
    description:
      'Get a better understanding of where your traffic is coming from.',
    href: 'blocks',
    icon: ViewGridIcon,
    showOnVerified: true,
    classNames: '',
    iconClass: 'text-gray-500'
  },
  {
    name: 'Comunidad',
    value: 'super_fans',
    icon: HeartIcon,
    showOnVerified: true,
    type: '',
    iconClass: 'text-gray-500'
  },
  {
    name: 'Productos',
    href: 'products',
    description: 'Lorem ipsum', //TODO Improve this description
    icon: ShoppingBagIcon,
    showOnVerified: true,
    classNames: '',
    iconClass: 'text-gray-500'
  },
  {
    name: 'Notificaciones',
    description: "Your customers' data will be safe and secure.",
    href: 'notifications',
    icon: BellIcon,
    showOnVerified: false,
    classNames: '',
    iconClass: 'text-gray-500'
  },
  {
    name: 'Audiencia',
    href: 'audience',
    description: 'Lorem ipsum', //TODO Improve this description
    icon: UsersIcon,
    showOnVerified: true,
    classNames: '',
    iconClass: 'text-gray-500'
  },

  {
    name: 'Analíticas',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: 'analytics',
    icon: TrendingUpIcon,
    showOnVerified: true,
    classNames: '',
    iconClass: 'text-gray-500',
    divider: true
  },
  // {
  //   name: 'Alertas de stream',
  //   description: 'Customize your stream element',
  //   href: 'stream_alerts',
  //   icon: VideoCameraIcon,
  //   showOnVerified: true,
  //   classNames: '',
  //   iconClass: 'text-gray-500',
  //   divider: true
  // },
  {
    name: 'Explorar',
    description: "Connect with third-party tools that you're already using.",
    href: 'explore',
    icon: SearchIcon,
    showOnVerified: false,
    classNames: '',
    iconClass: 'text-gray-500'
  },
  ,
  {
    name: 'Mis Suscripciones',
    description: 'Lorem ipsum', //TODO Improve this description
    href: 'my_subscriptions',
    icon: TagIcon,
    showOnVerified: false,
    classNames: '',
    iconClass: 'text-gray-500'
  },
  {
    name: 'Mis Compras',
    description: 'Lorem ipsum', //TODO Improve this description
    href: 'products_history',
    icon: ShoppingCartIcon,
    showOnVerified: false,
    classNames: '',
    iconClass: 'text-gray-500'
  },

  {
    name: 'Ajustes',
    description: "Connect with third-party tools that you're already using.",
    href: 'settings',
    icon: CogIcon,
    showOnVerified: false,
    classNames: '',
    iconClass: 'text-gray-500'
  }
];
const userNavigation = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function TopMenu({ screen, setScreen }: IMB_SideBarProps) {
  const { userLogout } = useApp();
  const router = useRouter();

  const { getIsConnectAccountEnabled, user, status, logout } = useUser();
  const [isConnectAccountEnabled, setIsConnectAccountEnabled] = useState(false);

  const [theHeight, setTheHeight] = useState(3.5);
  useEffect(() => {
    const isSafariMobile = detectSafariMobile();
    if (isSafariMobile) {
      setTheHeight(8.5);
    }
  }),
    [];

  useEffect(() => {
    const fetchIsConnectAccountEnabled = async () => {
      if (status !== 1) return;
      else if (user.linked_accounts?.paypal?.merchant_id) {
        setIsConnectAccountEnabled(true);
        return;
      }

      const isValid = await getIsConnectAccountEnabled();
      setIsConnectAccountEnabled(isValid);
    };

    fetchIsConnectAccountEnabled();
  }, [status, user]);

  return (
    <Popover as="header" className="relative z-50 bg-white shadow lg:hidden ">
      {({ open, close }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 ">
            <div className="relative flex h-16 justify-between">
              <div className="relative z-10 flex px-2">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="block h-5 w-auto"
                    src="/img/icons/MF_logo.svg"
                    alt="Misfans"
                  />
                </div>
              </div>
              <div className="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0"></div>
              <div className="relative z-10 flex items-center">
                {/* Mobile menu button */}
                <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Popover.Button>
              </div>
              <div className="hidden">
                <button
                  type="button"
                  className="flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-4 flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={user.profilePicture}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              key={item.name}
                              href="#"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block py-2 px-4 text-sm text-gray-700'
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <nav className="hidden" aria-label="Global">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    screen === item.href
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'inline-flex items-center rounded-md py-2 px-3 text-sm font-medium'
                  )}
                  aria-current={screen === item.href ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </nav>
          </div>
          {/* this is the one */}
          <Popover.Panel
            as="nav"
            className="absolute z-10 w-full overflow-y-scroll bg-white"
            style={{ height: `calc(100vh - ${theHeight}rem)` }}
            aria-label="Global"
          >
            <div className="space-y-1 px-2 pt-2 pb-3 ">
              {navigation.map((item) => {
                if (item.href === 'audience' && !user.is_verified) return;
                if (item.showOnVerified && !user.is_verified) return;
                if (item.href === 'products' && !isConnectAccountEnabled) {
                  return;
                }

                return (
                  <div>
                    <button
                      key={item.name}
                      onClick={() => {
                        if (item.value === 'super_fans') {
                          router.push(`/${user.username}/hub`);
                        } else {
                          setScreen(item.href);
                        }
                        close();
                      }}
                      className={classNames(
                        screen === item.href
                          ? 'bg-primary-50 text-primary'
                          : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900',
                        'block  w-full rounded-md py-2 px-3 text-left text-base font-medium'
                      )}
                      aria-current={screen === item.href ? 'page' : undefined}
                    >
                      <div className="flex items-center">
                        <item.icon
                          className={classNames(
                            screen === item.href
                              ? 'text-primary'
                              : 'text-gray-400',
                            'mr-4 h-6 w-6'
                          )}
                        />
                        <div className="grid w-full grid-cols-2">
                          <span className="justify-self-start">
                            {item.name}
                          </span>
                          <span className="justify-self-end">
                            {item.href !== 'notifications' ? null : (
                              <NotificationCount />
                            )}
                          </span>
                        </div>
                      </div>
                    </button>
                    {item.divider && <BasicDivider className="my-[16px]" />}
                  </div>
                );
              })}
            </div>
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <Avatar
                    profilePicture={user.profilePicture}
                    shape={isProfileImageHexagon(user) ? 'hexagon' : 'circle'}
                    size="sm"
                    theme={
                      user.customProfile?.theme === 'pride'
                        ? 'pride'
                        : 'default'
                    }
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user.name}
                  </div>
                  <div className="flex items-center gap-1">
                    <Link href={`/${user.username}`}>
                      <a
                        target="_blank"
                        className="text-sm text-primary underline"
                      >
                        mis.fans/{user.username}
                      </a>
                    </Link>

                    <Tippy
                      content="¡Enlace copiado!"
                      trigger="click"
                      interactive={false}
                      interactiveBorder={20}
                      className="rounded bg-gray-800 p-1 text-white opacity-70"
                      hideOnClick={false} // if you want
                      onShow={(instance) => {
                        setTimeout(() => {
                          instance.hide();
                        }, 1000);
                      }}
                    >
                      <button
                        onClick={() =>
                          navigator.clipboard.writeText(
                            `https://mis.fans/${user.username}`
                          )
                        }
                      >
                        <DuplicateIcon className="ml-1 h-4 w-4 text-gray-500 hover:text-gray-700" />
                      </button>
                    </Tippy>
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((item) => (
                  <button
                    id={item.id}
                    type="button"
                    key={item.name}
                    className="block w-full rounded-md py-2 px-3 text-left text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </button>
                ))}
                <button
                  onClick={() => {
                    userLogout();
                    cache.clear();
                    logout();
                  }}
                  className="block w-full rounded-md py-2 px-3 text-left text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                >
                  <div className="flex items-center">
                    <LoginIcon className="mr-2 h-6 w-6 " />

                    <span className="">Cerrar sesión</span>
                  </div>
                </button>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}

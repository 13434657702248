// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  editBlockScreen,
  editAudienceScreen,
  editSubscriptionsScreen,
  editAnalyticsScreen,
  editProductsScreen,
  editPurchaseScreen,
  editProfileViewer,
  userLogout as userLogoutRedux
} from 'reduxState/app/appState';

// Types
import { RootState } from 'reduxState/store';
import {
  AppRedux,
  BlockScreen,
  AudienceScreen,
  SubscriptionsScreen,
  AnalyticsScreen,
  ProductsScreen,
  PurchaseScreen
} from 'types/App';

const useApp = () => {
  const dispatch = useDispatch();
  const appData: AppRedux = useSelector((state: RootState) => state.app);

  const editBlockRedux = (payload: BlockScreen) => {
    if (Object.entries(payload).length === 0) return;

    return dispatch(editBlockScreen(payload));
  };

  const editAudienceRedux = (payload: AudienceScreen) => {
    if (Object.entries(payload).length === 0) return;

    return dispatch(editAudienceScreen(payload));
  };

  const editSubscriptionsRedux = (payload: SubscriptionsScreen) => {
    if (Object.entries(payload).length === 0) return;

    return dispatch(editSubscriptionsScreen(payload));
  };

  const editAnalyticsRedux = (payload: AnalyticsScreen) => {
    if (Object.entries(payload).length === 0) return;

    return dispatch(editAnalyticsScreen(payload));
  };

  const editProductsRedux = (payload: ProductsScreen) => {
    if (Object.entries(payload).length === 0) return;

    return dispatch(editProductsScreen(payload));
  };

  const editPurchaseRedux = (payload: PurchaseScreen) => {
    if (Object.entries(payload).length === 0) return;

    return dispatch(editPurchaseScreen(payload));
  };

  const editProfileViewerRedux = (payload: boolean) => {
    return dispatch(editProfileViewer(payload));
  };

  const userLogout = () => {
    return dispatch(userLogoutRedux());
  };

  return {
    app: appData,
    editBlockRedux,
    editAudienceRedux,
    editSubscriptionsRedux,
    editAnalyticsRedux,
    editProductsRedux,
    editPurchaseRedux,
    editProfileViewerRedux,
    userLogout
  };
};

export default useApp;

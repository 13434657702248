interface IOnboardingContainerProps {
  children: React.ReactNode;
  imageSrc?: string;
}

const OnboardingContainer: React.FC<IOnboardingContainerProps> = ({
  children,
  imageSrc
}) => {
  return (
    <div className="max-w-screen flex min-h-screen">
      <div className="mx-4 flex w-full items-center justify-center lg:mx-10">
        <div className="max-w-[500px] flex-1 py-2">{children}</div>
      </div>

      <img
        className="hidden max-w-[50%] text-white lg:inline-block"
        src={imageSrc || '/signupBack.jpeg'}
        alt="Misfans"
      />
    </div>
  );
};

export default OnboardingContainer;

// Helpers
import { classNames } from 'helpers/classNames';

type RectangleSkeletonProps = {
  className?: string;
};

const RectangleSkeleton: React.FC<RectangleSkeletonProps> = ({ className }) => {
  return (
    <div className={classNames('rounded-lg bg-slate-200', className)}></div>
  );
};

export default RectangleSkeleton;

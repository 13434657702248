// Components
import { Navigation } from './desktop/DK_Navigation';
import DK_USER2 from './user/DK_USER2';
import LogoutButton from './user/LogoutButton';

interface ISideBarProps {
  screen: string;
  setScreen: (value: string | ((prev: string) => void)) => void;
}

const SideBar = ({ screen, setScreen }: ISideBarProps) => {
  return (
    <>
      <header className="hidden h-full min-w-[16rem] max-w-[16rem] overflow-y-auto border-r border-gray-200 bg-gray-50 pt-9 lg:block  lg:scrollbar-none lg:scrollbar-thumb-gray-100">
        <div className="flex h-full w-full flex-col">
          <DK_USER2 />
          <div className="mb-4 mt-5 px-4">
            <Navigation screen={screen} setScreen={setScreen} />
          </div>

          <div className="my-3 mt-auto border-t border-gray-200 px-4">
            <LogoutButton />
          </div>
        </div>
      </header>

      {/* <MB_Navigation screen={screen} setScreen={setScreen} /> */}
    </>
  );
};

export default SideBar;

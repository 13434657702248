import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import hotkeys from 'hotkeys-js';

interface UserCommandMenuResult {
  open: boolean;
  resetState: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/** Hook needed to use the {@link CommandMenu} component, as it initializes
 * hotkey settings and initializes the component's opening commands.
 *
 * @returns hook result {@link UserCommandMenuResult}.
 */
export function useCommandMenu(): UserCommandMenuResult {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    // This allows to use hotkeys while user is focusing inputs.
    hotkeys.filter = function (event) {
      var tagName = (event.target || event.srcElement)['tagName'];
      hotkeys.setScope(
        /^(INPUT|TEXTAREA|SELECT)$/.test(tagName) ? 'input' : 'other'
      );
      return true;
    };

    // cmd+k for macOS, alt+k for Windows.
    hotkeys('command+k,alt+k', function (event, handler) {
      // Prevent the default refresh event under WINDOWS system
      event.preventDefault();

      if (handler.key === 'command+k') setOpen(true);
    });
  };

  const resetState = () => {
    setOpen(false);
  };

  return {
    open,
    resetState,
    setOpen
  };
}

import { Combobox } from '@headlessui/react';
import classNames from 'classnames';

import { CommandMenuActionShortcut } from './actionShortcut';
import { CommandAction } from './types';

export interface CommandMenuOptionProps {
  action: CommandAction;
}

export const CommandMenuOption = ({ action }: CommandMenuOptionProps) => {
  const { key, icon, name, shortcutRules: shortcut } = action;

  return (
    <Combobox.Option
      key={key}
      value={action}
      className={({ active }) =>
        classNames(
          'flex cursor-pointer select-none items-center rounded-md px-3 py-2',
          active && 'bg-gray-100 text-gray-900'
        )
      }
    >
      {({ active }) => (
        <>
          {icon && (
            <action.icon
              className={classNames('h-6 w-6 flex-none text-gray-400')}
              aria-hidden="true"
            />
          )}
          <span
            className={classNames(icon ? 'ml-3' : '', 'flex-auto truncate')}
          >
            {name}
          </span>
          {shortcut && (
            <CommandMenuActionShortcut
              active={active}
              shortcutRules={shortcut}
            />
          )}
        </>
      )}
    </Combobox.Option>
  );
};

// Components
import DigitalProductCard from 'components/dashboard/productsHistory/cards/DigitalProductCard';
import AudioCard from 'components/dashboard/productsHistory/cards/AudioCard';
import QuestionCard from 'components/dashboard/productsHistory/cards/QuestionCard';
import GreetingCard from 'components/dashboard/productsHistory/cards/GreetingCard';

interface DesktopHistoryListProps {
  purchases: any;
}

const DesktopHistoryList = (props: DesktopHistoryListProps) => {
  const { purchases } = props;

  return (
    <ul className="flex flex-col gap-4">
      {purchases.map((purchase, index) => {
        return (
          <div key={'shopping-card:' + purchase.id + `, index: ${index}`}>
            {(purchase.type === 'fileProtected' ||
              purchase.type === 'externalLink') && (
              <DigitalProductCard purchase={purchase} />
            )}
            {purchase.type === 'audio' && <AudioCard purchase={purchase} />}
            {purchase.type === 'question' && (
              <QuestionCard purchase={purchase} />
            )}
            {purchase.type === 'greeting' && (
              <GreetingCard purchase={purchase} />
            )}
          </div>
        );
      })}
    </ul>
  );
};

export default DesktopHistoryList;

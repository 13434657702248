import globalCfg from 'config/globalCfg';
import imageKit from 'libs/imageKit';
import { v4 as uuidv4 } from 'uuid';

interface IImageKitUpload {
  blob: Blob;
  extension: 'jpg' | 'png' | 'mp4';
}

const imageKitUpload = ({ blob, extension }: IImageKitUpload) => {
  return new Promise((resolve, reject) => {
    if (blob.size > globalCfg.MAX_SIZE_IMAGE)
      return resolve({
        error:
          'El archivo no debe superar los ' +
          `${globalCfg.MAX_SIZE_IMAGE / 1000000} MB`
      });

    imageKit.upload(
      {
        file: blob,
        fileName: `${uuidv4()}.${extension}`
      },
      (err, response) => {
        if (err)
          return resolve({
            error:
              'No hemos podido subir tu imagen :( por favor intentelo mas tarde'
          });

        return resolve({ data: response });
      }
    );
  });
};

export default imageKitUpload;

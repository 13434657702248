import { TrashIcon } from '@heroicons/react/solid';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

// Config
import globalCfg from 'config/globalCfg';

// Helpers
import { classNames } from 'helpers/classNames';

// Components
import AddImageIcon from 'assets/icons/AddImage';
import Unsplash from 'components/integrations/unsplash';
import UnsplashIcon from 'components/tailwind/icons/Unsplash';

const CoverImage = () => {
  const { userEdit, userEditSettingsFields } = useUser();
  const [dragEnter, SetDragEnter] = useState<boolean>(false);
  const [openPicker, setOpenPicker] = useState(false);

  const handleFile = (file: Blob) => {
    if (!file.type.includes('image')) {
      toast.error('Tu archivo debe ser una imagen', defaultToastOptions);
      return;
    } else if (file.size > globalCfg.MAX_SIZE_IMAGE) {
      toast.error(
        'La imagen no debe ser superior a 10 MB',
        defaultToastOptions
      );
      return;
    }

    const blobToUrl = URL.createObjectURL(file);

    userEditSettingsFields({
      cover_picture: {
        url: blobToUrl,
        blob: file
      }
    });
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files[0];

      handleFile(file);

      e.target.value = null;
    } catch (e) {
      console.log(e);
      console.log('handleOnChangeInput() Error');
    }
  };

  const handleOnClickRemovePicture = () => {
    userEditSettingsFields({
      cover_picture: {
        url: '',
        blob: null
      }
    });
  };

  const handleDropImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const file = e.dataTransfer.files[0];

      handleFile(file);
      SetDragEnter(false);

      e.target.value = null;
    } catch (e) {
      console.log(e);
      console.log('handleOnChangeInput() Error');
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SetDragEnter(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SetDragEnter(false);
  };

  return (
    <div className="relative">
      <div
        className={classNames(
          userEdit.cover_picture.url ? 'border-0' : 'border-2',
          'group relative flex h-40 w-full rounded-md border-dashed border-gray-300'
        )}
      >
        {userEdit.cover_picture.url ? (
          <img
            className="h-full w-full rounded object-cover object-center"
            src={userEdit.cover_picture.url}
            alt="a"
          />
        ) : (
          <div
            className={classNames(
              dragEnter && 'bg-gray-100 opacity-70',
              'relative flex h-full w-full flex-col items-center justify-center text-sm font-medium'
            )}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDropImage}
          >
            <AddImageIcon className="mb-3 h-12 w-12" />
            <div className="flex">
              <label
                htmlFor="iw_inputFileCover"
                className="cursor-pointer justify-center text-primary"
              >
                Sube un archivo
                <input
                  id="iw_inputFileCover"
                  className="hidden"
                  type="file"
                  onChange={handleOnChangeInput}
                />
              </label>
              <p className="ml-1 text-gray-600"> ó arrastralo al espacio</p>
            </div>
            <p className="text-gray-500">PNG, JPG, GIF hasta 10MB</p>
            <button className='absolute right-2 bottom-2 rounded-md bg-gray-100 p-2 transition-all duration-300 hover:bg-gray-200' onClick={() => setOpenPicker(true)}>
              <UnsplashIcon className='w-20  text-gray-700 fill-gray-700'/>
            </button>
          </div>
        )}

        {userEdit.cover_picture.url && (
          <div className="absolute inset-0 flex bg-white bg-opacity-50 opacity-0 transition-all duration-300 group-hover:opacity-100">
            <div
              className="z-50 m-auto cursor-pointer rounded text-red-400 hover:text-red-500"
              onClick={handleOnClickRemovePicture}
            >
              <TrashIcon className="h-10 w-10" />
            </div>
          </div>
        )}
      </div>
      <div className="absolute left-0 right-0 sm:left-auto sm:right-0">
        <Unsplash
          setUrl={(url) => {
            userEditSettingsFields({
              cover_picture: {
                url: url,
                blob: null
              }
            });
            setOpenPicker(false);
          }}
          modal={false}
          openPicker={openPicker}
          setOpenPicker={setOpenPicker}
        />
      </div>
    </div>
  );
};

export default CoverImage;

// Libraries
import { ArrowSmUpIcon } from '@heroicons/react/solid';

// Components
import SpinnerLoader from '../../SpinnerLoader';
import AnalyticsSkeleton from 'components/tailwind/loaders/AnalyticsSkeleton';

type AnalitycsCardProps = {
  title: string;
  Icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  value: number;
  growth?: number;
  period?: 'today' | '7d' | '30d' | 'infinity';
  isMoney?: boolean;
  loading?: boolean;
};

const AnalyticsCard: React.FC<AnalitycsCardProps> = ({
  title,
  Icon,
  value,
  growth,
  period,
  isMoney = false,
  loading = false
}) => {
  const mapPeriodToText = {
    today: 'hoy',
    '7d': 'esta semana',
    '30d': 'este mes',
    infinity: 'todo el tiempo'
  };

  if (loading) {
    return <AnalyticsSkeleton />;
  }

  return (
    <article className="flex items-center rounded-lg border border-gray-200 p-6 shadow">
      <div className="mr-5 flex rounded-md bg-primary-50 p-3">
        <Icon className="h-6 w-6 text-primary" />
      </div>
      <div>
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <h1 className="flex gap-2 text-2xl font-semibold text-gray-900">
          {isMoney ? '$' : ''}
          {value}
          {isMoney ? ' USD' : ''}
          {growth > 0 && period !== 'infinity' && (
            <span className="flex items-center text-sm font-semibold text-[#059669]">
              <ArrowSmUpIcon className="h-5 w-5 text-[#10B981]" />+ {growth}{' '}
              {mapPeriodToText[period]}
            </span>
          )}
        </h1>
      </div>
    </article>
  );
};

export default AnalyticsCard;

// Components
import Card from './Card';

const MetamaskButton: React.FC = () => {
  return (
    <Card
      img="/img/icons/metamask.svg"
      imgBackgroundColor="#D7C1B3"
      title="Metamask"
      description="Próximamente"
      buttonText="Conectar"
      onClickButton={() => {} /* TODO */}
      buttonClassName="border border-gray-300 text-gray-700 bg-white hover:bg-gray-50"
      disableButton={true}
      active={false}
    />
  );
};

export default MetamaskButton;

// Libraries
import toast from 'react-hot-toast';
import useSWR from 'swr';

// Components
import EmptyState from 'components/dashboard/EmptyState';
import BlockSkeleton from 'components/tailwind/loaders/BlockSkeleton';
import WalletCard from './WalletCard';
import WalletFaRegular from 'components/tailwind/icons/WalletFaRegular';

// Requests
import { AxiosWallets } from 'requests/local_api/AxiosSubscriptions';

const WalletScreen = () => {
  const { error, data } = useSWR('/subscriptions/wallets', AxiosWallets);

  if (error === undefined && data === undefined) {
    return <BlockSkeleton quantity={6} className="flex flex-col gap-3" />;
  } else if (error) {
    toast.error('Error obteniedo wallets');
  }

  return (
    <>
      {data.length === 0 ? (
        <EmptyState
          absolute={false}
          title="Aún no tienes ninguna wallet conectada a un creador."
          dashboard={true}
          className="flex-1"
          icon={<WalletFaRegular className="h-[34px] w-[39px] text-gray-400" />}
        />
      ) : (
        <div className="flex flex-col gap-4">
          {data.map((value, index) => (
            <WalletCard
              key={index}
              addr={value.address}
              creator_data={value.creator_data}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default WalletScreen;

// Components
import RectangleSkeleton from 'components/tailwind/loaders/RectangleSkeleton';
import Spacer from 'components/tailwind/layout/Spacer';

const AnalyticsSkeleton: React.FC = () => {
  return (
    <article className="flex animate-pulse items-center rounded-lg border border-gray-200 p-6 shadow">
      <RectangleSkeleton className="mr-5 h-14 w-14" />
      <div>
        <RectangleSkeleton className="h-5 w-24" />
        <Spacer className="h-1" />
        <RectangleSkeleton className="h-9 w-10" />
      </div>
    </article>
  );
};

export default AnalyticsSkeleton;

// Libraries
import { useRef, useState, useEffect } from 'react';
import { VolumeOffIcon, VolumeUpIcon } from '@heroicons/react/solid';

// Helpers
import { classNames } from 'helpers/classNames';
import { formatSecondsToVideoDuration } from 'helpers/formatData';

// Hooks
import useVideoPlayer, { HTMLVideoElementMF } from 'hooks/useVideoPlayer';
import useWindowSize from 'hooks/useWindowSize';

// Components
import PlayIcon from 'components/tailwind/icons/PlayIcon';
import PauseIcon from 'components/tailwind/icons/PauseIcon';
import WatchAgainIcon from 'components/tailwind/icons/WatchAgainIcon';
import EnterFullScreenIcon from 'components/tailwind/icons/EnterFullScreen';
import ExitFullScreenIcon from 'components/tailwind/icons/ExitFullScreen';
import MisFansIcon from 'components/tailwind/icons/MisFansIcon';

type CustomVideoPlayerProps = {
  className?: string;
  type?: 'request' | 'fileUpload';
  username?: string;
  videoUrl: string;
};

const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = ({
  className,
  type = 'request',
  username,
  videoUrl
}) => {
  const videoRef = useRef<HTMLVideoElementMF | null>(null);
  const windowDimensions = useWindowSize();
  const [isHeightLarger, serIsHeightLarger] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(true);
  const {
    togglePlay,
    playerState,
    handleVideoProgress,
    handleBuffer,
    handleOnTimeUpdate,
    handleVolumeChange,
    toggleMute,
    openFullScreen,
    closeFullScreen,
    handleVideoEnded
  } = useVideoPlayer(videoRef);

  const compareHeightAndWidth = () => {
    if (videoRef.current.videoHeight > videoRef.current.videoWidth) {
      serIsHeightLarger(true);
    }
    if (videoRef.current.readyState === 3) {
      handleVideoLoaded();
    }
  };

  const handleVideoLoaded = () => {
    setTimeout(() => {
      setIsVideoLoaded(true);
    }, 1000);
  };

  useEffect(() => {
    compareHeightAndWidth();
  }, [videoRef]);

  return (
    <div
      className={classNames(
        !isVideoLoaded
          ? type === 'request'
            ? 'h-[70vh] w-80 lg:h-[80vh]'
            : 'h-[300px] w-full'
          : isHeightLarger
          ? 'w-full min-w-[320px] sm:h-[70vh] lg:h-[80vh]'
          : 'lg:max-w-lg xl:max-w-3xl',
        className,
        'group relative flex justify-center overflow-hidden rounded-md transition-all'
      )}
    >
      <video
        src={videoUrl}
        ref={videoRef}
        disablePictureInPicture={true}
        controlsList="nodownload"
        crossOrigin="anonymous"
        playsInline={true}
        onContextMenu={(e) => e.preventDefault()}
        onLoadStart={() => setIsVideoLoaded(false)}
        onLoadedData={compareHeightAndWidth}
        onCanPlay={handleVideoLoaded}
        onTimeUpdate={handleOnTimeUpdate}
        onProgress={handleBuffer}
        onEnded={handleVideoEnded}
        onClick={togglePlay}
        className={classNames(
          !playerState.fullscreen && type === 'fileUpload' && 'object-cover',
          'h-full w-full'
        )}
      />
      {!isVideoLoaded ? (
        <div className="absolute z-20 h-full w-full bg-white">
          <div
            className={classNames(
              'z-20 flex h-full w-full animate-pulse items-center justify-center bg-slate-200'
            )}
          >
            <MisFansIcon className="fill-slate-500" />
          </div>
        </div>
      ) : (
        <>
          {username && (
            <button
              className={classNames(
                playerState.isPlaying && !playerState.videoEnded && 'hidden',
                'absolute left-0 top-0 z-10 flex items-center gap-1 p-4 text-lg font-semibold text-white'
              )}
              onClick={() => window.open(`/${username}`, '_blank')}
            >
              <img src="/logo-white.svg" className="h-5" />
              <span>|</span>
              {'@' + username}
            </button>
          )}
          <button
            type="button"
            onClick={togglePlay}
            className={classNames(
              playerState.isPlaying && !playerState.videoEnded && 'hidden',
              'group absolute left-0 right-0 top-0 bottom-0 w-full bg-black bg-opacity-20 hover:bg-opacity-25'
            )}
          >
            <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-gray-50 transition-all group-hover:scale-105">
              {playerState.videoEnded ? (
                <WatchAgainIcon className="h-8 w-8 scale-[1.6] text-primary" />
              ) : (
                <PlayIcon className="h-8 w-8 text-primary" />
              )}
            </div>
          </button>
          <div className="absolute left-0 right-0 bottom-0 min-h-[4px] transition-all">
            {(playerState.isPlaying ||
              windowDimensions?.width < 1024 ||
              playerState.started) && (
              <div className="relative bg-black bg-opacity-40">
                <input
                  type="range"
                  min="0"
                  max="100"
                  className="remove-slider-thumb absolute h-1 w-full cursor-pointer transition-all hover:scale-y-150"
                  value={playerState.progress}
                  onChange={(e) => handleVideoProgress(e)}
                  style={{
                    WebkitAppearance: 'none',
                    background:
                      'linear-gradient(to right, #542ae2 ' +
                      playerState.progress +
                      '%, #bdbdbd 0)'
                  }}
                />
                <div
                  className={classNames(
                    !playerState.isPlaying ? 'flex' : 'hidden',
                    'items-center px-2 py-2 pt-[13px] group-hover:flex lg:hidden'
                  )}
                >
                  <div className="flex items-center gap-3">
                    <button
                      onClick={togglePlay}
                      className="transition-all hover:scale-[1.25]"
                    >
                      {playerState.videoEnded ? (
                        <WatchAgainIcon className="white h-6 w-6 scale-[1.5] text-white" />
                      ) : playerState.isPlaying ? (
                        <PauseIcon className="mx-auto h-6 w-6 scale-110 text-white" />
                      ) : (
                        <PlayIcon className="mx-auto h-5 w-5 text-white" />
                      )}
                    </button>
                    <div className="flex items-center gap-2">
                      <button
                        className="transition-all hover:scale-[1.15]"
                        onClick={toggleMute}
                      >
                        {Math.round(playerState.volume * 100) > 0 &&
                        !playerState.isMuted ? (
                          <VolumeUpIcon className="mx-auto h-6 w-6 text-white" />
                        ) : (
                          <VolumeOffIcon className="mx-auto h-6 w-6 text-white" />
                        )}
                      </button>

                      <input
                        type="range"
                        min="0"
                        value={playerState.volume * 100}
                        onChange={(e) => handleVolumeChange(e)}
                        className={classNames(
                          isHeightLarger && 'lg:w-20',
                          'misfans-slider-thumb h-[4px] w-16 rounded-full'
                        )}
                        style={{
                          WebkitAppearance: 'none',
                          background: '#ffffff'
                        }}
                      />
                    </div>
                  </div>
                  <div className="ml-auto mr-6 text-white">
                    {formatSecondsToVideoDuration(
                      Math.round(playerState.currentTime)
                    )}{' '}
                    /{' '}
                    {formatSecondsToVideoDuration(
                      Math.round(playerState.duration)
                    )}
                  </div>
                  <button
                    onClick={
                      playerState.fullscreen ? closeFullScreen : openFullScreen
                    }
                    className="transition-all hover:scale-[1.15]"
                  >
                    {playerState.fullscreen ? (
                      <ExitFullScreenIcon className="h-6 w-6 text-white" />
                    ) : (
                      <EnterFullScreenIcon className="h-6 w-6 text-white" />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomVideoPlayer;

import React from 'react';

// Helpers
import { classNames } from 'helpers/classNames';

interface IBasicDividerProps {
  className?: string;
}

const BasicDivider: React.FC<IBasicDividerProps> = ({ className }) => {
  return (
    <div
      className={classNames(className, 'h-divider w-full bg-gray-300')}
    ></div>
  );
};

export default BasicDivider;

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  ForwardedRef
} from 'react';
import { TrashIcon } from '@heroicons/react/solid';

import { classNames } from 'helpers/classNames';
import { determineFileType, FileType, MimeTypes } from 'types/MimeTypes';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { formatBytes } from 'utils/formatBytes';

// Components
import SpinnerLoader from 'components/SpinnerLoader';
import VideoPlayer from 'components/tailwind/player/CustomVideoPlayerV2';
import ProgressBar from 'components/tailwind/loaders/ProgressBar';

enum FileInputError {
  failedLoadingBlob = 'Ha habido un error cargando la información del Archivo digital. Por favor contacta con el equipo de soporte.',
  generalMimeTypeValidationError = 'El archivo que has intentado subir no tiene un formato generalmente válido por nuestra plataforma.',
  maxFileSizeExceeded = 'El archivo que has intentado subir excede el tamaño máximo permitido.',
  mimeTypeNotSupportedError = 'El archivo no tiene un formato válido.',
  onDropFileError = 'Ha habido un error al cargar el archivo.'
}

const generalMimeTypeSupport = Object.values(MimeTypes);

enum InputFileState {
  loading = 'loading',
  showUrl = 'showUrl',
  empty = 'empty',
  showFile = 'showFile',
  showInfoFile = 'showInfoFile',
  loadingFile = 'loadingFile'
}

enum GeneralFileType {
  image = 'image',
  video = 'video',
  audio = 'audio',
  file = 'file'
}

interface FileInputProps {
  className?: string;
  file: File | null;
  fileUploadProgress?: number;
  maxFileSize?: number;
  mimeTypesSupported?: Array<string>;
  previsualize?: boolean;
  onClickInputDetailsHelp?: () => void;
  parentLoading?: boolean;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
  setFile: React.Dispatch<React.SetStateAction<File>>;
  showRemoveInput?: boolean;
  url?: string;
  resetState?: () => void;
  type?: keyof typeof GeneralFileType;
}

interface refAttributes {
  removeInput: () => void;
}

const MAX_FILE_SIZE = 25 * 1024 * 1024;

const RectangleFileInput = forwardRef(
  (props: FileInputProps, ref: ForwardedRef<refAttributes>) => {
    const {
      className,
      fileUploadProgress,
      maxFileSize = MAX_FILE_SIZE,
      mimeTypesSupported,
      onClickInputDetailsHelp,
      parentLoading,
      resetState,
      setError,
      setErrorMessage,
      setFile,
      showRemoveInput = true,
      type = 'file'
    } = props;

    useImperativeHandle(ref, () => ({
      removeInput: () => {
        onClickRemoveInput();
      }
    }));

    const [url, setUrl] = useState<string>(null);
    const [internalFile, setInternalFile] = useState<File>(null);
    const [fileType, setFileType] = useState<FileType>(null);
    const [fileUrl, setFileUrl] = useState<string>(null);
    const [dragEnter, SetDragEnter] = useState<boolean>(false);
    const [previsualize] = useState<boolean>(
      props.previsualize == undefined ? true : props.previsualize
    );
    const [inputState, setInputState] =
      useState<keyof typeof InputFileState>('empty');

    useEffect(() => {
      initialFileRender();
    }, [props.url]);

    useEffect(() => {
      if (fileUrl != null) setInputState('showFile');
    }, [fileUrl]);

    const initialFileRender = async () => {
      if (props.url) {
        if (previsualize) {
          setUrl(props.url);
          setInputState('showUrl');
        } else {
          setInputState('loadingFile');

          const blob = await fetch(url)
            .then((res) => res.blob())
            .catch((error) => new Error(error));

          if (blob instanceof Error) {
            return setErrorMessage(FileInputError.failedLoadingBlob);
          }

          const file = new File([blob], 'digital-product');
          setInternalFile(file);
          setInputState('showInfoFile');
        }
      }
    };

    const loadFileToState = async (rawFile: File) => {
      SetDragEnter(false);

      if (!rawFile) return;

      // General mimetypes validation for avoid misspelling or discarding weird types.
      if (generalMimeTypeSupport.some((mts) => mts === rawFile.type) == false) {
        return throwError(FileInputError.generalMimeTypeValidationError);
      }

      // Mime types according to the type of file.
      if (mimeTypesSupported) {
        if (mimeTypesSupported.some((mts) => mts === rawFile.type) == false) {
          return throwError(FileInputError.mimeTypeNotSupportedError);
        }
      } else {
        const validFile = validateFileSupport(rawFile, type);
        if (!validFile) {
          return throwError(FileInputError.mimeTypeNotSupportedError);
        }
      }

      // Max file size validation.
      if (rawFile.size > maxFileSize) {
        return throwError(FileInputError.maxFileSizeExceeded);
      }

      setFile(rawFile);
      setInternalFile(rawFile);
      cleanError();

      const fileType = determineFileType(rawFile.type as MimeTypes);
      setFileType(fileType);

      if ((type == FileType.image || type == FileType.video) && previsualize) {
        setFileUrl(URL.createObjectURL(rawFile));
      } else {
        setInternalFile(rawFile);
        setInputState('showInfoFile');
      }
    };

    const throwError = (error: FileInputError) => {
      setErrorMessage && setErrorMessage(error);
      setError && setError(true);
    };

    const cleanError = () => {
      setError && setError(false);
      setErrorMessage && setErrorMessage('');
    };

    const onClickRemoveInput = () => {
      setFile(null);
      setUrl(null);
      setFileUrl(null);
      setInputState('empty');
      resetState && resetState();
    };

    const onClickInputHelp = () => {
      onClickInputDetailsHelp && onClickInputDetailsHelp();
    };

    const onClickInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      loadFileToState(e.target.files[0]);
    };

    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      SetDragEnter(true);
    };

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      SetDragEnter(false);
    };

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        const file = e.dataTransfer.files[0];

        loadFileToState(file);
      } catch (e) {
        throwError(FileInputError.onDropFileError);
      }
    };

    const validateFileSupport = (
      file: File,
      type: keyof typeof GeneralFileType
    ) => {
      const fileType = determineFileType(file.type as MimeTypes);
      return fileType === type;
    };

    const render = (
      <div
        className={classNames(
          (inputState == 'empty' || inputState == 'loadingFile') &&
            'rounded-md border-[3px] border-dashed border-gray-300',
          inputState == 'showFile' && '',
          dragEnter && 'border-dashed border-primary-300 transition-all',
          className,
          'relative flex w-full'
        )}
      >
        {(inputState == 'showUrl' || inputState == 'showFile') &&
          showRemoveInput && (
            <div
              className={
                'absolute inset-0 flex rounded-md bg-gray-800 bg-opacity-50 opacity-0 group-hover:opacity-70'
              }
            >
              <div
                className={
                  'z-50 m-auto cursor-pointer rounded text-red-400 hover:text-red-600'
                }
                onClick={onClickRemoveInput}
              >
                <TrashIcon className={'h-10 w-10'} />
              </div>
            </div>
          )}
        {inputState == 'empty' && (
          <div
            className={classNames(
              dragEnter && '',
              'flex h-full w-full flex-col items-center justify-center py-4 text-sm font-medium'
            )}
            onDragEnter={(e) => !parentLoading && onDragEnter(e)}
            onDragLeave={(e) => !parentLoading && onDragLeave(e)}
            onDragOver={(e) => !parentLoading && onDragEnter(e)}
            onDrop={(e) => !parentLoading && onDrop(e)}
          >
            <div className={'py-2'}>
              <img
                src="/UploadIcon.svg"
                className={classNames(
                  dragEnter && 'text-primary-400',
                  'h-9 w-9 text-gray-500'
                )}
              />
            </div>
            <div className={'flex flex-col items-center text-base font-medium'}>
              {type == 'audio' && (
                <div className={'justify-center'}>
                  <label
                    className={'inline-block cursor-pointer pr-1 text-primary'}
                  >
                    Sube un archivo
                    <input
                      accept="audio/*"
                      className={'hidden'}
                      id={'iw_inputFileCover'}
                      onChange={onClickInput}
                      type={'file'}
                      disabled={parentLoading}
                    />
                  </label>
                  <p className={'inline-block text-gray-600'}>
                    ó arrástralo al espacio
                  </p>
                  <div className={'text-center text-gray-500'}>
                    <p className={'pr-1 align-middle'}>
                      MP3 hasta {formatBytes(MAX_FILE_SIZE)}
                    </p>
                    {onClickInputDetailsHelp && (
                      <QuestionMarkCircleIcon
                        className={'inline-block h-5 w-5 cursor-pointer'}
                        onClick={onClickInputHelp}
                      />
                    )}
                  </div>
                </div>
              )}
              {type == 'video' && (
                <div className={'justify-center'}>
                  <label
                    className={'inline-block cursor-pointer pr-1 text-primary'}
                  >
                    Sube un archivo
                    <input
                      accept="video/*"
                      className={'hidden'}
                      id={'iw_inputFileCover'}
                      onChange={onClickInput}
                      type="file"
                      disabled={parentLoading}
                    />
                  </label>
                  <p className={'inline-block text-gray-600'}>
                    ó arrástralo al espacio
                  </p>
                  <div className={'text-center text-gray-500'}>
                    <p className={'pr-1 align-middle'}>
                      MP4 ó MOV hasta {formatBytes(MAX_FILE_SIZE)}
                    </p>
                    {onClickInputDetailsHelp && (
                      <QuestionMarkCircleIcon
                        className={'inline-block h-5 w-5 cursor-pointer'}
                        onClick={onClickInputHelp}
                      />
                    )}
                  </div>
                </div>
              )}
              {type == 'file' && (
                <div className={'justify-center'}>
                  <label
                    className={'inline-block cursor-pointer pr-1 text-primary'}
                  >
                    Sube un archivo
                    <input
                      className={'hidden'}
                      id={'iw_inputFileCover'}
                      onChange={onClickInput}
                      type={'file'}
                      disabled={parentLoading}
                    />
                  </label>
                  <p className={'inline-block text-gray-600'}>
                    ó arrástralo al espacio
                  </p>
                  <div className={'text-center text-gray-500'}>
                    <p className={'pr-1 align-middle'}>
                      hasta {formatBytes(MAX_FILE_SIZE)}
                    </p>
                    {onClickInputDetailsHelp && (
                      <QuestionMarkCircleIcon
                        className={'inline-block h-5 w-5 cursor-pointer'}
                        onClick={onClickInputHelp}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {inputState == 'showFile' && fileType == 'image' && (
          <div
            className={
              'min-h-[240px] w-full rounded-md border-[1px] border-slate-900 bg-gray-100 bg-contain bg-center'
            }
          >
            {<img alt={'a'} src={fileUrl} />}
          </div>
        )}
        {inputState == 'showFile' && fileType == 'video' && (
          <div className="w-full rounded-lg border border-primary-100">
            <VideoPlayer
              videoUrl={fileUrl}
              className="rounded-b-none"
              type="fileUpload"
            />
            {fileUploadProgress !== undefined ? (
              <div className="py-3 px-4">
                <p className="font-medium text-gray-900">{internalFile.name}</p>
                <p className="mb-2 text-sm font-medium text-gray-500">
                  {formatBytes(internalFile.size)}
                </p>
                <div className="flex items-center justify-between">
                  <ProgressBar
                    progress={fileUploadProgress}
                    className="w-[88%]"
                  />
                  <span className="font-medium text-gray-800">
                    {fileUploadProgress}%
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        )}
        {inputState == 'showUrl' && (
          <img
            alt={'a'}
            className={
              'w-full rounded-md border-[1px] border-slate-900 bg-gray-100 bg-contain bg-center'
            }
            src={url}
          />
        )}
        {inputState == 'showInfoFile' && (
          <div className="w-full rounded-lg border border-primary-100">
            <div className="py-3 px-4">
              <div className="flex justify-between">
                <div>
                  <p className="font-medium text-gray-900">
                    {internalFile.name}
                  </p>
                  <p className="mb-2 text-sm font-medium text-gray-500">
                    {formatBytes(internalFile.size)}
                  </p>
                </div>
                <button
                  className={classNames(
                    parentLoading && 'cursor-not-allowed opacity-50',
                    'cursor-pointer rounded p-2 text-red-400 hover:text-red-600'
                  )}
                  onClick={onClickRemoveInput}
                  disabled={parentLoading}
                >
                  <TrashIcon className={'h-7 w-7'} />
                </button>
              </div>

              {fileUploadProgress ? (
                <div className="flex items-center justify-between">
                  <ProgressBar
                    progress={fileUploadProgress}
                    className="w-[88%]"
                  />
                  <span className="font-medium text-gray-800">
                    {fileUploadProgress}%
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        )}
        {inputState == 'loadingFile' && (
          <div
            className={
              'my-4 flex h-full w-full flex-col items-center justify-center text-sm font-medium'
            }
          >
            <SpinnerLoader color="#542ae2" className="h-6 w-6" />
          </div>
        )}
      </div>
    );

    return render;
  }
);

export default RectangleFileInput;

// Libraries
import { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/solid';

// Hooks
import { useUser } from 'hooks/useUser';
import useApp from 'hooks/useApp';

// Helpers
import { classNames } from 'helpers/classNames';

// Components
import ColorsDeclaration from './ColorsDeclaration';

/* 
  'pinkProfile',
  'lightBlueProfile',
  'orangeProfile',
  'yellowProfile',
  'greenProfile',
  'redProfile', 
*/

const colors = [
  'red',
  'pink',
  'yellow',
  'green',
  'cyan',
  'blue',
  'primary',
  'fuchsia',
  'jacarta',
  'ultramarine',
  'darktan',
  'mallard',
  'ebonyclay',
  'eagle'
];

const CustomProfile: React.FC = () => {
  const { userEdit, userEditSettingsFields } = useUser();
  const {
    app: { isProfileViewerOpen }
  } = useApp();

  const colorValue = userEdit.customProfile.buttonColor || 'primary';

  const handleColorClick = (color: string) => {
    if (colorValue === color || color === 'primary') {
      userEditSettingsFields({
        customProfile: {
          ...userEdit.customProfile,
          buttonColor: ''
        }
      });
    } else {
      userEditSettingsFields({
        customProfile: {
          ...userEdit.customProfile,
          buttonColor: color
        }
      });
    }
  };

  return (
    <div>
      <div
        className={classNames(
          isProfileViewerOpen ? 'md:grid-rows-2' : 'md:grid-rows-1',
          'grid grid-flow-col grid-rows-2 gap-[2px] xl:grid-rows-1'
        )}
      >
        {colors.map((color) => {
          return (
            <div
              className={classNames(
                `flex h-12 cursor-pointer items-center justify-center transition-all first:rounded-l-lg last:rounded-r-lg hover:scale-[1.02] xl:w-full ${
                  color === 'primary' ? 'bg-primary' : `bg-${color}-500`
                }`
              )}
              onClick={() => handleColorClick(color)}
            >
              {colorValue === color && (
                <CheckIcon className="text-rendering max-h-8 text-white" />
              )}
            </div>
          );
        })}
      </div>
      <ColorsDeclaration />
    </div>
  );
};

export default CustomProfile;

import {
  FC,
  ChangeEvent,
  ChangeEventHandler,
  MouseEventHandler,
  useState
} from 'react';
import { toast } from 'react-hot-toast';
import { XIcon, PlusIcon } from '@heroicons/react/solid';
import AddImageIcon from 'assets/icons/AddImage';
import globalCfg from 'config/globalCfg';

interface IFile {
  blob: null | File;
  url: string;
}

interface IInputFileProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled: boolean;
}

interface IPreviewProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onRemove: MouseEventHandler<HTMLButtonElement>;
  url: string;
  disabled: boolean;
}

interface Props {
  className?: string;
  file: IFile;
  setFile: (value: IFile | ((prev: IFile) => IFile)) => void;
  disabled?: boolean;
}

const InputFile: FC<IInputFileProps> = function ({ onChange, disabled }) {
  return (
    <label
      className={`group flex w-full items-center justify-center rounded-lg border-[3px] border-dashed border-gray-300 ${
        disabled ? '' : 'cursor-pointer'
      }`}
    >
      <AddImageIcon className="h-4 w-4 transition-all group-hover:stroke-primary" />

      <input
        type="file"
        className="hidden"
        accept="image/*"
        onChange={onChange}
        disabled={disabled}
      />
    </label>
  );
};

const Preview: FC<IPreviewProps> = function ({
  onChange,
  onRemove,
  url,
  disabled
}) {
  return (
    <div className={`relative w-full ${disabled ? '' : 'group'}`}>
      <img
        className="h-full w-full rounded-lg object-cover object-center"
        src={url}
      />

      <div className="invisible absolute inset-0 flex rounded-lg bg-white bg-opacity-50 opacity-0 transition-all group-hover:visible group-hover:opacity-100">
        <label className="flex w-full cursor-pointer items-center justify-center">
          <PlusIcon className="h-8 w-8 text-primary" />

          <input
            type="file"
            className="hidden"
            accept="image/*"
            onChange={onChange}
            disabled={disabled}
          />
        </label>

        <button
          type="button"
          className="absolute -top-2 -right-2 z-50 m-auto cursor-pointer rounded-full bg-white bg-opacity-100 p-1 opacity-0 shadow-lg transition duration-200 ease-in-out hover:bg-gray-200 group-hover:opacity-100"
          onClick={onRemove}
          disabled={disabled}
        >
          <XIcon className="h-3 w-3 text-gray-500" />
        </button>
      </div>
    </div>
  );
};

const ImageInput: FC<Props> = function ({
  className = '',
  file,
  setFile,
  disabled = false
}) {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    try {
      const theFile = e.target.files[0];

      e.target.value = null;

      if (!theFile.type.includes('image')) {
        toast.error('El archivo debe ser una imagen');
        return;
      } else if (theFile.size > globalCfg.MAX_SIZE_IMAGE) {
        toast.error('La imagen no debe ser superior a 10 MB');
        return;
      }

      const blobToURL = URL.createObjectURL(theFile);

      setFile((prev) => ({
        ...prev,
        blob: theFile,
        url: blobToURL
      }));
    } catch (e) {
      console.error(e);
      console.error('handleOnChange() Error ');
    }
  };

  const handleOnRemove = () => {
    if (disabled) return;

    setFile((prev) => ({
      ...prev,
      blob: null,
      url: ''
    }));
  };

  return (
    <div className={className}>
      <h5 className="mb-1 text-base font-medium">Imagen</h5>

      <div
        className={`flex max-h-[4.5rem] min-h-[4.5rem] min-w-[4.5rem] max-w-[4.5rem] ${
          disabled ? 'opacity-50' : ''
        }`}
      >
        {file.url ? (
          <Preview
            onChange={handleOnChange}
            onRemove={handleOnRemove}
            url={file.url}
            disabled={disabled}
          />
        ) : (
          <InputFile onChange={handleOnChange} disabled={disabled} />
        )}
      </div>
    </div>
  );
};

export default ImageInput;

// Types
import { DigitalProductPurchaseAggregate } from 'types/digitalProductPurchase';
import { ActionsList } from 'components/tailwind/optionsMenu/types';

// Libraries
import { useRouter } from 'next/router';
import { ShoppingBagIcon } from '@heroicons/react/outline';

// Components
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import CircleBadge from 'components/tailwind/badges/CircleBadge';
import FilePdfFa from 'components/tailwind/icons/FilePdfFa';
import LinkFa from 'components/tailwind/icons/LinkFa';
import PurchaseSubtitle from './PurchaseSubtitle';
import PurchaseCard from './PurchaseCard';
import PurchaseTitle from './PurchaseTitle';

// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

// Helpers
const dayjs = require('dayjs');
dayjs.locale(require('utils/dayjsCustomPlugins/es'));
dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('dayjs/plugin/isYesterday'));
dayjs.extend(require('utils/dayjsCustomPlugins/isBeforeYesterday'));

interface IDigitalProductCardProps {
  purchase: DigitalProductPurchaseAggregate;
}

const DigitalProductCard: React.FC<IDigitalProductCardProps> = (props) => {
  const { purchase } = props;

  const { width } = useWindowDimensions();
  const router = useRouter();

  const onClickSeePurchase = (
    digitalProductPurchase: DigitalProductPurchaseAggregate
  ) => {
    router.push(`/purchase/${digitalProductPurchase.uuid}`);
  };

  return (
    <PurchaseCard
      avatar={
        <CircleBadge className="bg-primary-50">
          <ShoppingBagIcon className="h-[22px] w-[22px] text-primary" />
        </CircleBadge>
      }
      title={
        <PurchaseTitle
          productName={purchase.name}
          creatorName={purchase.creator_data.name}
          creatorUserName={purchase.creator_data.username}
        />
      }
      subtitle={
        <PurchaseSubtitle
          date={dayjs(purchase.creation_date).format('MMMM DD, YYYY')}
          fileType={
            purchase.type === 'fileProtected'
              ? 'Archivo descargable'
              : 'Enlace externo'
          }
          price={purchase.price}
          fileTypeIcon={purchase.type}
        />
      }
      actions={
        <SecondaryButton
          title={width > 768 ? 'Ver Producto' : 'Ver'}
          variant={variantType.gray}
          onClick={() => onClickSeePurchase(purchase)}
          type={'button'}
          size="small"
        />
      }
    />
  );
};

export default DigitalProductCard;

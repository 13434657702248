import { FC, useState, useEffect, useRef, ChangeEvent } from "react"
import { toast } from "react-hot-toast"
import { v4 as uuidv4 } from "uuid"
import { supabase } from "utils/supabase-client"

// Components
import LabelRequired from "components/tailwind/inputs/LabelRequired"
import Input from "components/tailwind/inputs/Input"
import FormFooter from "components/tailwind/overlays/FormFooter"
import WhiteButton from "components/tailwind/buttons/WhiteButton"
import BasicButton from "components/tailwind/buttons/BasicButton"
import SecondaryButton, { variantType } from "components/tailwind/buttons/SecondaryButton"
import Preview from "./Preview"

// Hooks
import { useUser } from "hooks/useUser"

// Requests
import FetchGetProductData from "requests/local_next/shopify/FetchGetProductData"

// Types
import { IBlocks } from "types/Block"

interface Props {
  mode?: "edit" | "add"
  initialValue?: any,
  onClickCancel: () => void,
  setBlocks: (value: IBlocks[] | ((prev: IBlocks[]) => IBlocks[])) => void
}

const initialShopifyProductData = {
  title: "",
  image: "",
  price: 0,
  currency: ""
}

const AddShopifyProductForm: FC<Props> = function({
  mode = "add",
  initialValue,
  onClickCancel,
  setBlocks
}) {
  const { user, updateUserRedux } = useUser()

  const firstRender = useRef<boolean>(true)
  const [shopifyProduct, setShopifyProduct] = useState(initialValue?.data || initialShopifyProductData)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [fetching, setFetching] = useState<boolean>(false)
  const [value, setValue] = useState<string>(initialValue?.url || "")
  const [isValid, setIsValid] = useState<boolean>(false)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current =  false
      return
    }

    else if (!value) {
      setShopifyProduct(initialShopifyProductData)
      setErrorMessage("")
      return
    }

    const timeout = setTimeout(() => {
      getShopifyData()
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [value])

  const handleOnChangeURL = (e: ChangeEvent<HTMLInputElement>) => {
    if (isValid) {
      setIsValid(false)
    }

    setValue(e.target.value)
  }

  const getShopifyData = async () => {
    setFetching(true)

    setShopifyProduct(initialShopifyProductData)

    const { error, data } = await FetchGetProductData(value)

    setFetching(false)

    if (error) {
      setErrorMessage(error)
      return
    }

    setShopifyProduct(data)
    setErrorMessage("")
    setIsValid(true)
  }

  const handleOnClickAdd = async () => {
    if (
      !isValid ||
      fetching ||
      (!shopifyProduct.title && !shopifyProduct.image && !shopifyProduct.currency && !shopifyProduct.price) ||
      !!errorMessage
    ) return

    setFetching(true)

    const payload = {
      id: uuidv4(),
      type: "shopify_product",
      data: shopifyProduct,
      url: value,
      visible: true,
    }

    const updateBlocks = [payload, ...user.blocks]

    const { error } = await supabase
      .from("accounts")
      .update({
        blocks: updateBlocks
      })
      .match({ id: user.id })

    setFetching(false)

    if (error) {
      console.log(error)
      toast.error("A ocurrido un error")
      return
    }

    setBlocks((prev) => [payload, ...prev])

    updateUserRedux({
      blocks: updateBlocks
    })

    toast.success("Producto agregado satisfactoriamente!")
    onClickCancel()
  }

  const handleOnClickUpdate = async () => {
    if (
      !isValid ||
      !initialValue ||
      fetching ||
      !value ||
      initialValue.url === value ||
      (!shopifyProduct.title && !shopifyProduct.price && !shopifyProduct.currency && !shopifyProduct.image) ||
      !!errorMessage
    ) return

    let existsBlock: boolean = false

    const map = user.blocks.map((block) => {
      if (block.id !== initialValue.id || block.type !== "shopify_product") return block

      existsBlock = true
      block.url = value
      block.data = shopifyProduct

      return block
    })

    if (!existsBlock) {
      toast.error("No hemos podido encontrar el bloque para actualizar")
      return
    }

    setFetching(true)

    const { error } = await supabase
      .from("accounts")
      .update({ blocks: map })
      .match({ id: user.id })

    if (error) {
      console.error(error)
      toast.error("A ocurrido un error mientras se actualizaba el bloque")
      return
    }

    setBlocks(map)
    updateUserRedux({
      blocks: map
    })

    toast.success("Bloque actualizado satisfactoriamente!")

    onClickCancel()
  }

  const handleOnClickRemove = async () => {
    if (fetching || !initialValue || !initialValue.id) return

    const confirmation = confirm("¿Estás seguro de que quieres eliminar este bloque?")

    if (!confirmation) return

    const filter = user.blocks.filter((block) => block.id !== initialValue.id)

    setFetching(true)

    const { error } = await supabase
      .from("accounts")
      .update({
        blocks: filter
      })
      .match({ id: user.id })
    
    setFetching(false)

    if (error) {
      console.error(error)
      toast.error("A ocurrido un error mientras se eliminaba el bloque")
      return
    }

    setBlocks(filter)
    updateUserRedux({
      blocks: filter
    })

    toast.success("Bloque eliminado satisfactoriamente")
    onClickCancel()
  }

  return (
    <div>
      <div className="px-6 py-4">
        <form className="mb-4">
          <LabelRequired
            className="mb-2"
            title="URL"
            isRequired={true}
          />

          <Input
            placeholder="https://examplestore.myshopify.com"
            name="url"
            onChange={handleOnChangeURL}
            value={value}
            disabled={fetching}
            error={!!errorMessage}
          />

          {
            errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
          }
        </form>

        <Preview
          {...shopifyProduct}
        />
      </div>

      <FormFooter>
        {
          mode === "edit" ? (
            <SecondaryButton
              variant={variantType.red}
              title="Eliminar" 
              onClick={handleOnClickRemove}
              loading={fetching}
            />
          ) : (
            <WhiteButton
              type="button"
              title="Cancelar"
              onClick={onClickCancel}
            />

          )
        }

        <BasicButton
          type="button"
          title={mode === "edit" ? "Guardar Cambios" : "Añadir"}
          disabled={!isValid || fetching || (!shopifyProduct.title && !shopifyProduct.image && !shopifyProduct.price && !shopifyProduct.currency) || !!errorMessage || (mode === "edit" && initialValue.url === value)}
          onClick={mode === "edit" ? handleOnClickUpdate : handleOnClickAdd}
        />
      </FormFooter>
    </div>
  )
}

export default AddShopifyProductForm
// Helpers
import { DuplicateIcon } from '@heroicons/react/outline';
import Tippy from '@tippyjs/react';
import { classNames } from 'helpers/classNames';

enum Input {
  text = 'text',
  textArea = 'textArea'
}

interface IStaticInputProps {
  className?: string;
  value: string;
  type?: keyof typeof Input;
  display?: boolean;
}

const StaticInput: React.FC<IStaticInputProps> = ({
  className,
  value,
  type = 'text',
  display
}) => {
  return (
    <div
      className={classNames(
        className,
        type === 'textArea' && 'min-h-[116px]',
        display && 'flex truncate',
        'w-full rounded-md border border-gray-300 bg-gray-50 py-2 px-3 text-gray-800 '
      )}
    >
      {value}
      {display && (
        <Tippy
          content="¡Enlace copiado!"
          trigger="click"
          interactive={false}
          interactiveBorder={20}
          className="rounded bg-gray-800 p-1 text-white opacity-70"
          hideOnClick={false} // if you want
          onShow={(instance) => {
            setTimeout(() => {
              instance.hide();
            }, 1000);
          }}
        >
          <button onClick={() => navigator.clipboard.writeText(`${value}`)}>
            <DuplicateIcon className="ml-1 h-5 w-5 text-gray-500 hover:text-gray-700 " />
          </button>
        </Tippy>
      )}
    </div>
  );
};

export default StaticInput;

import { CommandAction, CommandActionHistory } from './types';

/** Class in charge of handling the logic that saves, obtains and defines the
 * rules on the command search history. Every time a user uses a CommandAction,
 * that action is saved in a history in LocalStorage as a future recommendation
 * for the user.
 */
export class RecentSearches {
  private static localStorageKey = 'command_searches_history';
  private static storedItemsLimit = 3;

  static updateLocalRecentSearches({
    actions,
    recentActions,
    selectedAction
  }: {
    actions: Array<CommandAction>;
    recentActions: Array<CommandAction>;
    selectedAction: CommandAction;
  }): Array<CommandAction> {
    const localStorage = this.getLocalRecentSearches();

    if (localStorage === null) {
      const list = [selectedAction];

      this.setLocalRecentSearches(list);
      return list;
    }

    let list = this.rehydrateList(actions, localStorage);

    const wasSearchedBefore = this.checkIfSelectedWasSearchedBefore(
      selectedAction,
      list
    );

    if (wasSearchedBefore) list = this.reorderSearchs(selectedAction, list);
    else list = this.addNewSearchItem({ recentActions, selectedAction });

    this.setLocalRecentSearches(list);

    return list;
  }

  private static checkIfSelectedWasSearchedBefore(
    selectedAction: CommandAction,
    currentList: Array<CommandAction>
  ) {
    return currentList.some((a) => a.name === selectedAction.name);
  }

  private static reorderSearchs(
    selectedAction: CommandAction,
    list: Array<CommandAction>
  ): Array<CommandAction> {
    let index = list.findIndex((a) => a.name === selectedAction.name);

    // This action currently exists in current history, so we only need to set
    // the selectedAction as the most recent.
    if (index > 0) {
      list.splice(index, 1);
      list = [selectedAction, ...list];
    }

    return list;
  }

  private static addNewSearchItem({
    recentActions,
    selectedAction
  }: {
    recentActions: Array<CommandAction>;
    selectedAction: CommandAction;
  }): Array<CommandAction> {
    // If the currentHistory limit will be excedeed, drop the last item.
    if (recentActions.length === this.storedItemsLimit) recentActions.pop();

    return [selectedAction, ...recentActions];
  }

  private static getLocalRecentSearches(): string | null {
    return localStorage.getItem(this.localStorageKey);
  }

  private static setLocalRecentSearches(list: Array<CommandAction>): void {
    const localSearches = this.mapListToHistory(list);

    localStorage.setItem(this.localStorageKey, JSON.stringify(localSearches));
  }

  static rehydrateList(
    actions: Array<CommandAction>,
    data: string
  ): Array<CommandAction> {
    const list: CommandActionHistory = JSON.parse(data);

    const keys = list.map((a) => a.key);
    const names = list.map((a) => a.name);

    return actions.filter(
      (a) => names.includes(a.name) || keys.includes(a.key)
    );
  }

  private static mapListToHistory(
    list: Array<CommandAction>
  ): CommandActionHistory {
    return list.map((a) => {
      return { key: a.key, name: a.name };
    });
  }
}

import BasicButton from 'components/tailwind/buttons/BasicButton';
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';

interface IStatusButtonProps {
  status?: 'pending' | 'done' | 'rejected';
  onClick?: () => void;
}

const StatusButton: React.FC<IStatusButtonProps> = (props) => {
  const { status, onClick, children } = props;

  if (status === 'pending') {
    return (
      <BasicButton
        title={children}
        className="cursor-not-allowed bg-opacity-100 hover:bg-primary active:ring-transparent"
        size="small"
      />
    );
  }

  if (status === 'done') {
    return (
      <SecondaryButton
        title={children}
        variant={variantType.gray}
        size="small"
        onClick={onClick}
      />
    );
  }

  if (status === 'rejected') {
    return (
      <BasicButton
        title={children}
        className="cursor-not-allowed bg-red-400 hover:bg-red-400 active:ring-transparent"
        size="small"
      />
    );
  }

  return null;
};

export default StatusButton;

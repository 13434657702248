// Helpers
import { classNames } from 'helpers/classNames';

interface IPurchaseCardProps {
  avatar: React.ReactElement;
  title?: React.ReactNode;
  subtitle: React.ReactNode;
  actions?: React.ReactElement;
  onClick?: () => void;
}

const PurchaseCard: React.FC<IPurchaseCardProps> = ({
  avatar,
  title,
  subtitle,
  actions,
  onClick
}) => {
  return (
    <div
      className={classNames(
        onClick && 'cursor-pointer',
        'flex items-center rounded-md border border-gray-200 px-5 py-4 shadow-sm md:py-5 md:px-6'
      )}
    >
      <div className="mr-3 hidden md:block">{avatar}</div>

      <div className="flex flex-col gap-3 md:ml-3" onClick={onClick}>
        {title}
        {subtitle}
      </div>

      <div className="ml-auto flex items-center">{actions}</div>
    </div>
  );
};

export default PurchaseCard;

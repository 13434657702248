// Types
import { IBlocks } from 'types/Block';
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

// Libraries
import { supabase } from 'utils/supabase-client';

type clickDay = {
  date: number;
  clicks: number;
};

type Clicks = {
  id: number;
  blockId: string;
  clicks: clickDay[];
};

interface IBlockClicks extends IBlocks {
  value: number;
}

interface IHandleDeleteBlockClick {
  block_id: string,
  last_title: string,
  last_url: string
}

// Helper functions
const getDatesBetween = (startDate: number, endDate: number) => {
  const dates: number[] = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate += 24 * 60 * 60 * 1000;
  }
  return dates;
};

const dateOptions = (period: string): DateTimeFormatOptions => {
  if (period == '30d') {
    return {
      day: 'numeric',
      month: 'numeric'
    };
  } else if (period == '7d') {
    return {
      weekday: 'long'
    };
  }
};

const getLinkBlocks = async (id: string) => {
  const { data, error } = await supabase
    .from('accounts')
    .select('blocks')
    .eq('id', id)
    .single();

  const linkBlocks: IBlocks[] = data.blocks.filter(
    (item) => item.type === 'link'
  );

  if (error) {
    console.log(error.message);
  }

  return linkBlocks;
};

const cleanClicksRowsIfPast30Days = async (clickRow: Clicks) => {
  const today = new Date(Date.now()).setHours(0, 0, 0, 0);
  const cleanedClicks = clickRow.clicks.filter(
    (click) => click.date > today - 30 * 24 * 60 * 60 * 1000
  );
  await supabase
    .from('clicks')
    .update({ clicks: cleanedClicks })
    .match({ blockId: clickRow.blockId });
};

// Primary functions
const addBlockClick = async (blockId: string, profileId: number) => {
  const { data, error } = await supabase
    .from('accounts')
    .select('blocks')
    .eq('id', profileId)
    .single();

  const blocksList: IBlocks[] = data.blocks;
  const blockUpdateId = blocksList.findIndex((block) => block.id == blockId);

  const lastClicks = blocksList[blockUpdateId].clicks;
  blocksList[blockUpdateId].clicks = lastClicks ? lastClicks + 1 : 1;

  const updateResponse = await supabase
    .from('accounts')
    .update({ blocks: blocksList })
    .eq('id', profileId);

  if (updateResponse.error) {
    console.log(error.message);
    throw error;
  }

  return 'OK';
};

const getUserAnalytics = async (id: string, period = '7d') => {
  const linkBlocks = await getLinkBlocks(id);
  const linkBlocksIds = linkBlocks.map((item) => item.id);
  const linkBlocksList = `(${linkBlocksIds.join(',')})`;

  const today = new Date(Date.now()).setHours(0, 0, 0, 0);

  const { data, error } = await supabase
    .from<Clicks>('clicks')
    .select()
    .or(`blockId.in.${linkBlocksList}`);

  if (error) {
    console.log(error.message);
  }

  if (new Date().getDate() === 1) {
    // We clean the clicks rows if it's the first day of the month
    data.forEach(async (clickRow) => {
      await cleanClicksRowsIfPast30Days(clickRow);
    });
  }

  let labels: string[];
  let values: number[];
  let totalValues: number = 0;

  const periodNumber = period == '30d' ? 29 : 6;
  const priorDate = new Date(today - periodNumber * 24 * 60 * 60 * 1000);
  const dates = getDatesBetween(priorDate.getTime(), today);

  const blockWithClicks: IBlockClicks[] = linkBlocks.map((item) => {
    const clickData = data.find((click) => click.blockId == item.id);

    if (!clickData) {
      handleCreateBlock(item.id);
      return { ...item, value: 0 };
    } else {
      // Count clicks from the period
      const clickDayFiltered = clickData.clicks.filter(
        (click) => click.date > priorDate.getTime()
      );
      const value = clickDayFiltered.reduce(
        (acc, click) => acc + click.clicks,
        0
      );
      return { ...item, value };
    }
  });

  labels = dates.map((date) =>
    new Date(date).toLocaleDateString('es-ES', dateOptions(period))
  );

  if (period == '7d') {
    labels = labels.map((date) => {
      return date.charAt(0).toUpperCase() + date.slice(1);
    });
  }

  values = dates.map((date) => {
    let clickCount = 0;
    data.forEach((clickRow) => {
      const clickDay = clickRow.clicks.find(
        (clickDay) => clickDay.date == date
      );
      clickCount += clickDay ? clickDay.clicks : 0;
    });
    totalValues += clickCount;
    return clickCount;
  });

  return { labels, values, totalValues, blockWithClicks };
};

const handleCreateBlock = async (blockId: string, customClicks = 0, creator_id = 0) => {
  const date = new Date(Date.now()).setHours(0, 0, 0, 0);

  const { error } = await supabase
    .from('theclicks')
    .insert({ blockId, clicks: [{ date: date, clicks: customClicks }], creator_id });

  if (error) {
    console.log(error.message);
  }

  return 'OK';
};

const handleBlockClick = async (blockId: string, creator_id: number) => {
  let todayDate = new Date(Date.now()).setHours(0, 0, 0, 0);

  const { data } = await supabase
    .from('theclicks')
    .select('clicks')
    .eq('blockId', blockId)
    .limit(1)
    .single();

  if (!data) {
    await handleCreateBlock(blockId, 1, creator_id);
  } else {
    let clickList: clickDay[] = data.clicks;
    let lastClickDay = clickList.pop();

    if (lastClickDay.date == todayDate) {
      lastClickDay.clicks += 1;
      clickList.push(lastClickDay);
    } else {
      clickList.push(lastClickDay, { date: todayDate, clicks: 1 });
    }

    await supabase
      .from('theclicks')
      .update({ clicks: clickList })
      .match({ blockId });
  }

  return 'OK';
};

const handleDeleteBlockClick = async ({ block_id, last_title, last_url }: IHandleDeleteBlockClick) => {
  await supabase
    .from("theclicks")
    .update({
      last_title,
      last_url
    })
    .match({ blockId: block_id })

    return true
}


export {
  getUserAnalytics,
  addBlockClick,
  handleCreateBlock,
  handleBlockClick,
  handleDeleteBlockClick
};

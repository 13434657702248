// @ts-nocheck
import TikTokPlayer_V2 from 'components/players/TikTokPlayer';
import { useFormik } from 'formik';
import { facebookShort_RegExp, tiktokShort_RegExp } from 'helpers/customRegExp';
import { matchHttp } from 'helpers/matchHttp';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import FacebookPlayer from 'react-player/facebook';
import TwitchVideoPlayer from 'react-player/twitch';
import YoutubeVideoPlayer from 'react-player/youtube';
import AxiosShortenerUrl from 'requests/local_next/AxiosShortenerUrl';
import FetchWebsiteTitle from "requests/local_next/FetchWebsiteTitle"
import { Mixpanel } from 'utils/mixpanel';
import { supabase } from 'utils/supabase-client';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import SpotifyPlayer_V2 from '../../../../players/SpotifyPlayer';
import TwitterPlayer from '../../../../players/TwitterPlayer';

// Components
import TwitchPlayerMedia from 'components/profile/blocks/media/TwitchPlayerMedia';
import YoutubePlayerMedia from 'components/profile/blocks/media/YoutubePlayerMedia';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import FacebookIcon from 'components/tailwind/icons/FacebookIcon';
import SpotifyIcon from 'components/tailwind/icons/SpotifyIcon';
import TiktokIcon from 'components/tailwind/icons/TiktokIcon';
import TwitchIcon from 'components/tailwind/icons/TwitchIcon';
import TwitterIcon from 'components/tailwind/icons/TwitterIcon';
import YoutubeIcon from 'components/tailwind/icons/YoutubeIcon';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import Input from 'components/tailwind/inputs/Input';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import SelectMenu from 'components/tailwind/inputs/SelectMenu';
// import Switch from 'components/tailwind/inputs/Switch';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';

const blockTypes = [
  {
    id: 1,
    name: (
      <span className="flex items-center">
        <YoutubeIcon className="mr-1 h-5 w-5" />
        YouTube
      </span>
    ),
    value: 'youtube'
  },
  {
    id: 2,
    name: (
      <span className="flex items-center">
        <SpotifyIcon className="mr-1 h-5 w-5" />
        Spotify
      </span>
    ),
    value: 'spotify'
  },
  {
    id: 4,
    name: (
      <span className="flex items-center">
        <TwitchIcon className="mr-1 h-5 w-5" />
        Twitch
      </span>
    ),
    value: 'twitch_live'
  },
  {
    id: 5,
    name: (
      <span className="flex items-center">
        <TiktokIcon className="mr-1 h-5 w-5" />
        TikTok
      </span>
    ),
    value: 'tiktok'
  },
  {
    id: 6,
    name: (
      <span className="flex items-center">
        <FacebookIcon className="mr-1 h-5 w-5" />
        Facebook
      </span>
    ),
    value: 'fbgaming'
  },
  {
    id: 7,
    name: (
      <span className="flex items-center">
        <TwitterIcon className="mr-1 h-5 w-5" />
        Twitter
      </span>
    ),
    value: 'twitter'
  }
];

enum playerPlatforms {
  youtube = YoutubeVideoPlayer,
  spotify = SpotifyPlayer_V2,
  twitch_live = TwitchVideoPlayer,
  tiktok = TikTokPlayer_V2,
  fbgaming = FacebookPlayer,
  twitter = TwitterPlayer
}

enum platformsUrl {
  youtube = 'www.youtube.com',
  spotify = 'open.spotify.com',
  twitch_live = 'www.twitch.tv',
  tiktok = 'www.tiktok.com',
  fbgaming = 'www.facebook.com/gaming',
  twitter = 'www.twitter.com'
}

const AddMediaForm = ({ closeModal, setBlocks, setLoading, handleClose }) => {
  const { user, updateUserRedux } = useUser();
  const [selected, setSelected] = useState(blockTypes[0]);
  const [errorSelectionMessage, setErrorSelectionMessage] = useState('');
  const [disableUrlInput, setDisableUrlInput] = useState<boolean>(false);
  const [onTop, setOnTop] = useState(true);

  Yup.addMethod(Yup.string, 'urlMatchPlatform', function (errorMessage) {
    return this.test(`test-card-type`, errorMessage, function (url) {
      const { path, createError } = this;

      return (
        playerPlatforms[selected.value].canPlay(url) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const formik: any = useFormik({
    initialValues: {
      title: '',
      url: ''
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('El título es requerido')
        .max(64, 'El título debe tener como máximo 64 carácteres'),
      url: Yup.string()
        .matches(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
          'URL inválida'
        )
        .required('La URL es requerida')
        .max(4000, 'La URL debe tener como máximo 4000 carácteres')
        .urlMatchPlatform(
          'La URL no es válida para el tipo de contenido seleccionado'
        )
    }),
    onSubmit: async (values) => {
      setLoading(true);
      if (
        selected.value === 'youtube' &&
        !YoutubeVideoPlayer.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      } else if (
        selected.value === 'spotify' &&
        !SpotifyPlayer_V2.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      } else if (
        selected.value === 'twitch_live' &&
        !TwitchVideoPlayer.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      } else if (
        selected.value === 'tiktok' &&
        !TikTokPlayer_V2.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      } else if (
        selected.value === 'fbgaming' &&
        !FacebookPlayer.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      } else if (
        selected.value === 'twitter' &&
        !TwitterPlayer.canPlay(values.url)
      ) {
        setLoading(false);
        return toast.error('La URL no es válida', defaultToastOptions);
      }

      const newBlock = {
        ...values,
        id: uuidv4(),
        visible: true,
        platform: selected.value,
        type: 'media'
      };

      const newBlocksPosition = onTop
        ? [newBlock, ...user.blocks]
        : [...user.blocks, newBlock];

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks: user.blocks ? newBlocksPosition : [newBlock]
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
        setLoading(false);
      }

      if (data) {
        Mixpanel.track('Added block', {
          username: user.username,
          type: 'media'
        });
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Añadido', defaultToastOptions);
        setLoading(false);
        handleClose();
      }
    }
  })

  useEffect(() => {
    const effectFunc = async () => {
      if (selected.value === 'tiktok' || selected.value === 'fbgaming') {
        if (
          tiktokShort_RegExp.test(formik.values.url) ||
          facebookShort_RegExp.test(formik.values.url)
        ) {
          setDisableUrlInput(true);

          const { error, data } = await AxiosShortenerUrl(formik.values.url);

          if (error) {
            toast.error(error, defaultToastOptions);
          } else {
            formik.values.url = data;
            formik.validateForm()
          }

          setDisableUrlInput(false);
        }
      }
    };

    effectFunc();
  }, [formik.values.url]);

  useEffect(() => {
    const effectFunc = async () => {
      const url = formik.values.url

      if (
        !YoutubeVideoPlayer.canPlay(url) &&
        !SpotifyPlayer_V2.canPlay(url)
      ) return

      const { error, data } = await FetchWebsiteTitle(url)

      if (error) {
        console.error(error)
        return
      }

      const theTitle = data.length > 60 ? data.slice(0, 61) + "..." : data

      formik.setFieldValue("title", theTitle || "No titulo")
    }

    effectFunc()
  }, [formik.values.url])

  const changeSelection = (e) => {
    setSelected(e);
    formik.resetForm();
    setErrorSelectionMessage('');
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormBody>
        <LabelRequired title="Tipo de bloque" isRequired={true} />
        <SelectMenu
          options={blockTypes}
          value={selected}
          onChange={(e) => changeSelection(e)}
        />
        {errorSelectionMessage && (
          <ErrorMessage errorText={errorSelectionMessage} />
        )}
        <div className="mt-4">
          <LabelRequired
            name="title"
            title="Título del bloque"
            isRequired={true}
          />
          <div className="mt-1">
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="Escribe el título"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={(formik.errors.title && formik.touched.title) as boolean}
            />
          </div>
          {formik.errors.title && formik.touched.title && (
            <ErrorMessage errorText={formik.errors.title as string} />
          )}
        </div>
        <div className="mt-4">
          <LabelRequired name="url" title="URL" isRequired={true} />
          <div className="mt-1">
            <Input
              type="text"
              name="url"
              id="url"
              placeholder={platformsUrl[selected.value]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.url}
              disabled={disableUrlInput}
              error={formik.errors.url && formik.touched.url}
            />
          </div>
          {formik.errors.url && formik.touched.url && (
            <ErrorMessage errorText={formik.errors.url} />
          )}
        </div>
        {!formik.values.url && (
          <div>
            <LabelRequired
              name="url"
              title="Vista previa"
              className="mt-4 mb-1"
            />
            <div
              className="relative inset-0 mb-2 flex items-center"
              aria-hidden="true"
            >
              <div className="flex h-24 w-full items-center justify-center rounded-md bg-gray-100">
                <span className="text-xs text-gray-500">
                  Al pegar tu enlace podrás previsualizarlo aquí
                </span>
              </div>
            </div>
          </div>
        )}
        {selected.value === 'youtube' &&
          YoutubeVideoPlayer.canPlay(matchHttp(formik.values.url)) && (
            <div className={'my-4'}>
              <YoutubePlayerMedia url={matchHttp(formik.values.url)} />
            </div>
          )}
        {selected.value === 'spotify' &&
          SpotifyPlayer_V2.canPlay(matchHttp(formik.values.url)) && (
            <div className={'my-4'}>
              <SpotifyPlayer_V2 url={matchHttp(formik.values.url)} />
            </div>
          )}
        {selected.value === 'twitch_live' &&
          TwitchVideoPlayer.canPlay(matchHttp(formik.values.url)) && (
            <div className={'my-4'}>
              <TwitchPlayerMedia url={matchHttp(formik.values.url)} />
            </div>
          )}

        {selected.value === 'tiktok' &&
          TikTokPlayer_V2.canPlay(matchHttp(formik.values.url)) && (
            <div className="my-4">
              <div className="flex justify-center">
                <TikTokPlayer_V2 url={matchHttp(formik.values.url)} size="xl" />
              </div>
            </div>
          )}

        {selected.value === 'fbgaming' &&
          FacebookPlayer.canPlay(matchHttp(formik.values.url)) && (
            <div className={'mt-4'}>
              <FacebookPlayer
                url={matchHttp(formik.values.url)}
                width="100%"
                height="100%"
              />
            </div>
          )}

        {selected.value === 'twitter' &&
          TwitterPlayer.canPlay(matchHttp(formik.values.url)) && (
            <div className="mt-4">
              <TwitterPlayer url={matchHttp(formik.values.url)} />
            </div>
          )}

        {/* //! Logic to add on Top or bottom of the block stack */}
        {/* <div>
          <Switch show={onTop} setShow={() => setOnTop(!onTop)} />
          <span>Logic to Add to top or bottom, true for top</span>
        </div> */}
      </FormBody>

      <FormFooter>
        <WhiteButton
          title="Cancelar"
          onClick={closeModal}
          disabled={formik.isSubmitting}
          className="px-6"
        />
        <BasicButton
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          loading={formik.isSubmitting}
          title="Añadir"
          className="w-40"
          loadingChildren="Añadiendo"
        />
      </FormFooter>
    </form>
  );
};

export default AddMediaForm;

// Libraries
import React, { useState } from 'react';

// Components
import Dropdown from 'components/tailwind/inputs/DropDown';

type MySubscriptionsHeaderRightProps = {
  setValue: (value: string | ((prev: string) => string)) => void;
};

const options = [
  { textContent: 'Suscripciones', value: 'subscriptions', defaultValue: true },
  { textContent: 'Wallets', value: 'wallets' }
];

const MySubscriptionsHeaderRight: React.FC<MySubscriptionsHeaderRightProps> = ({
  setValue
}) => {
  const [selected, setSelected] = useState(options[0]);

  const handleOnChange = (option) => {
    setValue(option.value);
    setSelected(option);
  };

  return (
    <Dropdown
      value={selected}
      onChange={(e) => handleOnChange(e)}
      options={options}
    />
  );
};

export default MySubscriptionsHeaderRight;

import { useEffect, useState } from 'react';
import { PauseIcon, PlayIcon, TrashIcon } from '@heroicons/react/solid';

import styles from './AudioPlayer.module.css';
import useAudioPlayer from './useAudioPlayer';

interface PlayerProps {
  audio: string | null;
  deleteAudio: () => void;
  onlyPlay?: boolean;
}

/**
 * @see - How was fixed using Player on Safari/Apple devices,
 * https://stackoverflow.com/questions/57504122/browser-denying-javascript-play
 */
const Player = (props: PlayerProps) => {
  const { audio, deleteAudio, onlyPlay } = props;

  const [error, setError] = useState<string>(null);

  const { error: playerError, isReady, player } = useAudioPlayer({ audio });

  useEffect(() => {
    setError(playerError);
  }, [playerError]);

  const parseTimer = (time: number) => {
    if (time === 0) return '0:00';

    let minutes = (time / 60).toFixed(0).toString();
    let seconds = (time % 60).toFixed(0).toString();

    if (minutes.length < 2) minutes = `${minutes}`;
    if (seconds.length < 2) seconds = `0${seconds}`;

    return `${minutes}:${seconds}`;
  };

  const onClickPlay = () => {
    if (isReady) player.play();
  };

  const onClickPause = () => {
    if (isReady) player.pause();
  };

  const onClickDelete = () => {
    if (isReady) player.restart();

    deleteAudio();
  };

  const onChangeSlider = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    player.changeCurrent(value);
  };

  if (error) return <div>{error}</div>;

  return (
    <div
      className={
        'flex w-full flex-row items-center rounded-full bg-gray-100 px-4'
      }
    >
      <audio id={'player'}>
        <source src={audio} type={'audio/aac'} />
      </audio>
      <div className={'my-2 flex items-center'}>
        {player.isPlaying === false && (
          <button onClickCapture={onClickPlay}>
            <PlayIcon
              className={'h-12 w-12 text-primary hover:text-primary-800'}
            />
          </button>
        )}
        {player.isPlaying === true && (
          <button onClickCapture={onClickPause}>
            <PauseIcon
              className={'h-12 w-12 text-primary hover:text-primary-800'}
            />
          </button>
        )}
      </div>
      <div className={'m-2 flex flex-1 items-center gap-2'}>
        <input
          className={`${styles.slider} h-6 w-full appearance-none p-0`}
          max={player.duration}
          min={0}
          step={0.1}
          type={'range'}
          value={player.current}
          onChange={(e) => onChangeSlider(e)}
        />
        <div className="text-lg font-medium text-gray-700">
          {player.isPlaying === true ? (
            <div>{parseTimer(player.current)}</div>
          ) : (
            <div>{parseTimer(player.duration)}</div>
          )}
        </div>
      </div>
      {onlyPlay !== true && (
        <div className={'my-2 flex items-center'}>
          <button
            onClick={onClickDelete}
            disabled={!isReady}
            className="rounded-full p-2 text-gray-400 transition-all hover:text-gray-500"
          >
            <TrashIcon className="h-8 w-8" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Player;

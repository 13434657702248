import React from 'react';

// Types
import { collectionSocialMediaType } from 'types/Web3';

// Components icons
import {
  GrInstagram,
  GrTwitter,
  GrYoutube,
  GrFacebook,
  GrSpotify,
  GrSnapchat
} from 'react-icons/gr';
import { FaTwitch, FaTiktok } from 'react-icons/fa';
import OpenseaIcon from 'components/tailwind/icons/OpenseaIcon';
import ClubhouseIcon from 'components/tailwind/icons/ClubhouseIcon';
import { GlobeAltIcon } from '@heroicons/react/solid';

interface ISocialIconProps {
  icon: collectionSocialMediaType;
  className?: string;
}

const SocialIcon = ({ icon, className }: ISocialIconProps) => {
  const classNameIcon: string = `h-5 w-5 ${className}`;

  // if (icon === 'instagram') return <GrInstagram className={classNameIcon} />;
  if (icon === 'twitter') return <GrTwitter className={classNameIcon} />;
  /* else if (icon === 'youtube') return <GrYoutube className={classNameIcon} />;
  else if (icon === 'facebook') return <GrFacebook className={classNameIcon} />;
  else if (icon === 'spotify') return <GrSpotify className={classNameIcon} />;
  else if (icon === 'tiktok') return <FaTiktok className={classNameIcon} />;
  else if (icon === 'snapchat') return <GrSnapchat className={classNameIcon} />; */ else if (
    icon === 'opensea'
  )
    return <OpenseaIcon className={classNameIcon} />;
  /* else if (icon === 'clubhouse')
    return <ClubhouseIcon className={classNameIcon} />;
  else if (icon === 'twitch') return <FaTwitch className={classNameIcon} />; */ else if (
    icon === 'website'
  )
    return <GlobeAltIcon className={classNameIcon} />;
  else return null;
};

export default SocialIcon;

// Helpers
import { classNames } from 'helpers/classNames';

type InputWithAddOnProps = {
  type?: 'text' | 'password' | 'email' | 'number';
  id?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  value: string;
  className?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  maxLength?: number;
  addOn?: string;
};

const InputWithAddOn: React.FC<InputWithAddOnProps> = ({
  type = 'text',
  id,
  name,
  onChange,
  onBlur,
  onKeyUp,
  onFocus,
  value,
  className,
  placeholder,
  error = false,
  disabled = false,
  maxLength,
  addOn = 'mis.fans/'
}) => {
  return (
    <div className={classNames(className, 'flex rounded-md shadow-sm')}>
      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
        {addOn}
      </span>
      <input
        type={type}
        name={name}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        value={value}
        disabled={disabled}
        className={classNames(
          error && 'border-red-500',
          'block w-full min-w-0 flex-1 rounded-none rounded-r-md border border-gray-300 px-3 py-2 text-gray-800 transition  duration-200 ease-in-out focus:border-primary focus:ring-primary active:text-gray-600 disabled:cursor-not-allowed disabled:opacity-50'
        )}
        placeholder={placeholder}
        maxLength={maxLength}
        onFocus={onFocus}
      />
    </div>
  );
};

export default InputWithAddOn;

import { useFormik } from 'formik';
import { classNames } from 'helpers/classNames';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import React from 'react';
import toast from 'react-hot-toast';
import { supabase } from 'utils/supabase-client';
import * as Yup from 'yup';

// Components
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import Input from 'components/tailwind/inputs/Input';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';

const EditDividerForm = ({
  selectedBlock,
  initialValues,
  closeModal,
  setBlocks,
  setLoading
}) => {
  const { user, updateUserRedux } = useUser();

  const formik: any = useFormik({
    initialValues,
    validationSchema: Yup.object({
      title: Yup.string()
        .required('El título del separador es obligatorio')
        .max(64, 'El título debe tener como máximo 64 carácteres')
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const blocks = user.blocks.reduce((acc, block) => {
        if (block.id === selectedBlock.id) {
          acc.push({
            ...values,
            id: block.id,
            visible: block.visible,
            type: block.type
          });
        } else {
          acc.push(block);
        }
        return acc;
      }, []);

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
        setLoading(false);
      }

      if (data) {
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Editado', defaultToastOptions);
        closeModal();
        setLoading(false);
      }
    }
  });

  const handleOnDeleteBlock = async () => {
    const confirmed = confirm(
      '¿Estás seguro de que quieres eliminar este bloque?'
    );

    if (confirmed) {
      setLoading(true);
      const blocks = user.blocks.reduce((acc, block) => {
        if (block.id !== selectedBlock.id) {
          acc.push(block);
        }
        return acc;
      }, []);

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
      }

      if (data) {
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Eliminado', defaultToastOptions);
      }

      setLoading(false);
      closeModal();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormBody>
        <LabelRequired
          isRequired={true}
          name="title"
          title="Título del bloque"
        />
        <div className="mt-1">
          <Input
            type="text"
            name="title"
            id="title"
            placeholder="Escribe el título"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            error={formik.errors.title && formik.touched.title}
          />
        </div>
        {formik.errors.title && formik.touched.title && (
          <ErrorMessage errorText={formik.errors.title} />
        )}
      </FormBody>

      <FormFooter>
        <SecondaryButton
          title="Eliminar"
          onClick={handleOnDeleteBlock}
          disabled={formik.isSubmitting}
          className="px-6"
          variant={variantType.red}
        />
        <BasicButton
          type="submit"
          title="Guardar cambios"
          disabled={!formik.isValid || !formik.dirty}
          loading={formik.isSubmitting}
          className="w-40 whitespace-nowrap"
          loadingChildren={'Guardando'}
        />
      </FormFooter>
    </form>
  );
};

export default EditDividerForm;

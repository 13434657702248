type IconProps = {
  className?: string;
};

const CopyRegularIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={className}
    >
      <path d="M17.6695 2.48309L15.5162 0.329766C15.307 0.118512 15.0187 0 14.7199 0H8.96835C7.72557 0 6.71835 1.00758 6.71835 2.25L6.71903 11.25C6.74999 12.4945 7.75897 13.5 8.99999 13.5H15.75C16.9875 13.5 18 12.4875 18 11.25V3.27832C18 2.9802 17.8805 2.69402 17.6695 2.48309ZM16.3125 11.25C16.3125 11.5606 16.0606 11.8125 15.75 11.8125H8.96835C8.65764 11.8125 8.40585 11.5606 8.40585 11.25V2.25457C8.40585 1.94393 8.65771 1.69207 8.96835 1.69207H13.4684L13.5 3.375C13.5 3.99621 14.0038 4.5 14.625 4.5H16.2809V11.25H16.3125ZM9.56249 15.75C9.56249 16.0606 9.31063 16.3125 8.99999 16.3125H2.21835C1.90763 16.3125 1.65585 16.0606 1.65585 15.75L1.68678 6.75352C1.68678 6.44288 1.93864 6.19102 2.24928 6.19102H5.62499V4.5H2.24963C1.00686 4.5 -0.000366211 5.50723 -0.000366211 6.75L-2.16795e-05 15.75C5.56642e-05 16.991 1.00756 18 2.24999 18H8.99999C10.2375 18 11.25 16.9875 11.25 15.75V14.625H9.59413L9.56249 15.75Z" />
    </svg>
  );
};

export default CopyRegularIcon;

// Libraries
import { useEffect, useState } from 'react';

// Types
import { ScreenType } from 'types/screenType';

// Hooks
import { useUser } from 'hooks/useUser';

const useScreen = () => {
  const { user } = useUser();

  const [screen, setScreen] = useState<keyof typeof ScreenType>(
    user.is_verified ? 'blocks' : 'notifications'
  );
  const [action, setAction] = useState<any>(null);

  return {
    screen,
    setScreen
  };
};

export default useScreen;

import { useState } from 'react';

import AudioPlayer from 'components/audioPlayer';
import { AudioUploader } from 'components/audioUploader';

enum AudioAction {
  uploader = 'uploader',
  player = 'player'
}

interface AudioActionsProps {
  audioFile: File;
  cleanStates: () => void;
  setAudioFile: (file: File) => void;
  setAudioUrl: (audio: string) => void;
}

export const AudioActions = ({
  audioFile,
  cleanStates,
  setAudioFile,
  setAudioUrl
}: AudioActionsProps) => {
  const [activeAction, setActiveAction] = useState<AudioAction | null>(null);

  const setActionUploader = () => {
    setActiveAction(AudioAction.uploader);
  };

  const setActionPlayer = () => {
    setActiveAction(AudioAction.player);
  };

  const resetState = () => {
    setActiveAction(null);
    cleanStates();
  };

  return (
    <div className="flex flex-col gap-2">
      {(activeAction === null || activeAction === AudioAction.player) && (
        <AudioPlayer
          setAudioUrl={setAudioUrl}
          resetState={resetState}
          setAction={setActionPlayer}
        />
      )}
      {(activeAction === null || activeAction === AudioAction.uploader) && (
        <AudioUploader
          file={audioFile}
          resetState={resetState}
          setAction={setActionUploader}
          setFile={setAudioFile}
        />
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

// Components
import NotificationWithActions from 'components/tailwind/overlays/NotificationWithActions';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';

// Helpers
import imageKitUpload from 'helpers/imageKitUpload';
import ReactPortal from 'helpers/ReactPortal';

// Axios
import AxiosUpdateUserSettings from 'requests/local_api/AxiosUpdateUserSettings';
import AxiosGetImageBlurHash from 'requests/local_api/others/AxiosGetImageBlurHash';

// Hooks
import { useUser } from 'hooks/useUser';
import useWindow from 'hooks/useWindowDimensions';

interface ISaveChangesProps {
  userHaveChanges: boolean;
  setUserHaveChanges: (value: boolean | ((prev: boolean) => void)) => void;
}

const SaveChanges = ({
  userHaveChanges,
  setUserHaveChanges
}: ISaveChangesProps) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const { user, userEdit, userEditSettingsFields, updateUserRedux } = useUser();
  const { width } = useWindow();

  useEffect(() => {
    const objectEntries = Object.entries(userEdit);

    let userHaveChanged: boolean = false;

    for (let i = 0; i < objectEntries.length; i++) {
      const [key, value] = objectEntries[i];

      if (user[key] === undefined) continue;
      else if (key === 'cover_picture' || key === 'profilePicture') {
        if (user[key] !== value.url) {
          userHaveChanged = true;
          break;
        }
        continue;
      } else if (key === 'social_medias') {
        const stringifyEdit = JSON.stringify(userEdit[key]);
        const stringifyUser = JSON.stringify(user[key]);

        if (stringifyEdit !== stringifyUser) {
          break;
        }
        continue;
      } else if (key === 'stream_alerts_cfg') {
        const objectEntriesMain = Object.entries(value);

        for (let j = 0; j < objectEntriesMain.length; j++) {
          const [objectAlertKey, objectAlertValue] = objectEntriesMain[j];
          const objectEntriesStream = Object.entries(objectAlertValue);
          for (let k = 0; k < objectEntriesStream.length; k++) {
            const [keyStream, valueStream] = objectEntriesStream[k];
            if (valueStream !== user[key][objectAlertKey][keyStream]) {
              userHaveChanged = true;
              break;
            }
          }
        }
        continue;
      } else if (key === 'customProfile') {
        const stringifyEdit = JSON.stringify(userEdit[key]);
        const stringifyUser = JSON.stringify(user[key]);

        if (stringifyEdit !== stringifyUser) {
          userHaveChanged = true;
          break;
        }
        continue;
      }

      if (value !== user[key]) {
        userHaveChanged = true;
        break;
      }
    }

    setUserHaveChanges(userHaveChanged);
  }, [user, userEdit]);

  const handleOnClickSave = async () => {
    let profile_picture_url: string = userEdit.profilePicture.url;
    let cover_picture_url: string = userEdit.cover_picture.url;
    let cover_picture_blurhash: string = userEdit.cover_picture_blurhash;

    const regex = /^[a-zA-Z0-9_]+$/;
    const isValid = regex.test(userEdit.username);
    if (!isValid) {
      return toast.error(
        'El nombre de usuario no es válido. Solo se permiten caracteres alfanuméricos, guiones bajos y números.'
      );
    } else if (userEdit.username.length < 3) {
      return toast.error(
        'El nombre de usuario debe tener al menos 3 caracteres.'
      );
    }

    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(userEdit.email)) {
      return toast.error('El correo electrónico no es válido.');
    }

    setFetching(true);

    if (userEdit.profilePicture.blob) {
      const { error, data }: any = await imageKitUpload({
        blob: userEdit.profilePicture.blob,
        extension: 'jpg'
      });

      if (error) {
        toast.error(error);
        profile_picture_url = user.profilePicture;
      } else profile_picture_url = data.url;
    }

    if (userEdit.cover_picture.blob) {
      const { error, data }: any = await imageKitUpload({
        blob: userEdit.cover_picture.blob,
        extension: 'jpg'
      });

      if (error) {
        cover_picture_url = user.cover_picture;
        toast.error(error);
      } else {
        cover_picture_blurhash = await AxiosGetImageBlurHash(data.url);
        cover_picture_url = data.url;
      }
    }

    const isNewEmail = user.email !== userEdit.email;

    isNewEmail && toast('Se enviará un correo para confirmar tu nuevo correo.');

    const { error } = await AxiosUpdateUserSettings({
      username: userEdit.username,
      name: userEdit.name,
      email: userEdit.email,
      isNewEmail,
      biography: userEdit.biography,
      profilePicture: profile_picture_url,
      cover_picture: cover_picture_url,
      cover_picture_blurhash: cover_picture_blurhash,
      social_medias: userEdit.social_medias,
      stream_alerts_cfg: userEdit.stream_alerts_cfg,
      customProfile: userEdit.customProfile
    });

    if (error) {
      toast.error(error);
    } else {
      const theData: any = {};

      Object.entries(userEdit).forEach((arr) => {
        const [key, value] = arr;

        if (key === 'profilePicture' || key === 'cover_picture') {
          theData[key] = value.url;
        } else theData[key] = value;
      });

      updateUserRedux(theData);
      toast.success('Datos guardados correctamente!');
    }

    setFetching(false);
  };

  const handleOnClickCancel = () => {
    userEditSettingsFields({
      username: user.username,
      name: user.name,
      email: user.email,
      biography: user.biography,
      profilePicture: {
        url: user.profilePicture,
        blob: null
      },
      cover_picture: {
        url: user.cover_picture,
        blob: null
      },
      social_medias: user.social_medias,
      customProfile: user.customProfile
    });
  };

  return (
    <div className="flex flex-col">
      <BasicButton
        className="mb-3 w-full"
        type="button"
        title="Guardar cambios"
        onClick={handleOnClickSave}
        loading={fetching}
        disabled={!userHaveChanges}
        loadingChildren={'Guardando'}
      />
      {userHaveChanges && (
        <>
          <WhiteButton
            type="button"
            title="Cancelar"
            onClick={handleOnClickCancel}
            disabled={fetching}
          />
          {width > 768 && (
            <ReactPortal wrapperId="notifications-portal">
              <NotificationWithActions
                title="Tienes cambios sin guardar"
                description="Guarda tus cambios haciendo click en el botón de guardar cambios"
                actions={
                  <BasicButton
                    title="Guardar cambios"
                    onClick={handleOnClickSave}
                    loading={fetching}
                    size="mini"
                    className="mt-2 min-w-[106px] max-w-fit"
                    loadingChildren={'Guardando'}
                  />
                }
                show={userHaveChanges}
                setShow={() => console.log('No action')}
                hideCloseIcon={true}
              />
            </ReactPortal>
          )}
        </>
      )}
    </div>
  );
};

export default SaveChanges;

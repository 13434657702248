// Libraries
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';

// Components
import CircleBulletLoader from 'components/tailwind/loaders/CircleBulletLoader';

// Helpers
import { classNames } from 'helpers/classNames';
import { sleep } from 'utils/functionHelpers';

enum STATUS {
  COMPLETE = 'COMPLETE',
  CURRENT = 'CURRENT',
  UPCOMING = 'UPCOMING'
}

type LinktreeStep = {
  name: string;
  status: STATUS;
};

type LinktreeImportStepsProps = {
  className?: string;
  success: boolean;
};

const linktreeStepsDefault: LinktreeStep[] = [
  { name: 'Tu nombre', status: STATUS.CURRENT },
  { name: 'Imagen de perfil', status: STATUS.UPCOMING },
  { name: 'Imagen de portada', status: STATUS.UPCOMING },
  { name: 'Redes sociales', status: STATUS.UPCOMING },
  { name: 'Enlaces', status: STATUS.UPCOMING }
];

const TIME_PER_STEP = 1500;

const LinktreeImportSteps: React.FC<LinktreeImportStepsProps> = ({
  className,
  success
}) => {
  const [linktreeSteps, setLinktreeSteps] = useState(linktreeStepsDefault);

  const updateLinktreeSteps = (index: number, status: STATUS) => {
    const newLinktreeSteps = [...linktreeSteps];
    newLinktreeSteps[index].status = status;

    setLinktreeSteps(newLinktreeSteps);
  };

  const handleLastStep = async () => {
    if (success) {
      updateLinktreeSteps(linktreeSteps.length - 1, STATUS.COMPLETE);
    } else {
      if (linktreeSteps[linktreeSteps.length - 2].status === STATUS.COMPLETE) {
        updateLinktreeSteps(linktreeSteps.length - 1, STATUS.CURRENT);
      }
    }
    setLinktreeSteps(linktreeStepsDefault);
  };

  useEffect(() => {
    handleLastStep();
  }, [success]);

  useEffect(() => {
    const asyncUpdateLinktreeSteps = async () => {
      for (let i = 0; i < linktreeSteps.length; i++) {
        if (i === linktreeSteps.length - 1) {
          await handleLastStep();
          return;
        }
        updateLinktreeSteps(i, STATUS.CURRENT);
        await sleep(TIME_PER_STEP);
        updateLinktreeSteps(i, STATUS.COMPLETE);
      }
    };
    asyncUpdateLinktreeSteps();
  }, []);

  return (
    <ul className={classNames('grid grid-cols-2', className)}>
      {linktreeSteps.map((linktreeStep, index) => {
        return (
          <li className="flex items-center" key={index}>
            {linktreeStep.status === STATUS.COMPLETE ? (
              <CheckCircleIcon
                className="h-4 w-4 text-green-500"
                aria-hidden="true"
              />
            ) : (
              <CircleBulletLoader
                width="20"
                height="20"
                animate={linktreeStep.status === STATUS.CURRENT && true}
              />
            )}
            <p
              className={classNames(
                'ml-1 text-sm',
                linktreeStep.status === STATUS.COMPLETE
                  ? 'text-gray-400'
                  : 'text-gray-300'
              )}
            >
              {linktreeStep.name}
            </p>
          </li>
        );
      })}
    </ul>
  );
};

export default LinktreeImportSteps;

// Libraries
import React from 'react';

// Hooks
import { useUser } from 'hooks/useUser';

// Components
import AvatarWithText from 'components/tailwind/avatars/AvatarWithText';

const DK_USER2 = () => {
  const { user } = useUser();
  const hexagonShape =
    user.is_verified && user.linked_accounts.phantom.addr.length > 0;

  return (
    <a
      href={`/${user.username.toLowerCase()}`}
      target="_blank"
      className="group flex cursor-pointer items-center justify-between border-b border-gray-200 px-4 pb-4"
    >
      <AvatarWithText
        username={user.username}
        name={user.name}
        profilePicture={user.profilePicture}
        hexagonShape={hexagonShape}
        theme={user.customProfile?.theme === 'pride' ? 'pride' : 'default'}
      />
    </a>
  );
};

export default DK_USER2;

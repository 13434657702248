import theImageKit from "imagekit-javascript";
import servicesCfg from "config/servicesCfg";

const {IMAGE_KIT: {PUBLIC_KEY, URL_ENDPOINT, AUTH_URL}} = servicesCfg

const imageKit = new theImageKit({
    publicKey: PUBLIC_KEY,
    urlEndpoint: URL_ENDPOINT,
    authenticationEndpoint: AUTH_URL
});

export default imageKit;
import { getCookie } from 'helpers/handleCookies';
import { AxiosApi } from '../../AxiosBase';

export const AxiosGetSuggestions = async () => {
  const userToken = getCookie('token');

  if (!userToken) return { error: 'No estas logeado' };

  try {
    const { data } = await AxiosApi.get('/explore/suggestions', {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    if (data.message !== 'OK') return { error: data.message };

    return {
      data: data.data
    };
  } catch (e) {
    console.log(e);
    console.log('AxiosGetSuggestions() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

export const AxiosSearchUser = async (q: string) => {
  if (!q) return { error: 'No hay valor en el campo' };
  else if (q.length < 2)
    return {
      error: 'Para buscar a un creador debe contener almenos 2 caracteres'
    };

  try {
    const { data } = await AxiosApi.post(
      '/explore/search-user',
      { q },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (data.message !== 'OK') return { error: data.message };

    return {
      data: data.data
    };
  } catch (e) {
    console.log(e);
    console.log('AxiosSearchUser() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

export const AxiosSearchByTag = async (tagId: number) => {
  if (!tagId) return { error: 'Debes seleccionar un tipo de creador' };

  try {
    const { data } = await AxiosApi.post(
      '/explore/search-by-tag',
      { tagId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (data.message !== 'OK') return { error: data.message };

    return {
      data: data.data
    };
  } catch (e) {
    console.log(e);
    console.log('AxiosSearchByTag() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

const FetchGetProductData = async (url: string) => {
  try {
    const data = await fetch(
      "/api/gloset/info",
      {
        method: "POST",
        body: JSON.stringify({ url }),
        headers: {
          "Content-Type": "application/json"
        },
      }
    )
      .then(res => res.json())

    if (data.message !== "OK") return { error: data.message }

    return {
      data: data.data
    }
  }

  catch(e) {
    console.error(e)
    console.error("FetchGetProductData() Error")
    return { error: "Error al conectarse con el servidor" }
  }

}

export default FetchGetProductData
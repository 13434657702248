// Helpers
import { isProfileImageHexagon } from 'helpers/logicFunctions';
const dayjs = require('dayjs');
dayjs.locale(require('utils/dayjsCustomPlugins/es'));
dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('dayjs/plugin/isYesterday'));
dayjs.extend(require('utils/dayjsCustomPlugins/isBeforeYesterday'));

// Components
import Avatar from 'components/tailwind/avatars/Avatar';

interface ISubscriptionCardProps {
  notification: any;
}

const SubscriptionCard: React.FC<ISubscriptionCardProps> = ({
  notification
}) => {
  return (
    <div className="mt-5 block rounded-md">
      <div className="">
        <div className="flex justify-between">
          <div className="">
            <div className="flex items-start text-base text-gray-500">
              <Avatar
                profilePicture={notification.fan_data?.profilePicture}
                shape={
                  isProfileImageHexagon(notification.fan_data)
                    ? 'hexagon'
                    : 'circle'
                }
                size="sm"
                theme={
                  notification.fan_data?.customProfile?.theme === 'pride'
                    ? 'pride'
                    : 'default'
                }
              />
              <div className="ml-3">
                <p className="mb-1 truncate text-base text-gray-500 sm:w-96">
                  {dayjs(notification.created).isToday() ? (
                    <>
                      Hoy{' '}
                      <span className="text-sm italic">
                        {dayjs(notification.created).format('HH:mm')}
                      </span>
                    </>
                  ) : dayjs(notification.created).isYesterday() ? (
                    <>
                      Ayer{' '}
                      <span className="text-sm italic">
                        {dayjs(notification.created).format('HH:mm')}
                      </span>
                    </>
                  ) : dayjs(notification.created).isBeforeYesterday() ? (
                    <>
                      Antier{' '}
                      <span className="text-sm italic">
                        {dayjs(notification.created).format('HH:mm')}
                      </span>
                    </>
                  ) : (
                    <>
                      {dayjs(notification.created).format('DD [de] MMMM')}{' '}
                      <span className="text-sm italic">
                        {dayjs(notification.created).format('HH:mm')}
                      </span>
                    </>
                  )}
                </p>
                <div className="mb-1 text-gray-700">
                  <a
                    className={`font-semibold ${
                      notification.fan_data?.username
                        ? 'cursor-pointer text-primary underline'
                        : ''
                    }`}
                    href={
                      notification.fan_data?.username
                        ? `/${notification.fan_data?.username}`
                        : '#'
                    }
                    target={
                      notification.fan_data?.username ? '_blank' : '_self'
                    }
                  >
                    {notification?.fan_data?.username || 'un fan'}
                  </a>{' '}
                  se ha suscrito por
                  <a className="font-semibold text-mf-purple"> $5 USD</a>
                </div>
              </div>
            </div>
            <div className="ml-[2.7rem] mt-2 flex items-center sm:mt-0"></div>
          </div>
        </div>
      </div>
      <div className="mt-5 h-divider w-full bg-gray-300"></div>
    </div>
  );
};

export default SubscriptionCard;

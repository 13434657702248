// Components
import InputWithAddOn from 'components/tailwind/inputs/InputWithAddOn';
import Label from 'components/tailwind/inputs/Label';

// Hooks
import { useUser } from 'hooks/useUser';
import useApp from 'hooks/useApp';

// Request
import AxiosUpdateUserSettings from 'requests/local_api/AxiosUpdateUserSettings';

// Helpers
import { classNames } from 'helpers/classNames';

// Libraries
import { toast } from 'react-hot-toast';
import { Fragment, useState } from 'react';

const socialSorts = [
  'facebook',
  'instagram',
  'twitter',
  'youtube',
  'twitch',
  'opensea',
  'clubhouse',
  'spotify',
  'tiktok',
  'snapchat'
  //'pinterest'
];

const SocialMediaInputs = () => {
  const { userEdit, userEditSettingsFields, updateUserRedux } = useUser();
  const [socialName, setSocialName] = useState<string>('');
  const {
    app: { isProfileViewerOpen }
  } = useApp();

  const updateSocialMedia = async () => {
    const { error } = await AxiosUpdateUserSettings({
      social_medias: userEdit.social_medias
    });
    if (error) {
      toast.error(error);
    } else {
      const theData: any = {};

      Object.entries(userEdit).forEach((arr) => {
        const [key, value] = arr;

        if (key === 'profilePicture' || key === 'cover_picture') {
          theData[key] = value.url;
        } else theData[key] = value;
      });

      updateUserRedux(theData);
      toast.success(`${socialName} actualizado!`);
    }
  };

  let timer: number;
  const timeoutVal = 1000;

  const handleKeyUp: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = () => {
    window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
    timer = window.setTimeout(() => {
      updateSocialMedia();
    }, timeoutVal);
  };

  const handleOnChangeInputs: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    window.clearTimeout(timer);
    const targetName = e.target.name;
    const targetValue = e.target.value;
    setSocialName(targetName[0].toUpperCase() + targetName.substring(1));

    userEditSettingsFields({
      social_medias: {
        ...userEdit.social_medias,
        [targetName]: targetValue
      }
    });
  };

  return (
    <div
      className={classNames(
        isProfileViewerOpen ? 'lg:grid-cols-1' : 'lg:grid-cols-2',
        'grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-2'
      )}
    >
      {socialSorts.map((value, index) => {
        const social = userEdit.social_medias[value.toLowerCase()];

        if (social === undefined) {
          userEditSettingsFields({
            social_medias: {
              ...userEdit.social_medias,
              [value.toLowerCase()]: ''
            }
          });
        }

        return (
          <div>
            <Label
              title={value[0].toUpperCase() + value.substring(1)}
              className="mb-1"
            />
            <InputWithAddOn
              key={index}
              name={value.toLowerCase()}
              placeholder=""
              onChange={handleOnChangeInputs}
              onKeyUp={handleKeyUp}
              value={social}
              addOn={`${value}.${value === 'opensea' ? 'io' : 'com'}/`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SocialMediaInputs;

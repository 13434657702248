import { createApi } from 'unsplash-js';
import * as nodeFetch from 'node-fetch';
import React, { FC, Fragment, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Oval } from 'react-loader-spinner';
import { XIcon } from '@heroicons/react/outline';
import InputForGiphy from 'components/tailwind/inputs/InputForGiphy';
import { classNames } from 'helpers/classNames';
import InputWithLeadingIcon from 'components/tailwind/inputs/InputWithLeadingIcon';
import { HiSearch } from 'react-icons/hi';

type Photo = {
  id: number;
  width: number;
  height: number;
  urls: { large: string; regular: string; raw: string; small: string };
  color: string | null;
  user: {
    username: string;
    name: string;
  };
};

const api = createApi({
  // Don't forget to set your access token here!
  // See https://unsplash.com/developers
  accessKey: 'i3ly2MwAUJCpk569WW8cNAZuvacK0fCzFVhRSgnj79g'
});

interface IPhotoComp {
  photo: Photo;
  setUrl: (url: string) => void;
  openPicker?: boolean;
  setOpenPicker?: (openPicker: boolean) => void;
}

const PhotoComp: React.FC<IPhotoComp> = ({
  photo,
  setUrl,
  openPicker,
  setOpenPicker
}) => {
  const { user, urls } = photo;

  return (
    <Fragment>
      <div onClick={() => setUrl(`${urls.regular}`)}>
        <img className="img cursor-pointer" src={urls.regular} />
      </div>
    </Fragment>
  );
};

const Body: React.FC<IUnsplashProps> = ({
  setUrl,
  modal,
  openPicker,
  setOpenPicker
}) => {
  const [query, setQuery] = useState('Gradient');
  const [photos, setPhotos] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>();
  const perPage = 9;

  function handleChange(evt) {
    setQuery(evt.target.value);
    setLoading(true);
    setPhotos([]);
  }

  const getMorePhotos = async (page: number) => {
    try {
      await api.search
        .getPhotos({
          query: query,
          page: page,
          perPage: perPage
        })
        .then((result) => {
          setTotal(result.response.total);
          setPage(page);
          photos.length
            ? setPhotos([...photos, ...result.response.results])
            : setPhotos(result.response.results);
        });
    } catch (err) {
      console.log('Unable to retrieve photos. Reason: ' + err);
    }
  };

  useEffect(() => {
    setPage(1);
    getMorePhotos(1);
  }, [query]);

  useEffect(() => {
    if (!openPicker) return;
    const handleOnClick = (e: any) => {
      let target = e.target;
      let reference = false;
      while (target) {
        if (target.classList.contains('clickAway')) {
          reference = true;
          break;
        }
        target = target.parentElement;
      }
      if (!reference) {
        setOpenPicker(false);
      }
    };
    window.addEventListener('click', handleOnClick);
    return () => {
      window.removeEventListener('click', handleOnClick);
    }
  }, [openPicker]);

  if (photos === null) {
    return <div>Loading...</div>;
  } else if (photos === null && !isLoading) {
    return (
      <div>
        <div>PS: Make sure to set your access token!</div>
      </div>
    );
  } else {
    return (
      <div className="relative z-50 flex flex-col">
        {openPicker && (
          <div className="clickAway">
            <div
              className={classNames(
                modal ? 'absolute' : 'static',
                ' flex w-full items-center gap-[8px] overflow-y-scroll rounded-t-md border-gray-300 bg-white py-[10px] pr-[10px] pl-[5px] shadow-md transition-all duration-300 sm:w-[395px] '
              )}
            >
              <XIcon
                className="h-6 cursor-pointer px-1 text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => setOpenPicker(false)}
              />
              <InputWithLeadingIcon
                Icon={<HiSearch className="mr-1 h-5 w-5 text-gray-400" />}
                placeholder="Buscar en Unsplash"
                name="search"
                className="w-full"
                onChange={handleChange}
              />
            </div>
            <div
              className={classNames(
                modal ? 'absolute' : 'static',
                'z-50 h-[20rem] w-full overflow-y-scroll border-gray-300  bg-white p-2 shadow-md transition-all duration-300 sm:w-[395px] '
              )}
              id="scrollableDiv"
            >
              <div className="">
                <InfiniteScroll
                  dataLength={photos.length}
                  hasMore={isLoading}
                  className={`mx-auto columns-2 gap-[3px] space-y-3`}
                  loader={
                    <div className="flex w-[200%] items-center justify-center py-2">
                      <Oval color="#542AE2" secondaryColor="#E8E8FF" />
                    </div>
                  }
                  scrollableTarget="scrollableDiv"
                  next={() => getMorePhotos(page + 1)}
                >
                  {photos.map((photo) => (
                    <div key={photo.id} className="break-inside-avoid">
                      <PhotoComp photo={photo} setUrl={setUrl} />
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};

interface IUnsplashProps {
  modal?: boolean;
  openPicker?: boolean;
  setUrl: (url: string) => void;
  setOpenPicker?: (openPicker: boolean) => void;
}

const Unsplash: React.FC<IUnsplashProps> = (props) => {
  const { setUrl, modal, openPicker, setOpenPicker } = props;

  return (
    <div>
      <Body
        setUrl={setUrl}
        modal={modal}
        openPicker={openPicker}
        setOpenPicker={setOpenPicker}
      />
    </div>
  );
};

export default Unsplash;

import { FC } from "react"

interface Props {
  title: string,
  price: number,
  currency: string,
  image: string
}

const Preview: FC<Props> = function({
  title, price, currency, image
}) {
  const hasProduct = !!title && !!price && !!currency && !!image

  return (
    <div className="mb-2">
      <h4 className="mb-2 text-base text-gray-700 font-medium">Vista previa</h4>

      <div
        className={`flex min-h-[7rem] rounded-lg p-4 ${hasProduct ? "bg-[#FF4C38]" : "bg-gray-100"}`}
      >
        {
          hasProduct && (
            <>
            <div className="min-w-[5rem] max-w-[5rem] min-h-[5rem] max-h-[5rem] mr-4">
              <img
                className="w-full h-full object-cover object-center rounded-lg"
                src={image}
                alt="misfans gloset preview"
              />
            </div>

            <div className="text-gray-50 text-lg">
              <h5 className="font-semibold">{title}</h5>
              <span className="font-medium">${price.toFixed(2)} {currency}</span>
            </div>
            </>
          )
        }
      </div>

      {
        hasProduct && (
          <h6 className="text-sm flex items-center mt-2">
            <span className="mr-1">Powered by</span>
            <img
              className="w-14"
              src="/img/icons/gloset_text.svg"
            />
          </h6>
        )
      }
    </div>
  )
}

export default Preview
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { useRouter } from 'next/router';
import hotkeys from 'hotkeys-js';

import { Account } from 'types/accounts';
import { ScreenType } from 'types/screenType';
import { useGlobalModalState } from 'hooks/useGlobalModalState';
import { ActionShorcutRules, ActionShortcut, CommandAction } from './types';
import { ActionTriggerType } from 'types/actionTriggerType';
import { CommandActionManager } from './actions';
import { CommandMenuCombobox } from './combobox';
import { CommandMenuDialog } from './dialog';
import { CommandMenuLoadingActions } from './loadingActions';
import { RecentSearches } from './recentSearches';

export interface CommandMenuProps {
  account: Account;
  parentOpenDialog: boolean;
  parentSetOpen: () => void;
  setActionTrigger: Dispatch<SetStateAction<ActionTriggerType>>;
  setScreen: Dispatch<SetStateAction<keyof typeof ScreenType>>;
}

export const CommandMenu = ({
  account,
  parentOpenDialog,
  parentSetOpen,
  setActionTrigger,
  setScreen
}: CommandMenuProps) => {
  const localStorageKey = 'command_searches_history';
  const router = useRouter();
  const { getModalState } = useGlobalModalState();

  const [query, setQuery] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [actions, setActions] = useState<Array<CommandAction>>(null);
  const [recentActions, setRecentActions] = useState<Array<CommandAction>>([]);
  const [selectedAction, setSelectedAction] = useState<CommandAction>(null);
  const [hotkeyTriggered, setHotkeyTriggered] = useState<CommandAction>(null);

  useEffect(() => {
    loadActions();
  }, []);

  useEffect(() => {
    if (actions !== null) initialization();
  }, [actions]);

  useEffect(() => {
    const globalModalState = getModalState();

    // Detects if parent component triggered command+k hotkey or any else
    // input for opening the command menu. This modal can't be open if any
    // existing modal in the system was opened before.
    if (parentOpenDialog && open === false && globalModalState === false)
      setOpen(true);

    // If user tried to open CommandMenu while another modal was opened, call
    // parentSetOpen function and reset state to false (preventing dead state).
    if (parentOpenDialog === true && globalModalState === true) parentSetOpen();
  }, [parentOpenDialog]);

  useEffect(() => {
    // If selectedActions is setted, trigger it's funcion and call for updating
    // search history.
    if (selectedAction !== null) {
      // This function calls cleaning states.
      updateLocalRecentSearches(selectedAction);

      selectedAction.action && selectedAction.action();
    }
  }, [selectedAction]);

  useEffect(() => {
    // Only trigger action if CommandMenu is open.
    if (hotkeyTriggered && open) {
      setSelectedAction(hotkeyTriggered);
      setHotkeyTriggered(null);
    } else {
      // Cleaning state required because if user press the same hotkey twice
      // this state could preserve previous action state. Not wanted.
      setHotkeyTriggered(null);
    }
  }, [hotkeyTriggered]);

  const loadActions = async () => {
    setLoading(true);
    const loadedActions = await CommandActionManager.load({
      account,
      router,
      setActionTrigger,
      setScreen
    });

    setActions(loadedActions);
  };

  const initialization = () => {
    // Initialization of hotkeys and actions history on LocalStorage.
    setHotkeys();
    setLocalRecentSearches();
    setLoading(false);
  };

  const setHotkeys = () => {
    // Create hotkey function for each action.
    actions.forEach((action) => {
      const { shortcutRules } = action;

      // If action has shorcut defined, create hotkey funcion.
      if (shortcutRules) {
        const specialKeys = getRule(shortcutRules).specialKeys;
        const commonKeys = getRule(shortcutRules).commonKeys;

        if (commonKeys.length > 0 && specialKeys.length > 0) {
          let hotkey = specialKeys.join('+') + '+';
          hotkey += commonKeys.join('+');

          hotkeys(`${hotkey}`, (event, _) => {
            // Event optimization.
            event.preventDefault();
            setHotkeyTriggered(action);
          });
        }
      }
    });
  };

  const getRule = (shortcutRules: ActionShorcutRules): ActionShortcut => {
    if (navigator.platform.indexOf('Mac') > -1 && shortcutRules.macOS)
      return shortcutRules.macOS;

    if (navigator.platform.indexOf('Win') > -1 && shortcutRules.windows)
      return shortcutRules.macOS;

    return shortcutRules.common;
  };

  const setLocalRecentSearches = () => {
    const data: string | null = localStorage.getItem(localStorageKey);

    if (data !== null) {
      const locals = RecentSearches.rehydrateList(actions, data);
      setRecentActions(locals);
    }
  };

  const updateLocalRecentSearches = (action: CommandAction) => {
    const newList = RecentSearches.updateLocalRecentSearches({
      actions,
      recentActions,
      selectedAction: action
    });

    closeDialog();
    // Few delay, because when dialog closes the delayed transition takes
    // some time to close dialog, so this setState needs to be delayed for
    // preventing weird rerendering on closing.
    setTimeout(() => setRecentActions(newList), 300);
  };

  const closeDialog = () => {
    parentSetOpen();
    setOpen(false);
    setSelectedAction(null);
  };

  const resetQuery = () => {
    setQuery('');
  };

  const onChangeSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const onChangeCombobox = (action: CommandAction) => {
    setSelectedAction(action);
  };

  return (
    <CommandMenuDialog
      open={open}
      resetQuery={resetQuery}
      setOpen={closeDialog}
    >
      {loading && actions === null ? (
        <CommandMenuLoadingActions />
      ) : (
        <CommandMenuCombobox
          actions={actions}
          onChangeSearchInput={onChangeSearchInput}
          query={query}
          recentActions={recentActions}
          selectedAction={selectedAction}
          onChangeCombobox={onChangeCombobox}
        />
      )}
    </CommandMenuDialog>
  );
};

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import React from 'react';

interface IEmptyStateProps {
  absolute?: boolean;
  title: string;
  description: string;
}

const ErrorState = ({
  absolute = true,
  title,
  description
}: IEmptyStateProps) => {
  return (
    <div
      className={`flex items-center justify-center rounded-lg lg:border-2 lg:border-dashed border-gray-200 dark:border-gray-500  ${
        absolute ? 'absolute inset-0' : 'h-[calc(100vh-180px)]'
      } `}
    >
      <div className="flex flex-col items-center">
        <ExclamationCircleIcon className="mb-2 h-24 w-24 text-gray-600" />

        <h3 className="mb-1 text-xl">{title}</h3>
        <p className="text-base font-semibold text-gray-900 dark:text-gray-400">
          {description}
        </p>
      </div>
    </div>
  );
};

export default ErrorState;

import { Switch } from '@headlessui/react';
import { CurrencyDollarIcon as CurrencyDollarIconSwitch } from '@heroicons/react/outline';
import {
  GiftIcon,
  HandIcon,
  LinkIcon,
  MenuAlt4Icon,
  MicrophoneIcon,
  MinusIcon,
  MusicNoteIcon,
  PencilIcon,
  PhotographIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  TagIcon
} from '@heroicons/react/solid';
import { FaShopify } from "react-icons/fa"
import BlockSkeleton from 'components/dashboard/blocks/BlockSkeleton';
import EditDigitalProductBlockForm from 'components/dashboard/blocks/forms/digitalProductForms/EditDigitalProductBlockForm';
import EditDividerForm from 'components/dashboard/blocks/forms/dividerForms/EditDividerForm';
import EditLinkForm from 'components/dashboard/blocks/forms/linkForms/EditLinkForm';
import EditMediaForm from 'components/dashboard/blocks/forms/mediaForms/EditMediaForm';
import EditMonetizationForm from 'components/dashboard/blocks/forms/monetizationForms/EditMonetizationForm';
import EditNftShowCaseForm from 'components/dashboard/blocks/forms/nftShowCaseForms/EditNftShowCaseForm';
import ShopifyProductForm from "components/dashboard/blocks/forms/shopifyProductForms/ShopifyProductForm";
import GlosetProductForm from "components/dashboard/blocks/forms/glosetProductForms/GlosetProductForm"
import SelectBlock from 'components/dashboard/blocks/SelectBlock';
import EmptyState from 'components/dashboard/EmptyState';
import HeaderScreen from 'components/dashboard/HeaderScreen';
import SwitchCard from 'components/dashboard/settings/SwitchCard2';
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import FormModal from 'components/tailwind/overlays/FormModal';
import { classNames } from 'helpers/classNames';
import { matchHttp } from 'helpers/matchHttp';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BsTwitter } from 'react-icons/bs';
import { ReactSortable } from 'react-sortablejs';
import AxiosUpdateUserSettings from 'requests/local_api/AxiosUpdateUserSettings';
import { AxiosCreatePlan } from 'requests/paypal/AxiosSubscriptions';
import { Account, Block } from 'types/accounts';
import { IBlocks } from 'types/Block';
import { DigitalProduct } from 'types/digitalProduct';
import { postDataV2 } from 'utils/helpers';
import { supabase } from 'utils/supabase-client';

// Hooks
import useApp from 'hooks/useApp';

// Libraries
import { ViewGridIcon } from '@heroicons/react/outline';

const BlocksScreen = () => {
  const { user, updateUserRedux, getIsConnectAccountEnabled, status } =
    useUser();
  const { app, editBlockRedux } = useApp();
  const [blocks, setBlocks] = useState<any[]>(user.blocks);
  const [selectedBlock, setSelectedBlock] = useState<any>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isConnectEnabled, setIsConnectEnabled] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [fetchingConnect, setFetchingConnect] = useState(true);
  const [showQuickActionsCard, setShowQuickActionsCard] = useState(false);
  const {
    app: { isProfileViewerOpen }
  } = useApp();

  useEffect(() => {
    const fetchIsConnectEnabled = async () => {
      if (status !== 1) {
        setFetchingConnect(false);
        return;
      } else if (user.linked_accounts?.paypal?.merchant_id) {
        setFetchingConnect(false);
        setIsConnectEnabled(2);
        editBlockRedux({
          stripeConnect: { isConnectEnabled: true, isLoading: false }
        });
        setShowQuickActionsCard(true);
        return;
      } else if (!user.connect_id) {
        setFetchingConnect(false);
        editBlockRedux({
          stripeConnect: { isConnectEnabled: false, isLoading: false }
        });
        return setIsConnectEnabled(1);
      }

      const isValid = await getIsConnectAccountEnabled();
      setFetchingConnect(false);
      setIsConnectEnabled(isValid ? 2 : 1);
      editBlockRedux({
        stripeConnect: { isConnectEnabled: isValid, isLoading: false }
      });

      setShowQuickActionsCard(true);
    };
    fetchIsConnectEnabled();

    if (user.linked_accounts.phantom.addr) {
      setShowQuickActionsCard(true);
    }
  }, [status]);

  const handleOpenEditModal = (block) => {
    setSelectedBlock(block);
    setOpenEditModal(true);
  };

  const handleOnChangeBlockVisibility = async (block: Block) => {
    // it changes the enabled state of the digital product in case it was
    // previously disabled and it wanted to enable the block corresponding to
    // the digital product.
    if (
      block.type === 'digitalProduct' &&
      block.digitalProductId !== undefined
    ) {
      const { error } = await supabase
        .from<DigitalProduct>('digital_products')
        .update({
          is_enabled: true
        })
        .match({ id: block.digitalProductId });
    }

    const blocks = user.blocks.reduce((acc, item) => {
      if (item.id === block.id) {
        acc.push({
          ...item,
          visible: !item.visible
        });
      } else {
        acc.push(item);
      }

      return acc;
    }, []);

    const { data, error } = await supabase
      .from('accounts')
      .update({
        blocks
      })
      .eq('id', user.id);

    if (error) {
      toast.error(error.message);
    }

    if (data) {
      updateUserRedux(data[0]);
      setBlocks(data[0].blocks);
      console.log('Se editó el bloque con éxito');
    }
  };

  const _onUpdateBlocks = async () => {
    setBlocks(blocks);

    const updateSupabaseBlocks = async () => {
      try {
        const { data } = await supabase
          .from('accounts')
          .update({
            blocks
          })
          .eq('id', user.id);

        return data;
      } catch (error) {
        return error;
      }
    };

    toast.promise(updateSupabaseBlocks(), {
      loading: 'Guardando cambios',
      success: (data) => {
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        return 'Guardado con éxito';
      },
      error: (error) => {
        return error.message;
      }
    });
  };

  const openUrl = (block: IBlocks) => {
    if (block.url) {
      window.open(matchHttp(block.url), '_blank');
    } else if (block.type == 'nftShowcase') {
      window.open(`https://solscan.io/token/${block.address}`, '_blank');
    }
  };

  const _handleOnToogleSubscription = async () => {
    if (!user.connect_id && !user.linked_accounts?.paypal?.merchant_id)
      return toast.error(
        'No tienes ninguna cuenta bancaria conectada',
        defaultToastOptions
      );
    else if (
      user.linked_accounts?.paypal?.merchant_id &&
      !user.linked_accounts.paypal?.subscription?.plan_id &&
      !user.linked_accounts?.paypal?.subscription?.product_id
    ) {
      const { error, data } = await AxiosCreatePlan();

      if (error) return toast.error(error);

      updateUserRedux({
        linked_accounts: {
          ...user.linked_accounts,
          paypal: {
            ...user.linked_accounts.paypal,
            subscription: data
          }
        },
        subscriptionEnabled: true
      });

      toast.success('Suscripcion activada!');
      return;
    } else if (
      !user.price_id &&
      !user.linked_accounts?.paypal?.subscription?.plan_id &&
      !user.linked_accounts?.paypal?.subscription?.product_id
    ) {
      const _data = {
        username: user.username,
        account_id: user.connect_id,
        images: [
          'https://res.cloudinary.com/misfans/image/upload/v1632438280/android-chrome-512x512_zirg4m.png'
        ],
        price: 5
      };

      const { price_id, message } = await postDataV2({
        url: '/api/create-product',
        data: _data
      });

      const { error } = await AxiosUpdateUserSettings({
        price_id
      });

      if (error) {
        return toast.error(error);
      }

      if (message === 'OK') {
        updateUserRedux({
          price_id,
          subscriptionEnabled: true
        });

        toast.success('Se creó el precio con éxito');
      }
      return;
    }

    const { error } = await AxiosUpdateUserSettings({
      subscriptionEnabled: !user.subscriptionEnabled
    });

    if (error) {
      return toast.error(error);
    }

    updateUserRedux({
      subscriptionEnabled: !user.subscriptionEnabled
    });
  };

  return (
    <div className="flex min-h-full flex-col">
      <HeaderScreen
        title="Bloques"
        RightComponent={<SelectBlock blocks={blocks} setBlocks={setBlocks} />}
      />
      {app.blockScreen.stripeConnect.isConnectEnabled && (user.internal_options.blocks?.subscription !== false || user.internal_options.blocks?.donation !== false) && (
        <div className="mb-6 rounded-lg md:border md:border-gray-200 md:p-6 md:shadow-sm">
          <h3 className="mb-5 text-lg font-medium text-gray-600">
            Acciones rápidas
          </h3>
          {app.blockScreen.stripeConnect.isLoading ? (
            <BlockSkeleton
              quantity={3}
              className="grid grid-cols-1 gap-4 md:grid-cols-2"
            />
          ) : (
            <div
              className={classNames(
                isProfileViewerOpen ? 'md:grid-cols-1' : 'md:grid-cols-2',
                'grid grid-cols-1 gap-4 xl:grid-cols-2'
              )}
            >
              {app.blockScreen.stripeConnect.isConnectEnabled && (
                <>
                  {
                    user.connect_id && user.internal_options.blocks?.subscription !== false && (
                      <SwitchCard
                        title="Suscripciones"
                        description="Tu audiencia puede acceder a contenido exclusivo bajo una suscripción mensual."
                        Icon={
                          <TagIcon
                            className="h-5 w-5 text-[#542AE2]"
                            aria-hidden="true"
                          />
                        }
                        actived={user.subscriptionEnabled}
                        onClick={_handleOnToogleSubscription}
                      />
                    )
                  }
                  {
                    user.internal_options.blocks?.donation !== false && (
                      <SwitchCard
                        className=""
                        title="Donaciones"
                        description="Tu audiencia puede apoyarte enviándote donaciones."
                        Icon={
                          <CurrencyDollarIconSwitch
                            className="h-5 w-5 text-[#542AE2]"
                            aria-hidden="true"
                          />
                        }
                        actived={user.donationEnabled}
                        onClick={async () => {
                          const { error } = await AxiosUpdateUserSettings({
                            donationEnabled: !user.donationEnabled
                          });

                          if (error) {
                            return toast.error(error);
                          }

                          updateUserRedux({
                            donationEnabled: !user.donationEnabled
                          });
                        }}
                      />
                    )
                  }
                </>
              )}
            </div>
          )}
        </div>
      )}

      {(!blocks || blocks.length === 0) && (
        <EmptyState
          absolute={false}
          title="Aún no tienes ningún bloque"
          dashboard={true}
          icon={<ViewGridIcon className="h-12 w-12 text-gray-400" />}
          className="flex-1"
        />
      )}
      {blocks && blocks.length > 0 && (
        <ul role="list">
          <ReactSortable
            list={blocks}
            setList={setBlocks}
            group="blocks"
            animation={200}
            delay={2}
            onEnd={_onUpdateBlocks}
            handle=".handle"
            className="flex flex-col gap-4"
          >
            {blocks.map((block, index) => {
              if (block.type === "monetization") {
                if (
                  (block.feature === "greetings" && user.internal_options.blocks?.greeting === false) ||
                  (block.feature === "questions" && user.internal_options.blocks?.question === false) ||
                  (block.feature === "audio" && user.internal_options.blocks?.audio === false)
                ) return <></>
              }

              else if (block.type === "digitalProduct" && user.internal_options.blocks?.digital_product === false) return <></>
              else if (block.type === "nftShowcase" && user.internal_options.blocks?.nft_collection_hub === false) return <></>

              return (
                <li key={'block: ' + index}>
                  <div className="dark:bg-[#2A2A2A] block rounded-md border border-gray-200 bg-white shadow-sm">
                    <div className="px-6 py-5">
                      <div className="flex justify-between">
                        <div
                          className="flex cursor-pointer items-center"
                          onClick={() => openUrl(block)}
                        >
                          <div className="flex items-center text-base">
                            {block.type === 'link' && (
                              <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary-50">
                                <LinkIcon
                                  className="h-6 w-6 text-[#542AE2]"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                            {block.type === 'monetization' &&
                              block.feature === 'gifts' && (
                                <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary-50">
                                  <GiftIcon
                                    className="h-6 w-6 text-[#542AE2]"
                                    aria-hidden="true"
                                  />
                                </div>
                              )}
                            {block.type === 'monetization' &&
                              block.feature === 'greetings' && (
                                <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary-50">
                                  <HandIcon
                                    className="h-6 w-6 text-[#542AE2]"
                                    aria-hidden="true"
                                  />
                                </div>
                              )}
                            {block.type === 'monetization' &&
                              block.feature === 'questions' && (
                                <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary-50">
                                  <QuestionMarkCircleIcon
                                    className="h-6 w-6 text-[#542AE2]"
                                    aria-hidden="true"
                                  />
                                </div>
                              )}

                            {block.type === 'monetization' &&
                              block.feature === 'audio' && (
                                <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary-50">
                                  <MicrophoneIcon
                                    className="h-6 w-6 text-[#542AE2]"
                                    aria-hidden="true"
                                  />
                                </div>
                              )}
                            {block.type === 'media' &&
                              block.platform === 'youtube' && (
                                <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-[#FAB6A0]">
                                  <img
                                    src="/img/icons/youtube.svg"
                                    className="h-6 w-6 text-[#D63907]"
                                    alt="youtube-icon"
                                  />
                                </div>
                              )}

                            {block.type === 'media' &&
                              block.platform === 'spotify' && (
                                <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-[#CDFBBD]">
                                  <img
                                    src="/img/icons/spotify.svg"
                                    className="h-6 w-6 text-[#42C414]"
                                    alt="spotify-icon"
                                  />
                                </div>
                              )}

                            {block.type === 'media' &&
                              block.platform === 'twitch_live' && (
                                <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-[#D6BDFB]">
                                  <img
                                    src="/img/icons/twitch_live.svg"
                                    className="h-6 w-6 text-[#9146FF]"
                                    alt="twitch_live-icon"
                                  />
                                </div>
                              )}

                            {block.type === 'media' &&
                              block.platform === 'tiktok' && (
                                <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary-50">
                                  <MusicNoteIcon
                                    className="h-6 w-6 text-[#542AE2]"
                                    aria-hidden="true"
                                  />
                                </div>
                              )}

                            {block.type === 'media' &&
                              block.platform === 'fbgaming' && (
                                <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary-50">
                                  <MusicNoteIcon
                                    className="h-6 w-6 text-[#542AE2]"
                                    aria-hidden="true"
                                  />
                                </div>
                              )}

                            {block.type === 'media' &&
                              block.platform === 'twitter' && (
                                <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-blue-300">
                                  <BsTwitter className="h-6 w-6 text-[#1C9BEF]" />
                                </div>
                              )}

                            {block.type === 'nftShowcase' && (
                              <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary-50">
                                <PhotographIcon
                                  className="h-6 w-6 text-[#542AE2]"
                                  aria-hidden="true"
                                />
                              </div>
                            )}

                            {block.type === 'divider' && (
                              <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary-50">
                                <MinusIcon
                                  className="h-6 w-6 text-[#542AE2]"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                            {(block.type === 'digitalProduct' || block.type === "gloset_product") && (
                              <div className="mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary-50">
                                <ShoppingBagIcon
                                  className="h-6 w-6 text-[#542AE2]"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                            {
                              block.type === "shopify_product" && (
                                <div className="h-9 w-9 flex justify-center items-center mr-2 rounded-full bg-primary-50">
                                  <FaShopify
                                    className="h-6 w-6 text-[#542AE2]"
                                  />
                                </div>
                              )
                            }
                            <div>
                              <div
                                className={classNames(
                                  isProfileViewerOpen ? 'md:w-2' : 'md:w-96',
                                  '-mb-1 w-[9rem] truncate  text-base font-semibold leading-6 text-gray-700 sm:w-96 xl:w-96'
                                )}
                              >
                                {
                                  (block.type === "shopify_product" || block.type === "gloset_product")
                                    ? block.data.title
                                    : block.title
                                }
                              </div>
                              <p
                                className={classNames(
                                  isProfileViewerOpen ? 'md:w-2' : 'md:w-96',
                                  'w-28 truncate text-gray-500 sm:w-96 xl:w-96'
                                )}
                              >
                                {block.url ||
                                  block.address ||
                                  block.description ||
                                  ''}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <Switch
                            checked={block.visible}
                            onChange={() => handleOnChangeBlockVisibility(block)}
                            className={classNames(
                              block.visible ? 'bg-primary' : 'bg-gray-200',
                              'relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
                            )}
                          >
                            <span className="sr-only">Switch for block</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                block.visible ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                              )}
                            />
                          </Switch>

                          <SecondaryButton
                            title={
                              <>
                                <span className="hidden md:block">Editar</span>
                                <PencilIcon className="h-5 w-5 md:hidden" />
                              </>
                            }
                            onClick={() => handleOpenEditModal(block)}
                            variant={variantType.gray}
                            className="mr-2 px-[9px] md:mx-3 md:px-4"
                            size="small"
                          />

                          <MenuAlt4Icon className="handle h-[38px] w-[38px] flex-shrink-0 cursor-grab py-2 pr-1.5 text-gray-500 hover:text-gray-300 active:cursor-grabbing" />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ReactSortable>
        </ul>
      )}

      {selectedBlock && (
        <FormModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          title="Editar bloque"
        >
          {selectedBlock.type === 'link' && (
            <EditLinkForm
              selectedBlock={selectedBlock}
              initialValues={{
                title: selectedBlock.title,
                url: selectedBlock.url,
                og_image: selectedBlock.og_image || ""
              }}
              setLoading={setLoading}
              closeModal={() => setOpenEditModal(false)}
              setBlocks={setBlocks}
            />
          )}
          {selectedBlock.type === 'monetization' && (
            <EditMonetizationForm
              selectedBlock={selectedBlock}
              initialValues={{
                title: selectedBlock.title,
                description: selectedBlock.description,
                price: selectedBlock.price ? selectedBlock.price : 1
              }}
              setLoading={setLoading}
              closeModal={() => setOpenEditModal(false)}
              setBlocks={setBlocks}
            />
          )}
          {selectedBlock.type === 'media' && (
            <EditMediaForm
              selectedBlock={selectedBlock}
              initialValues={{
                title: selectedBlock.title,
                url: selectedBlock.url
              }}
              setLoading={setLoading}
              closeModal={() => setOpenEditModal(false)}
              setBlocks={setBlocks}
            />
          )}
          {selectedBlock.type === 'nftShowcase' && (
            <EditNftShowCaseForm
              selectedBlock={selectedBlock}
              initialValues={{
                title: selectedBlock.title,
                address: selectedBlock.address
              }}
              setLoading={setLoading}
              closeModal={() => setOpenEditModal(false)}
              setBlocks={setBlocks}
            />
          )}

          {selectedBlock.type === 'divider' && (
            <EditDividerForm
              selectedBlock={selectedBlock}
              initialValues={{
                title: selectedBlock.title
              }}
              setLoading={setLoading}
              closeModal={() => setOpenEditModal(false)}
              setBlocks={setBlocks}
            />
          )}

          {selectedBlock.type === 'digitalProduct' && (
            <EditDigitalProductBlockForm
              blocks={blocks}
              closeModal={() => setOpenEditModal(false)}
              handleClose={() => setOpenEditModal(false)}
              selectedBlock={selectedBlock}
              setBlocks={setBlocks}
              setLoading={setLoading}
              updateUserRedux={updateUserRedux}
              user={user as unknown as Account}
            />
          )}
          {
            selectedBlock.type === "shopify_product" && (
              <ShopifyProductForm
                mode="edit"
                onClickCancel={() => setOpenEditModal(false)}
                setBlocks={setBlocks}
                initialValue={selectedBlock}
              />
            )
          }
          {
            selectedBlock.type === "gloset_product" && (
              <GlosetProductForm
                mode="edit"
                onClickCancel={() => setOpenEditModal(false)}
                setBlocks={setBlocks}
                initialValue={selectedBlock}
              />
            )
          }
        </FormModal>
      )}
    </div>
  );
};

export default BlocksScreen;

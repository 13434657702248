// Types
import { variantType } from 'components/tailwind/buttons/SecondaryButton';
import { profileThemes } from 'types/customizationTypes';

// Components
import SecondaryButton from 'components/tailwind/buttons/SecondaryButton';
import CardWithAvatarAndActions from 'components/tailwind/cards/CardWithAvatarAndActions';
import Avatar from 'components/tailwind/avatars/Avatar';

interface IProfileCardProps {
  className?: string;
  username: string;
  name: string;
  profile_picture: string;
  hexagonShape?: boolean;
  theme?: keyof typeof profileThemes;
}

const ProfileCard = ({
  className = '',
  username,
  name,
  profile_picture,
  hexagonShape = false,
  theme = 'default'
}: IProfileCardProps) => {
  return (
    <CardWithAvatarAndActions
      avatar={
        <Avatar
          profilePicture={profile_picture}
          shape={hexagonShape ? 'hexagon' : 'circle'}
          theme={theme}
        />
      }
      title={name}
      subtitle={username}
      onClick={() => window.open(`/${username}`, '_blank')}
      actions={
        <SecondaryButton
          title="Ir al perfil"
          variant={variantType.gray}
          onClick={() => window.open(`/${username.toLowerCase()}`, '_blank')}
          className="ml-auto"
          size="small"
        />
      }
    />
  );
};

export default ProfileCard;

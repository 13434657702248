import { defaultToastOptions } from 'helpers/toastOptions';
import { useRouter } from 'next/router';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { AxiosSwitchSubscription } from 'requests/paypal/AxiosSubscriptions';
import { postDataV2 } from 'utils/helpers';

// Libraries
import { ExclamationIcon, LightningBoltIcon } from '@heroicons/react/outline';

// Types
import { variantType } from 'components/tailwind/buttons/SecondaryButton';
import { Subscriptions } from 'types/App';
import { ActionsList } from 'components/tailwind/optionsMenu/types';

// Components
import SecondaryButton from 'components/tailwind/buttons/SecondaryButton';
import CardWithAvatarAndActions from 'components/tailwind/cards/CardWithAvatarAndActions';
import Spacer from 'components/tailwind/layout/Spacer';
import SimpleAlert from 'components/tailwind/overlays/SimpleAlert';
import CircleBadge from 'components/tailwind/badges/CircleBadge';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import ActionButton from 'components/tailwind/buttons/ActionButton';
import Avatar from 'components/tailwind/avatars/Avatar';
import OptionsMenuButton from 'components/tailwind/optionsMenu/OptionsMenuButton';

// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

const dayjs = require('dayjs');
dayjs.locale(require('utils/dayjsCustomPlugins/es'));
dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('dayjs/plugin/isYesterday'));
dayjs.extend(require('utils/dayjsCustomPlugins/isBeforeYesterday'));
const getDiffereceOfSuscriptionDates = (endDate) => {
  const date1 = dayjs(Date());
  const date2 = dayjs(endDate);

  return date2.diff(date1, 'day');
};

interface IMySubCardProps {
  sub: Subscriptions;
  onSwitch: (value: { id: string }) => void;
}

const SubsCard = ({ sub, onSwitch }: IMySubCardProps) => {
  const router = useRouter();
  const [fetching, setFetching] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const hexagonShape =
    sub?.creator_data?.is_verified &&
    sub?.creator_data?.linked_accounts.phantom.addr.length > 0;

  const { width } = useWindowDimensions();

  const differenceOfSuscriptionDates =
    1 + getDiffereceOfSuscriptionDates(sub.current_period_end);

  const _onClickCancelToogleSubscription = async (
    subscriptionId,
    cancelAtPeriodEnd
  ) => {
    setFetching(true);

    // Paypal Toogle
    if (sub.service === 'paypal' || sub.price_id === 'paypal') {
      const { error } = await AxiosSwitchSubscription({
        type: cancelAtPeriodEnd ? 'ACTIVATE' : 'SUSPEND',
        subscription_id: sub.id
      });

      setFetching(false);

      if (error) {
        console.log({ error });
        return;
      }

      setOpen(false);
      onSwitch({
        id: sub.id
      });
      return;
    }

    // Stripe Toogle
    const response = await postDataV2({
      url: '/api/cancel-subscription',
      data: { subscriptionId }
    });

    if (response.error) {
      setFetching(false);
      return toast.error(response.error.message, defaultToastOptions);
    } else {
      setOpen(false);

      setTimeout(() => {
        onSwitch({
          id: sub.id
        });
        setFetching(false);
      }, 355);
    }
  };

  const actions: ActionsList<any> = [
    {
      event: () => router.push(`/${sub.creator_data.username}/hub`),
      label: 'Ir al hub'
    },
    {
      event: () => setOpen(true),
      label: sub.cancel_at_period_end ? 'Renovar' : 'Cancelar'
    }
  ];

  return (
    <>
      <CardWithAvatarAndActions
        avatar={
          <Avatar
            profilePicture={sub.creator_data.profilePicture}
            shape={hexagonShape ? 'hexagon' : 'circle'}
            theme={
              sub.creator_data.customProfile?.theme === 'pride'
                ? 'pride'
                : 'default'
            }
          />
        }
        title={sub.creator_data.username}
        subtitle={
          sub.cancel_at_period_end ? (
            <span>
              Se cancela en{' '}
              <h1 className="inline text-[16px] font-semibold">
                {differenceOfSuscriptionDates} días
              </h1>
            </span>
          ) : (
            <span>
              Se renueva en{' '}
              <h1 className="inline text-[16px] font-semibold">
                {differenceOfSuscriptionDates} días
              </h1>
            </span>
          )
        }
        onClick={() => window.open(`/${sub.creator_data.username}`, '_blank')}
        actions={
          <div className="flex items-center sm:mt-0">
            {width > 764 ? (
              <>
                <SecondaryButton
                  title="Ir al hub"
                  onClick={() =>
                    router.push(`/${sub.creator_data.username}/hub`)
                  }
                  variant={variantType.gray}
                  size="small"
                />

                <Spacer className="w-2 md:w-3" />

                {sub.metadata.unlinked !== 'true' && (
                  <SecondaryButton
                    variant={
                      sub.cancel_at_period_end
                        ? variantType.primary
                        : variantType.red
                    }
                    onClick={() => setOpen(true)}
                    disabled={fetching}
                    title={sub.cancel_at_period_end ? 'Renovar' : 'Cancelar'}
                    size="small"
                  />
                )}
              </>
            ) : (
              <OptionsMenuButton actions={actions} value={null} />
            )}
          </div>
        }
      />
      <SimpleAlert
        open={open}
        setOpen={() => setOpen(false)}
        circleBadge={
          <CircleBadge color={sub.cancel_at_period_end ? 'green' : 'red'}>
            {sub.cancel_at_period_end ? (
              <LightningBoltIcon className="h-6 w-6 text-[#10B981]" />
            ) : (
              <ExclamationIcon className="h-6 w-6 text-red-500" />
            )}
          </CircleBadge>
        }
        title={
          sub.cancel_at_period_end
            ? 'Renovar suscripción'
            : 'Cancelar suscripción'
        }
        textContent={
          sub.cancel_at_period_end ? (
            <>
              Estás a punto de renovar tu suscripción a{' '}
              <span className="font-medium">{sub.creator_data.username}</span>.
            </>
          ) : (
            <>
              Estás a punto de cancelar tu suscripción a{' '}
              <span className="font-medium">{sub.creator_data.username}</span>.
            </>
          )
        }
        actions={
          <>
            <ActionButton
              variant={sub.cancel_at_period_end ? 'green' : 'red'}
              title={
                sub.cancel_at_period_end
                  ? 'Renovar suscripción'
                  : 'Cancelar suscripción'
              }
              loading={fetching}
              onClick={() =>
                _onClickCancelToogleSubscription(
                  sub.id,
                  sub.cancel_at_period_end
                )
              }
              loadingChildren={
                sub.cancel_at_period_end ? 'Renovando' : 'Cancelando'
              }
            />
            <WhiteButton
              type="button"
              title="Cancelar"
              onClick={() => setOpen(false)}
              disabled={fetching}
              className="min-w-[134px]"
            />
          </>
        }
      />
    </>
  );
};

export default SubsCard;

//Libraries
import React from 'react';

// Components
import CardWithAvatarAndActions from 'components/tailwind/cards/CardWithAvatarAndActions';
import Avatar from 'components/tailwind/avatars/Avatar';

//Helpers
import { formatPublicKey } from 'helpers/formatData';

interface ICardProps {
  creator_data: any;
  addr: string;
}

const Card = ({ creator_data, addr }: ICardProps) => {
  const hexagonShape =
    creator_data.is_verified &&
    creator_data.linked_accounts.phantom.addr.length > 0;

  return (
    <CardWithAvatarAndActions
      avatar={
        <Avatar
          profilePicture={creator_data.profilePicture}
          shape={hexagonShape ? 'hexagon' : 'circle'}
          theme={
            creator_data?.customProfile?.theme === 'pride' ? 'pride' : 'default'
          }
        />
      }
      title={creator_data.username}
      subtitle={formatPublicKey(addr)}
    />
  );
};

export default Card;

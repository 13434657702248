import { Dispatch, SetStateAction } from 'react';
import { NextRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import {
  UsersIcon,
  TrendingUpIcon,
  BellIcon,
  SearchIcon,
  TagIcon,
  HeartIcon,
  CogIcon,
  SupportIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  ViewGridIcon
} from '@heroicons/react/outline';

import { Account } from 'types/accounts';
import { ScreenType } from 'types/screenType';
import { CommandAction, CommonKey, SpecialKey } from '../types';

export interface DirectNavigationActionsProps {
  account: Account;
  router: NextRouter;
  setScreen: Dispatch<SetStateAction<keyof typeof ScreenType>>;
}

export function directNavigationActions({
  account,
  router,
  setScreen
}: DirectNavigationActionsProps): Array<CommandAction> {
  let actions: Array<CommandAction> = [
    {
      action: () => router.push(`/${account.username}`),
      key: uuidv4(),
      name: 'Ir a mi Perfil de Creador',
      shortcutRules: {
        common: {
          commonKeys: [CommonKey.M],
          specialKeys: [SpecialKey.ctrl, SpecialKey.alt]
        },
        macOS: {
          commonKeys: [CommonKey.M],
          specialKeys: [SpecialKey.ctrl, SpecialKey.cmd]
        }
      }
    },
    {
      action: () => setScreen('blocks'),
      icon: ViewGridIcon,
      key: uuidv4(),
      name: 'Ir a Bloques',
      predefined: true
    },
    {
      action: () => setScreen('super_fans'),
      icon: HeartIcon,
      key: uuidv4(),
      name: 'Ir a Comunidad'
    },
    {
      action: () => setScreen('products'),
      icon: ShoppingBagIcon,
      key: uuidv4(),
      name: 'Ir a Productos',
      predefined: true
    },
    {
      action: () => setScreen('notifications'),
      icon: BellIcon,
      key: uuidv4(),
      name: 'Ir a Notificaciones',
      predefined: true,
      shouldBeVerified: true
    },
    {
      action: () => setScreen('audience'),
      icon: UsersIcon,
      key: uuidv4(),
      name: 'Ir a Audiencia'
    },
    {
      action: () => setScreen('analytics'),
      icon: TrendingUpIcon,
      key: uuidv4(),
      name: 'Ir a Analíticas',
      predefined: true,
      shouldBeVerified: true
    },
    {
      action: () => setScreen('explore'),
      icon: SearchIcon,
      key: uuidv4(),
      name: 'Ir a Explorar',
      shouldBeVerified: true
    },
    {
      action: () => setScreen('my_subscriptions'),
      icon: TagIcon,
      key: uuidv4(),
      name: 'Ir a Mis Suscripciones',
      shouldBeVerified: true
    },
    {
      action: () => setScreen('products_history'),
      icon: ShoppingCartIcon,
      key: uuidv4(),
      name: 'Ir a Mis Compras',
      shouldBeVerified: true
    },
    {
      action: () => setScreen('settings'),
      icon: CogIcon,
      key: uuidv4(),
      name: 'Ir a Ajustes',
      predefined: true,
      shouldBeVerified: true
    },
    {
      action: () => setScreen('support'),
      icon: SupportIcon,
      key: uuidv4(),
      name: 'Iniciar conversación con soporte',
      predefined: true,
      shouldBeVerified: true
    }
  ];

  return actions.filter((action) =>
    action.shouldBeVerified === true
      ? account.is_verified
        ? true
        : false
      : true
  );
}

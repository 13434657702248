!(function (e, o) {
  'object' == typeof exports && 'undefined' != typeof module
    ? (module.exports = o(require('dayjs')))
    : 'function' == typeof define && define.amd
    ? define(['dayjs'], o)
    : ((e =
        'undefined' != typeof globalThis
          ? globalThis
          : e || self).dayjs_locale_es = o(e.dayjs));
})(this, function (e) {
  'use strict';
  function o(e) {
    return e && 'object' == typeof e && 'default' in e ? e : { default: e };
  }
  var s = o(e),
    d = {
      name: 'es',
      monthsShort: 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_'),
      weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split(
        '_'
      ),
      weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
      weekdaysMin: 'do_lu_ma_mi_ju_vi_sá'.split('_'),
      months:
        'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
          '_'
        ),
      weekStart: 1,
      formats: {
        LT: 'H:mm',
        LTS: 'H:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D [de] MMMM [de] YYYY',
        LLL: 'D [de] MMMM [de] YYYY H:mm',
        LLLL: 'dddd, D [de] MMMM [de] YYYY H:mm'
      },
      relativeTime: {
        future: 'en %s',
        past: 'hace %s',
        s: 'unos segundos',
        m: 'un minuto',
        mm: '%d minutos',
        h: 'una hora',
        hh: '%d horas',
        d: 'un día',
        dd: '%d días',
        M: 'un mes',
        MM: '%d meses',
        y: 'un año',
        yy: '%d años'
      },
      ordinal: function (e) {
        return e + 'º';
      }
    };
  return s.default.locale(d, null, !0), d;
});

import { DotsVerticalIcon } from '@heroicons/react/solid';

interface DotButtonProps {
  onClickShowActions: () => void;
}

const DotButton = (props: DotButtonProps) => {
  const { onClickShowActions } = props;

  return (
    <button
      aria-expanded={'true'}
      aria-haspopup={'true'}
      className={
        'inline-flex w-full justify-center bg-white py-2 pl-2 text-sm font-medium text-gray-700'
      }
      id={'menu-button'}
      onClick={onClickShowActions}
      type={'button'}
    >
      <DotsVerticalIcon className={'h-6 w-6 text-gray-500 hover:text-gray-300'} />
    </button>
  );
};

export default DotButton;

import { AxiosApi } from '../AxiosBase';
import { getCookie } from 'helpers/handleCookies'

export const AxiosAllData = async (period: string) => {
  const userToken = getCookie("token");

  if (!userToken) return { error: "No estas logeado" }

  try {
    const { data } = await AxiosApi.get(
      `/analytics/all?period=${period}`,
      {
        headers: {
          "Authorization": `Bearer ${userToken}`
        }
      }
    )

    if (data.message !== "OK") return { error: data.message }

    return { data: data.data }
  }
  
  catch(e) {
    console.log(e);
    console.log("AxiosAllData() Error");
    return { error: "AxiosAllData() Error" }
  }
}
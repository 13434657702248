import { useEffect, useState } from 'react';

enum UseAudioPlayerError {
  stalledError = '',
  eventError = '',
  unknownError = '',
  invalidNumberError = ''
}

interface AudioPlayerListeners {
  /** Event that is fired when an error is raised. */
  error: (e: ErrorEvent) => void;

  /** Event that fires when the audio is fully loaded with it metadata. */
  loadedData: (e: Event) => void;

  /** The stalled event is fired when the user agent is trying to fetch media
   * data, but data is unexpectedly not forthcoming. */
  stalled: (e: Event) => void;

  /** Event that fires every time the currentTime of the audio changes. */
  timeUpdate: (e: Event) => void;
}

interface UseAudioPlayerProps {
  audio: string | null;
  autoplay?: boolean;
}

interface useAudioPlayerReturn {
  error: string | null;
  isReady: boolean;
  player: {
    changeCurrent: (curr: number) => void;
    current: number;
    duration: number;
    isPlaying: boolean;
    pause: () => void;
    play: () => void;
    restart: () => void;
    start: () => void;
  };
}

function useAudioPlayer(props: UseAudioPlayerProps): useAudioPlayerReturn {
  const { audio, autoplay } = props;

  const [current, setCurrent] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  const [isReady, setIsReady] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  useEffect(() => {
    if (audio !== null || audio !== undefined) {
      try {
        const player = document.getElementById('player') as HTMLAudioElement;

        initPlayer(player);

        const listeners = createListeners(player);

        addListeners(player, listeners);

        return () => {
          removeListeners(player, listeners);
        };
      } catch (error) {
        console.error(error);
        setErrorMessage(UseAudioPlayerError.unknownError);
      }
    }
  }, [audio]);

  useEffect(() => {
    if (isReady) {
      const player = document.getElementById('player') as HTMLAudioElement;

      try {
        isPlaying ? player.play() : player.pause();
      } catch (error) {
        console.log(error);
      }
    }
  }, [isPlaying]);

  const isInvalidNumber = (number: number) => {
    return Number.isNaN(number) === true || Number.isFinite(number) === false;
  };

  const initPlayer = (player: HTMLAudioElement) => {
    // Super hacky solution for correct audio loading. Without this src always
    // have NaN or Infinity value in duration property. This will cause
    // missleading behaviours through all the hook. Idk why this work (I mean,
    // it's unbelivable that it is necessary to do these initializations).

    player.src = audio;
    // [WARNING] Don't forget to reset volume when audio is ready.
    player.volume = 0;
    // Perf improvement on metadata loading.
    player.preload = 'metadata';

    // No fu*k idea what 1e101 means, but works.
    player.currentTime = 1e101;
  };

  const playerIsReady = (player: HTMLAudioElement) => {
    if (isInvalidNumber(player.duration) && isInvalidNumber(player.currentTime))
      return setErrorMessage(UseAudioPlayerError.invalidNumberError);

    // Prevent duplicate executions by events.
    if (isReady === false) {
      player.volume = 1;
      player.currentTime = 0;
      player.autoplay = false;
      player.muted = false;

      setDuration(player.duration);
      setCurrent(0);
      setIsReady(true);

      // Autoplay
      if (autoplay) setIsPlaying(true);
      else setIsPlaying(false);
    }
  };

  const currentUpdated = (player: HTMLAudioElement) => {
    if (isInvalidNumber(player.currentTime))
      return setErrorMessage(UseAudioPlayerError.invalidNumberError);

    // Reset current time when when the audio finishes playing, otherwise update.
    if (player.duration === player.currentTime) {
      setCurrent(0);
      setIsPlaying(false);
    } else setCurrent(player.currentTime);
  };

  const createListeners = (player: HTMLAudioElement): AudioPlayerListeners => {
    const error = (e: ErrorEvent) => {
      console.error(e);
      setErrorMessage(UseAudioPlayerError.eventError);
    };

    const loadedData = (_: Event) => playerIsReady(player);

    const stalled = (_: Event) =>
      setErrorMessage(UseAudioPlayerError.stalledError);

    const timeUpdate = (_: Event) => currentUpdated(player);

    return {
      error,
      loadedData,
      stalled,
      timeUpdate
    };
  };

  const addListeners = (
    player: HTMLAudioElement,
    listeners: AudioPlayerListeners
  ) => {
    const { error, loadedData, stalled, timeUpdate } = listeners;

    player.addEventListener('error', (e) => error(e));
    player.addEventListener('loadeddata', (e) => loadedData(e));
    player.addEventListener('stalled', (e) => stalled(e));
    player.addEventListener('timeupdate', (e) => timeUpdate(e));
  };

  const removeListeners = (
    player: HTMLAudioElement,
    listeners: AudioPlayerListeners
  ) => {
    const { error, loadedData, stalled, timeUpdate } = listeners;

    player.removeEventListener('error', (e) => error(e));
    player.removeEventListener('loadeddata', (e) => loadedData(e));
    player.removeEventListener('stalled', (e) => stalled(e));
    player.removeEventListener('timeupdate', (e) => timeUpdate(e));
  };

  const pause = () => setIsPlaying(false);

  const play = () => setIsPlaying(true);

  const restart = () => {
    setIsPlaying(false);
    setCurrent(0);
  };

  const start = () => setIsReady(true);

  const changeCurrent = (curr: number) => {
    const player = document.getElementById('player') as HTMLAudioElement;

    player.currentTime = curr;
    setCurrent(curr);
  };

  return {
    error: errorMessage,
    isReady,
    player: {
      changeCurrent,
      current,
      duration,
      isPlaying,
      pause,
      play,
      restart,
      start
    }
  };
}

export default useAudioPlayer;

// Helpers
import { classNames } from 'helpers/classNames';

interface IFormFooterProps {
  className?: string;
}

const FormFooter: React.FC<IFormFooterProps> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        'flex justify-end gap-3 border-t border-gray-200 bg-gray-50 py-4 px-6',
        className
      )}
    >
      {children}
    </div>
  );
};

export default FormFooter;

// Components
import InfiniteProgressBar from 'components/tailwind/loaders/InfiniteProgressBar';
import ModalHandler from 'components/tailwind/overlays/ModalHandler';

interface SimpleAlertProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  circleBadge: React.ReactElement;
  title: string;
  textContent: React.ReactNode;
  actions: React.ReactElement;
  loading?: boolean;
}

const SimpleAlert: React.FC<SimpleAlertProps> = ({
  open = false,
  setOpen,
  circleBadge,
  title,
  textContent,
  actions,
  loading = false
}) => {
  return (
    <ModalHandler open={open} setOpen={setOpen}>
      <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
        {loading && <InfiniteProgressBar />}
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex flex-shrink-0 items-center justify-center sm:mx-0">
            {circleBadge}
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </h3>
            <div className="mt-2">
              <p className="text-base text-gray-500">{textContent}</p>
            </div>
          </div>
        </div>
        <div className="mt-5 flex flex-col gap-3 sm:mt-4 sm:flex-row-reverse">
          {actions}
        </div>
      </div>
    </ModalHandler>
  );
};

export default SimpleAlert;

import HeaderScreen from 'components/dashboard/HeaderScreen';
import MySubscriptionsHeaderRight from 'components/dashboard/mySubscriptions/MySubscriptionsHeaderRight';
import SubsScreen from 'components/dashboard/mySubscriptions/subs/index';
import WalletScreen from 'components/dashboard/mySubscriptions/wallet/WalletScreen';
import { useState } from 'react';

const MySubscriptionsScreen = () => {
  const [screen, setScreen] = useState<string>('subscriptions');

  return (
    <div className="flex min-h-full flex-col">
      <HeaderScreen
        title="Mis Suscripciones"
        RightComponent={<MySubscriptionsHeaderRight setValue={setScreen} />}
      />

      {screen === 'subscriptions' && <SubsScreen />}
      {screen === 'wallets' && <WalletScreen />}
    </div>
  );
};

export default MySubscriptionsScreen;

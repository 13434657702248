import { Combobox } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/solid';

export interface CommandMenuLoadingActionsProps {}

export const CommandMenuLoadingActions =
  ({}: CommandMenuLoadingActionsProps) => {
    return (
      <Combobox onChange={() => {}} value={''}>
        <div className="relative">
          <SearchIcon className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" />
          <Combobox.Input
            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
            disabled={true}
            onChange={() => {}}
            placeholder="Search..."
          />
        </div>
        <div className="h-96 max-h-96 scroll-py-2 flex-row items-center justify-center divide-y divide-gray-100 overflow-y-auto">
          {[...Array(9)].map((_, i) => (
            <div
              className="flex select-none items-center rounded-md px-3 py-2"
              key={i}
            >
              <div className="h-6 w-6 flex-none animate-pulse rounded-lg bg-slate-200"></div>
              <div className="ml-1 h-6 w-64 flex-none animate-pulse rounded-lg bg-slate-200"></div>
            </div>
          ))}
        </div>
      </Combobox>
    );
  };

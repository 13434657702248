// Helpers
import { AxiosApi } from 'requests/AxiosBase';
import { getCookie } from 'helpers/handleCookies';

const AxiosGetFanPurchases = async (
  url: string,
  search: string,
  value: string
) => {
  try {
    const userToken = getCookie('token');

    const result = await AxiosApi.get(
      `/fan-purchases?time=${value}${search ? `&search=${search}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    );

    if (result.status == 204) return null;

    return result.data;
  } catch (error) {
    console.log('AxiosGetFanPurchases Error:', error);
    if (error instanceof Error) return error;

    return new Error('Error al ejecutar AxiosGetFanPurchases: ' + error);
  }
};

export default AxiosGetFanPurchases;

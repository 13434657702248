type Step = {
  name: string;
  status: 'complete' | 'current' | 'upcoming';
};

interface ISimpleStepsProps {
  steps: Step[];
}

const SimpleSteps: React.FC<ISimpleStepsProps> = ({ steps }) => {
  return (
    <nav aria-label="Progress" className="w-full">
      <p className="mb-3 text-sm font-medium text-gray-600">
        Paso {steps.findIndex((step) => step.status === 'current') + 1} de{' '}
        {steps.length}
      </p>
      <ol role="list" className="flex gap-1">
        {steps.map((step) => (
          <li key={step.name} className="flex-1">
            {step.status === 'complete' ? (
              <div className="rounded-[4px] border-t-4 border-indigo-600"></div>
            ) : step.status === 'current' ? (
              <div
                className="rounded-[4px] border-t-4 border-indigo-600"
                aria-current="step"
              ></div>
            ) : (
              <div className="rounded-[4px] border-t-4 border-gray-200"></div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default SimpleSteps;

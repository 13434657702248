import Axios from "axios";

const AxiosShortenerUrl = async (url: string) => {
    if (!url) return { error: "Falta la url" }

    try {
        const { data } = await Axios.post(
            "/api/social-shortener-url",
            { url },
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );

        if (data.message !== "OK") return { error: data.message }


        return { data: data.data }
    }

    catch(e) {
        console.log(e);
        console.log("AxiosShortenerUrl() Error");
        return { error: "Error al conectarse con el servidor" }
    }
}

export default AxiosShortenerUrl;
import { AxiosApi } from "../../AxiosBase";
import { getCookie } from "helpers/handleCookies";

interface IAxiosLinkAccountPhantom {
    addr: string;
}

const AxiosLinkAccountPhantom = async (payload: IAxiosLinkAccountPhantom) => {
    const userToken = getCookie("token");

    if (!userToken) return { error: "No estas logeado" }

    try {
        const { data } = await AxiosApi.post("/link-account/phantom", payload, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userToken}`
            }
        });

        if (data.message !== "OK") return { error: data.message }

        return { success: "PRO" }
    }

    catch(e) {
        console.log(e);
        console.log("AxiosLinkAccountPhantom() Error");
        return { error: "Error al conectarse con el servidor" }
    }

};

export default AxiosLinkAccountPhantom;
import { useState } from 'react';

import { isProfileImageHexagon } from 'helpers/logicFunctions';

import Avatar from 'components/tailwind/avatars/Avatar';
import AudioNotificationModal from '../modals/AudioModal';

const dayjs = require('dayjs');
dayjs.locale(require('utils/dayjsCustomPlugins/es'));
dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('dayjs/plugin/isYesterday'));
dayjs.extend(require('utils/dayjsCustomPlugins/isBeforeYesterday'));

interface AudioCardProps {
  notification: any;
}

const AudioCard = (props: AudioCardProps) => {
  const { notification } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onCloseModal = () => {
    setTimeout(() => {
      setOpenModal(!openModal);
    }, 300);
  };

  return (
    <div className="mt-5 block rounded-md">
      <div className="">
        <div className="flex justify-between">
          <div className="">
            <div className="flex items-start text-base text-gray-500">
              <Avatar
                profilePicture={notification.fan_data?.profilePicture}
                shape={
                  isProfileImageHexagon(notification.fan_data)
                    ? 'hexagon'
                    : 'circle'
                }
                size="sm"
                theme={
                  notification?.fan_data?.customProfile?.theme === 'pride'
                    ? 'pride'
                    : 'default'
                }
              />

              <div className="ml-3">
                <p className="mb-1 truncate text-base text-gray-500 sm:w-96">
                  {dayjs(notification.creation_date).isToday() ? (
                    <>
                      Hoy{' '}
                      <span className="text-sm italic">
                        {dayjs(notification.creation_date).format('HH:mm')}
                      </span>
                    </>
                  ) : dayjs(notification.creation_date).isYesterday() ? (
                    <>
                      Ayer{' '}
                      <span className="text-sm italic">
                        {dayjs(notification.creation_date).format('HH:mm')}
                      </span>
                    </>
                  ) : dayjs(notification.creation_date).isBeforeYesterday() ? (
                    <>
                      Antier{' '}
                      <span className="text-sm italic">
                        {dayjs(notification.creation_date).format('HH:mm')}
                      </span>
                    </>
                  ) : (
                    <>
                      {dayjs(notification.creation_date).format('DD [de] MMMM')}{' '}
                      <span className="text-sm italic">
                        {dayjs(notification.creation_date).format('HH:mm')}
                      </span>
                    </>
                  )}
                </p>
                <div className="mb-1 text-gray-700">
                  <a
                    className={`font-semibold ${
                      notification.fan_data?.username
                        ? 'cursor-pointer text-primary underline'
                        : 'cursor-default'
                    }`}
                    href={
                      notification.fan_data?.username
                        ? `/${notification.fan_data?.username}`
                        : ''
                    }
                    target={
                      notification.fan_data?.username ? '_blank' : '_self'
                    }
                  >
                    {notification?.fan_data?.username ||
                      notification.name ||
                      'un fan'}
                  </a>{' '}
                  te ha solicitado un audio por
                  <a className="font-semibold text-mf-purple">
                    {' '}
                    ${notification.amount} USD
                  </a>
                </div>
                <p className="text-base font-medium leading-6 text-mf-purple">
                  {notification.comments}
                </p>
                <button
                  className={`mt-2 inline-flex cursor-pointer items-center rounded  bg-white py-2 px-4 text-sm font-medium shadow-sm hover:bg-gray-50 active:outline-none active:ring-2 active:ring-offset-2 ${
                    notification.status === 'done'
                      ? ' bg-[#10B981] text-white hover:bg-[#059669] active:ring-[#10B981]'
                      : notification.status === 'rejected'
                      ? ' bg-red-400 text-white hover:bg-[#DC2626] active:ring-red-400'
                      : 'border border-gray-300 text-gray-700  active:ring-primary'
                  }`}
                  onClick={() => setOpenModal(true)}
                >
                  {notification.status === 'done' ? (
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2 inline h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <span className="">Completado</span>
                    </div>
                  ) : notification.status === 'rejected' ? (
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2 inline h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                      <span className="">Rechazado</span>
                    </div>
                  ) : (
                    <>Responder</>
                  )}
                </button>
              </div>
            </div>
            <div className="ml-[2.7rem] mt-2 flex items-center sm:mt-0"></div>
          </div>
        </div>
      </div>
      <div className="mt-5 h-divider w-full bg-gray-300"></div>
      <AudioNotificationModal
        notification={notification}
        setLoading={setLoading}
        onCloseModal={onCloseModal}
        openModal={openModal}
        loading={loading}
      />
    </div>
  );
};

export default AudioCard;

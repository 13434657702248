import { useFormik } from 'formik';
import { classNames } from 'helpers/classNames';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import React from 'react';
import toast from 'react-hot-toast';
import { supabase } from 'utils/supabase-client';
import * as Yup from 'yup';

// Helpers
import { prepareNFTBlock } from 'requests/local_next/web3';

// Components
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import Input from 'components/tailwind/inputs/Input';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';

const EditNftShowCaseForm = ({
  selectedBlock,
  initialValues,
  closeModal,
  setBlocks,
  setLoading
}) => {
  const { user, updateUserRedux } = useUser();

  const formik: any = useFormik({
    initialValues,
    validationSchema: Yup.object({
      address: Yup.string().required('El address de eth es requerido')
    }),
    onSubmit: async (values) => {
      setLoading(true);

      const nftMetadata = await prepareNFTBlock(values.address);
      if (nftMetadata.error) {
        toast.error(nftMetadata.error, defaultToastOptions);
        setLoading(false);
      } else {
        const blocks = user.blocks.reduce((acc, block) => {
          if (block.id === selectedBlock.id) {
            acc.push({
              ...values,
              id: block.id,
              visible: block.visible,
              type: block.type,
              nftBlockData: nftMetadata
            });
          } else {
            acc.push(block);
          }
          return acc;
        }, []);

        const { data, error } = await supabase
          .from('accounts')
          .update({
            blocks
          })
          .eq('id', user.id);

        if (error) {
          toast.error(error.message, defaultToastOptions);
          setLoading(false);
        }

        if (data) {
          updateUserRedux(data[0]);
          setBlocks(data[0].blocks);
          toast.success('Bloque Editado', defaultToastOptions);
          closeModal();
          setLoading(false);
        }
      }
    }
  });

  const handleOnDeleteBlock = async () => {
    const confirmed = confirm(
      '¿Estás seguro de que quieres eliminar este bloque?'
    );

    if (confirmed) {
      setLoading(true);
      const blocks = user.blocks.reduce((acc, block) => {
        if (block.id !== selectedBlock.id) {
          acc.push(block);
        }
        return acc;
      }, []);

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
        setLoading(false);
      }

      if (data) {
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Eliminado', defaultToastOptions);
        setLoading(false);
      }

      closeModal();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormBody>
        <LabelRequired name="address" title="Token Address" isRequired={true} />
        <div className="mt-1">
          <Input
            type="text"
            name="address"
            id="address"
            placeholder="Pega aquí el address del token"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            error={formik.errors.address && formik.touched.address}
          />
        </div>
        {formik.errors.address && formik.touched.address && (
          <ErrorMessage errorText={formik.errors.address} />
        )}
      </FormBody>
      <FormFooter>
        <SecondaryButton
          title="Eliminar"
          onClick={handleOnDeleteBlock}
          variant={variantType.red}
          className="px-6"
          disabled={formik.isSubmitting}
        />
        <BasicButton
          title="Guardar cambios"
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          loading={formik.isSubmitting}
          className="w-40 whitespace-nowrap"
          loadingChildren={'Guardando'}
        />
      </FormFooter>
    </form>
  );
};

export default EditNftShowCaseForm;

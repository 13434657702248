const FetchGetProductData = async (url: string) => {
  try {
    const data = await fetch("/api/shopify/info", {
      method: "POST",
      body: JSON.stringify({ url }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())

    if (data.message !== "OK") return { error: data.message }

    return { data: data.data }
  }

  catch(e) {
    console.error(e)
    console.error("FetchGetProductData() Shopify Error")
    return { error: "Link Error" }
  }
}

export default FetchGetProductData
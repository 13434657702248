type TSelectButtonsOptions = {
  id: number;
  name: string;
  value: string;
  icon: any;
};

type SelectButtonsProps = {
  disabled?: boolean;
  options: TSelectButtonsOptions[];
  value: TSelectButtonsOptions;
  onChange: (option: TSelectButtonsOptions) => void;
  className?: string;
};

const SelectButtons: React.FC<SelectButtonsProps> = ({
  disabled = false,
  options,
  value,
  onChange,
  className
}) => {
  return (
    <div className="flex flex-col gap-2 sm:flex-row">
      {options.map((option) => (
        <button
          key={option.id}
          disabled={disabled}
          onClick={() => onChange(option)}
          type="button"
          className={`flex w-full items-center justify-center rounded-md border py-2 font-medium text-gray-700 transition-all duration-150 disabled:cursor-not-allowed disabled:opacity-50  ${
            value.value === option.value
              ? 'bg-mf-purple-100 text-mf-purple'
              : `border-gray-300 ${disabled ? '' : 'hover:bg-gray-50'}`
          }`}
        >
          <option.icon
            className={`mr-2 h-5 w-5 transition-all duration-150   ${
              value.value === option.value ? 'text-primary' : `text-gray-400`
            }`}
          />
          <span>{option.name}</span>
        </button>
      ))}
    </div>
  );
};

export default SelectButtons;

import * as AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

import { MimeTypes } from 'types/MimeTypes';

interface IUploadFileArgs {
  /** List of supported contentTypes for upload. */
  contentType: keyof typeof MimeTypes;

  /** File for upload. */
  file: File;

  /** Name of the folder where the file will be located. */
  folder: string;

  /** Any information that correlates the file on the AWS side. */
  metadata: { [key: string]: string };

  /**  */
  setUploadProgress?: (progress: number) => void;
}

async function uploadFileAWS(args: IUploadFileArgs): Promise<string | Error> {
  try {
    const { contentType, file, folder, metadata, setUploadProgress } = args;

    const AWS_S3_BUCKET_NAME = process.env.NEXT_PUBLIC_AWS_S3_BUCKET_NAME;
    const AWS_S3_REGION = process.env.NEXT_PUBLIC_AWS_S3_REGION;
    const AWS_ACCESS_KEY_ID = process.env.NEXT_PUBLIC_AWS_ACCESS_KEY_ID;
    const AWS_SECRET_KEY_ACCESS = process.env.NEXT_PUBLIC_AWS_SECRET_KEY_ACCESS;

    // Each file has a uuid as name.
    const fileName = uuidv4();

    let policy = {
      Statement: [
        {
          Sid: 'Stmt1S3UploadAssets',
          Effect: 'Allow',
          Action: ['s3:PutObject', 's3:PutObjectAcl'],
          Resource: [`arn:aws:s3:::${AWS_S3_BUCKET_NAME}/${folder}/${fileName}`]
        }
      ]
    };

    // TODO file data should be stored on DB for tracking purposes.

    // Build an access token for AWS authorized communication.
    let federatedToken = await new AWS.STS({
      accessKeyId: AWS_ACCESS_KEY_ID,
      region: AWS_S3_REGION,
      secretAccessKey: AWS_SECRET_KEY_ACCESS
    })
      .getFederationToken({
        Name: 'S3UploadWebToken',
        Policy: JSON.stringify(policy),
        DurationSeconds: 60 * 60 // 1 hour
      })
      .promise();

    let s3 = new AWS.S3({
      accessKeyId: federatedToken.Credentials.AccessKeyId,
      region: AWS_S3_REGION,
      secretAccessKey: federatedToken.Credentials.SecretAccessKey,
      sessionToken: federatedToken.Credentials.SessionToken
    });

    let s3Upload = s3.upload({
      ACL: 'public-read',
      Body: file,
      Bucket: AWS_S3_BUCKET_NAME,
      CacheControl: 'max-age=630720000, public',
      ContentType: contentType,
      Key: `${folder}/${fileName}`,
      Metadata: metadata
    });

    if (setUploadProgress) {
      setUploadProgress(0);
      s3Upload.on('httpUploadProgress', function (event) {
        const percent = ((event.loaded / event.total) * 100).toFixed(0);
        setUploadProgress(Number(percent));
      });
    }

    let uploadResult = await s3Upload.promise();

    return uploadResult.Location;

    // TODO file data should be updated on DB for tracking purposes.
  } catch (error) {
    return new Error(error);
  }
}

export default uploadFileAWS;

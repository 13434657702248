import useSWR from 'swr';

// Components
import EmptyState from 'components/dashboard/EmptyState';
import BlockSkeleton from 'components/tailwind/loaders/BlockSkeleton';
import Card from './Card';
import WalletFaRegular from 'components/tailwind/icons/WalletFaRegular';

// Requests
import { AxiosWallets } from 'requests/local_api/AxiosAudience';

const WalletScreen = () => {
  const { error, data } = useSWR('/audience/wallets', AxiosWallets);

  if (error === undefined && data === undefined) {
    return <BlockSkeleton quantity={6} className="flex flex-col gap-3" />;
  } else if (error) return <h4>{error.toString()}</h4>;

  return (
    <>
      {data.length === 0 ? (
        <EmptyState
          absolute={false}
          title="Aún no tienes ninguna wallet conectada a tu perfil."
          dashboard={true}
          className="flex-1"
          icon={<WalletFaRegular className="h-[34px] w-[39px] text-gray-400" />}
        />
      ) : (
        <div className="flex flex-col gap-4">
          {data.map((value, index) => (
            <Card key={index} addr={value.address} fan_data={value.fan_data} />
          ))}
        </div>
      )}
    </>
  );
};

export default WalletScreen;

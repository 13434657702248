// Libraries
import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import { FilterIcon } from '@heroicons/react/outline';
import { classNames } from 'helpers/classNames';

// Types
import { Dispatch, SetStateAction } from 'react';

export type TOptions = {
  textContent: string;
  value: string;
  defaultValue?: boolean;
};

type SimpleDropdownProps = {
  options: TOptions[];
  setValue: Dispatch<SetStateAction<TOptions>>;
};

const SimpleFilter: React.FC<SimpleDropdownProps> = ({ setValue, options }) => {
  const [selected, setSelected] = useState(
    options.find((option) => option.defaultValue)
  );

  const handleOnChange = (option) => {
    setValue(option);
    setSelected(option);
  };

  return (
    <Listbox value={selected} onChange={(e) => handleOnChange(e)}>
      {({ open }) => (
        <div>
          <div className="relative mt-1">
            <Listbox.Button
              className={classNames(
                'relative w-full cursor-pointer rounded-md border bg-white py-3 pl-6 pr-[54px] text-left shadow-sm focus:border-mf-purple focus:outline-none focus:ring-1 focus:ring-mf-purple'
              )}
            >
              <span className="block truncate text-base font-medium text-gray-700">
                {selected.value === 'all' ? 'Filtrar' : selected.textContent}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-5">
                <FilterIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-1 w-fit min-w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((type, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9 transition-all duration-300'
                      )
                    }
                    value={type}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {type.textContent}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-primary' : 'text-primary',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default SimpleFilter;

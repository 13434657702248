import { FC, FormEvent, ChangeEvent, ChangeEventHandler, useState, useEffect } from "react"
import { toast } from "react-hot-toast"
import FormModal from "components/tailwind/overlays/FormModal"
import FormFooter from "components/tailwind/overlays/FormFooter"
import BasicButton from "components/tailwind/buttons/BasicButton"
import WhiteButton from "components/tailwind/buttons/WhiteButton"
import LabelRequired from "components/tailwind/inputs/LabelRequired"
import Input from "components/tailwind/inputs/Input"
import { linktree_RegExp_2 } from "helpers/customRegExp"
import { AxiosImportLinkTree } from "requests/local_api/importAccounts"
import { useUser } from "hooks/useUser"

interface ICheckBoxProps {
  name: string,
  title: string,
  onChange: ChangeEventHandler<HTMLInputElement>
  disabled: boolean
}

interface ILinkTreeModalProps {
  show: boolean,
  setShow: (value: boolean | ((prev: boolean) => boolean)) => void
}

const list = [
  {
    title: "Nombre",
    name: "name"
  },
  {
    title: "Biografía",
    name: "biography"
  },
  {
    title: "Imagen de perfil",
    name: "profile_picture"
  },
  {
    title: "Imagen de fondo",
    name: "cover_picture"
  },
  {
    title: "Enlaces",
    name: "blocks"
  },
  {
    title: "Redes sociales",
    name: "socials_media"
  }
]

const CheckBox: FC<ICheckBoxProps> = function({
  name, title, onChange, disabled
}) {
  return (
    <div className="flex items-center mb-2 last:mb-0">
      <input
        className="mr-3 text-primary rounded focus:ring-0"
        type="checkbox"
        name={name}
        onChange={onChange}
        disabled={disabled}
      />

      <span>{title}</span>
    </div>
  )
}

const LinkTreeModal: FC<ILinkTreeModalProps> = function({
  show,
  setShow
}) {
  const { updateUserRedux, userEditSettingsFields } = useUser()

  const [fetching, setFetching] = useState<boolean>(false)
  const [url, setUrl] = useState<string>("")
  const [options, setOptions] = useState<Array<string>>([])
  const [errorMessage, setErrorMessage] = useState<string>("")

  useEffect(() => {
    if (show) return

    setUrl("")
    setOptions([])
  }, [show])

  const handleOnChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setUrl(value)

    if (!linktree_RegExp_2.test(value)) {

      if (!errorMessage) {
        setErrorMessage("Verifique la url de linktr.ee")
      }

      return
    }

    setErrorMessage("")
  }

  const handleOnChangeInputCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target

    setOptions((prev) => {
      if (!prev.includes(name)) {
        if (checked) return [...prev, name]
        return prev
      }

      const filter = prev.filter((value) => value !== name)

      return filter
    })
  }

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      !url ||
      options[0] === undefined ||
      !linktree_RegExp_2.test(url)
    ) return

    const linkTreeUsername = url.split("/").reverse()[0].toLowerCase()

    setFetching(true)
    const { error, data } = await AxiosImportLinkTree({
      username: linkTreeUsername,
      options
    })

    setFetching(false)

    if (error) {
      toast.error(error)
      return
    }

    updateUserRedux(data)
    userEditSettingsFields({
      biography: data.biography,
      cover_picture: {
        blob: null,
        url: data.cover_picture
      },
      profilePicture: {
        blob: null,
        url: data.profilePicture
      },
      social_medias: data.social_medias
    })

    toast.success("Datos actualizado satisfactoriamente!")
    setShow(false)
  }

  return (
    <FormModal
      open={show}
      setOpen={setShow}
      title="Importar Linktr.ee"
    >
      <form onSubmit={handleOnSubmit}>
        <div className="p-6">
          <div className="mb-4">
            <LabelRequired
              className="mb-1"
              title="Tu enlace de Linktr.ee"
              isRequired={true}
            />

            <Input   
              placeholder="linktr.ee/username"
              name="username"
              error={!!errorMessage}
              onChange={handleOnChangeInput}
              value={url}
              disabled={fetching}
            />

            {
              errorMessage && <p className="text-sm mt-1 text-red-500 font-normal">{errorMessage}</p>
            }
          </div>

          <div>
            <LabelRequired
              className="mb-1"
              title="Selecciona lo que deseas importar"
              isRequired={true}
            />

            {
              list.map((value, index) => (
                <CheckBox
                  key={index}
                  onChange={handleOnChangeInputCheckBox}
                  disabled={fetching}
                  {...value}
                />
              ))
            }
          </div>
          
        </div>

        <FormFooter>
          <WhiteButton
            type="button"
            title="Cancelar"
            onClick={() => setShow(false)}
            disabled={fetching}
          />

          <BasicButton
            type="submit"
            title="Añadir"
            disabled={!!errorMessage || !url || options[0] === undefined}
            loading={fetching}
          />
        </FormFooter>
      </form>
    </FormModal>
  )
}

export default LinkTreeModal
// Helpers
import { classNames } from 'helpers/classNames';

type PriceInputProps = {
  id: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value: number;
  className?: string;
  error?: boolean;
  disabled?: boolean;
  errorText?: string;
};

const PriceInput: React.FC<PriceInputProps> = ({
  id,
  name,
  onChange,
  onBlur,
  value,
  className,
  error = false,
  disabled = false,
  errorText = ''
}) => {
  return (
    <>
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500">$</span>
        </div>
        <input
          type="number"
          name={name}
          id={id}
          aria-describedby="price"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          className={classNames(
            className,
            error && 'border-red-500',
            'block w-full rounded-md border border-gray-300 pl-7 pr-12 placeholder-gray-500 transition duration-200 ease-in-out focus:border-primary focus:ring-primary disabled:cursor-not-allowed disabled:opacity-50'
          )}
          disabled={disabled}
          placeholder="0.00"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500" id="price-currency">
            USD
          </span>
        </div>
      </div>
      {error && <div className={'mt-1 text-sm text-red-500'}>{errorText}</div>}
    </>
  );
};

export default PriceInput;

type IconProps = {
  className?: string;
};

const WalletIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 31 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25.0881 4.09082C25.7257 4.09082 26.2387 4.60912 26.2387 5.24796C26.2387 5.8868 25.7257 6.40511 25.0881 6.40511H7.44605C6.38704 6.40511 5.52843 7.26862 5.52843 8.33368V21.448C5.52843 22.5135 6.38704 23.3765 7.44605 23.3765H23.554C24.6135 23.3765 25.4716 22.5135 25.4716 21.448V12.9622C25.4716 11.8967 24.6135 11.0337 23.554 11.0337H8.98014C8.34253 11.0337 7.82957 10.5178 7.82957 9.87654C7.82957 9.23529 8.34253 8.71939 8.98014 8.71939H23.554C25.8839 8.71939 27.7728 10.619 27.7728 12.9622V21.448C27.7728 23.7912 25.8839 25.6908 23.554 25.6908H7.44605C5.11614 25.6908 3.22729 23.7912 3.22729 21.448V8.33368C3.22729 5.99046 5.11614 4.09082 7.44605 4.09082H25.0881ZM20.1023 17.2051C20.1023 16.3517 20.7878 15.6622 21.6364 15.6622C22.4849 15.6622 23.1705 16.3517 23.1705 17.2051C23.1705 18.0585 22.4849 18.748 21.6364 18.748C20.7878 18.748 20.1023 18.0585 20.1023 17.2051Z"
        fill="#542AE2"
      />
    </svg>
  );
};

export default WalletIcon;

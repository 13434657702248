import { ChangeEvent, useEffect } from 'react';

import { Combobox } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/solid';

import { CommandAction } from './types';
import { supabase } from 'utils/supabase-client';
import { CommandMenuSearchList } from './searchList';
import { CommandMenuEmptySearch } from './emptySearch';
import { CommandMenuRecentSearchList } from './recentSearchList';

export interface CommandMenuComboboxProps {
  actions: Array<CommandAction>;
  onChangeSearchInput: (event: ChangeEvent<HTMLInputElement>) => void;
  query: string;
  recentActions: Array<CommandAction>;
  selectedAction: CommandAction;
  onChangeCombobox: (action: CommandAction) => void;
}

export const CommandMenuCombobox = ({
  actions,
  onChangeSearchInput,
  query,
  recentActions,
  selectedAction,
  onChangeCombobox
}: CommandMenuComboboxProps) => {
  const maxInputLength = 20;
  const queryReportableLength = 3;
  const filterRegex = RegExp(`.*${query.toLowerCase().split('').join('.*')}.*`);

  useEffect(() => {
    /* Calculate filtered Actions. */
    const filteredActions = actions.filter((action) => {
      if (typeof action.name === 'string') {
        return action.name.toLowerCase().match(filterRegex);
      }
      return true;
    });

    // If query meets the following conditions, should be reported as not found.
    if (
      query !== '' &&
      query.length > queryReportableLength &&
      filteredActions.length === 0
    )
      reportFailedSearch(query);
  }, [query]);

  const reportFailedSearch = async (query: string) => {
    await supabase.from('failed_cmd_search').insert({
      creationDate: new Date(),
      value: query
    });
  };

  /** Filter actions before rendering. */
  const filteredActions = actions.filter((action) => {
    if (typeof action.name === 'string') {
      return action.name.toLowerCase().match(filterRegex);
    }
    return true;
  });

  return (
    <Combobox onChange={onChangeCombobox} value={selectedAction}>
      <div className="relative">
        <SearchIcon className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" />
        <Combobox.Input
          className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
          maxLength={maxInputLength}
          onChange={onChangeSearchInput}
          placeholder="Busca una acción, sección ó perfil..."
        />
      </div>
      {/* Combobox content. */}
      {/* If query was empty, render EmptySearchComponent and RecentSearchList. */}
      {query === '' && (
        <Combobox.Options
          static
          className="max-h-96 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
        >
          {recentActions.length > 0 && (
            <CommandMenuRecentSearchList recentActions={recentActions} />
          )}
          <CommandMenuEmptySearch actions={actions} />
        </Combobox.Options>
      )}
      {/* If query wasn't an empty string value, render search coincidences. */}
      {query !== '' && <CommandMenuSearchList actions={filteredActions} />}
    </Combobox>
  );
};

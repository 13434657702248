import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

// Hooks
import useApp from 'hooks/useApp';

import { ScreenType } from 'types/screenType';
import { LateralViewer } from './lateralViewer';

export interface ProfileViewerProps {
  children: ReactNode;
  screen: keyof typeof ScreenType;
}

const ProfileViewer = (props: ProfileViewerProps) => {
  const { children, screen } = props;
  const {
    app: { isProfileViewerOpen },
    editProfileViewerRedux
  } = useApp();

  const changeViewer = () => {
    editProfileViewerRedux(!isProfileViewerOpen);
  };

  useEffect(() => {
    if (isAdmittedScreen() === false) editProfileViewerRedux(false);
  }, [screen]);

  const isAdmittedScreen = () => {
    switch (screen) {
      case 'blocks':
      case 'settings':
        return true;
      case 'analytics':
      case 'audience':
      case 'explore':
      case 'my_subscriptions':
      case 'notifications':
      case 'products':
      case 'products_history':
        return false;
      default:
        return false;
    }
  };

  return (
    <div className="relative flex h-[100%] flex-row">
      <div
        className={classNames(
          'h-full transition-all duration-1000 ease-in-out',
          isProfileViewerOpen
            ? `w-full lg:w-[calc(100%-414px)]`
            : 'w-full lg:w-[100%]'
        )}
      >
        {children}
      </div>
      {
        <LateralViewer
          changeViewer={changeViewer}
          viewerOpen={isProfileViewerOpen}
          isAdmittedScreen={isAdmittedScreen()}
        />
      }
    </div>
  );
};

export { ProfileViewer };

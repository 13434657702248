interface IPreviewStreamProps {
  duration: string;
  isTriggered: boolean;
}

const PreviewStream = (IPreviewStreamProps) => {
  const gifList = [
    'https://media.giphy.com/media/w0joFBL3JJNH8k8yao/giphy.gif',
    'https://media.giphy.com/media/A3wJP0CEg0OKs34Mnn/giphy.gif',
    'https://media.giphy.com/media/gF7XQkVTxiGq6zJUOD/giphy.gif',
    'https://media.giphy.com/media/fipN1GOuDK8txSqay3/giphy.gif',
    'https://media.giphy.com/media/AQ333qHbX5vaYSEsT1/giphy.gif',
    'https://media.giphy.com/media/KcQJxawltZ6IaDuZsa/giphy.gif',
    'https://media.giphy.com/media/Lq5r6qXmb5RUIZGbT0/giphy.gif',
    'https://media.giphy.com/media/iFroMXf8HkQD5LrJOJ/giphy.gif',
    'https://media.giphy.com/media/JOGDMviQHZvC2QGWeg/giphy.gif',
    'https://media.giphy.com/media/KHF9fbygB3SRoWt3iC/giphy.gif',
    'https://media.giphy.com/media/oIeqTPPXedjyw/giphy.gif'
  ];

  const nameList = [
    'Hiram',
    'Gabriela',
    'Fernanda',
    'Juan',
    'Monica',
    'Jorge',
    'Nestor',
    'Tomas',
    'Dany',
    'Eduardo',
    'Alex'
  ];

  const msgList = [
    '¡Hola! me gusta mucho tu stream',
    'Soy tu fan numero 1!',
    '¡Te veo en el stream!',
    'Mandame un saludo',
    'Si ganas esta partida te vuelvo a donar!'
  ];

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const { duration, isTriggered } = IPreviewStreamProps;
  return (
    <div className="flex max-w-[250px] flex-col justify-center transition-all duration-300">
      {isTriggered ? (
        <img
          src={gifList[getRandomInt(gifList.length)]}
          className="h-[150px] w-[250px] animate-fade-in rounded-md bg-[#E3E3E3] shadow"
        />
      ) : (
        <div className="h-[150px] w-[250px] animate-fade-in rounded-md bg-[#E3E3E3] shadow" />
      )}
      {isTriggered ? (
        <h1 className="animate-fade-in text-center text-base font-semibold leading-6 text-white">
          {nameList[getRandomInt(nameList.length)]}
        </h1>
      ) : (
        <h1 className="animate-fade-in text-center text-base font-semibold leading-6 text-white">
          Usuario
        </h1>
      )}
      {isTriggered ? (
        <h1 className="animate-fade-in text-center text-white">
          {msgList[getRandomInt(msgList.length)]}
        </h1>
      ) : (
        <h1 className="animate-fade-in text-center text-white">
          Mensaje del usuario
        </h1>
      )}
    </div>
  );
};

export default PreviewStream;

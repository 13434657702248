import classNames from 'classnames';

import { ActionShorcutRules, ActionShortcut, SpecialKey } from './types';

export interface CommandMenuActionShortcutProps {
  active: boolean;
  shortcutRules: ActionShorcutRules;
}

export const CommandMenuActionShortcut = ({
  active,
  shortcutRules
}: CommandMenuActionShortcutProps) => {
  const specialKey = (key: SpecialKey) => {
    switch (key) {
      case SpecialKey.cmd:
        return '⌘';
      case SpecialKey.option:
        return '⌥';
      case SpecialKey.shift:
        return '⇧';
      default:
        return key;
    }
  };

  const getRule = (): ActionShortcut => {
    if (navigator.platform.indexOf('Mac') > -1 && shortcutRules.macOS)
      return shortcutRules.macOS;

    if (navigator.platform.indexOf('Win') > -1 && shortcutRules.windows)
      return shortcutRules.macOS;

    return shortcutRules.common;
  };

  const specialKeys = getRule().specialKeys;
  const commonKeys = getRule().commonKeys;

  return (
    <span
      className={classNames(
        'ml-3 flex-none text-xs font-semibold',
        active ? 'text-indigo-100' : 'text-gray-400'
      )}
    >
      {specialKeys.map((key, i) => (
        <kbd className="font-sans" key={i}>{`${specialKey(key)} + `}</kbd>
      ))}
      {commonKeys.map((key, i) => (
        <kbd className="font-sans" key={i}>
          {i !== commonKeys.length - 1 ? `${key} + ` : `${key}`}
        </kbd>
      ))}
    </span>
  );
};

import { Combobox } from '@headlessui/react';

import { CommandMenuNotFound } from './notFound';
import { CommandMenuOption } from './option';
import { CommandAction } from './types';

export interface CommandMenuSearchListProps {
  actions: Array<CommandAction>;
}

export const CommandMenuSearchList = ({
  actions
}: CommandMenuSearchListProps) => {
  return (
    <Combobox.Options
      static
      className="max-h-96 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
    >
      <li className="p-2">
        <ul className="text-sm text-gray-700">
          {actions.length === 0 ? (
            <CommandMenuNotFound />
          ) : (
            actions.map((action) => (
              <CommandMenuOption action={action} key={action.key} />
            ))
          )}
        </ul>
      </li>
    </Combobox.Options>
  );
};

import { useState, useEffect } from "react"
import { useFormik } from 'formik';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import toast from 'react-hot-toast';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import { supabase } from 'utils/supabase-client';
import * as Yup from 'yup';

// Functions
import { handleDeleteBlockClick } from 'requests/local_next/analytics';

// Components
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import Input from 'components/tailwind/inputs/Input';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import ImageInput from "./ImageInput"

// Libs
import AwsStorage from "libs/AwsStorage"

// Requests
import FetchLinkMetaData from "requests/local_next/FetchLinkMetaData"

const EditLinkForm = ({
  selectedBlock,
  initialValues,
  closeModal,
  setBlocks,
  setLoading
}) => {
  const { user, updateUserRedux } = useUser();
  const [count, setCount] = useState<number>(0)
  const [fetching, setFetching] = useState<boolean>(false)
  const [file, setFile] = useState({
    blob: null,
    url: initialValues.og_image as string
  })

  const formik: any = useFormik({
    initialValues: {
      url: initialValues.url,
      title: initialValues.title
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('El título es requerido')
        .max(64, 'El título debe tener como máximo 64 carácteres'),
      url: Yup.string()
        .matches(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
          'URL inválida'
        )
        .required('La URL es requerida')
        .max(4000, 'La URL debe tener como máximo 4000 carácteres')
    }),
    onSubmit: async (values) => {
      setLoading(true);

      let image_url = file.url.startsWith("blob:") ? "" : file.url

      if (file.blob) {
        const response = await AwsStorage({
          file: file.blob,
          folder: "og_image_links",
          contentType: "image/jpeg",
          metadata: { website: values.url }
        })

        if (!(response instanceof Error)) {
          image_url = response
        }
      }

      const blocks = user.blocks.reduce((acc, block) => {
        if (block.id === selectedBlock.id) {
          acc.push({
            id: selectedBlock.id,
            title: values.title,
            url: values.url,
            visible: block.visible,
            type: block.type,
            og_image: image_url
          });
        } else {
          acc.push(block);
        }
        return acc;
      }, []);

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
        setLoading(false);
      }

      if (data) {
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Editado', defaultToastOptions);
        closeModal();
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (count <= 0) return
    else if (file.blob || file.url) {
      setFile((prev) => (
        {
          ...prev,
          blob: null,
          url: ""
        }
      ))
    }

    formik.values.title = ""

    const { url } = formik.errors

    if (url) return

    const timeout = setTimeout(() => {
      getHeadData()
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [count, formik])

  const handleOnDeleteBlock = async () => {
    const confirmed = confirm(
      '¿Estás seguro de que quieres eliminar este bloque?'
    );

    if (confirmed) {
      setLoading(true);
      const blocks = user.blocks.reduce((acc, block) => {
        if (block.id !== selectedBlock.id) {
          acc.push(block);
        }
        return acc;
      }, []);

      const { data, error } = await supabase
        .from('accounts')
        .update({
          blocks
        })
        .eq('id', user.id);

      if (error) {
        toast.error(error.message, defaultToastOptions);
      }

      if (data) {
        handleDeleteBlockClick({
          block_id: selectedBlock.id,
          last_title: selectedBlock.title,
          last_url: selectedBlock.url
        });
        updateUserRedux(data[0]);
        setBlocks(data[0].blocks);
        toast.success('Bloque Eliminado', defaultToastOptions);
      }
      setLoading(false);

      closeModal();
    }
  };

  const getHeadData = async () => {
    setCount(0)
    setFetching(true)

    const { error, data } = await FetchLinkMetaData(formik.values.url)

    setFetching(false)

    if (error) {
      toast.error(error)
      return
    }

    console.log(data)

    setFile((prev) => (
      {
        ...prev,
        url: data.image
      }
    ))

    formik.setValues({
      title: data.title,
      url: formik.values.url
    })
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormBody>
        <div>
          <LabelRequired name="url" title="URL" isRequired={true} />
          <div className="mt-1">
            <Input
              type="text"
              name="url"
              id="url"
              placeholder="ej www.google.com"
              onChange={(e) => {
                setCount((prev) => prev + 1)
                formik.handleChange(e)
              }}
              onBlur={formik.handleBlur}
              value={formik.values.url}
              error={formik.errors.url && formik.touched.url}
              disabled={fetching || formik.isSubmitting}
            />
          </div>
          {formik.errors.url && formik.touched.url && (
            <ErrorMessage errorText={formik.errors.url} />
          )}
        </div>
        <div className="mt-4 flex">
          <ImageInput
            className="mr-4"
            file={file}
            setFile={setFile}
            disabled={fetching || formik.isSubmitting}
          />

          <div className="w-full">
            <LabelRequired
              name="title"
              title="Título del enlace"
              isRequired={true}
            />
            <div className="mt-1">
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="Escribe el título"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                error={formik.errors.title && formik.touched.title}
                disabled={fetching || formik.isSubmitting}
              />
            </div>
            {formik.errors.title && formik.touched.title && (
              <ErrorMessage errorText={formik.errors.title} />
            )}
          </div>
        </div>
      </FormBody>
      <FormFooter>
        <SecondaryButton
          title="Eliminar"
          onClick={handleOnDeleteBlock}
          variant={variantType.red}
          className="px-6"
          disabled={fetching || formik.isSubmitting}
        />
        <BasicButton
          type="submit"
          disabled={fetching || !formik.isValid || initialValues.og_image !== file.url ? false : !formik.dirty}
          loading={formik.isSubmitting}
          title="Guardar cambios"
          className="w-40 whitespace-nowrap"
          loadingChildren={'Guardando'}
        />
      </FormFooter>
    </form>
  );
};

export default EditLinkForm;

import React from 'react';

interface IProfileCardSkeletonProps {
  className?: string;
  quantity?: number;
}

const ProfileCardSkeleton = ({
  className = '',
  quantity = 1
}: IProfileCardSkeletonProps) => {
  return (
    <>
      {Array.from(Array(quantity).keys()).map((_, index) => (
        <div
          className={`flex animate-pulse items-center rounded border border-slate-200 p-3.5 ${className}`}
          key={index}
        >
          <div className="mr-3.5 h-14 w-14 rounded-full bg-slate-200"></div>
          <div className="">
            <h3 className="mb-2 h-4 w-20 rounded bg-slate-200 text-lg font-semibold"></h3>
            <h4 className="h-4 w-32 rounded bg-slate-200 text-[#D2D4D7] md:w-64"></h4>
          </div>
          <div className="ml-auto h-10 w-20 rounded-lg bg-slate-200"></div>
        </div>
      ))}
    </>
  );
};

export default ProfileCardSkeleton;

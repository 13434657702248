import MainCardLayout from 'components/tailwind/MainCardLayout';
import imageKitUpload from 'helpers/imageKitUpload';
import { useUser } from 'hooks/useUser';
import React, { useState, Fragment } from 'react';
import { toast } from 'react-hot-toast';
import AxiosStepRegisterProcess from 'requests/local_api/AxiosStepRegisterProcess';
import { useRouter } from 'next/router';
import getUsernameReference from 'helpers/getUsernameReference';

// Components
import CharacterCounter from 'components/tailwind/inputs/CharacterCounter';
import TextArea from 'components/tailwind/inputs/TextArea';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import Input from 'components/tailwind/inputs/Input';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import Label from 'components/tailwind/inputs/Label';
import SimpleSteps from 'components/tailwind/steps/SimpleSteps';
import OnboardingContainer from 'components/onboarding/OnboardingContainer';

// Icons
import { TrashIcon } from '@heroicons/react/solid';
import { Mixpanel } from 'utils/mixpanel';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import AxiosAuthVerifyReferralCode from 'requests/local_api/AxiosAuthVerifyReferralCode';

const BaseSteps = [
  { name: 'Completar mi cuenta', status: 'current' },
  { name: '¿Tienes un código de creador?', status: 'upcoming' },
  { name: 'Importa Linktree', status: 'upcoming' },
  { name: 'Tags', status: 'upcoming' }
];

const AddImage = () => {
  const router = useRouter();
  const { updateUserRedux, userEditSettingsFields } = useUser();
  const [name, setName] = useState<string>('');
  const [bio, setBio] = useState<string>('');
  const [image, setImage] = useState({
    blob: null,
    url: ''
  });
  const [referral, setReferral] = useState<string>('');
  const [fetching, setFetching] = useState<boolean>(false);
  const [steps, setSteps] = useState<any>(BaseSteps);
  const [subStep, setSubStep] = useState<number>(0);

  const handleOnSubmit = async () => {
    setFetching(true);

    if (referral) {
      const { error: errorReferral } = await AxiosAuthVerifyReferralCode(
        referral
      );

      if (errorReferral) {
        toast.error(errorReferral);
        setFetching(false);
        return;
      }
    }

    let profile_picture_url = '';

    if (image.blob) {
      const { error, data }: any = await imageKitUpload({
        blob: image.blob,
        extension: 'jpg'
      });

      if (error) {
        toast.error(error);
      } else {
        profile_picture_url = data.url;
      }
    }

    const { error, data } = await AxiosStepRegisterProcess({
      step: 1,
      name: name,
      bio: bio,
      profilePicture: profile_picture_url,
      referral
    });

    if (error) {
      toast.error(error);
      setFetching(false);
      return;
    }

    updateUserRedux({
      name,
      profilePicture: profile_picture_url,
      biography: bio,
      registerProcess: 2,
      is_verified: data.isReferred,
      referred: data.isReferred
    });

    userEditSettingsFields({
      name,
      biography: bio,
      profilePicture: {
        blob: null,
        url: profile_picture_url
      }
    });

    if (!data.isReferred) {
      const usernameRef = getUsernameReference();

      Mixpanel.track('Fan Completed profile', {
        name,
        hasImage: !!profile_picture_url,
        referral: 'fan'
      });

      if (usernameRef) {
        router.push(`/${usernameRef}`);
      }
    } else {
      Mixpanel.track('Creator Completed profile', {
        name,
        hasImage: !!profile_picture_url,
        referral
      });
    }
  };

  const handleOnContinue = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!name) {
      toast.error('Debes ingresar un nombre para tu perfil');
      setFetching(false);
      return;
    }

    setSteps([
      { name: 'Completar mi cuenta', status: 'complete' },
      { name: '¿Tienes un código de creador?', status: 'current' },
      { name: 'Importa Linktree', status: 'upcoming' },
      { name: 'Tags', status: 'upcoming' }
    ]);

    setSubStep(1);
  };

  const removeUserFile = () => {
    setImage({
      blob: null,
      url: ''
    });
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files[0];

      e.target.value = null;

      if (!file.type.includes('image'))
        return toast.error('La foto de perfil debe ser una imagen');
      else if (file.size > 10_485_760)
        return toast.error('La imagen no debe superar los 10 MB');

      const blobToUrl = URL.createObjectURL(file);

      setImage({
        blob: file,
        url: blobToUrl
      });
    } catch (e) {
      console.log(e);
      console.log('handleOnChangeInput() Error');
    }
  };

  return (
    <OnboardingContainer>
      <SimpleSteps steps={steps} />
      {subStep == 0 ? (
        <Fragment>
          <form onSubmit={handleOnContinue} className="mt-4">
            <div className="mb-6">
              <h1 className="mb-2 text-2xl font-semibold text-gray-800">
                Completar mi cuenta
              </h1>
              <p className="text-gray-600">
                Añade una imagen, tu nombre y cuéntanos un poco más de ti.{' '}
              </p>
            </div>

            <div className="mb-4 sm:col-span-6">
              <div className="flex items-center">
                <span className="relative inline-block h-[100px] w-[100px] overflow-hidden rounded-full bg-gray-100">
                  {image.url ? (
                    <img
                      src={image.url}
                      alt="profile"
                      className="h-full w-full object-cover"
                    />
                  ) : (
                    <svg
                      className="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  )}
                  <div className="absolute inset-0 rounded-full bg-white bg-opacity-50 opacity-0 transition-all duration-300 hover:opacity-100">
                    <div
                      className="flex h-full w-full cursor-pointer items-center justify-center"
                      onClick={removeUserFile}
                    >
                      <TrashIcon className="h-8 w-8 text-red-400" />
                    </div>
                  </div>
                </span>
                <div className="ml-4 flex">
                  <div className="relative flex cursor-pointer items-center rounded-md border border-gray-300 bg-white py-2 px-4 shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2 focus-within:ring-offset-gray-50 hover:bg-gray-50">
                    <label
                      htmlFor="user-photo"
                      className="cursor-pointer text-base font-medium text-gray-700"
                    >
                      <span className="cursor-pointer">Subir Imagen</span>
                      <span className="sr-only cursor-pointer">user photo</span>
                    </label>
                    <input
                      id="user-photo"
                      name="user-photo"
                      type="file"
                      accept="image/*"
                      onChange={handleOnChangeInput}
                      className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6">
              <LabelRequired title="Nombre" isRequired={true} />
              <Input
                type="text"
                name="name"
                id="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Escribe tu nombre"
              />
            </div>

            <LabelRequired title="Biografía" isRequired={false} />
            <TextArea
              onChange={(e) => setBio(e.target.value)}
              rows={4}
              value={bio}
              name="bio"
              id="bio"
              placeholder="Escribe un poco acerca de tí"
              maxLength={230}
            />
            <CharacterCounter value={bio} maxLength={230} className="mt-1" />

            <BasicButton
              type="submit"
              title="Continuar"
              disabled={!name.length}
              className="mt-6 w-full"
              loading={fetching}
            />
          </form>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mt-4">
            <div className="mb-6">
              <h1 className="mb-2 text-2xl font-semibold text-gray-800">
                ¿Tienes un código de creador?
              </h1>
              <p className="text-gray-600">
                Actualmente nuestra plataforma para creadores esta disponible a
                través de un código de invitación.
              </p>
            </div>

            <Label title="Ingresa tu código" className="mb-1" />
            <Input
              type="text"
              name="referral"
              id="referral"
              onChange={(e) => setReferral(e.target.value)}
              value={referral}
              placeholder="000-000-000"
            />

            <p className="mt-4 text-gray-500">
              ¿Eres creador de contenido?{' '}
              <a
                href="https://sd8yx7081i5.typeform.com/to/eeglTIla"
                className="text-primary underline decoration-1 underline-offset-2"
              >
                Aplica como creador
              </a>
            </p>

            <BasicButton
              title="Continuar"
              onClick={handleOnSubmit}
              disabled={!referral.length}
              className="mt-6 w-full"
              loading={fetching}
            />

            <WhiteButton
              title="Saltar"
              onClick={handleOnSubmit}
              disabled={fetching}
              className="mt-6 w-full"
            />
          </div>
        </Fragment>
      )}
    </OnboardingContainer>
  );
};

export default AddImage;

// Types
import { variantType } from 'components/tailwind/buttons/SecondaryButton';

// Components
import SecondaryButton from 'components/tailwind/buttons/SecondaryButton';
import CardWithAvatarAndActions from 'components/tailwind/cards/CardWithAvatarAndActions';
import Avatar from 'components/tailwind/avatars/Avatar';

const SubCard = ({ sub }) => {
  const hexagonShape =
    sub.fan_data.is_verified &&
    sub.fan_data.linked_accounts.phantom.addr.length > 0;

  return (
    <a
      onClick={() => window.open(`/${sub.fan_data.username}`, '_blank')}
      className="cursor-pointer"
    >
      <CardWithAvatarAndActions
        avatar={
          <Avatar
            profilePicture={sub.fan_data.profilePicture}
            shape={hexagonShape ? 'hexagon' : 'circle'}
            theme={
              sub.fan_data?.customProfile?.theme === 'pride'
                ? 'pride'
                : 'default'
            }
          />
        }
        title={sub.fan_data.name}
        subtitle={sub.fan_data.username}
        actions={
          <SecondaryButton
            title="Ir al perfil"
            variant={variantType.gray}
            onClick={() => window.open(`/${sub.fan_data.username}`, '_blank')}
            size="small"
          />
        }
      />
    </a>
  );
};

export default SubCard;

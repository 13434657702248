import { useEffect, useState } from 'react';
import { AtSymbolIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';

import ReactPortal from 'helpers/ReactPortal';
import useScreen from 'hooks/useScreen';
import { Account } from 'types/accounts';
import { ActionTriggerType } from 'types/actionTriggerType';
import { AxiosAuthSendConfirmationEmail } from 'requests/local_api/AxiosAuthSendConfirmationEmail';
import { Mixpanel } from 'utils/mixpanel';
import { ScreenType } from 'types/screenType';
import { supabase } from 'utils/supabase-client';
import { useCommandMenu } from 'hooks/useCommandMenu';
import { useNotification } from 'hooks/useNotification';
import { useUser } from 'hooks/useUser';

import AnalyticsScreen from 'screens/dashboard/analytics';
import AudienceScreen from 'screens/dashboard/audience';
import BlocksScreen from 'screens/dashboard/blocks';
import ExploreScreen from 'screens/dashboard/explore';
import StreamScreen from 'screens/dashboard/stream_alerts';
import MyShoppingScreen from 'screens/dashboard/my-shopping';
import MySubscriptionsScreen from 'screens/dashboard/my-subscriptions';
import NotificationsScreen from 'screens/dashboard/notifications';
import ProductsScreen from 'screens/dashboard/products';
import SettingsScreen from 'screens/dashboard/settings';

import AppNotifications from 'components/dashboard/AppNotifications';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import MetaDefault from 'components/meta/MetaDefault';
import NotificationWithActions from 'components/tailwind/overlays/NotificationWithActions';
import SideBar from 'components/dashboard/sidebar/Index';
import TopMenu from 'components/dashboard/topMenu';
import VerifyEmailBanner from 'components/dashboard/VerifyEmailBanner';
import { CommandMenu } from 'components/commandMenu';
import { ProfileViewer } from 'components/profileViewer';
import { navigation as navigation_1 } from 'components/dashboard/sidebar/desktop/DK_Navigation';
import { ActionTriggerContext } from 'context/actionTrigger';

const DashboardScreen = () => {
  const { user, status } = useUser();
  const { screen, setScreen } = useScreen();
  const commandMenu = useCommandMenu();

  const {
    getFeaturesNotifications,
    listenFeaturesNotifications,
    status: notificationsStatus
  } = useNotification();
  const [showVerifyNotification, setShowVerifyNotification] = useState(true);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [actionTrigger, setActionTrigger] = useState<ActionTriggerType>(null);

  useEffect(() => {
    if (status !== 1) return;

    getFeaturesNotifications();
  }, [status]);

  useEffect(() => {
    if (notificationsStatus !== 1 || status !== 1) return;

    const { audios, digitalProducts, donations, greetings, questions } =
      listenFeaturesNotifications(user.id);

    return () => {
      audios.off('INSERT');
      digitalProducts.off('INSERT');
      donations.off('INSERT');
      greetings.off('INSERT');
      questions.off('INSERT');
    };
  }, [notificationsStatus, status]);

  useEffect(() => {
    const getValue = localStorage.getItem('mf_dashboard');

    if (!getValue) return;

    const some = navigation_1.some((value) => value.value === getValue);

    if (some && user.is_verified) {
      setScreen(getValue as keyof typeof ScreenType);
    }
  }, []);

  const sendConfirmationEmail = async () => {
    setVerifyLoading(true);
    const { data } = await supabase
      .from('accounts')
      .select('*')
      .eq('id', user.id)
      .single();

    if (data?.is_email_verified) {
      setVerifyLoading(false);
      setShowVerifyNotification(false);
      toast('Tu correo ya ha sido verificado');
      return;
    }

    if (!user) return toast.error('Debes tener una sesión activa');

    const { email } = user;

    if (!email) return toast.error('Debes tener un email registrado');

    const { error } = await AxiosAuthSendConfirmationEmail({ email });

    if (error) return toast.error('Ha ocurrido un error');

    setVerifyLoading(false);
    setShowVerifyNotification(false);
    toast.success('Se envió el correo correctamente');
  };

  return (
    <div>
      <ActionTriggerContext.Provider value={{ action: actionTrigger }}>
        <CommandMenu
          account={user as Account}
          parentOpenDialog={commandMenu.open}
          parentSetOpen={commandMenu.resetState}
          setActionTrigger={setActionTrigger}
          setScreen={setScreen}
        />

        <MetaDefault title={'Dashboard'} />

        <AppNotifications />

        {!user.is_email_verified && user.platform !== 'google' && (
          <ReactPortal wrapperId="notifications-portal">
            <NotificationWithActions
              icon={<AtSymbolIcon className="h-5 w-5 text-yellow-500" />}
              title="Favor de verificar su email"
              urlSound="/sounds/emailMissing.mp3"
              description="Es necesario para algunas funciones"
              actions={
                <BasicButton
                  title="Reenviar Email"
                  onClick={() => {
                    Mixpanel.track('User triggered resend verification email', {
                      username: user.username
                    });
                    sendConfirmationEmail();
                  }}
                  disabled={verifyLoading}
                  loading={verifyLoading}
                  size="mini"
                  cn="mt-2 min-w-[106px] max-w-fit"
                />
              }
              show={showVerifyNotification}
              setShow={setShowVerifyNotification}
              hideCloseIcon={false}
            />
          </ReactPortal>
        )}

        <div className="fixed inset-0 mx-auto flex w-full max-w-[2000px] flex-col">
          {user.registerProcess <= 1 && <VerifyEmailBanner />}

          <div
            className={`flex ${
              user.registerProcess <= 1 ? 'h-[94%]' : 'h-full'
            }`}
          >
            <SideBar screen={screen} setScreen={setScreen} />

            <div className="w-full">
              <TopMenu screen={screen} setScreen={setScreen} />
              <ProfileViewer screen={screen}>
                <div className="relative mx-auto h-full w-full overflow-y-auto px-4 pt-7 pb-40 lg:px-8 lg:pb-14 xl:w-[100%]">
                  {screen === 'blocks' && <BlocksScreen />}

                  {screen === 'audience' && <AudienceScreen />}

                  {screen === 'settings' && <SettingsScreen />}

                  {screen === 'analytics' && <AnalyticsScreen />}

                  {screen === 'notifications' && <NotificationsScreen />}

                  {screen === 'explore' && <ExploreScreen />}

                  {screen === 'my_subscriptions' && <MySubscriptionsScreen />}

                  {screen === 'products' && <ProductsScreen />}

                  {screen === 'products_history' && <MyShoppingScreen />}

                  {screen === 'stream_alerts' && <StreamScreen />}
                </div>
              </ProfileViewer>
            </div>
          </div>
        </div>
      </ActionTriggerContext.Provider>
    </div>
  );
};

export default DashboardScreen;

// Helpers
import { formatPublicKey } from 'helpers/formatData';
import { isProfileImageHexagon } from 'helpers/logicFunctions';

// Components
import CardWithAvatarAndActions from 'components/tailwind/cards/CardWithAvatarAndActions';
import Avatar from 'components/tailwind/avatars/Avatar';
import CircleBadge from 'components/tailwind/badges/CircleBadge';
import WalletIcon from 'components/tailwind/icons/WalletIcon';

// Types
import { IUser } from 'types/User';

interface ICardProps {
  fan_data: IUser;
  addr: string;
}

const Card = ({ fan_data, addr }: ICardProps) => {
  return (
    <CardWithAvatarAndActions
      avatar={
        fan_data && fan_data.profilePicture ? (
          <Avatar
            profilePicture={fan_data.profilePicture}
            shape={isProfileImageHexagon(fan_data) ? 'hexagon' : 'circle'}
            theme={
              fan_data?.customProfile?.theme === 'pride' ? 'pride' : 'default'
            }
          />
        ) : (
          <CircleBadge className="h-[50px] w-[50px] bg-primary-50">
            <WalletIcon />
          </CircleBadge>
        )
      }
      title={fan_data && fan_data.username}
      subtitle={formatPublicKey(addr)}
    />
  );
};

export default Card;

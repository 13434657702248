// Helpers
import { classNames } from 'helpers/classNames';

interface IFormBodyProps {
  className?: string;
}

const FormBody: React.FC<IFormBodyProps> = ({ children, className }) => {
  /* 212px */
  return (
    <div
      className={classNames(
        className,
        'max-h-[calc(100vh-250px)] overflow-y-scroll px-6 pt-4 pb-8 sm:overflow-y-auto'
      )}
    >
      {children}
    </div>
  );
};

export default FormBody;

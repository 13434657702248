/**
 * @see https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
 *
 * @returns
 */
export function detectIOSDevice() {
  return (
    window.indexedDB ||
    window.SpeechSynthesisUtterance ||
    window['webkitAudioContext'] ||
    window.matchMedia ||
    (window.history && 'pushState' in window.history)
  );
}

// Libraries
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';

// Helpers
import { classNames } from 'helpers/classNames';

interface ICollapseProps {
  buttonContent: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

const Collapse: React.FC<ICollapseProps> = ({
  buttonContent,
  children,
  className
}) => {
  return (
    <Disclosure>
      {({ open }) => (
        <Fragment>
          <Disclosure.Button className={classNames(className, 'w-full')}>
            <div className="flex w-full cursor-pointer items-center justify-between">
              {buttonContent}
              <ChevronRightIcon
                className={classNames(
                  open && 'rotate-90 transform',
                  'h-6 w-6 text-gray-500'
                )}
              />
            </div>
          </Disclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel>{children}</Disclosure.Panel>
          </Transition>
        </Fragment>
      )}
    </Disclosure>
  );
};

export default Collapse;

import React from 'react';

// Helpers
import { classNames } from 'helpers/classNames';

// Components
import EmptyIcon from 'components/tailwind/icons/EmptyIcon';

interface IEmptyStateProps {
  absolute?: boolean;
  icon?: React.ReactNode;
  title: string;
  description?: string;
  dashboard?: boolean;
  className?: string;
}

const EmptyState = ({
  absolute = true,
  icon: Icon,
  title,
  description,
  dashboard = false,
  className
}: IEmptyStateProps) => {
  return (
    <div
      className={classNames(
        `dark:border-gray-500 flex items-center justify-center rounded-lg border-gray-200 lg:border-2 lg:border-dashed  ${
          absolute ? 'absolute inset-0' : 'h-full'
        }`,
        className
      )}
    >
      <div className="flex flex-col items-center">
        {dashboard && !Icon ? (
          <>
            <EmptyIcon className="h-8 w-8 text-gray-400" />

            <h3 className="mb-1 mt-2 text-center font-semibold text-gray-900">
              {title}
            </h3>
          </>
        ) : (
          <>
            {Icon}

            <h3 className="mb-1 mt-2 text-center font-semibold text-gray-900">
              {title}
            </h3>
          </>
        )}
      </div>
    </div>
  );
};

export default EmptyState;

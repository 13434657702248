import { useFormik } from 'formik';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import toast from 'react-hot-toast';
import { AxiosAuthSendConfirmationEmail } from 'requests/local_api/AxiosAuthSendConfirmationEmail';
import AxiosUpdateUserSettings from 'requests/local_api/AxiosUpdateUserSettings';
import { Mixpanel } from 'utils/mixpanel';
import { supabase } from 'utils/supabase-client';
import * as Yup from 'yup';

// Components
import OnboardingContainer from 'components/onboarding/OnboardingContainer';
import BasicDivider from 'components/tailwind/dividers/BasicDivider';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import Input from 'components/tailwind/inputs/Input';
import Label from 'components/tailwind/inputs/Label';

const AddEmailForm = () => {
  const { logout, updateUserRedux } = useUser();

  const formik = useFormik({
    initialValues: {
      email: '',
      email2: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email inválido')
        .max(64, 'El email debe tener como máximo 64 carácteres')
        .required('Este campo es obligatorio'),
      email2: Yup.string()
        .required('Este campo es obligatorio')
        .email('El email no es válido')
        .oneOf([Yup.ref('email')], 'Los emails son diferentes')
    }),
    onSubmit: async (values) => {
      try {
        const { data } = await supabase
          .from('accounts')
          .select('id')
          .eq('email', values.email)
          .single();

        if (data) {
          return toast.error(
            'Ese Email ya está registrado',
            defaultToastOptions
          );
        }
        const { error } = await AxiosAuthSendConfirmationEmail(values);

        if (error) {
          formik.errors.email = error;
          return;
        }

        const { error: error2 } = await AxiosUpdateUserSettings({
          email: values.email
        });

        if (error2) {
          toast.error(error2);
        }

        updateUserRedux({ email: values.email });

        toast.success(
          'Se ha enviado un correo de confirmación',
          defaultToastOptions
        );
      } catch (error) {
        console.log(error);
        toast.error('Ha ocurrido un error', defaultToastOptions);
      }
    }
  });
  return (
    <OnboardingContainer>
      <h1 className="mb-2 text-2xl font-semibold text-gray-800">
        Agrega un correo electrónico
      </h1>
      <p className="text-gray-600">
        Para continuar agrega un correo electrónico para recibir notificaciones
        de la actividad en tu cuenta.
      </p>

      <BasicDivider className="my-4" />

      <div className="w-full">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <Label title="Correo electrónico" className="mb-1" />
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="tu@correo.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={(formik.errors.email && formik.touched.email) as boolean}
            />
            {formik.errors.email && formik.touched.email && (
              <ErrorMessage errorText={formik.errors.email} />
            )}
          </div>
          <div className="mb-4">
            <Label title="Confirmar correo electrónico" className="mb-1" />
            <Input
              type="email"
              name="email2"
              id="email2"
              placeholder="tu@correo.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email2}
              error={(formik.errors.email2 && formik.touched.email2) as boolean}
            />
            {formik.errors.email2 && formik.touched.email2 && (
              <ErrorMessage errorText={formik.errors.email2 as string} />
            )}
          </div>

          <BasicButton
            type="submit"
            title="Continuar"
            disabled={!formik.isValid || !formik.dirty}
            loading={formik.isSubmitting}
            className="w-full"
          />
        </form>

        <p className="mt-4 text-gray-500">
          ¿Deseas registrarte con otro método?{' '}
          <button
            onClick={() => {
              Mixpanel.track(
                'Clicked on "Regresar" button in AddEmailForm for Twitter Account'
              );
              logout();
            }}
            className="text-primary underline decoration-1 underline-offset-2"
          >
            Regresar
          </button>
        </p>
      </div>
    </OnboardingContainer>
  );
};

export default AddEmailForm;

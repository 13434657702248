import React, { useEffect, useState } from 'react';
import { TrashIcon, XIcon, PlusIcon } from '@heroicons/react/solid';

import { classNames } from 'helpers/classNames';
import AddImageIcon from 'assets/icons/AddImage';
import { determineFileType, FileType, MimeTypes } from 'types/MimeTypes';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { formatBytes } from 'utils/formatBytes';
import SpinnerLoader from 'components/SpinnerLoader';

enum FileInputError {
  failedLoadingBlob = 'Ha habido un error cargando la información del Archivo digital. Por favor contacta con el equipo de soporte.',
  generalMimeTypeValidationError = 'El archivo que has intentado subir no tiene un formato generalmente válido por nuestra plataforma.',
  mimeTypeNotSupportedError = 'El archivo no tiene un formato válido.',
  onDropFileError = 'Ha habido un error al cargar el archivo.'
}

const generalMimeTypeSupport = Object.values(MimeTypes);

enum InputFileState {
  loading = 'loading',
  showUrl = 'showUrl',
  empty = 'empty',
  showFile = 'showFile',
  showInfoFile = 'showInfoFile',
  loadingFile = 'loadingFile'
}

interface FileInputProps {
  file: File | null;
  mimeTypesSupported?: Array<string>;
  previsualize?: boolean;
  onClickInputDetailsHelp?: () => void;
  parentLoading?: boolean;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
  setFile: React.Dispatch<React.SetStateAction<File>>;
  url?: string;
}

const SquareFileInput = (props: FileInputProps) => {
  const {
    mimeTypesSupported,
    onClickInputDetailsHelp,
    parentLoading,
    setError,
    setErrorMessage,
    setFile
  } = props;

  const [url, setUrl] = useState<string>(null);
  const [internalFile, setInternalFile] = useState<File>(null);
  const [fileType, setFileType] = useState<FileType>(null);
  const [fileUrl, setFileUrl] = useState<string>(null);
  const [dragEnter, SetDragEnter] = useState<boolean>(false);
  const [previsualize] = useState<boolean>(
    props.previsualize == undefined ? true : props.previsualize
  );
  const [inputState, setInputState] =
    useState<keyof typeof InputFileState>('empty');

  useEffect(() => {
    initialFileRender();
  }, [props.url]);

  useEffect(() => {
    if (fileUrl != null) setInputState('showFile');
  }, [fileUrl]);

  const initialFileRender = async () => {
    if (props.url) {
      if (previsualize) {
        setUrl(props.url);
        setInputState('showUrl');
      } else {
        setInputState('loadingFile');

        const blob = await fetch(url)
          .then((res) => res.blob())
          .catch((error) => new Error(error));

        if (blob instanceof Error) {
          return setErrorMessage(FileInputError.failedLoadingBlob);
        }

        const file = new File([blob], 'digital-product');
        setInternalFile(file);
        setInputState('showInfoFile');
      }
    }
  };

  const loadFileToState = async (rawFile: File) => {
    // General mimetypes validation for avoid misspelling or discarding weird types.
    if (generalMimeTypeSupport.some((mts) => mts === rawFile.type) == false) {
      throwError(FileInputError.generalMimeTypeValidationError);
    }

    if (mimeTypesSupported) {
      if (mimeTypesSupported.some((mts) => mts === rawFile.type) == false) {
        throwError(FileInputError.mimeTypeNotSupportedError);
      }

      setFile(rawFile);
      setInternalFile(rawFile);
    }

    const type = determineFileType(rawFile.type as MimeTypes);
    setFileType(type);

    if (type == FileType.image && previsualize) {
      setFileUrl(URL.createObjectURL(rawFile));
    } else {
      setInternalFile(rawFile);
      setInputState('showInfoFile');
    }
  };

  const throwError = (error: FileInputError) => {
    setErrorMessage && setErrorMessage(error);
    setError && setError(true);
  };

  const onClickRemoveInput = () => {
    setFile(null);
    setUrl(null);
    setFileUrl(null);
    setInputState('empty');
  };

  const onClickInputHelp = () => {
    onClickInputDetailsHelp && onClickInputDetailsHelp();
  };

  const onClickInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    loadFileToState(e.target.files[0]);
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    SetDragEnter(true);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    SetDragEnter(false);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const file = e.dataTransfer.files[0];

      loadFileToState(file);
      SetDragEnter(false);
    } catch (e) {
      throwError(FileInputError.onDropFileError);
    }
  };

  const render = (
    <div
      className={classNames(
        parentLoading && 'bg-gray-100',
        (inputState == 'empty' ||
          inputState == 'showInfoFile' ||
          inputState == 'loadingFile') &&
          'rounded-md border-[3px] border-dashed border-gray-300',
        inputState == 'showFile' && '',
        'group relative flex h-40 w-40 cursor-pointer items-center justify-center'
      )}
    >
      {(inputState == 'showUrl' || inputState == 'showFile') &&
        parentLoading == false && (
          <div
            className={classNames(
              dragEnter && 'bg-gray-100 opacity-70',
              'absolute inset-0 flex rounded-md'
            )}
            onDragEnter={(e) => !parentLoading && onDragEnter(e)}
            onDragLeave={(e) => !parentLoading && onDragLeave(e)}
            onDragOver={(e) => !parentLoading && onDragEnter(e)}
            onDrop={(e) => !parentLoading && onDrop(e)}
          >
            <label
              className={
                'flex h-full w-full cursor-pointer items-center justify-center bg-white bg-opacity-50 opacity-0 transition duration-200 ease-in-out group-hover:opacity-70'
              }
            >
              <PlusIcon className="absolute h-9 w-9 text-primary" />
              <input
                className={'hidden'}
                id={'iw_inputFileCover'}
                onChange={onClickInput}
                type={'file'}
                disabled={parentLoading}
              />
            </label>
            <div
              className={
                'absolute -top-2 -right-2 z-50 m-auto cursor-pointer rounded-full bg-white bg-opacity-100 p-1 opacity-0 shadow-lg transition duration-200 ease-in-out hover:bg-gray-200 group-hover:opacity-100'
              }
              onClick={onClickRemoveInput}
            >
              <XIcon className="h-3 w-3 text-gray-500" />
            </div>
          </div>
        )}
      {inputState == 'empty' && (
        <div
          className={classNames(
            dragEnter && 'bg-gray-100 opacity-70',
            'my-4 flex h-full w-full flex-col items-center justify-center text-sm font-medium'
          )}
          onDragEnter={(e) => !parentLoading && onDragEnter(e)}
          onDragLeave={(e) => !parentLoading && onDragLeave(e)}
          onDragOver={(e) => !parentLoading && onDragEnter(e)}
          onDrop={(e) => !parentLoading && onDrop(e)}
        >
          <label
            className={
              'flex gap-1 flex-col h-full w-full cursor-pointer items-center justify-center pr-1'
            }
          >
            <AddImageIcon className="h-9 w-9 fill-transparent stroke-gray-500 group-hover:stroke-primary" />
            <input
              className={'hidden'}
              id={'iw_inputFileCover'}
              onChange={onClickInput}
              type={'file'}
              disabled={parentLoading}
              accept={
                mimeTypesSupported
                  ? mimeTypesSupported.join(',')
                  : 'image/*,video/*,audio/*'
              }
            />
            <span className='text-gray-400 w-[70%] text-center text-sm leading-5 font-medium'>JPG, PNG hasta 10MB</span>
          </label>
        </div>
      )}
      {inputState == 'showFile' && (
        <div className={'h-full w-full rounded-md bg-gray-100'}>
          {
            <img
              alt={'a'}
              src={fileUrl}
              className="h-full w-full rounded-md object-cover"
            />
          }
        </div>
      )}
      {inputState == 'showUrl' && (
        <img
          alt={'a'}
          className="h-full w-full rounded-md object-cover"
          src={url}
        />
      )}
      {inputState == 'showInfoFile' && (
        <div
          className={
            'my-4 flex h-full w-full flex-col items-center justify-center text-sm font-medium'
          }
        >
          <p className={'inline-block text-center text-gray-600'}>
            {`${internalFile.name} - ${formatBytes(internalFile.size)}`}
          </p>
        </div>
      )}
      {inputState == 'loadingFile' && (
        <div
          className={
            'my-4 flex h-full w-full flex-col items-center justify-center text-sm font-medium'
          }
        >
          <SpinnerLoader color="#542ae2" className="h-6 w-6" />
        </div>
      )}
    </div>
  );

  return render;
};

export default SquareFileInput;

import HeaderScreen from 'components/dashboard/HeaderScreen';
import CustomProfile from 'components/dashboard/settings/customProfile/CustomProfile';
import DeleteAccountButton from 'components/dashboard/settings/DeleteAccountButton';
import MetamaskButton from 'components/dashboard/settings/linkAccounts/MetamaskButton';
import PaypalButton from 'components/dashboard/settings/linkAccounts/PaypalButton';
import PhantomButton from 'components/dashboard/settings/linkAccounts/PhantomButton';
import LinkTreeButton from 'components/dashboard/settings/linkAccounts/LinkTreeButton';
import StripeConnectButton from 'components/dashboard/settings/linkAccounts/StripeConnectButton';
import SaveChanges from 'components/dashboard/settings/SaveChanges';
import SocialMediaInputs from 'components/dashboard/settings/socialMedia/SocialMediaInputs';
import CoverImage from 'components/dashboard/settings/userPicture/CoverImage2';
import ProfileImage from 'components/dashboard/settings/userPicture/ProfileImage2';
import Web3Settings from 'components/dashboard/settings/web3/Web3Settings';
import CharacterCounter from 'components/tailwind/inputs/CharacterCounter';
import Input from 'components/tailwind/inputs/Input';
import InputWithAddOn from 'components/tailwind/inputs/InputWithAddOn';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import TextArea from 'components/tailwind/inputs/TextArea';
import { useUser } from 'hooks/useUser';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import AxiosApplyReferal from 'requests/local_api/user/AxiosApplyReferal';
import { supabase } from 'utils/supabase-client';

// Helpers
import { classNames } from 'helpers/classNames';

// Hooks
import useApp from 'hooks/useApp';

// Components
import BasicButton from 'components/tailwind/buttons/BasicButton';
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import CopyRegularIcon from 'components/tailwind/icons/CopyRegularIcon';
import copyToClipboard from 'utils/copyToClipboard';
import FormModal from 'components/tailwind/overlays/FormModal';
import MailIcon from 'components/tailwind/icons/MailIcon';
import ChevronRight from 'components/tailwind/icons/ChevronRight';
import AxiosSendReferralCodeByEmail from 'requests/local_api/AxiosSendReferralCodeByEmail';

type TReferalCode = {
  code: string;
  loading: boolean;
  error: string;
};

type TMultiReferralCode = {
  code: string;
  createdAt: string;
  generated_by: string;
  generated_for: string;
  id: number;
  intents: number;
  is_multi_active: boolean;
  is_multi_use: boolean;
  updatedAt: string;
  used_at: string;
  used_by: string;
  used_by_object: any;
};

const SettingsScreen = () => {
  const [userHaveChanges, setUserHaveChanges] = useState<boolean>(false);
  const [isConnectEnabled, setIsConnectEnabled] = useState<boolean>(false);
  const [platformConnected, setPlatformConnected] = useState<string>('');
  const [referalCode, setReferalCode] = useState<TReferalCode>({
    code: '',
    loading: false,
    error: ''
  });
  const [multiReferralCode, setMultiReferralCode] =
    useState<TMultiReferralCode>(null);
  const [isSendReferralCodeModalOpen, setIsSendReferralCodeModalOpen] =
    useState<boolean>(false);
  const [sendReferralEmail, setSendReferralEmail] = useState<string>('');
  const {
    userEdit,
    userEditSettingsFields,
    user,
    getIsConnectAccountEnabled,
    updateUserRedux
  } = useUser();
  const {
    app: { isProfileViewerOpen }
  } = useApp();

  useEffect(() => {
    const fetchIsConnectEnabled = async () => {
      if (user.linked_accounts?.paypal?.merchant_id) {
        setIsConnectEnabled(true);
        setPlatformConnected('paypal');
        return;
      }

      const isValid = await getIsConnectAccountEnabled();
      setIsConnectEnabled(isValid);

      if (isValid) {
        setPlatformConnected('stripe');
      }
    };
    fetchIsConnectEnabled(), [];
  });

  const handleOnChangeInputs = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;

    if (userEdit[targetName] === undefined) return;

    userEditSettingsFields({
      [targetName]: targetValue
    });
  };

  const handleValidateReferalCode = async () => {
    setReferalCode({
      ...referalCode,
      loading: true
    });
    const response = await AxiosApplyReferal({
      referral: referalCode.code,
      userId: user.id
    });

    if (response.message == 'OK') {
      toast.success('Código de referido aplicado con éxito!');
      setReferalCode({
        code: '',
        loading: false,
        error: 'false'
      });
      updateUserRedux(response.data);
    } else {
      setReferalCode({
        ...referalCode,
        error: response.message
      });
      return;
    }
  };

  useEffect(() => {
    const userHaveMultiReferralCodeEnabled = async () => {
      const { data, error } = await supabase
        .from('referreds')
        .select('*')
        .eq('generated_for', user.id)
        .single();

      if (error) {
        console.log(error);
        // toast.error(error.message);
      }

      if (data) {
        setMultiReferralCode(data);
      }
    };
    userHaveMultiReferralCodeEnabled();
  }, []);

  const onClickButtonCopyReferralCode = async (code: string) => {
    const result = await copyToClipboard(code);

    if (!(result instanceof Error))
      toast.success(
        '¡Se ha copiado el código de invitación en tu portapapeles!'
      );
  };

  return (
    <div
      className={`transition-all duration-300 ${
        userHaveChanges ? 'pb-20 sm:pb-8' : 'pb-0'
      }`}
    >
      <HeaderScreen title="Ajustes" />
      <div className="mb-6 rounded-lg border-gray-200 bg-white md:border md:p-6 md:shadow-sm">
        <div className="mb-4">
          <h3 className="text-lg font-medium text-gray-900">Perfil</h3>
          <p className="text-base text-gray-500">
            Personaliza tu perfil con tu imagen, foto de portada, biografía y
            color
          </p>
        </div>

        <div className="mb-6">
          <LabelRequired
            title="Foto de perfil"
            className="mb-[14px]"
            isRequired={false}
          />
          <ProfileImage />
        </div>

        {(user.is_verified || user.referred) && (
          <div className="mb-6 transition-all duration-1000">
            <LabelRequired
              title="Imagen de fondo"
              className="mb-1"
              isRequired={false}
            />
            <CoverImage />
          </div>
        )}

        <div className="mb-6">
          <LabelRequired title="Nombre" className="mb-1" isRequired={true} />
          <Input
            placeholder="Nombre Apellido"
            name="name"
            value={userEdit.name}
            onChange={handleOnChangeInputs}
          />
        </div>

        <div className="mb-6">
          <LabelRequired
            title="Nombre de usuario"
            isRequired={true}
            className="mb-1"
          />
          <InputWithAddOn
            name="username"
            addOn="mis.fans"
            placeholder="usuario"
            value={userEdit.username}
            onChange={handleOnChangeInputs}
          />
        </div>

        <div className="mb-6">
          <LabelRequired
            title="Correo electrónico"
            className="mb-1"
            isRequired={true}
          />
          <Input
            className="mb-6"
            placeholder="tu@correo.com"
            type="email"
            name="email"
            value={userEdit.email}
            //! Only google accounts can't change email
            disabled={user.platform === 'google'}
            onChange={handleOnChangeInputs}
          />
        </div>

        {(user.is_verified || user.referred) && (
          <div className="mb-5">
            <LabelRequired
              title="Biografía"
              isRequired={false}
              className="mb-1"
            />
            <TextArea
              placeholder="Cuéntale al mundo lo que haces"
              name="biography"
              value={userEdit.biography}
              onChange={handleOnChangeInputs}
              maxLength={230}
            />
            <CharacterCounter
              value={userEdit.biography}
              maxLength={230}
              className="mt-2"
            />
          </div>
        )}

        {user.is_verified && (
          <div className="mb-5">
            <LabelRequired
              title="Selecciona tu color de acento"
              isRequired={false}
              className="mb-1"
            />
            <CustomProfile />
          </div>
        )}

        <SaveChanges
          userHaveChanges={userHaveChanges}
          setUserHaveChanges={setUserHaveChanges}
        />
      </div>
      {(user.is_verified || user.referred) && (
        <div className="mb-9 rounded-lg border-gray-200 bg-white transition-all duration-1000 md:mb-6 md:border md:p-6 md:shadow-sm">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900">
              Redes sociales
            </h3>
            <p className="text-base text-gray-500">
              En esta sección puedes agregar tus redes sociales, recuerda
              hacerlo para que tus fans no se pierdan ninguna novedad!
            </p>
          </div>

          <SocialMediaInputs />
        </div>
      )}
      {!user.is_verified && (
        <div className="mb-9 rounded-lg border-gray-200 bg-white transition-all duration-1000 md:mb-6 md:border md:p-6 md:shadow-sm">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900">
              Código de referido
            </h3>
            <p className="text-sm text-gray-500">
              Desbloquea todas las herramientas para creadores y comienza a
              monetizar tu audiencia.
            </p>
          </div>

          <Input
            className="mb-1"
            placeholder="000-000-000"
            name="referalCode"
            value={referalCode.code}
            onChange={(e) =>
              setReferalCode((prevState) => ({
                ...prevState,
                code: e.target.value
              }))
            }
          />
          {referalCode.error && (
            <p className="text-sm text-red-500">{referalCode.error}</p>
          )}
          <BasicButton
            className="mt-5 w-full"
            type="button"
            title="Aplicar código"
            onClick={handleValidateReferalCode}
            loading={referalCode.loading}
            disabled={referalCode.loading || referalCode.code.length < 3}
          />
        </div>
      )}
      <div className="mb-9 rounded-lg border-gray-200 bg-white transition-all duration-1000 md:mb-6 md:border md:p-6 md:shadow-sm">
        <div className="mb-4">
          <h3 className="text-lg font-medium text-gray-900">Integraciones</h3>
          <p className="text-base text-gray-500">
            En esta sección podrás agregar las integraciones necesarias para
            recibir tus ingresos
          </p>
        </div>

        <div
          className={classNames(
            isProfileViewerOpen ? 'lg:grid-cols-1' : 'lg:grid-cols-2',
            'grid gap-3 transition-all duration-1000 md:gap-5 xl:grid-cols-2'
          )}
        >
          {user.is_verified && (
            <>
              <StripeConnectButton
                isConnectActive={isConnectEnabled}
                platform={platformConnected}
              />
              <PaypalButton
                isConnectActive={isConnectEnabled}
                platform={platformConnected}
              />
            </>
          )}

          <PhantomButton />
          <MetamaskButton />
          <LinkTreeButton />
        </div>
      </div>
      {user.is_verified && (
        <div className="mb-9 rounded-lg border-gray-200 bg-white transition-all duration-1000 md:mb-6 md:border md:p-6 md:shadow-sm">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900">Web 3</h3>
            <p className="text-base text-gray-500">
              En esta sección podrás activar funciones con tu wallet de Solana.
            </p>
          </div>

          <div
            className={classNames(
              isProfileViewerOpen ? 'lg:grid-cols-1' : 'lg:grid-cols-2',
              'grid gap-3 transition-all duration-1000 md:gap-5 xl:grid-cols-2'
            )}
          >
            <Web3Settings />
          </div>
        </div>
      )}
      {multiReferralCode?.is_multi_use && multiReferralCode?.is_multi_active && (
        <div className="mb-9 rounded-lg border-gray-200 bg-white transition-all duration-1000 md:mb-6 md:border md:p-6 md:shadow-sm">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900">
              Mi código de invitación
            </h3>
            <p className="text-base text-gray-500">
              Tiene {multiReferralCode?.intents} invitaciones restantes
            </p>
          </div>

          <div className="mb-6 flex w-full">
            <Input
              parentClassName="w-full"
              placeholder="tu código de invitación"
              name="code"
              value={multiReferralCode?.code}
              disabled={true}
              onChange={() => {}}
            />
            <SecondaryButton
              className="ml-2"
              LeftIcon={<MailIcon className=" w-[22px] text-gray-500" />}
              onClick={() => setIsSendReferralCodeModalOpen(true)}
              size="small"
              variant={variantType.gray}
            />
            <SecondaryButton
              className="ml-2"
              LeftIcon={<CopyRegularIcon className=" w-[18px] fill-gray-500" />}
              onClick={() =>
                onClickButtonCopyReferralCode(multiReferralCode?.code)
              }
              size="small"
              variant={variantType.gray}
            />
          </div>
        </div>
      )}
      <div className="mb-9 rounded-lg border-gray-200 bg-white md:mb-6 md:border md:p-6 md:shadow-sm">
        <div className="mb-4">
          <h3 className="text-lg font-medium text-gray-900">
            Borrar mi cuenta
          </h3>
          <p className="text-sm text-gray-500">
            Esta acción es irreversible. Para borrar tu cuenta deberás de contar
            con todas tus solicitudes completadas
          </p>
        </div>
        <DeleteAccountButton />
      </div>
      <FormModal
        open={isSendReferralCodeModalOpen}
        setOpen={setIsSendReferralCodeModalOpen}
        title={'Enviar código via email'}
      >
        <div className="mx-6 my-4 flex">
          <Input
            parentClassName="w-full"
            id="email"
            name="email"
            placeholder="creator@mis.fans"
            value={sendReferralEmail}
            onChange={(e) => setSendReferralEmail(e.target.value)}
          />
          <SecondaryButton
            className="ml-2"
            LeftIcon={<ChevronRight className=" w-[18px]" />}
            onClick={async () => {
              await AxiosSendReferralCodeByEmail({
                code: multiReferralCode?.code,
                email: sendReferralEmail,
                username: user.username
              });
              setIsSendReferralCodeModalOpen(false);
              setTimeout(() => {
                setSendReferralEmail('');
                toast.success('Código enviado!!!');
              }, 300);
            }}
            size="small"
            variant={variantType.gray}
          />
        </div>
      </FormModal>
    </div>
  );
};

export default SettingsScreen;

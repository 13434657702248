// Components
import BlockSkeleton from 'components/dashboard/blocks/BlockSkeleton';
import EmptyState from 'components/dashboard/EmptyState';
import ErrorState from 'components/dashboard/ErrorState';
import DesktopHistoryList from './desktopHistoryList';

interface ProductHistoryScreenProps {
  error: any;
  data: any;
}

/** In charge of rendering the history of purchases of Digital Products.
 */
const ProductHistoryScreen = (props: ProductHistoryScreenProps) => {
  const { error, data } = props;

  const errorPurchase = (
    <ErrorState
      absolute={false}
      description={
        'No hemos podido cargar tu historial de compras, por favor contacta con soporte.'
      }
      title={'Oops!'}
    />
  );

  const loaderPurchases = (
    <BlockSkeleton quantity={8} className={'flex flex-col gap-2'} />
  );

  const emptyPurchases = (
    <EmptyState
      absolute={false}
      dashboard={true}
      title={'Aún no tienes ningún producto adquirido.'}
      className="flex-1"
    />
  );

  const purchasesList = <DesktopHistoryList purchases={data} />;

  if (error === undefined && data === undefined) {
    return loaderPurchases;
  } else if (error) {
    return errorPurchase;
  }

  if (data && data.length > 0) return purchasesList;

  return emptyPurchases;
};

export default ProductHistoryScreen;

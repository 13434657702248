import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import SpinnerLoader from 'components/tailwind/loaders/SpinnerLoader';
import { defaultToastOptions } from 'helpers/toastOptions';
import toast from 'react-hot-toast';
import { Account, Block } from 'types/accounts';
import { DigitalProduct } from 'types/digitalProduct';
import { IUserEdit } from 'types/User';
import { supabase } from 'utils/supabase-client';

// Helpers
import { Mixpanel } from 'utils/mixpanel';

// Components
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import BoxOpenFaLight from 'components/tailwind/icons/BoxOpenFaLight';
// import Switch from 'components/tailwind/inputs/Switch';

enum CreateDigitalProductBlockFormError {
  loadDigitalProductsFailure = 'Ha habido un problema obteniendo tus productos digitales. Por favor contacta con soporte.'
}

interface CreateDigitalProductBlockFormProps {
  blocks: Block[];
  closeModal: () => void;
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  user: Account;
  updateUserRedux: (payload: IUserEdit) => {
    type: string;
    payload: IUserEdit;
  };
}

const CreateDigitalProductBlockForm = (
  props: CreateDigitalProductBlockFormProps
) => {
  const { blocks, handleClose, closeModal, setBlocks, user, updateUserRedux } =
    props;
  const router = useRouter();

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [digitalProducts, setDigitalProducts] = useState<Array<DigitalProduct>>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selected, setSelected] = useState<DigitalProduct>(null);
  const [onTop, setOnTop] = useState(true);

  useEffect(() => {
    loadDigitalProductList();
  }, []);

  const loadDigitalProductList = async () => {
    let { data, error } = await supabase
      .from<DigitalProduct>('digital_products')
      .select('*')
      .filter('creator_account_id', 'eq', user.id)
      .filter('is_deleted', 'eq', false)
      .order('creation_date', {
        ascending: true
      });

    if (error) {
      setErrorMessage(
        CreateDigitalProductBlockFormError.loadDigitalProductsFailure
      );
      return setError(true);
    } else {
      // Remove all products that already exists as blocks!
      setDigitalProducts(
        data.filter((value, index, array) => {
          if (!value.id) return false;

          return !blocks.some(
            (block) =>
              block.type == 'digitalProduct' &&
              block.digitalProductId == value.id
          );
        })
      );
    }

    setLoading(false);
  };

  const onClickCreateBlock = async () => {
    setSubmitting(true);
    setLoading(true);

    const newBlock: Block = {
      currency: selected.currency,
      digitalProductId: selected.id,
      id: uuidv4(),
      image: selected.placeholder,
      price: selected.price,
      title: selected.name,
      type: 'digitalProduct',
      url: `${process.env.NEXT_PUBLIC_URL}/${user.username}/${selected.product_uuid}`,
      visible: true
    };

    const newBlocksPosition = onTop
      ? [newBlock, ...user.blocks]
      : [...user.blocks, newBlock];

    const { data, error } = await supabase
      .from('accounts')
      .update({
        blocks: user.blocks ? newBlocksPosition : [newBlock]
      })
      .eq('id', user.id);

    if (error) {
      toast.error(error.message, defaultToastOptions);
      setLoading(false);
    }

    if (data) {
      Mixpanel.track('Added block', {
        username: user.username,
        type: 'digital-product'
      });
      updateUserRedux(data[0]);
      setBlocks(data[0].blocks);
      toast.success('Bloque Añadido', defaultToastOptions);
      setLoading(false);
      handleClose();
    }
  };

  const onClickDigitalProduct = (digitalProduct: DigitalProduct) => {
    if (selected == null) return setSelected(digitalProduct);

    if (digitalProduct.id == selected.id) return setSelected(null);
    else return setSelected(digitalProduct);
  };

  const onClickGoToMyProductsPage = () => {
    // TODO this navigation should be improved, reloading isn't the way.
    localStorage.setItem('mf_dashboard', 'products');
    router.reload();
  };

  const onClickCancel = () => {
    closeModal();
  };

  const loadingDigitalProducts = (
    <div className={'flex flex-col items-center justify-items-center py-24'}>
      <div className={'mb-2'}>
        <SpinnerLoader color={'#542ae2'} size={12} />
      </div>
      <p>Cargando los productos digitales</p>
    </div>
  );

  const errorBlockForm = (
    <div className={'my-24 mx-auto flex w-1/3 flex-col items-center'}>
      <ExclamationCircleIcon
        className={'mb-2 h-12 w-12 items-center text-gray-400'}
      />
      <h3 className={'mb-1 text-center text-xl'}>Oops! Algo ha pasado</h3>
      <p
        className={
          'dark:text-gray-400 text-center text-base font-semibold text-gray-900'
        }
      >
        {errorMessage}
      </p>
    </div>
  );

  const emptyDigitalProductList = (
    <>
      <div className={'my-24 mx-auto flex w-3/4 flex-col items-center'}>
        <BoxOpenFaLight className="mb-4 h-[100px] w-[100px] text-gray-500" />
        <h3 className="mb-2 text-center font-semibold text-gray-800">
          ¡No tienes más productos por mostrar!
        </h3>
        <p className="mb-4 text-center text-sm text-gray-900">
          Crea un nuevo producto para poder añadirlo a tus bloques.
        </p>
        <WhiteButton
          disabled={loading}
          onClick={onClickGoToMyProductsPage}
          title="Ir a Productos"
          className="w-[155px]"
        />
      </div>
      <FormFooter className="h-[82px]"></FormFooter>
    </>
  );

  const digitalProductList = (
    <>
      <FormBody>
        <LabelRequired
          isRequired={true}
          title="Selecciona un producto"
          className="mb-2"
        />
        <ul
          role={'list'}
          className={classNames(
            submitting ? 'opacity-50 transition ease-in-out' : '',
            'grid grid-cols-3 gap-x-6 gap-y-6 pb-8'
          )}
        >
          {digitalProducts.map((digitalProduct, index) => (
            <li className={'relative'} key={index}>
              <div
                className={classNames(
                  selected != null && selected.id == digitalProduct.id
                    ? 'ring-2 ring-indigo-500 ring-offset-2 ring-offset-gray-100 transition ease-in-out'
                    : 'transition ease-in-out',
                  'group aspect-w-8 aspect-h-8 block w-full overflow-hidden rounded-lg bg-gray-100'
                )}
              >
                <img
                  src={digitalProduct.placeholder}
                  alt={''}
                  className={classNames(
                    submitting ? '' : 'group-hover:opacity-80',
                    'delay-30 pointer-events-none object-cover transition ease-in-out'
                  )}
                />
                <button
                  className={classNames(
                    submitting ? '' : 'focus:outline-none',
                    'absolute inset-0'
                  )}
                  disabled={loading || submitting}
                  onClick={() => onClickDigitalProduct(digitalProduct)}
                  type={'button'}
                >
                  <span className={'sr-only'}>{digitalProduct.name}</span>
                </button>
              </div>
              <p
                className={
                  'pointer-events-none mt-2 block truncate text-center text-sm font-medium text-gray-700'
                }
              >
                {digitalProduct.name}
              </p>
            </li>
          ))}
        </ul>
        {/* //! Logic to add on Top or bottom of the block stack */}
        {/* <div>
          <Switch show={onTop} setShow={() => setOnTop(!onTop)} />
          <span>Logic to Add to top or bottom, true for top</span>
        </div> */}
      </FormBody>

      <FormFooter>
        <WhiteButton
          title="Cancelar"
          onClick={onClickCancel}
          disabled={loading}
          className="px-6"
        />
        <BasicButton
          title="Añadir"
          disabled={loading || selected == null}
          loading={submitting}
          onClick={onClickCreateBlock}
          className="w-40"
          loadingChildren="Añadiendo"
        />
      </FormFooter>
    </>
  );

  if (loading) return loadingDigitalProducts;

  if (error) return errorBlockForm;

  if (loading == false && digitalProducts.length == 0)
    return emptyDigitalProductList;

  return digitalProductList;
};

export default CreateDigitalProductBlockForm;

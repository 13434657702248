import { CubeIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';

// Helpers
import { isProfileImageHexagon } from 'helpers/logicFunctions';

// Components
import Avatar from 'components/tailwind/avatars/Avatar';

const dayjs = require('dayjs');
dayjs.locale(require('utils/dayjsCustomPlugins/es'));
dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('utils/dayjsCustomPlugins/isBeforeYesterday'));

const DonationCard = ({ notification }) => {
  const [showMore, setShowMore] = useState(false);
  const [imageIsLoading, setImageIsLoading] = useState(true);

  useEffect(() => {
    const image = new Image();
    image.src = notification.gif;
    image.addEventListener('load', () => {
      setImageIsLoading(false);
    });
  }, []);

  return (
    <div>
      <div className="mt-5 flex items-start text-base text-gray-500">
        <div className="">
          <Avatar
            profilePicture={notification.fan_data?.profilePicture}
            shape={
              isProfileImageHexagon(notification.fan_data)
                ? 'hexagon'
                : 'circle'
            }
            size="sm"
            theme={
              notification.fan_data?.customProfile?.theme === 'pride'
                ? 'pride'
                : 'default'
            }
          />
        </div>
        <div className="ml-3">
          <p className="mb-1 text-base text-gray-500">
            {dayjs(notification.createdAt).isToday() ? (
              <>
                Hoy{' '}
                <span className="text-sm italic">
                  {dayjs(notification.createdAt).format('HH:mm')}
                </span>
              </>
            ) : dayjs(notification.createdAt).isYesterday() ? (
              <>
                Ayer{' '}
                <span className="text-sm italic">
                  {dayjs(notification.createdAt).format('HH:mm')}
                </span>
              </>
            ) : dayjs(notification.createdAt).isBeforeYesterday() ? (
              <>
                Antier{' '}
                <span className="text-sm italic">
                  {dayjs(notification.createdAt).format('HH:mm')}
                </span>
              </>
            ) : (
              <>
                {dayjs(notification.createdAt).format('DD [de] MMMM')}{' '}
                <span className="text-sm italic">
                  {dayjs(notification.createdAt).format('HH:mm')}
                </span>
              </>
            )}
          </p>

          <div className="mb-1 text-gray-700">
            <a
              className={`font-semibold ${
                notification.fan_data?.username
                  ? 'cursor-pointer text-primary underline'
                  : 'cursor-default'
              }`}
              href={
                notification.fan_data?.username
                  ? `/${notification.fan_data?.username}`
                  : '#'
              }
              target={notification.fan_data?.username ? '_blank' : '_self'}
            >

              { notification.name || 'un fan'}
            </a>{' '}
            te envió una donación de{' '}
            <a className="font-semibold text-mf-purple">
              ${notification.amount} USD
            </a>
          </div>
          <p className="mb-1 text-ellipsis text-base font-medium leading-6 text-mf-purple lg:mr-40">
            {showMore
              ? notification.message
              : notification.message.length > 55
              ? notification.message.substring(0, 55) + '...'
              : notification.message}
          </p>
          {notification.message.length > 55 && (
            <p
              className="cursor-pointer text-xs hover:text-white"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? 'Ver menos...' : 'Ver más...'}
            </p>
          )}
          {console.log(notification.gif)}
          {notification.gif &&
            (imageIsLoading ? (
              <div className="h-[6.25rem] w-[12.5rem] animate-pulse rounded-lg bg-slate-200"></div>
            ) : (
              <img
                src={notification.gif}
                alt="profile"
                className="mb-2 h-auto max-w-[12.5rem] rounded object-cover"
              />
            ))}
        </div>
      </div>{' '}
      <div className="mt-5 h-divider w-full bg-gray-300"></div>
    </div>
    // <div className="mt-4 flex rounded-md  shadow-sm">
    //   {notification.gif && (
    //     <img
    //       src={notification.gif}
    //       alt="profile"
    //       className="mr-2 h-auto w-[6rem] rounded-none rounded-bl-md rounded-tl-md object-cover"
    //     />
    //   )}
    //   <div className="px-4 py-4 sm:px-6">
    //     <div className="flex justify-between">
    //       <div className="">
    //         <div className="flex items-center text-base text-gray-500">
    //           {notification.gif === '' && (
    //             <div className="mr-2 flex max-h-[2.25rem] min-h-[2.25rem] min-w-[2.25rem] max-w-[2.25rem] items-center justify-center rounded-full bg-primary-100">
    //               {notification?.fan_data?.profilePicture ? (

    //                 <img
    //                   src={notification?.fan_data?.profilePicture}
    //                   alt="profile"
    //                   className="h-9 w-9 rounded-full object-cover"
    //                 />
    //               ) : (
    //                 <CubeIcon
    //                   className="h-6 w-6 text-primary"
    //                   aria-hidden="true"
    //                 />
    //               )}
    //             </div>
    //           )}

    //           <div>
    // <p className="-mb-1 text-sm text-gray-500">
    //   {dayjs(notification.createdAt).isToday()
    //     ? 'Hoy'
    //     : dayjs(notification.createdAt).isYesterday()
    //     ? 'Ayer'
    //     : dayjs(notification.createdAt).isBeforeYesterday()
    //     ? 'Antier'
    //     : dayjs(notification.createdAt).format('DD [de] MMMM')}
    // </p>
    //             <div className="text-lg font-semibold text-gray-700">
    //               {notification.fan_data?.username ||
    //                 notification.name ||
    //                 'un fan'}{' '}
    //               te envió una donación de ${notification.amount} USD
    //             </div>
    //             <p className="text-ellipsis text-sm text-gray-500 lg:mr-40">
    //               {showMore
    //                 ? notification.message
    //                 : notification.message.length > 55
    //                 ? notification.message.substring(0, 55) + '...'
    //                 : notification.message}
    //             </p>
    //             {notification.message.length > 55 && (
    //               <p
    //                 className="cursor-pointer text-xs hover:text-white"
    //                 onClick={() => setShowMore(!showMore)}
    //               >
    //                 {showMore ? 'Ver menos...' : 'Ver más...'}
    //               </p>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default DonationCard;

import RectangleSkeleton from 'components/tailwind/loaders/RectangleSkeleton';

type TableSkeletonProps = {
  className?: string;
  rowClassName?: string;
  numberOfColumns?: number;
  numberOfRows?: number;
};

const TableSkeleton: React.FC<TableSkeletonProps> = ({
  className,
  rowClassName,
  numberOfColumns,
  numberOfRows
}) => {
  return (
    <tbody className="animate-pulse divide-y divide-gray-200 bg-white">
      {[...Array(numberOfRows)].map((row, id) => (
        <tr key={id} className={rowClassName}>
          {[...Array(numberOfColumns)].map((column, id) => (
            <td className="h-12 whitespace-nowrap py-4 px-3 text-sm font-medium">
              <RectangleSkeleton className="mx-auto h-5 w-24 bg-slate-200" />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableSkeleton;

import { useState, Fragment } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { defaultToastOptions } from 'helpers/toastOptions';
import { UseFormikResult } from 'types/formikTypes';
import { SupportIcon } from '@heroicons/react/solid';
import { useNotification } from 'hooks/useNotification';
import { Audio } from 'types/monetization';
import AudioPlayer from '../../../audioPlayer';
import uploadFileAWS from 'libs/AwsStorage';
import { AxiosApi } from 'requests/AxiosBase';
import { AudioActions } from './audioActions';

// Helpers
import { classNames } from 'helpers/classNames';

// Form Modal
import FormModal from 'components/tailwind/overlays/FormModal';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import BasicDivider from 'components/tailwind/dividers/BasicDivider';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import StaticInput from 'components/tailwind/inputs/StaticInput';
import Label from 'components/tailwind/inputs/Label';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import TextArea from 'components/tailwind/inputs/TextArea';
import CharacterCounter from 'components/tailwind/inputs/CharacterCounter';
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import { detectIOSDevice } from 'helpers/detectiOS';

const dayjs = require('dayjs');
dayjs.locale(require('utils/dayjsCustomPlugins/es'));
dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('dayjs/plugin/isYesterday'));
dayjs.extend(require('utils/dayjsCustomPlugins/isBeforeYesterday'));

interface AudioCardForm {
  reason: string;
}

interface AudioNotificationModalProps {
  notification: Audio;
  onCloseModal: () => void;
  openModal: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}

const AudioNotificationModal = (props: AudioNotificationModalProps) => {
  const { notification, onCloseModal, openModal, setLoading, loading } = props;

  const [audioCreated, setAudioCreated] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [audio, setAudio] = useState<string>(null);
  const [audioFile, setAudioFile] = useState<File>(null);

  const notificationStatusMap = {
    pending: 'Responder audio',
    rejected: 'Audio rechazado',
    done: 'Audio respondido'
  };

  const { answerAudioNotification, rejectAudioNotification } =
    useNotification();

  const formik: UseFormikResult = useFormik<AudioCardForm>({
    initialValues: {
      reason: notification.reasonOfRejection
        ? notification.reasonOfRejection
        : ''
    },
    validationSchema: Yup.object({
      reason: Yup.string()
        .required('Este campo es requerido')
        .max(200, 'La razón no puede ser mayor a 200 caracteres')
    }),
    onSubmit: async (values) => await onSubmit(values)
  });

  const onSubmit = async (values: AudioCardForm) => {
    setLoading(true);
    const { message, error } = await rejectAudioNotification(
      values.reason,
      notification.id
    );

    if (error) {
      toast.error(error.message, defaultToastOptions);
    }

    if (message === 'OK') {
      toast.success('Respuesta Rechazada con éxito', defaultToastOptions);
    }

    onCloseModal();
    setLoading(false);
  };

  const cleanStates = () => {
    setAudio(null);
    setAudioFile(null);
    setAudioCreated(false);
  };

  const getAudioUrl = (url: string) => {
    setAudio(url);
    setAudioCreated(true);
  };

  const getAudioFile = (file: File) => {
    setAudioFile(file);
    setAudioCreated(true);
  };

  const onClickSendResponse = async () => {
    setLoading(true);
    let file: File | null = null;

    const uri: string | undefined = process.env.NEXT_PUBLIC_API_AUDIO_CONVERSOR;

    if (uri === undefined) {
      const msg =
        'Ha habido un problema cargando el audio. Por favor contacta con nuestro equipo de soporte.';
      setLoading(false);
      setMessage(msg);
      return toast.error(msg);
    }

    if (audio) {
      const response = await fetch(audio);
      const data = await response.blob();
      file = new File([data], notification.id.toString(), {
        type: data.type
      });
    }

    if (audioFile) {
      file = audioFile;
    }

    if (detectIOSDevice()) {
      const result = await uploadFileAWS({
        contentType: 'audio/mpeg',
        file: file,
        folder: 'audios/original',
        metadata: {
          process: 'monetizationAudioResponse',
          name: 'audio',
          userId: notification.creator_account_id.toString()
        }
      });

      if (result instanceof Error) {
        const msg =
          'Ha habido un problema cargando el audio. Por favor contacta con nuestro equipo de soporte.';
        setLoading(false);
        setMessage(msg);
        return toast.error(msg);
      }

      await audioWasSuccess({ original: result });
    } else {
      const formData = new FormData();
      formData.append('audio', file);
      formData.append('process', 'monetizationAudioResponse');
      formData.append('userId', notification.creator_account_id.toString());

      const headers = new Headers();

      headers.append('Access-Control-Allow-Origin', '*');

      const result: { converted: string; original: string } | Error =
        await fetch(`${uri}/api/audios`, {
          body: formData,
          method: 'POST',
          headers
        })
          .then(async (res) => {
            if (res.status > 299) return new Error(await res.json());
            else return await res.json();
          })
          .catch((e) => {
            return new Error(e);
          });

      if (result instanceof Error) {
        const msg =
          'Ha habido un problema cargando el audio. Por favor contacta con nuestro equipo de soporte.';
        setLoading(false);
        setMessage(msg);
        return toast.error(msg);
      }

      await audioWasSuccess(result);
    }
  };

  const audioWasSuccess = async (result: {
    converted?: string;
    original: string;
  }) => {
    const { message, error: notificationError } = await answerAudioNotification(
      result.original,
      notification.id,
      result.converted
    );

    if (notificationError) {
      const msg =
        'Ha habido un problema cargando el audio. Por favor contacta con nuestro equipo de soporte.';
      setLoading(false);
      setMessage(msg);
      return toast.error(msg);
    }

    if (message === 'OK') {
      toast.success('Respuesta enviada');
    }

    if (notification.uuid !== undefined) {
      await AxiosApi.post('/email/audio-response', {
        creator_id: String(notification.creator_account_id),
        audio_id: Number(notification.id)
      });
    }

    setLoading(false);
    setMessage('');
    onCloseModal();
  };

  const _onCloseModal = () => {
    setShowReject(false);
    onCloseModal();
  };

  return (
    <FormModal
      open={openModal}
      setOpen={_onCloseModal}
      title={
        showReject
          ? 'Rechazar solicitud'
          : notificationStatusMap[notification.status]
      }
    >
      {showReject ? (
        <form onSubmit={formik.handleSubmit}>
          <FormBody
            className={classNames(
              notification.status === 'done' && 'max-h-[calc(100vh-200px)]'
            )}
          >
            <div>
              <Label title="Fecha" className="mb-1" />
              <StaticInput
                value={dayjs(notification.creation_date).format(
                  'DD/MM/YYYY HH:mm'
                )}
              />
            </div>

            <div className="mt-4">
              <Label title="Para" className="mb-1" />
              <StaticInput
                value={notification.name ? notification.name : 'Anónimo'}
              />
            </div>

            <div className="mt-4">
              <Label title="Instrucciones" className="mb-1" />
              <StaticInput
                value={
                  notification.comments
                    ? notification.comments
                    : 'Sin comentarios'
                }
                type="textArea"
              />
            </div>

            <BasicDivider className="my-4" />

            <div>
              <LabelRequired
                name="reason"
                title="Motivo de rechazo"
                isRequired={true}
              />
              <div className="mt-2">
                <TextArea
                  name="reason"
                  id="reason"
                  placeholder="Escribe el motivo de por que estas rechazando esta solicitud."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reason}
                  error={
                    (formik.errors.reason && formik.touched.reason) as boolean
                  }
                  rows={4}
                  className="mb-2"
                  maxLength={230}
                />
                <CharacterCounter
                  value={formik.values.reason}
                  maxLength={230}
                />
              </div>
              {formik.errors.reason && formik.touched.reason && (
                <ErrorMessage errorText={formik.errors.reason as string} />
              )}
            </div>

            <div className="mt-4 text-sm text-gray-500 ">
              Esta acción es irreversible. El rechazo de la solicitud pasará a
              revisión de nuestro equipo para determinar si viola nuestros
              Términos de servicio.
            </div>
          </FormBody>

          <FormFooter>
            <WhiteButton
              title="Cancelar"
              onClick={_onCloseModal}
              disabled={formik.isSubmitting}
              className="px-5"
            />
            <SecondaryButton
              type="submit"
              title="Rechazar"
              className="w-40"
              disabled={!formik.isValid || !formik.dirty}
              variant={variantType.red}
              loading={formik.isSubmitting}
              loadingChildren="Rechazando"
            />
          </FormFooter>
        </form>
      ) : (
        <Fragment>
          <FormBody>
            <div>
              <Label title="Fecha" className="mb-1" />
              <StaticInput
                value={dayjs(notification.creation_date).format(
                  'DD/MM/YYYY HH:mm'
                )}
              />
            </div>

            <div className="mt-4">
              <Label title="Para" className="mb-1" />
              <StaticInput
                value={notification.name ? notification.name : 'Anónimo'}
              />
            </div>

            <div className="mt-4">
              <Label title="Instrucciones" className="mb-1" />
              <StaticInput
                value={
                  notification.comments
                    ? notification.comments
                    : 'Sin comentarios'
                }
                type="textArea"
              />
            </div>

            {notification.status === 'pending' && (
              <div className="mt-4">
                <label htmlFor="inputFile">
                  <Label title="Selecciona una opción" className="mb-2" />
                </label>
                <AudioActions
                  audioFile={audioFile}
                  cleanStates={cleanStates}
                  setAudioFile={getAudioFile}
                  setAudioUrl={getAudioUrl}
                />

                <BasicDivider className="mt-4 mb-6" />
                <SecondaryButton
                  title="Rechazar solicitud"
                  onClick={() => setShowReject(true)}
                  variant={variantType.red}
                  className="w-full"
                  disabled={loading}
                />
              </div>
            )}

            {notification.status === 'done' && (
              <div className="mt-4">
                <Label title="Tu Audio enviado" className="mb-1" />
                <AudioPlayer
                  setAudioUrl={getAudioUrl}
                  audio={
                    notification.url
                      ? notification.url
                      : notification.originalUrl
                  }
                  onlyPlay
                />
              </div>
            )}

            {notification.status === 'rejected' && (
              <div>
                <BasicDivider className="my-4" />
                <Label title="Motivo de rechazo" className="mb-2" />
                <StaticInput
                  value={notification.reasonOfRejection}
                  type="textArea"
                />
              </div>
            )}
          </FormBody>

          {notification.status === 'pending' && (
            <FormFooter>
              <WhiteButton
                title="Cancelar"
                onClick={onCloseModal}
                disabled={formik.isSubmitting || loading}
                className="px-6"
              />
              <BasicButton
                title="Enviar"
                onClick={onClickSendResponse}
                disabled={audioCreated == false}
                loading={loading}
                loadingChildren="Enviando"
                className="w-40"
              />
            </FormFooter>
          )}

          {notification.status === 'rejected' && (
            <FormFooter>
              <WhiteButton
                title="Contactar al soporte"
                id="iw_supportBtn"
                size="medium"
                className="w-full"
                leftIcon={<SupportIcon className="h-5 w-5 text-gray-500" />}
              />
            </FormFooter>
          )}
        </Fragment>
      )}
    </FormModal>
  );
};

export default AudioNotificationModal;

import ExploreHeaderRight from 'components/dashboard/explore/ExploreHeaderRight';
import ProfileCard from 'components/dashboard/explore/ProfileCard';
import ProfileCardSkeleton from 'components/dashboard/explore/ProfileCardSkeleton';
import HeaderScreen from 'components/dashboard/HeaderScreen';
import Input from 'components/form/Input';
import React, { useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import {
  AxiosGetSuggestions,
  AxiosSearchByTag,
  AxiosSearchUser
} from 'requests/local_api/explore/AxiosExplore';
import EmptyState from 'components/dashboard/EmptyState';

// Components
import SearchFaRegular from 'components/tailwind/icons/SearchFaRegular';
import InputWithLeadingIcon from 'components/tailwind/inputs/InputWithLeadingIcon';

let timeout: any = undefined;

export type Tag = {
  id: number;
  name: string;
  value: string;
};

const ExploreScreen = () => {
  const [users, setUsers] = useState({
    status: 0,
    message: '',
    data: [],
    suggestions: []
  });
  const effectFunc = async () => {
    const { error, data } = await AxiosGetSuggestions();

    if (error) {
      setUsers((prev) => ({
        ...prev,
        status: 1,
        message: error
      }));
      return;
    }

    setUsers((prev) => ({
      ...prev,
      status: 2,
      data,
      suggestions: data
    }));
  };

  const getUsersBySearch = async (value: string) => {
    if (!value) {
      setUsers((prev) => ({
        ...prev,
        data: prev.suggestions,
        status: 2
      }));
      return;
    }

    setUsers((prev) => ({
      ...prev,
      status: 0
    }));

    const { error, data } = await AxiosSearchUser(value);

    if (error) {
      setUsers((prev) => ({
        ...prev,
        status: 1,
        message: error
      }));
      return;
    }

    setUsers((prev) => ({
      ...prev,
      status: 2,
      data
    }));
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      getUsersBySearch(e.target.value);
    }, 300);
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const search = formData.get('search') as string;

    if (timeout) {
      clearTimeout(timeout);
    }

    getUsersBySearch(search);
  };

  const getUsersByTag = async (tagId: number) => {
    setUsers((prev) => ({
      ...prev,
      status: 0
    }));

    const { error, data } = await AxiosSearchByTag(tagId);

    if (error) {
      setUsers((prev) => ({
        ...prev,
        status: 1,
        message: error
      }));
      return;
    }

    setUsers((prev) => ({
      ...prev,
      status: 2,
      data
    }));
  };

  const handleOnClickTag = (newTag: Tag) => {
    if (newTag.id === 0) {
      setUsers((prev) => ({
        ...prev,
        status: 0
      }));
      effectFunc();
    } else {
      getUsersByTag(newTag.id);
    }
  };

  return (
    <div className="flex min-h-full flex-col">
      <HeaderScreen
        title="Explorar"
        RightComponent={
          <ExploreHeaderRight handleOnClickTag={handleOnClickTag} />
        }
      />

      <div className="mb-8 flex flex-col gap-4 md:flex-row md:items-center">
        <form className="flex-grow" onSubmit={handleOnSubmit}>
          <InputWithLeadingIcon
            Icon={<HiSearch className="mr-1 h-5 w-5 text-gray-400" />}
            placeholder="Buscar por nombre o usuario"
            name="search"
            onChange={handleOnChangeInput}
          />
        </form>
      </div>

      <h3 className="mb-4 text-lg font-medium text-gray-600">
        Perfiles que te pueden interesar
      </h3>

      {users.status === 0 && (
        <ProfileCardSkeleton className="mb-4" quantity={5} />
      )}

      {users.status === 1 && <h3>{users.message}</h3>}

      {users.status === 2 &&
        (users.data[0] === undefined ? (
          <EmptyState
            absolute={false}
            title="No hay resultados para esta búsqueda"
            dashboard={true}
            icon={
              <SearchFaRegular className="h-[39px] w-[39px] text-gray-400" />
            }
            className="flex-1"
          />
        ) : (
          <div className="flex flex-col gap-4">
            {users.data.map((value, index) => {
              const hexagonShape =
                value.is_verified &&
                value.linked_accounts.phantom.addr.length > 0;

              return (
                <ProfileCard
                  key={index}
                  username={value.username}
                  name={value.name}
                  profile_picture={value.profilePicture}
                  hexagonShape={hexagonShape}
                  theme={
                    value.customProfile?.theme === 'pride' ? 'pride' : 'default'
                  }
                />
              );
            })}
          </div>
        ))}
    </div>
  );
};

export default ExploreScreen;

import { useFormik } from 'formik';
import { classNames } from 'helpers/classNames';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { supabase } from 'utils/supabase-client';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import Link from 'next/link';

// Helpers
import { prepareNFTBlock } from 'requests/local_next/web3';

// Components
import BasicButton from 'components/tailwind/buttons/BasicButton';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import Input from 'components/tailwind/inputs/Input';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
// import Switch from 'components/tailwind/inputs/Switch';
import { Mixpanel } from 'utils/mixpanel';

const AddNftShowCaseForm = ({
  closeModal,
  setBlocks,
  setLoading,
  handleClose
}) => {
  const { user, updateUserRedux } = useUser();
  const [onTop, setOnTop] = useState(true);

  const formik: any = useFormik({
    initialValues: {
      address: ''
    },
    validationSchema: Yup.object({
      address: Yup.string().required('El address del NFT es requerido')
    }),
    onSubmit: async (values) => {
      setLoading(true);

      const nftMetadata = await prepareNFTBlock(values.address);

      const newBlock = {
        ...values,
        id: uuidv4(),
        visible: true,
        type: 'nftShowcase',
        nftBlockData: nftMetadata
      };
      //
      const newBlocksPosition = onTop
        ? [newBlock, ...user.blocks]
        : [...user.blocks, newBlock];

      if (nftMetadata.error) {
        toast.error(nftMetadata.error, defaultToastOptions);
        setLoading(false);
      } else {
        const { data, error } = await supabase
          .from('accounts')
          .update({
            blocks: user.blocks ? newBlocksPosition : [newBlock]
          })
          .eq('id', user.id);

        if (error) {
          toast.error(error.message, defaultToastOptions);
          setLoading(false);
        }

        if (data) {
          Mixpanel.track('Added block', {
            username: user.username,
            type: 'nftShowcase'
          });
          updateUserRedux(data[0]);
          setBlocks(data[0].blocks);
          toast.success('Bloque Añadido', defaultToastOptions);
          setLoading(false);
          handleClose();
        }
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormBody>
        <LabelRequired
          name="address"
          title="Solana Token Address"
          isRequired={true}
        />
        <div className="mt-1">
          <Input
            type="text"
            name="address"
            id="address"
            placeholder="Pega aquí el address del token"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            error={formik.errors.address && formik.touched.address}
          />
          <Link href="https://misfans.gitbook.io/misfans/guias/agregar-mi-primer-bloque/tipos-de-bloques/nft-showcase">
            <a className="mt-1 text-[12px] text-gray-700 hover:cursor-pointer hover:underline">
              ¿Como encontrar el address de un NFT?
            </a>
          </Link>
        </div>
        {formik.errors.address && formik.touched.address && (
          <ErrorMessage errorText={formik.errors.address} />
        )}
        {/* //! Logic to add on Top or bottom of the block stack */}
        {/* <div>
          <Switch show={onTop} setShow={() => setOnTop(!onTop)} />
          <span>Logic to Add to top or bottom, true for top</span>
        </div> */}
      </FormBody>

      <FormFooter>
        <WhiteButton
          title="Cancelar"
          onClick={closeModal}
          disabled={formik.isSubmitting}
          className="px-6"
        />
        <BasicButton
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          loading={formik.isSubmitting}
          title="Añadir"
          className="w-40"
          loadingChildren="Añadiendo"
        />
      </FormFooter>
    </form>
  );
};

export default AddNftShowCaseForm;

import { Grid } from 'react-loader-spinner';

const BuildingProfile = () => {
  return (
    <section className="flex h-screen w-screen flex-col items-center justify-center bg-white">
      <Grid color="#542AE2" />
      <h1 className="mt-5 lg:text-xl">Estamos configurando tu perfil...</h1>
    </section>
  );
};

export default BuildingProfile;

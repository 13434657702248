// Libraries
import toast from 'react-hot-toast';
import { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { ExclamationIcon } from '@heroicons/react/outline';

// Hooks
import { useNotification } from 'hooks/useNotification';

// Helpers
import { supabase } from 'utils/supabase-client';
import { useUser } from 'hooks/useUser';

// Components
import ModalHandler from 'components/tailwind/overlays/ModalHandler';
import SimpleAlert from 'components/tailwind/overlays/SimpleAlert';
import CircleBadge from 'components/tailwind/badges/CircleBadge';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import ActionButton from 'components/tailwind/buttons/ActionButton';

const DeleteAccountButton: React.FC = () => {
  const { logout, user } = useUser();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { notifications } = useNotification();

  const handleDeleteAccount = async () => {
    setLoading(true);
    if (notifications.pendingCount > 0) {
      toast.error(
        'No puedes eliminar tu cuenta si tienes solicitudes pendientes'
      );
      setLoading(false);
    } else {
      const { data, error } = await supabase
        .from('accounts')
        .delete()
        .eq('id', user.id);

      if (error) {
        toast.error('No se pudo eliminar la cuenta. Intente más tarde');
        setLoading(false);
      }
      if (data) {
        logout();
        setLoading(false);
      }
    }
  };

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        disabled={loading}
        className="flex justify-center gap-2 rounded-md bg-red-100 px-4 py-2 text-base font-medium text-red-700 shadow-sm hover:bg-red-200 disabled:cursor-not-allowed disabled:opacity-70"
      >
        {loading ? (
          <TailSpin color="#B91C1C" width={20} height={20} />
        ) : (
          'Borrar mi cuenta'
        )}
      </button>
      <SimpleAlert
        open={open}
        setOpen={() => setOpen(false)}
        circleBadge={
          <CircleBadge color="red">
            <ExclamationIcon className="h-6 w-6 text-red-500" />
          </CircleBadge>
        }
        title="Estás a punto de borrar tu cuenta"
        textContent="Toda tu información será removida de nuestros servidores para
                  siempre. Esta acción no puede desahacerse."
        actions={
          <>
            <ActionButton
              variant="red"
              title="Borrar"
              disabled={loading}
              onClick={handleDeleteAccount}
            />
            <WhiteButton
              type="button"
              title="Cancelar"
              onClick={() => setOpen(false)}
              disabled={loading}
              className="min-w-[134px]"
            />
          </>
        }
      />
    </>
  );
};

export default DeleteAccountButton;

import { Fragment, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Listbox, Transition } from '@headlessui/react';
import {
  CurrencyDollarIcon,
  LinkIcon,
  MinusIcon,
  MusicNoteIcon,
  PhotographIcon,
  PlusIcon,
  ShoppingBagIcon
} from '@heroicons/react/solid';
import MediaFa from 'components/tailwind/icons/MediaFa';

import { Account } from 'types/accounts';
import { ActionTriggerBlocks } from 'types/actionTriggerType';
import { ActionTriggerContext } from 'context/actionTrigger';

import { FaShopify } from 'react-icons/fa';
import { classNames } from 'helpers/classNames';
import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';

import AddDividerForm from './forms/dividerForms/AddDividerForm';
import AddLinkForm from './forms/linkForms/AddLinkForm';
import AddMediaForm from './forms/mediaForms/AddMediaForm';
import AddMonetizationForm from './forms/monetizationForms/AddMonetizationForm';
import AddNftShowCase from './forms/nftShowCaseForms/AddNftShowCaseForm';
import CreateDigitalProductBlockForm from './forms/digitalProductForms/CreateDigitalProductBlockForm';
import ShopifyProductForm from './forms/shopifyProductForms/ShopifyProductForm';
import GlosetProductForm from './forms/glosetProductForms/GlosetProductForm';

// Components
import FormModal from 'components/tailwind/overlays/FormModal';


const blockTypes = [
  {
    id: 1,
    name: 'Enlace',
    modalTitle: 'Añadir enlace',
    type: 'link',
    icon: LinkIcon
  },
  {
    id: 2,
    name: 'Monetización',
    modalTitle: 'Añadir bloque de monetización',
    type: 'monetization',
    icon: CurrencyDollarIcon
  },
  {
    id: 6,
    name: 'Producto Digital',
    modalTitle: 'Añadir Producto Digital',
    type: 'digitalProductSingle',
    icon: ShoppingBagIcon
  },
  {
    id: 7,
    name: 'Producto de Shopify',
    modalTitle: 'Añadir producto de Shopify',
    type: 'shopify_product',
    icon: FaShopify
  },
  {
    id: 8,
    name: 'Producto de Gloset',
    modalTitle: 'Añadir producto de Gloset',
    type: 'gloset_product',
    icon: ShoppingBagIcon
  },
  {
    id: 3,
    name: 'Media',
    modalTitle: 'Añadir bloque de media',
    type: 'media',
    icon: MediaFa 
  },
  {
    id: 4,
    name: 'NFT Showcase',
    modalTitle: 'Añadir un NFT Showcase',
    type: 'nftShowcase',
    icon: PhotographIcon
  },
  {
    id: 5,
    name: 'Separador',
    modalTitle: 'Añadir un separador',
    type: 'divider',
    icon: MinusIcon
  }
];

export default function Select({ blocks, setBlocks }) {
  const { action } = useContext(ActionTriggerContext);

  const [selected, setSelected] = useState(blockTypes[-1]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isConnectAccountEnabled, setIsConnectAccountEnabled] = useState(false);

  const { getIsConnectAccountEnabled, user, status, updateUserRedux } =
    useUser();

  useEffect(() => {
    // It's important to use setTimeout. For some reason the first immediate
    // render don't want to change the "selected" state.
    setTimeout(() => setActionTriggered(), 300);
  }, [action]);

  useEffect(() => {
    const fetchIsConnectAccountEnabled = async () => {
      if (status !== 1) return;
      else if (user.linked_accounts?.paypal?.merchant_id) {
        setIsConnectAccountEnabled(true);
        return;
      }

      const isValid = await getIsConnectAccountEnabled();
      setIsConnectAccountEnabled(isValid);
    };

    fetchIsConnectAccountEnabled();
  }, [status, user]);

  useEffect(() => {
    if (selected?.id >= 9) {
      setOpen(false);
      setSelected(undefined);
      toast('Bajo desarrollo', { ...defaultToastOptions, icon: '🛠' });
      return;
    }

    if (selected !== null && selected !== undefined) {
      setOpen(true);
    }
  }, [selected]);

  const setActionTriggered = () => {
    switch (action) {
      case ActionTriggerBlocks.addBlockDivider:
        setSelected(blockTypes.find((b) => b.id === 5));
        break;
      case ActionTriggerBlocks.addBlockLink:
        setSelected(blockTypes.find((b) => b.id === 1));
        break;
      case ActionTriggerBlocks.addBlockMedia:
        setSelected(blockTypes.find((b) => b.id === 3));
        break;
      case ActionTriggerBlocks.addBlockMonetization:
        setSelected(blockTypes.find((b) => b.id === 2));
        break;
      case ActionTriggerBlocks.addBlockNFT:
        setSelected(blockTypes.find((b) => b.id === 4));
        break;
      case ActionTriggerBlocks.addBlockProduct:
        setSelected(blockTypes.find((b) => b.id === 6));
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelected(undefined);
  };

  useEffect(() => {
    if (!open) {
      setSelected(undefined);
    }
  }, [open]);

  return (
    <>
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button className="relative w-[166px] cursor-pointer rounded-md border border-transparent bg-mf-purple py-3 px-[13.5px] shadow-sm hover:bg-mf-purple-hover active:outline-none active:ring-2 active:ring-mf-purple active:ring-offset-2">
                <span className="flex items-center">
                  <span className="pointer-events-none flex items-center">
                    <PlusIcon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-3 text-base font-medium leading-6 text-white">
                    Añadir Bloque
                  </span>
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute right-0 z-10 mt-1 w-fit min-w-[166px] overflow-auto whitespace-nowrap rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {blockTypes.map((blockType) => {
                    // To disable monetization add modal if connect account is not enabled
                    if (
                      (blockType.id === 2 || blockType.id === 6) &&
                      !isConnectAccountEnabled
                    ) {
                      return null;
                    }

                    else if (
                      blockType.id === 2 &&
                      user.internal_options.blocks?.greeting === false &&
                      user.internal_options.blocks?.question === false &&
                      user.internal_options.blocks?.audio === false
                    ) return null

                    else if (
                      blockType.id === 6 &&
                      user.internal_options.blocks?.digital_product === false
                    ) return null

                    else if (
                      blockType.id === 4 &&
                      user.internal_options.blocks?.nft_collection_hub === false
                    ) return null

                    return (
                      <Listbox.Option
                        key={blockType.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'group relative cursor-pointer select-none py-2 pl-3 pr-9 text-sm transition-all duration-300'
                          )
                        }
                        value={blockType}
                      >
                        {({ selected }) => (
                          <>
                            <div className="group flex items-center">
                              {blockType.icon && (
                                <blockType.icon className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                              )}
                              <span
                                className={classNames(
                                  'ml-3 block truncate text-sm font-normal'
                                )}
                              >
                                {blockType.name}
                              </span>
                            </div>
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {selected && (
        <FormModal open={open} setOpen={setOpen} title={selected.modalTitle}>
          {selected.id === 1 && (
            <AddLinkForm
              closeModal={handleClose}
              setBlocks={setBlocks}
              setLoading={setLoading}
              handleClose={handleClose}
            />
          )}
          {selected.id === 2 && (
            <AddMonetizationForm
              closeModal={handleClose}
              setBlocks={setBlocks}
              setLoading={setLoading}
              handleClose={handleClose}
            />
          )}
          {selected.id === 3 && (
            <AddMediaForm
              closeModal={handleClose}
              setBlocks={setBlocks}
              setLoading={setLoading}
              handleClose={handleClose}
            />
          )}
          {selected.id === 4 && (
            <AddNftShowCase
              closeModal={handleClose}
              setBlocks={setBlocks}
              setLoading={setLoading}
              handleClose={handleClose}
            />
          )}
          {selected.id === 5 && (
            <AddDividerForm
              closeModal={handleClose}
              setBlocks={setBlocks}
              setLoading={setLoading}
              handleClose={handleClose}
            />
          )}
          {selected.id === 6 && (
            <CreateDigitalProductBlockForm
              blocks={blocks}
              closeModal={handleClose}
              setBlocks={setBlocks}
              setLoading={setLoading}
              handleClose={handleClose}
              user={user as unknown as Account}
              updateUserRedux={updateUserRedux}
            />
          )}
          {selected.id === 7 && (
            <ShopifyProductForm
              onClickCancel={handleClose}
              setBlocks={setBlocks}
            />
          )}
          {selected.id === 8 && (
            <GlosetProductForm
              onClickCancel={handleClose}
              setBlocks={setBlocks}
            />
          )}
        </FormModal>
      )}
    </>
  );
}

// Libraries
import { LinkIcon } from '@heroicons/react/solid';
import { DownloadIcon } from '@heroicons/react/outline';

// Components
import CalendarFa from 'components/tailwind/icons/CalendarFa';
import FileFa from 'components/tailwind/icons/FileFa';
import MoneyFa from 'components/tailwind/icons/MoneyFa';
import ChartLineUpFa from 'components/tailwind/icons/ChartLineUpFa';

interface IPurchaseSubtitleProps {
  date: string | number;
  fileType: string;
  price: number | string;
  fileTypeIcon?: 'fileProtected' | 'externalLink' | 'default';
  subTitleType?: 'products' | 'purchases';
}

const PurchaseSubtitle: React.FC<IPurchaseSubtitleProps> = (props) => {
  const {
    date,
    fileType,
    price,
    fileTypeIcon = 'default',
    subTitleType = 'purchases'
  } = props;

  return (
    <section className="flex gap-[10px] sm:gap-6">
      <article className="flex items-center gap-1">
        {subTitleType === 'purchases' ? (
          <CalendarFa className="h-5 w-5 text-primary-400" />
        ) : (
          <ChartLineUpFa className="h-5 w-5 text-primary-400" />
        )}
        <span className="text-sm font-medium text-gray-700">{date}</span>
      </article>

      <article className="flex items-center gap-1">
        <MoneyFa className="h-5 w-5 text-primary-400" />
        <span className="text-sm font-medium text-gray-700">${price} USD</span>
      </article>

      <article className="hidden items-center gap-1 sm:flex">
        {fileTypeIcon === 'externalLink' && (
          <LinkIcon className="h-5 w-5 text-primary-400" />
        )}
        {fileTypeIcon === 'fileProtected' && (
          <DownloadIcon className="h-5 w-5 text-primary-400" />
        )}
        {fileTypeIcon === 'default' && (
          <FileFa className="h-5 w-5 text-primary-400" />
        )}
        <span className="text-sm font-medium text-gray-700 underline underline-offset-2">
          {fileType}
        </span>
      </article>
    </section>
  );
};

export default PurchaseSubtitle;

// Libraries
import { useState } from 'react';

import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import toast from 'react-hot-toast';
import { postDataV2 } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';
import { supabase } from 'utils/supabase-client';
import Card from './Card';

interface IStripeConnectButtonProps {
  isConnectActive: boolean;
  platform: string;
}

const StripeConnectButton = ({
  isConnectActive,
  platform
}: IStripeConnectButtonProps) => {
  const { user, updateUserRedux } = useUser();
  const [fetching, setFetching] = useState<boolean>(false);

  const handleOnClick = async () => {
    if (user.internal_options?.links_accounts?.stripe?.enabled === false) return

    setFetching(true);
    if (!user.connect_id) {
      const account = await postDataV2({
        url: '/api/create-connect-account'
      });

      if (account.id) {
        const accountId: string = account.id;

        const { error } = await supabase
          .from('accounts')
          .update({ connect_id: accountId })
          .eq('id', user.id);

        if (error) {
          return toast.error(error.message, defaultToastOptions);
        }

        updateUserRedux({
          ...user,
          connect_id: accountId
        });

        const _account = {
          account: accountId
        };

        const connect_link = await postDataV2({
          url: '/api/create-connect-link',
          data: _account
        });

        if (connect_link.url) {
          setFetching(false);

          Mixpanel.track('Click on create Stripe Connect Account', {
            username: user.username
          });

          return window.open(connect_link.url, '_blank');
        }
        setFetching(false);
      }
      setFetching(false);
    } else if (user.connect_id) {
      const _account = {
        account: user.connect_id
      };

      const connect_link = await postDataV2({
        url: '/api/create-connect-link',
        data: _account
      });

      if (connect_link.url) {
        setFetching(false);
        return window.open(connect_link.url, '_blank');
      }
      setFetching(false);
    }
  };

  return (
    <Card
      img="/img/icons/stripe.svg"
      imgBackgroundColor="#989FED"
      title="Stripe"
      description="Recibe pagos con Stripe."
      buttonText={
        isConnectActive && platform === 'stripe' ? 'Conectado' : 'Conectar'
      }
      buttonClassName={
        isConnectActive && platform === 'stripe'
          ? 'bg-primary text-white'
          : 'border border-gray-300 text-gray-700 bg-white focus:ring-2 focus:ring-primary focus:ring-offset-2 hover:bg-gray-50 disabled:hover:bg-white'
      }
      onClickButton={handleOnClick}
      disableButton={user.internal_options?.links_accounts?.stripe?.enabled === false}
      active={(!platform && user.internal_options?.links_accounts?.stripe?.enabled !== false) || platform === "stripe"}
      //active={!platform ? true : isConnectActive && platform === 'stripe'}
      tooltipText={
        isConnectActive && platform === 'stripe'
          ? 'Tu cuenta está conectada con stripe, si deseas realizar algún cambio contacta con soporte'
          : ''
      }
      loading={fetching}
      isConnected={isConnectActive && platform === 'stripe'}
    />
  );
};

export default StripeConnectButton;

import {AxiosApi} from "../AxiosBase";

// Helpers
import {getCookie} from "helpers/handleCookies";

const AxiosReSendVerifyEmail = async () => {
    const userToken = getCookie("token");

    if (!userToken) return {error: "No estas logeado"}

    try {
        const {data} = await AxiosApi.get("/auth/resend-email", {
            headers: {
                "Authorization": `Bearer ${userToken}`
            }
        });

        if (data.message !== "OK") return {error: data.message}

        return {success: "PRO"}
    }

    catch(e) {
        console.log(e);
        console.log("AxiosReSendVerifyEmail() Error");
        return {error: "Error al conectarse con el servidor"}
    }


}

export default AxiosReSendVerifyEmail;
import { AxiosApi } from '../AxiosBase';

interface SendReferralEmailProps {
  email: string;
  code: string;
  username: string;
}

const  AxiosSendReferralCodeByEmail = async ({
  email,
  username,
  code
}: SendReferralEmailProps) => {
  try {
    const { data } = await AxiosApi.post(
      '/email/send-referral-code',
      {
        email,
        username,
        code
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (data.message !== 'OK')
      return { error: data.message, errorCode: data.code };

    return { data: data.data };
  } catch (e) {
    console.log(e);
    console.log('AxiosSendReferralCodeByEmail() Error');
    return { error: 'Error al conectarse con el servidor' };
  }
};

export default AxiosSendReferralCodeByEmail;
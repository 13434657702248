// Libraries
import { MicrophoneIcon } from '@heroicons/react/solid';

// Components
import WhiteButton from 'components/tailwind/buttons/WhiteButton';

interface EmptyPlayerProps {
  startRecording: () => void;
}

const EmptyPlayer = (props: EmptyPlayerProps) => {
  const { startRecording } = props;

  const onClickStartRecording = () => startRecording();

  return (
    <WhiteButton
      onClick={onClickStartRecording}
      title="Grabar audio"
      type={'button'}
      className="w-full"
      leftIcon={<MicrophoneIcon className="h-5 w-5 text-gray-400" />}
    />
  );
};

export default EmptyPlayer;

// Libraries
import { useState, useRef, Fragment } from 'react';
import toast from 'react-hot-toast';
import { GiftIcon } from '@heroicons/react/outline';
import { SupportIcon } from '@heroicons/react/solid';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Types
import { MimeTypes } from 'types/MimeTypes';

// Helpers
import uploadFileAWS from 'libs/AwsStorage';

// Request
import { AxiosApi } from 'requests/AxiosBase';

// Hooks
import { useNotification } from 'hooks/useNotification';

// Components
import FormModal from 'components/tailwind/overlays/FormModal';
import WhiteButton from 'components/tailwind/buttons/WhiteButton';
import BadgeIcon from 'components/tailwind/badges/BadgeIcon';
import SecondaryButton, {
  variantType
} from 'components/tailwind/buttons/SecondaryButton';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import LabelRequired from 'components/tailwind/inputs/LabelRequired';
import TextArea from 'components/tailwind/inputs/TextArea';
import CharacterCounter from 'components/tailwind/inputs/CharacterCounter';
import FormBody from 'components/tailwind/overlays/FormBody';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import BasicDivider from 'components/tailwind/dividers/BasicDivider';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import StaticInput from 'components/tailwind/inputs/StaticInput';
import Label from 'components/tailwind/inputs/Label';
import RectangleFileInput from 'components/tailwind/inputs/RectangleFileInput';
import CustomVideoPlayerV2 from 'components/tailwind/player/CustomVideoPlayerV2';

// Constants
const notificationStatusMap = {
  pending: 'Responder saludo',
  rejected: 'Saludo rechazado',
  done: 'Saludo respondido'
};

const dayjs = require('dayjs');
dayjs.locale(require('utils/dayjsCustomPlugins/es'));
dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('dayjs/plugin/isYesterday'));
dayjs.extend(require('utils/dayjsCustomPlugins/isBeforeYesterday'));

interface IGreetingModalProps {
  notification: any;
  setOpenModal: (openModal: boolean) => void;
  openModal: boolean;
}

const GreetingModal: React.FC<IGreetingModalProps> = (props) => {
  const { notification, setOpenModal, openModal } = props;

  const { answerGreetingNotification, rejectGreetingNotification } =
    useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [greetingFile, setGreetingFile] = useState<File>(null);
  const [message, setMessage] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const rectangleFileInputRef = useRef();

  const formik = useFormik({
    initialValues: {
      reason: ''
    },
    validationSchema: Yup.object({
      reason: Yup.string()
        .required('Este campo es requerido')
        .max(200, 'La razón no puede ser mayor a 200 caracteres')
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const { message, error } = await rejectGreetingNotification(
        values.reason,
        notification.id
      );

      if (error) {
        toast.error(error.message);
      }

      if (message === 'OK') {
        toast.success('Respuesta Rechazada con éxito');
      }

      resetForm();
      _onCloseModal();
      setLoading(false);
    }
  });

  const _onClickSendResponse = async () => {
    setLoading(true);

    let greeting: string = null;

    if (greetingFile === null) {
      setMessage('El video es obligatorio');
      return setLoading(false);
    }

    if (!greetingFile.type.includes('video')) {
      setMessage('El formato debe de ser un video');
      return setLoading(false);
    }

    const uploadResponse = await uploadGreetingVideo();
    if (uploadResponse instanceof Error) {
      setLoading(false);
      return toast.error(uploadResponse.message);
    }
    greeting = uploadResponse;

    const answerResponse = await answerGreetingNotification(
      greeting,
      notification.id
    );

    if (answerResponse.error) {
      setLoading(false);
      return toast.error(answerResponse.error);
    }

    if (answerResponse.message === 'OK') {
      toast.success('Respuesta enviada');
    }

    await AxiosApi.post('/email/greeting-response', {
      creator_id: notification.creator_id,
      greeting_id: Number(notification.id)
    });

    setLoading(false);
    cleanStates();
    setOpenModal(false);
  };

  const _onClickDeleteCurrentFile = () => {
    rectangleFileInputRef.current &&
      (rectangleFileInputRef.current as any).removeInput();
    setGreetingFile(null);
  };

  const uploadGreetingVideo = async () => {
    return await uploadFileAWS({
      contentType: greetingFile.type as MimeTypes,
      file: greetingFile,
      folder: 'greetings',
      metadata: {
        process: 'answerGreeting',
        name: 'greeting',
        userId: notification.creator_id
      },
      setUploadProgress: setUploadProgress
    });
  };

  const _onCloseModal = () => {
    cleanStates();
    setShowReject(false);
    setTimeout(() => {
      setOpenModal(false);
    }, 300);
  };

  const cleanStates = () => {
    setUploadProgress(0);
    setMessage('');
    setGreetingFile(null);
  };

  return (
    <FormModal
      open={openModal}
      setOpen={_onCloseModal}
      title={
        showReject
          ? 'Rechazar solicitud'
          : notificationStatusMap[notification.status]
      }
    >
      {showReject ? (
        <form onSubmit={formik.handleSubmit}>
          <FormBody>
            <div>
              <Label title="Fecha" className="mb-1" />
              <StaticInput
                value={dayjs(notification.createdAt).format('DD/MM/YYYY HH:mm')}
              />
            </div>

            <div className="mt-4">
              <Label title="Para" className="mb-1" />
              <StaticInput
                value={notification?.fan_data?.name || notification.name}
              />
              {notification.isGift && (
                <BadgeIcon
                  text="Esto es un regalo"
                  Icon={<GiftIcon className="h-4 w-4 text-white" />}
                  color="primary"
                  className="mt-2"
                />
              )}
            </div>

            <div className="mt-4">
              <Label title="Instrucciones" className="mb-1" />
              <StaticInput value={notification.instructions} type="textArea" />
            </div>

            <BasicDivider className="my-4" />

            <div>
              <LabelRequired
                name="reason"
                title="Motivo de rechazo"
                isRequired={true}
              />
              <div className="mt-2">
                <TextArea
                  name="reason"
                  id="reason"
                  placeholder="Escribe el motivo de por que estas rechazando esta solicitud."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reason}
                  error={formik.errors.reason && formik.touched.reason}
                  rows={4}
                  className="mb-2"
                  maxLength={230}
                />
                <CharacterCounter
                  value={formik.values.reason}
                  maxLength={230}
                />
              </div>
              {formik.errors.reason && formik.touched.reason && (
                <ErrorMessage errorText={formik.errors.reason} />
              )}
            </div>

            <div className="mt-4 text-sm text-gray-500 ">
              Esta acción es irreversible. El rechazo de la solicitud pasará a
              revisión de nuestro equipo para determinar si viola nuestros
              Términos de servicio.
            </div>
          </FormBody>

          <FormFooter>
            <WhiteButton
              title="Cancelar"
              onClick={_onCloseModal}
              disabled={formik.isSubmitting}
              className="px-5"
            />
            <SecondaryButton
              type="submit"
              title="Rechazar"
              className="w-40"
              disabled={!formik.isValid || !formik.dirty}
              variant={variantType.red}
              loading={formik.isSubmitting}
              loadingChildren="Rechazando"
            />
          </FormFooter>
        </form>
      ) : (
        <Fragment>
          <FormBody>
            <div>
              <Label title="Fecha" className="mb-1" />
              <StaticInput
                value={dayjs(notification.createdAt).format('DD/MM/YYYY HH:mm')}
              />
            </div>

            <div className="mt-4">
              <Label title="Para" className="mb-1" />
              <StaticInput
                value={notification?.fan_data?.name || notification.name}
              />
              {notification.isGift && (
                <BadgeIcon
                  text="Esto es un regalo"
                  Icon={<GiftIcon className="h-4 w-4 text-white" />}
                  color="primary"
                  className="mt-2"
                />
              )}
            </div>

            <div className="mt-4">
              <Label title="Instrucciones" className="mb-1" />
              <StaticInput value={notification.instructions} type="textArea" />
            </div>

            {notification.status === 'pending' && (
              <div className="mt-4">
                <Label title="Subir archivo" className="mb-2" />
                <RectangleFileInput
                  file={greetingFile}
                  fileUploadProgress={uploadProgress}
                  previsualize={true}
                  ref={rectangleFileInputRef}
                  setFile={setGreetingFile}
                  setErrorMessage={setMessage}
                  showRemoveInput={false}
                  type="video"
                />
                {message && <ErrorMessage errorText={message} />}

                {greetingFile && greetingFile.type.includes('video') ? null : (
                  <>
                    <BasicDivider className="mt-4 mb-6" />

                    <SecondaryButton
                      title="Rechazar solicitud"
                      onClick={() => setShowReject(true)}
                      variant={variantType.red}
                      className="w-full"
                    />
                  </>
                )}
              </div>
            )}

            {notification.status === 'done' && (
              <div className="mt-4">
                <Label title="Archivo" className="mb-2" />
                <CustomVideoPlayerV2
                  videoUrl={notification.url}
                  type="fileUpload"
                />
              </div>
            )}

            {notification.status === 'rejected' && (
              <div>
                <BasicDivider className="my-4" />
                <Label title="Motivo de rechazo" className="mb-2" />
                <StaticInput
                  value={notification.reasonOfRejection}
                  type="textArea"
                />
              </div>
            )}
          </FormBody>

          {notification.status === 'pending' && (
            <FormFooter>
              {!greetingFile && (
                <>
                  <WhiteButton
                    title="Cancelar"
                    className="px-6"
                    onClick={_onCloseModal}
                  />
                  <BasicButton
                    title="Enviar"
                    onClick={() => {}}
                    loading={loading}
                    disabled={true}
                    className="w-40"
                  />
                </>
              )}

              {greetingFile && greetingFile.type.includes('video') && (
                <>
                  <SecondaryButton
                    title="Eliminar video"
                    onClick={_onClickDeleteCurrentFile}
                    variant={variantType.red}
                    disabled={loading}
                  />
                  <BasicButton
                    title="Enviar"
                    onClick={() => _onClickSendResponse()}
                    loading={loading}
                    loadingChildren="Enviando"
                    className="w-40"
                  />
                </>
              )}
            </FormFooter>
          )}

          {notification.status === 'rejected' && (
            <FormFooter>
              <WhiteButton
                title="Contactar al soporte"
                id="iw_supportBtn"
                size="medium"
                className="w-full"
                leftIcon={<SupportIcon className="h-5 w-5 text-gray-500" />}
              />
            </FormFooter>
          )}
        </Fragment>
      )}
    </FormModal>
  );
};

export default GreetingModal;

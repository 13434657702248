const AppNotifications: React.FC = () => {
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div
        id="notifications-portal"
        className="flex w-full flex-col items-center space-y-4 sm:items-end"
      ></div>
    </div>
  );
};

export default AppNotifications;
